import React from 'react';
import styled from 'styled-components';

const GradientContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 15px 0px 0px;
`;

const Gradient = styled.div`
  background: ${({ fromColor, toColor }) => `linear-gradient(${fromColor}, ${toColor})`};
  height: 200px;
  width: 20px;
  border-radius: 5px;
  margin: 10px 0;
`;

const Label = styled.div`
  margin: 5px 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: #333;
`;

const LinearGradient = ({ colorRange, min, max }) => {
  return (
    <GradientContainer>
      <Label>{min}</Label>
      <Gradient fromColor={colorRange[0]} toColor={colorRange[colorRange.length - 1]} />
      <Label>{max}</Label>
    </GradientContainer>
  );
};

export default LinearGradient;
