import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  padding: 10px;
  box-sizing: border-box;
  font-family: Open Sans;
`;
const EachRow = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  align-items: center;
  justify-items: center;
  gap: 10px;
`;

const Star = styled.div`
  font-size: 14px; /* Adjust font size */
  color: #636363;
  text-align: center;
  width: 100%;
  font-family: Montserrat;
`;

const PercentageBar = styled.div`
  display: flex;
  border-radius: 2px;
  background: ${(props) => props.color};

  width: ${(props) => props.width};
  height: 30px;
  padding: 5px 25px;
  justify-content: center;
  box-sizing: border-box;

  color: #000;
  font-weight: 400;
  position: relative;

  ${(props) => {
    if (parseInt(props.actualWidth) < 0) {
      return `&:after {
            content:'${props.actualWidth}${props.type}';
            position: absolute;
            left:110%;
            color:#222;
          }`;
    }
  }}
`;


const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;

  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FunnelGraph = ({ data, maxWidth = '100%', type = '' }) => {
  const sortedData = data
    .filter((el) => el.value > 0)
    .map((e1) => {
      return {
        name: e1.name,
        value: parseFloat(e1.value),
        fill: e1.fill
      }
    })
    .sort((a, b) => b.value - a.value);

  const maxValue = Math.max(...sortedData.map((item) => item.value));
  const multiplier = 100 / maxValue;



  const tooltipFormatter = (value) => {
    return ` ${typeof value === 'number' && value !== 0 && value}`;
  };


  return (
    <Wrapper maxWidth={maxWidth} className="pdf-page" style={{ pageBreakInside: 'avoid' }}>
      {
        sortedData && sortedData?.length ?
          sortedData.map((value, name) => (
            <EachRow key={name}>
              <Star>{value.name}</Star>
              <PercentageBar
                width={Math.floor(value.value * multiplier) + '%'}
                actualWidth={value.value}
                type={type}
                color={value.fill || '#353cf7'}
              >
                {value.value > 0 && tooltipFormatter(value.value) + type}
              </PercentageBar>
            </EachRow>
          ))
          :

          <NoDataWrapper>
            No Data Available
          </NoDataWrapper>

      }
    </Wrapper>
  );
};

export default FunnelGraph;
