import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  text-align: left;
  font-family: Open Sans;
`;

export const HeaderWrapper = styled.div`
  padding: 20px 0 20px 25px;
  display: flex;
  flex-direction: column;

  position: relative;
`;
export const Span1 = styled.span`
  color: #636363;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
`;

export const Span2 = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  margin: 8px 0;
`;

export const ChannelsText = styled.div`
  display: flex;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  font-weight: 600;
  margin-right: 8px;
`;

export const TwitterText = styled.p`
  display: flex;
  align-items: center;
  gap: 2px;
  color: rgba(0, 0, 0, 0.84);
  margin: 0;

  & svg {
    width: 20px;
    height: 20px;
    padding-top: 5px;
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  padding-bottom: 10px;
  & .created_at {
    font-size: 14px;
  }
  & .button {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    background: #353cf7;
    color: #fff;

    height: 35px;
    padding: 5px 20px;
    border-radius: 10px;

    border: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    & > span {
      margin-left: 10px;
    }
    &:active {
      transform: scale(0.98);
    }
  }
  & .cancel {
    background: #fff;
    color: #353cf7;
    border: 1px solid #353cf7;
  }

  & .buttonContainer {
    display: flex;
    gap: 10px;
  }
`;
export const DateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;

  font-size: 15px;
  font-weight: 600;

  & input {
    box-shadow: 0px 0px 8px 0px #a2a2a23d;
    border: 1px solid #d9d9d9;
    margin-top: 5px;
    margin-left: -3px;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    color: #636363;

    padding: 5px 20px;
    border-radius: 5px;
  }
`;
export const CreateTopic = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  background: #353cf7;
  color: #fff;

  padding: 10px 20px;
  border-radius: 20px;

  border: 0px;

  &:active {
    transform: scale(0.98);
  }
`;

export const Icon = styled.div``;

export const StyledContainer = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`;

export const QueryPara = styled.div`
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  margin-top: 10px;
`;

export const TabHeading = styled.div`
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;

  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5px;

  & > div {
    color: #353cf7;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  //Active Tab (Monthly or Annual Plan)
  & .activePlanTab {
    color: #353cf7;
    border-bottom: 2px solid #353cf7;
    font-weight: 600;
  }
`;
