import tenantAxiosInstance from '../utils/tenantAxiosInstance';
const API_URL = process.env.REACT_APP_BACKEND_URL;

const fetchAllMailList = async (tenant_id) => {
  return tenantAxiosInstance
    .get(API_URL + `mail/list/${tenant_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const fetchAllMailListCI = async (tenant_id) => {
  return tenantAxiosInstance
    .get(API_URL + `competitive_intelligence/mail/list/${tenant_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const shareReport = async (body, job_id, newReportData, tenant_id, user_id) => {
  return tenantAxiosInstance
    .post(API_URL + `share/report/${tenant_id}/${user_id}`, {
      job_id: job_id + '',
      render_field_string: JSON.stringify(body),
      report_json_string: JSON.stringify(newReportData)
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const shareReportCI = async ({body, ci_id, newReportData,title_name,subtitle, tenant_id, user_id}) => {
  return tenantAxiosInstance
    .post(API_URL + `competitive_intelligence/share/report/${tenant_id}/${user_id}`, 
    {
      "title_name":title_name,
      "competitive_intelligence_id":ci_id,
      "render_field_string":"{}",
      "report_json_string":JSON.stringify(newReportData),
      "subtitle":subtitle
  }
  
  
  
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const shareReportChatter = async (body, job_id, repData, tenant_id, user_id) => {
  return tenantAxiosInstance
    .post(API_URL + `share/report/${tenant_id}/${user_id}`, {
      job_id: job_id + '',
      render_field_string: JSON.stringify(body),
      report_json_string: JSON.stringify(repData)
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};



const getReport = async (tenant_id, email_id) => {
  return tenantAxiosInstance
    .get(API_URL + `mail/report/${tenant_id}/${email_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const getReportCI = async (tenant_id, email_id) => {
  return tenantAxiosInstance
    .get(API_URL + `competitive_intelligence/mail/report/${tenant_id}/${email_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};
const deleteReportCI = async (tenant_id, email_id) => {
  return tenantAxiosInstance
    .put(API_URL + `competitive_intelligence/mail/softdelete/${tenant_id}/${email_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const deleteReport = async (tenant_id, email_id) => {
  return tenantAxiosInstance
    .put(API_URL + `mail/softdelete/${tenant_id}/${email_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const hardDeleteReport = async (tenant_id, email_id) => {
  return tenantAxiosInstance
    .put(API_URL + `mail/harddelete/${tenant_id}/${email_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const hardDeleteReportCI = async (tenant_id, email_id) => {
  return tenantAxiosInstance
  
    .put(API_URL + `competitive_intelligence/mail/harddelete/${tenant_id}/${email_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const getPosts = async (tenant_id) => {
  return tenantAxiosInstance

    .get(API_URL + `all_posts/chatter/topic/${tenant_id}`)
    .then((response) => {

      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};


const getSearchPosts = async ({tenant_id,page,query,sortorder,column_name,channel}) => {


  return tenantAxiosInstance
    .post(API_URL + `all_posts/searchsort/${tenant_id}`, {
     
        "query":query,
        "page":page,
        "column_name":column_name,
        "sortorder":sortorder,
        "channel":channel
    
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error;
    })
}

const SentinelHubService = {
  fetchAllMailList,
  fetchAllMailListCI,
  shareReport,
  shareReportCI,
  shareReportChatter,
  getReport,
  getReportCI,
  deleteReport,
  deleteReportCI,
  hardDeleteReport,
  hardDeleteReportCI,
  getPosts,
  getSearchPosts
};

export default SentinelHubService;
