
import styled from 'styled-components';


export const TextWrapper = styled.div`
        margin-bottom: 12px;
        font-size: 15px;
        font-weight: 400;
`;

export const TableWrapper = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
`;

export const ThHeader = styled.th`
    background: rgba(53, 60, 247, 1);
    font-weight: 400;
    /* background-color: #f4f4f4; */
    color: white;
    text-align: left;
    padding: 10px;
`;
export const ThHeader2 = styled.th`
    background: rgba(53, 60, 247, 1);
    font-weight: 400;
    /* background-color: #f4f4f4; */
    color: white;
    text-align: center;
    padding: 10px;

`;


export const TdCells = styled.th`
padding: 10px;
border: 1px solid rgba(162, 162, 162, 1);
font-size: 15px;
font-weight: 500;
color: black;
`;

export const TdCells2 = styled.th`
padding: 10px;
border: 1px solid rgba(162, 162, 162, 1);
text-align:center;
font-size: 15px;
font-weight: 500;
color: black;
`;
export const StyledRow = styled.tr`
 
  
  &:hover {
    background-color: #f1f1f1;
  }
`;