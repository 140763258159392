import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import BarGraph from '../../../../../Common/BarGraph/BarGraph';
import {
    ExportIcon,
    GreenEllipseIcon,
    NeutralEllipseIcon,
    NeutralIcon,
    RedEllipseIcon,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
import SentimentDrillDown from '../../../../../components/SentimentsDrilldown/SentimenDrillDOwn';

import {
    Wrapper,
    BoxWrapper,
    HeadingSpan,
    PieDiv,
    PieWrapper,
    HeadSpan3,
    DivWrapper,
    HeadingSpan1,
    FlexWrapper,
    ContentWrapper5,
    UserSpan,
    ReviewsWrapper,
    ReviewContainer,
    Container,
    Container1,
    ReviewContainer1,
    ReviewContainer2,
    TopPostWrapper,
    Circle,
    PositiveCircle,
    NeutralCircle,
    NegativeCircle,
    TopRepostWrapper,
    PostCountWrapper,
    Footer
} from './SentimentsTab.styled.js'
import { useParsedEndDate, useParsedStartDate } from '../../../../../utils/ParseDate.jsx';

function SentimentsTab({
    chatterData,
    plan,
    selectedReport = {},
    filteredData,
    filterOption,
    view,
    filteredStartDate,
    filteredEndDate,
    reset,
    child,
    hideDropdown,
    
  startDate = '',
  endDate = '',
  formatValue
}) {
    const [userType, setUserType] = useState('verified');
    const [sentimentGraph, setSentimentGraph] = useState('DonutChart');

    const componentRef = useRef(null);

    let planSpecificData;
    if (selectedReport) {
        if (plan === 'BrandAstraPro') {
            planSpecificData = selectedReport?.data;
        } else if (plan === 'BrandEssence') {
            planSpecificData = selectedReport?.data;
            // planSpecificData = selectedReport?.BrandAstraEssence;
        } else if (plan === "BrandAstraElite") {
            planSpecificData = selectedReport?.BrandAstraElite;
        } else {

            planSpecificData = selectedReport?.data;
        }
    } else if (chatterData) {
        if (plan === 'BrandAstraPro') {
            planSpecificData = chatterData?.data;
        } else if (plan === 'BrandEssence') {
            planSpecificData = chatterData?.data;
            // planSpecificData = chatterData?.BrandAstraEssence;
        } else if (plan === "BrandAstraElite") {
            planSpecificData = chatterData?.BrandAstraElite;
        } else {
            planSpecificData = chatterData?.data;

        }
    }

    const averageRating =
        userType === 'verified'
            ? planSpecificData?.sentiments?.verified_purchase_impact
                .verified_average_rating
            : planSpecificData?.sentiments?.verified_purchase_impact
                .non_verified_avg_rating;

    const handleOptionClick = (option) => {
        setUserType(option);
    };

    const formattedDate = useParsedStartDate(false, '', startDate, formatValue);
    const endDateParsed = useParsedEndDate(false, '', endDate, formatValue);
    return (
        <Wrapper>
            {
                child?.length > 0 && child[0].value == true &&
                <SentimentDrillDown
                    className="pdf-page"
                    style={{
                        pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
                    }}
                    data={filteredData ? filteredData?.sentiments_tab : planSpecificData?.sentiments_tab}
                    startDate={formattedDate
                    }
                    endDate={endDateParsed
                    }
                    filterOption={filterOption}
                    view={view}
                    channelName={selectedReport?.channel}
                    hideDropdown={hideDropdown}
                />
            }

            {
                child?.length > 0 && child[1].value == true &&

                <>
                    {chatterData?.channel === 'amazon' || selectedReport?.channel === 'amazon' ? (
                        <TopRepostWrapper className="pdf-page"  style={{ 
                            pageBreakBefore: 'auto',pageBreakAfter:'auto',pageBreakInside:'avoid',}}>
                            <p className='title'>Top Helpful Reviews</p>
                            <PostCountWrapper>
                                <div>
                                    <PositiveCircle />
                                    {`Total Positive Liked Reviews - ${filteredData
                                        ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                            ?.no_of_positive_helpful_reviews
                                        : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                            ?.no_of_positive_helpful_reviews
                                        }`}
                                </div>
                                <div>
                                    <NegativeCircle />
                                    Total Negative Helpful Reviews -{' '}
                                    {filteredData
                                        ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                            ?.no_of_negative_helpful_reviews
                                        : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                            ?.no_of_negative_helpful_reviews}
                                </div>
                                <div>
                                    <NeutralCircle />
                                    Total Neutral Helpful Reviews -{' '}
                                    {filteredData
                                        ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                            ?.no_of_neutral_helpful_reviews
                                        : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                            ?.no_of_neutral_helpful_reviews}
                                </div>
                            </PostCountWrapper>
                            {

                            }
                            <TopPostWrapper>
                                <div className='positiveReview'>
                                    <PositiveCircle />
                                    <div className='reviewText'>
                                        Most Positive Helpful Review
                                        <br />
                                        <br />
                                        {filteredData
                                            ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_positive_helpful_reviews?.most_positive_helpful_review
                                            : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_positive_helpful_reviews?.most_positive_helpful_review}
                                    </div>
                                    <div className='reviewCount'>
                                        {filteredData
                                            ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_positive_helpful_reviews?.most_positive_helpful_review_count
                                            : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_positive_helpful_reviews?.most_positive_helpful_review_count}
                                        <div className='peopleLiked'>
                                            People found <br /> this helpful
                                        </div>
                                    </div>
                                </div>
                                <div className='negativeReview'>
                                    <NegativeCircle />
                                    <div className='reviewText'>
                                        Most Negative Helpful Review
                                        <br />
                                        <br />
                                        {filteredData
                                            ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                                ?.helpful_reviews?.most_negative
                                            : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_negative_helpful_reviews?.most_negative_helpful_review}
                                    </div>
                                    <div className='reviewCount'>
                                        {filteredData
                                            ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_negative_helpful_reviews?.most_negative_helpful_review_count
                                            : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_negative_helpful_reviews?.most_negative_helpful_review_count}
                                        <div className='peopleLiked'>
                                            People found <br /> this helpful
                                        </div>
                                    </div>
                                </div>
                                <div className='neutralReview'>
                                    <NeutralCircle />
                                    <div className='reviewText'>
                                        Most Neutral Helpful Review
                                        <br />
                                        <br />
                                        {filteredData
                                            ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_neutral_helpful_reviews?.most_neutral_helpful_review
                                            : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_neutral_helpful_reviews?.most_neutral_helpful_review}
                                    </div>
                                    <div className='reviewCount'>
                                        {filteredData
                                            ? filteredData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_neutral_helpful_reviews?.most_neutral_helpful_review_count
                                            : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                                                ?.top_neutral_helpful_reviews?.most_neutral_helpful_review_count}
                                        <div className='peopleLiked'>
                                            People found <br /> this helpful
                                        </div>
                                    </div>
                                </div>
                            </TopPostWrapper>
                            <Footer>
                                This data is observed from{' '}
                                {formattedDate}{' '}
                                to{' '}
                                {endDateParsed}
                            </Footer>
                        </TopRepostWrapper>
                    ) : (
                        <TopRepostWrapper className="pdf-page"  style={{ 
                            pageBreakBefore: 'auto',pageBreakAfter:'auto',pageBreakInside:'avoid',}}>
                            <p className='title'>Top Liked Reviews</p>

                            <PostCountWrapper>
                                <div>
                                    <PositiveCircle />
                                    {`Total Positive Liked Reviews - ${filteredData
                                        ? filteredData?.sentiments?.top_liked_reviews
                                            .total_positive_liked_reviews
                                        : planSpecificData?.sentiments_tab?.top_liked_reviews
                                            ?.total_positive_liked_reviews
                                        }`}
                                </div>
                                <div>
                                    <NegativeCircle />
                                    Total Negative Liked Reviews -{' '}
                                    {filteredData
                                        ? filteredData?.sentiments?.top_liked_reviews
                                            ?.total_negative_liked_reviews
                                        : planSpecificData?.sentiments_tab?.top_liked_reviews
                                            ?.total_negative_liked_reviews}
                                </div>
                                <div>
                                    <NeutralCircle />
                                    Total Neutral Liked Reviews -{' '}
                                    {filteredData
                                        ? filteredData?.sentiments?.top_liked_reviews
                                            ?.total_neutral_liked_reviews
                                        : planSpecificData?.sentiments_tab?.top_liked_reviews
                                            ?.total_neutral_liked_reviews}
                                </div>
                            </PostCountWrapper>
                            <TopPostWrapper>
                                <div className='positiveReview'>
                                    <PositiveCircle />
                                    <div className='reviewText'>
                                        Most Positive Liked Review
                                        <br />
                                        <br />
                                        {filteredData
                                            ? filteredData?.sentiments?.top_liked_reviews
                                                ?.top_positive_liked_reviews?.most_positive_liked_review || 'No Data Avaliable'
                                            : planSpecificData?.sentiments_tab?.top_liked_reviews
                                                ?.top_positive_liked_reviews?.most_positive_liked_review || 'No Data Avaliable'}
                                    </div>
                                    <div className='reviewCount'>
                                        {filteredData
                                            ? filteredData?.sentiments?.top_liked_reviews
                                                ?.top_positive_liked_reviews?.most_positive_liked_review_count
                                            : planSpecificData?.sentiments_tab?.top_liked_reviews
                                                ?.top_positive_liked_reviews?.most_positive_liked_review_count}
                                        <div className='peopleLiked'>
                                            People Liked
                                        </div>
                                    </div>
                                </div>
                                <div className='negativeReview'>
                                    <NegativeCircle />
                                    <div className='reviewText'>
                                        Most Negative Liked Review
                                        <br />
                                        <br />
                                        {filteredData
                                            ? filteredData?.sentiments?.top_liked_reviews
                                                ?.top_negative_liked_reviews?.most_negative_liked_review || 'No Data Avaliable'
                                            : planSpecificData?.sentiments_tab?.top_liked_reviews
                                                ?.top_negative_liked_reviews?.most_negative_liked_review || 'No Data Avaliable'}
                                    </div>
                                    <div className='reviewCount'>
                                        {filteredData
                                            ? filteredData?.sentiments?.top_liked_reviews
                                                ?.top_negative_liked_reviews?.most_negative_liked_review_count
                                            : planSpecificData?.sentiments_tab?.top_liked_reviews
                                                ?.top_negative_liked_reviews?.most_negative_liked_review_count}
                                        <div className='peopleLiked'>
                                            People Liked
                                        </div>
                                    </div>
                                </div>
                                <div className='neutralReview'>
                                    <NeutralCircle />
                                    <div className='reviewText'>
                                        Most Neutral Liked Review
                                        <br />
                                        <br />
                                        {filteredData
                                            ? filteredData?.sentiments?.top_liked_reviews
                                                ?.top_neutral_liked_reviews?.most_neutral_liked_review || 'No Data Avaliable'
                                            : planSpecificData?.sentiments_tab?.top_liked_reviews
                                                ?.top_neutral_liked_reviews?.most_neutral_liked_review || 'No Data Avaliable'}
                                    </div>
                                    <div className='reviewCount'>
                                        {filteredData
                                            ? filteredData?.sentiments?.top_liked_reviews
                                                ?.top_neutral_liked_reviews?.most_neutral_liked_review_count
                                            : planSpecificData?.sentiments_tab?.top_liked_reviews
                                                ?.top_neutral_liked_reviews?.most_neutral_liked_review_count}
                                        <div className='peopleLiked'>
                                            People Liked
                                        </div>
                                    </div>
                                </div>
                            </TopPostWrapper>
                            <Footer>
                                This data is observed from{' '}
                                {formattedDate}{' '}
                                to{' '}
                                {endDateParsed}
                            </Footer>
                        </TopRepostWrapper>
                    )}
                </>
            }
        </Wrapper>
    );
}

export default SentimentsTab;
