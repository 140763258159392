import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { logout } from '../../../store/redux/slices/authSlice';

import {
  NotificationIcon,
  LogoutIcon,
} from '../../../Common/SvgIcon/CommonSvgIcons';
import UserProfileIcon from '../../../Common/Avatar/Avatar.jsx';
import { useSelector } from 'react-redux';
import {
  LogoutPopUP,
  Triangle,
} from '../../../Common/CommonStyledElement/CommonStyledElement';

const Wrapper = styled.div`
  height: 60px;
  background: #353cf7;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  & > * {
    display: flex;
    gap: 12px;
    padding: 10px;
  }

  // Style for BA LOGO
  & > :nth-child(1) {
    margin-left: 15px;
    img {
      width: 50px;
      cursor: pointer;
    }
  }

  // Style for Tabs
  & > :nth-child(2) {
    & > * {
      width: 18px;
      height: 18px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      cursor: pointer;

      & svg {
        width: 17px;
      }
    }
  }
  & > :nth-child(3) {
    justify-content: end;
    align-items: center;
    margin-right: 20px;

    // Notification Icon
    & > :nth-child(1) {
      width: 14px;
      height: 14px;
      padding: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      cursor: pointer;
      border-radius: 50%;
      position: relative;

      & > span {
        position: absolute;
        top: -4px;
        right: -4px;
        background: #fc6758;
        width: 18px;
        height: 18px;
        font-size: 12px;
        border-radius: 50%;
        color: #fff;
      }
    }
  }

  // Style for active tab navigation
  .activeTab {
    background: #fff;
    width: auto;
    border-radius: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 600;
    transition: all 50ms linear;
    & svg {
      width: 17px;
    }
  }
`;

function BrandAstraAdminHeader() {
  const navigate = useNavigate();
  const [logoutPopupVisible, setLogoutPopupVisible] = useState(false);
  const popUpRef = useRef(null);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  //to hide logout popup on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setLogoutPopupVisible(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  //Show PopUp on user icon click
  const handleUserIconClick = () => {
    setLogoutPopupVisible(!logoutPopupVisible);
  };

  const logoutHandler = () => {
    setLogoutPopupVisible(!logoutPopupVisible);
    dispatch(logout());
    navigate('/login');
    window.location.reload();
  };
  return (
    <Wrapper ref={popUpRef}>
      <div onClick={() => navigate('/login')}>
        <img
          src={`${process.env.PUBLIC_URL}/images/Brand Astra_BA.png`}
          alt='logoImg'
        />
      </div>
      <div></div>
      <div>
        <div>
          {NotificationIcon}
          <span>1</span>
        </div>
        <div onClick={handleUserIconClick} data-testid='userIcon'>
          <UserProfileIcon
            picture={user?.profile_pic || ''}
            userName={user?.first_name || ''}
            bgColor={user?.role_id === 1 ? '#00D5D0' : '#00D5D0'}
          />
        </div>
        {logoutPopupVisible && (
          <>
            <LogoutPopUP>
              <div className='userPic'>
                <UserProfileIcon
                  picture={user?.profile_pic || ''}
                  userName={user?.first_name || ''}
                  bgColor={user?.role_id === 1 ? '#00D5D0' : '#00D5D0'}
                />
              </div>
              <div className='userName'>{user?.user_name}</div>
              <div>{user?.email_id}</div>
              <div className='line'></div>
              <div className='myProfile_logout' onClick={logoutHandler}>
                {LogoutIcon}
                Logout
              </div>
            </LogoutPopUP>
            <Triangle />
          </>
        )}
      </div>
    </Wrapper>
  );
}

export default BrandAstraAdminHeader;
