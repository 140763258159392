
import styled from 'styled-components';



export const Wrapper = styled.div`
  padding: 30px;

  & .title {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
  }
`;

export const EmotionAnalysisWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;
  pageBreakInside: avoid;
  pageBreakBefore: always;
  
  .titleContainer {
    margin: 25px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .correlationGraphContainer {
    padding: 1px 20px 20px 20px;
  }

  & > p:first-child {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    margin: 20px 0px 0px 10px;
  }
`;

export const FilteredGraphContainer =  styled.div`
flex: 1;
display: flex;
width: 100%;
margin: 20px 0px 20px 50px;
align-items: center;
`;

export const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  margin: 28px 0 20px 0;
  text-align: center;
`;

export const FunnelGraphContaining = styled.div`
  width: 80%;
  height: 100%;
  margin: 20px;
`;

export const GraphContainer = styled.div`
  margin: 10px 0px 0px 20px;
`;