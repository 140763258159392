import React, { useState, useEffect, useRef } from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
} from 'recharts';

const AreaGraph = ({ data, height = 260, ylabel}) => {
  const parentRef = useRef(null);
  const [numericWidth, setNumericWidth] = useState(400);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const parentWidth = parentRef?.current?.offsetWidth;
      setNumericWidth(parentWidth);
    });

    resizeObserver.observe(parentRef?.current);

    return () => resizeObserver.disconnect();
  }, []);
  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%', paddingBottom: '3%' }}>
      <AreaChart width={numericWidth} height={height} data={data}
       margin={{ top: 10, right: 30, left: 0, bottom: 12 }} // Adjust margins here
     
      >
        <XAxis
          dataKey='date'
          label={{
            value: 'Timeline',
            position: 'insideBottom',
            fill: 'black',
            offset: -12,
            fontWeight: 'bold',
            fontSize: 14,
          }}
          interval={data.length > 20 ? Math.ceil(data.length / 20) : 0}
          dy={4}
          dx={-20}
          tick={{ fontSize: 9, angle: -10 }}
        />
        <YAxis
          type='number'
          /* domain={[minYValue - domainPadding, maxYValue + domainPadding]} */
          tick={{ fontSize: 12 }}
          tickCount={6}
        >
          <Label
            value={ylabel}
            offset={15}
            fontSize={16}
            position='insideLeft'
            angle={-90}
            fontWeight={500}
            fill='#222'
          />
        </YAxis>
        <Tooltip />
        <Area dataKey={ylabel?.toLowerCase() || 'reviews'} fill='#6F62FE' stroke='#6F62FE' />
      </AreaChart>
    </div>
  );
};

export default AreaGraph;
