import styled from 'styled-components';

export const ItemListWrapper = styled.div`
  width: 100%;
  align-items: center;
  box-sizing: border-box;

  ${({ exclusion }) =>
    !exclusion &&
    `
    margin-top:10px;
  border-radius: 20px;
  border: 1px solid rgba(99, 99, 99, 0.41);
  background: #fff;

  padding: 30px 30px 20px 20px;

  // box-shadow: 0px 0px 8px 0px #a2a2a2;
  & > div:not(:last-child) {
    border-bottom: 1px solid rgba(99, 99, 99, 0.49);
  }
  `}

  ${({ exclusion }) =>
    exclusion &&
    `
  padding:10px 0;
`}
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ exclusion }) =>
    !exclusion &&
    `
    padding: 20px;
`}
  span {
    color: #000;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 600;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.16px;
  }

  ${({ exclusion }) =>
    exclusion &&
    `
      padding:5px 0;
      span {
      font-weight: 500;
      }
`}
`;
