import axiosInstance from '../utils/axiosInstance';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const getAllUserOf_A_Tenant = (tenant_id) => {
  return axiosInstance
    .get(API_URL + `get/userall/${tenant_id}`)
    .then((response) => {
      return response.data;
    });
};

const createUser = (body) => {
  return axiosInstance
    .post(API_URL + 'tenant/createuser', body)
    .then((response) => {
      return response.data;
    });
};

const deleteUser = ({ tenant_id, user_id }) => {
  return axiosInstance
    .delete(API_URL + `tenant/deleteuser/${tenant_id}/${user_id}`)
    .then((response) => {
      return response.data;
    });
};

const tenanTBasicDetails = (tenant_id) => {
  return axiosInstance
    .get(API_URL + `tenantmaster/gettenant/company/${tenant_id}`)
    .then((response) => {
      return response.data;
    });
};

const updateTenanTBasicDetails = (body, tenant_id) => {
  return axiosInstance
    .put(
      API_URL + `orchestration/tenant_management_settings/${tenant_id}`,
      body
    )
    .then((response) => {
      return response.data;
    });
};

const tenanTBasicDetails_extras = (tenant_id) => {
  return axiosInstance
    .get(API_URL + `orchestration/tenant_management_basic/${tenant_id}`)
    .then((response) => {
      return response.data;
    });
};

const personalDetailsOfTenantUser = (tenant_id, user_id) => {
  return axiosInstance
    .get(API_URL + `tenant/getuser/details/${tenant_id}/${user_id}`)
    .then((response) => {
      return response.data;
    });
};

const updatePersonalDetailTenantUser = (tenant_id, user_id, body) => {
  return axiosInstance
    .put(API_URL + `tenant/updateuser/${tenant_id}/${user_id}`, body)

    .then((response) => {
      return response.data;
    });
};

const ExecutivePortalTenantManagement = {
  getAllUserOf_A_Tenant,
  createUser,
  deleteUser,
  tenanTBasicDetails,
  tenanTBasicDetails_extras,
  updateTenanTBasicDetails,
  personalDetailsOfTenantUser,
  updatePersonalDetailTenantUser,
};

export default ExecutivePortalTenantManagement;
