import React, { useEffect, useRef, useState } from 'react';
import {
  Wrapper,
  HeaderWrapper,
  Span1,
  Span2,
  QueryPara,
  ChannelsText,
  TwitterText,
  StyledContainer,
  TabHeading,
  Icon,
  DownloadWrapper,
} from './ViewReport.styled';
import html2pdf from 'html2pdf.js';
import { FacebookLogoSmall, InstagramIconSmall, X_icon, download_icon } from '../../Common/SvgIcon/CommonSvgIcons';
import { useSelector, useDispatch } from 'react-redux';
import Sentiments from '../ActiveTopic/Sentiments/SentimentsTab';
import Insights from '../ActiveTopic/Insights/InsightsTab';
import ReviewsTab from '../ActiveTopic/ReviewsTab/ReviewsTab';
import Overview from '../ActiveTopic/OverViewTab/OverView';
import { formatDate, formatDateFilter } from '../../utils';

import {
  fetchCreatedTopic,
  createNewTopic,
  activeTopicList,
} from '../../store/redux/slices/topicSlice';

import { getReport } from '../../store/redux/slices/sentinelHubSlice';

import { useNavigate } from 'react-router-dom';
import DownloadFile from './Download/Download';
import OverviewTabInsta from '../ActiveTopic/OverViewTab/OverviewTabInsta';
import OverviewTabFb from '../ActiveTopic/OverViewTab/OverviewTabFb';
import CustomAnalysisTab from './Download/ReportDownload/CustomAnalysisTab';
import ClusterAnalysisTab from './Download/ReportDownload/ClusterAnalysisTab';


function convertDateTime(inputDateTime) {
  let dtObject = new Date(inputDateTime);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let formattedDate =
    monthNames[dtObject.getMonth()] +
    ' ' +
    dtObject.getDate() +
    ' on ' +
    (dtObject.getHours() % 12 || 12) +
    ':' +
    (dtObject.getMinutes() < 10 ? '0' : '') +
    dtObject.getMinutes() +
    ' ' +
    (dtObject.getHours() >= 12 ? 'PM' : 'AM');

  return formattedDate;
}

const ViewReport = ({
  formatValue='dd-MM-yyyy',
  data,
  job_id,
  email_id,
  handleCancelClick,
  created_at,
  is_topic = true,
  hideDropdown
}) => {
  const [AnalysisFilter, setAnalysisFilter] = useState('')
  const [activeTab, setActiveTab] = useState('overViewTab');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [download, setDownload] = useState(false)
  const contentDivRef = useRef(null);

  let { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};
  // const topicPreviewData = data;
  const { createdTopicData } = useSelector((state) => state.topicBuilder);

  let { getAllReportData } = useSelector((state) => state.sentinelHub) || [];

  let render_string = '{}';
  let outlook_report = {};

  if (getAllReportData.find((el) => el.email_id === email_id)) {
    let tempData = getAllReportData.find((el) => el.email_id === email_id);
    render_string = tempData?.render_field_string;
    outlook_report = tempData?.report;
  }
  // else {
  //   dispatch(getReport(email_id));
  // }

  let fetchedData;

  const selectedTopic = createdTopicData.find(
    (topic) => topic.job_id === job_id
  );
  if (typeof job_id === 'number' && selectedTopic !== undefined) {
    topicPreviewData = selectedTopic;
    fetchedData = selectedTopic?.data;
  } else if (typeof email_id === 'string') {
    topicPreviewData = outlook_report;
  }
  fetchedData = topicPreviewData?.data;
  // fetchedData = getAllReportData?.length > 0  && getAllReportData[0]?.outlook_report;
  // }
  console.log(fetchedData, getAllReportData, "getAllReportData");

  // useEffect(() => {
  //   if (typeof job_id === 'number' && selectedTopic === undefined) {
  //     dispatch(fetchCreatedTopic(job_id)).then((res) => {
  //       fetchedData = res.payload?.data?.data;
  //     });
  //   }
  // }, [job_id]);

  useEffect(() => {
    if (getAllReportData?.length > 0 && getAllReportData?.[0]?.render_field_string) {
      try {
        const parsedFilter = JSON.parse(getAllReportData[0].render_field_string);
        setAnalysisFilter(parsedFilter);  // Set the parsed object to state
        // console.log(getAllReportData,'getAllReportData');

      } catch (error) {
        console.error('Failed to parse render_string:', error);
        setAnalysisFilter(null);
      }
    } else {
      console.warn('No valid render_string found');
      setAnalysisFilter(null);
    }
  }, [getAllReportData]);


  const overView_tab = getAllReportData?.[0]?.report?.data?.overview_tab || {};
  const sentiments_tab = getAllReportData?.[0]?.report?.data?.sentiments_tab || {};
  const insights_tab = getAllReportData?.[0]?.report?.data?.insights_tab || {};
  const clusterAnalysis_tab = getAllReportData?.[0]?.report?.data?.cluster_analysis_tab || {};
  const startDate = (getAllReportData?.[0]?.report?.start_date) || '2024/01/01';
  const endDate = (getAllReportData?.[0]?.report?.end_date) || '2024/01/02';

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overViewTab':
        return (
          getAllReportData?.[0]?.report?.channel === 'twitter' ?
            <Overview
              formatValue={formatValue}
              overview_tab={overView_tab}
              startDate={startDate}
              endDate={endDate}
              hideDropdown={hideDropdown}
            />
            :
            getAllReportData?.[0]?.report?.channel === 'facebook' ?
              <OverviewTabFb
                formatValue={formatValue}
                overview_tab={overView_tab}
                startDate={startDate}
                endDate={endDate}
                hideDropdown={hideDropdown}

              />
              :

              <OverviewTabInsta
                formatValue={formatValue}
                overview_tab={overView_tab}
                startDate={startDate}
                endDate={endDate}
                hideDropdown={hideDropdown}
              />
        );
      case 'sentimentsTab':
        return (
          <Sentiments
            formatValue={formatValue}
            sentiments_tab={sentiments_tab}
            startDate={startDate}
            endDate={endDate}
            channel={getAllReportData?.[0]?.report?.channel}
            hideDropdown={hideDropdown}
          />
        );
      case 'insightTab':
        return (
          <Insights
            formatValue={formatValue}
            channel={getAllReportData?.[0]?.report?.channel}
            insights_data={insights_tab}
            startDate={startDate}
            endDate={endDate}
            hideDropdown={hideDropdown}
          />
        );
      case 'clusterAnalysis':
        return (
          // <ClusterAnalysisTab
          //   formatValue={formatValue}
          //   cluster_analysis={clusterAnalysis_tab}
          //   startDate={startDate}
          //   endDate={endDate}
          //   job_id={job_id}
          // />
          <ClusterAnalysisTab
            cluster_analysis={clusterAnalysis_tab}
            formatValue={formatValue}
            startDate={startDate}
            endDate={endDate}
            child={[
              {
                  "id": "4aa",
                  "name": "Sentiments",
                  "type": "checkbox",
                  "value": true,
                  "display": true
              },
              {
                  "id": "4ab",
                  "name": "Emotions",
                  "type": "checkbox",
                  "value": true,
                  "display": true
              }
          ]}
            // child={AnalysisFilter?.Cluster_Analysis?.child}
          />
        );
      case 'customAnalysis':
        return (
          <CustomAnalysisTab
            showCustom={false}
            AnalysisFilter={AnalysisFilter?.Custom_Analysis?.localVal}
            formatValue={formatValue}
            job_id={job_id}
            preview_uuid={getAllReportData?.[0]?.report?.preview_uuid}
          />
        );
      case 'reviewsTab':
        return (
          <ReviewsTab
            formatValue={formatValue}
            channel={getAllReportData?.[0]?.report?.channel}
            startDate={startDate}
            endDate={endDate}
            preview_uuid={getAllReportData?.[0]?.report?.preview_uuid}
            job_id={data?.job_id}
            hideDropdown={hideDropdown}
            email_id={getAllReportData?.[0]?.email_id}
            showReport={true}
          />
        );
      default:
        return null;
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };



  const handleGeneratePdf = (refsArray) => {
    setDownload(true)
    const element = document.getElementById('element-to-convert');


    setAnalysisFilter(getAllReportData?.length > 0 && JSON.parse(getAllReportData[0]?.render_field_string))


    const options = {
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
    };
    setTimeout(() => {


      // Call the html2pdf function with the selected element and options
      html2pdf().from(element).set(options).save();

      setDownload(false)
    }, 3000); // 3000 milliseconds = 3 seconds

  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <Span1>{getAllReportData?.[0]?.report?.title || getAllReportData?.[0]?.report?.chatter_title}</Span1>
        <Span2>{getAllReportData?.[0]?.report?.description}</Span2>
        <StyledContainer>
          <ChannelsText>Channels:</ChannelsText>
          <TwitterText>
            <Icon>{getAllReportData?.[0]?.report?.is_topic && getAllReportData?.[0]?.report?.channel == 'facebook' ? FacebookLogoSmall : getAllReportData?.[0]?.report?.channel == 'twitter' ? X_icon : InstagramIconSmall}</Icon> &nbsp; {getAllReportData?.[0]?.report?.channel ? getAllReportData?.[0]?.report?.channel == 'twitter' ? '' : getAllReportData?.[0]?.report?.channel?.replace(/{|}/g, '') : ''}
          </TwitterText>
        </StyledContainer>
        {
          getAllReportData?.[0]?.report?.is_topic == false ?
            <b style={{ color: 'rgb(53, 60, 247)', wordBreak: 'break-all', cursor: 'pointer', textDecoration: 'underline' }}>{getAllReportData?.[0]?.report?.hyperlink}</b>
            :

            <QueryPara>
              <b>Query Used:&nbsp;</b> {getAllReportData?.[0]?.report?.keywords_string}

              <br />
            </QueryPara>
        }
        {/* <br /> lang: en since: 2023-01-01 until: 2023-05-18 geocode: 20.5937,78.9629,2000 km */}

      </HeaderWrapper>
      <DownloadWrapper>
        <div className='created_at'>
          {is_topic ? 'Topic ' : 'Chatter '}
          Created at {convertDateTime(created_at)}
        </div>

        <div className='buttonContainer'>
          <button className='button cancel' onClick={handleCancelClick}>
            Cancel
          </button>

          <button className='button'
            onClick={() => handleGeneratePdf(contentDivRef)}>
            {
              download ?
                '...downloading'
                :
                <>Download {download_icon}</>
            }
          </button>
        </div>
      </DownloadWrapper>
      <TabHeading>
        <div
          className={activeTab === 'overViewTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('overViewTab')}
        >
          Overviews
        </div>
        <div
          className={activeTab === 'sentimentsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('sentimentsTab')}
        >
          Sentiments
        </div>
        <div
          className={activeTab === 'insightTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('insightTab')}
        >
          Insights
        </div>
        <div
          className={activeTab === 'clusterAnalysis' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('clusterAnalysis')}
        >
          Cluster Analysis
        </div>
        <div
          className={activeTab === 'customAnalysis' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('customAnalysis')}
        >
          Custom Analysis
        </div>
        <div
          className={activeTab === 'reviewsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('reviewsTab')}
        >
          Posts
        </div>
      </TabHeading>
      {renderTabContent()}

      <div style={{ display: 'none' }}>
        <div ref={contentDivRef} id="element-to-convert">
          <DownloadFile
            AnalysisFilter={AnalysisFilter}
            email_id={data?.email_id + ''}
            created_at={data.creation_time}
            is_topic={data.is_topic}
            data={data}

            formatValue={formatValue}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ViewReport;