import react, { useState } from 'react';
import styled from 'styled-components';
import WordCloudComponent from '../../Common/WordCloud/WordCloud';
import WordListGraph from '../../Common/WordList/WordListGraph';
import GraphDropdown from '../../Common/GraphDropDown/GraphDropdown';

const WordContainer = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 20px;
`;

const Title = styled.div`
  color: #636363;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const SubTitle = styled.div`
  color: #000;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const ImageWrapper = styled.div`
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
`;

const SelectorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: min-content;
  margin-bottom: 15px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;

  color: #fff;

  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
`;

const Option = styled.span`
  white-space: nowrap;
  cursor: pointer;
  color: ${(props) => (props.selected ? 'white' : 'black')};
  background-color: ${(props) => (props.selected ? 'blue' : 'transparent')};
  padding: 8px 35px;
  border-radius: 5px;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  &:hover {
    text-decoration: underline;
  }
`;

const Span = styled.div`
  color: rgba(0, 0, 0, 0.87);

  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-align: right;
`;

const WordCloud = ({
  data = {
    unigram_word_cloud: [],
    bigram_word_cloud: [],
    trigram_word_cloud: [],
    word_list: [],
  },
  startDate,
  endDate,
}) => {
  const [selectedOption, setSelectedOption] = useState('wordCloud');

  const [selectedWordCloudGraph, setSelectedWordCloudGraph] =
    useState('Unigram');

  let wordCloudData = [];

  if (selectedWordCloudGraph === 'Unigram') {
    wordCloudData = data?.unigram_word_cloud || [];
  } else if (selectedWordCloudGraph === 'Bigram') {
    wordCloudData = data?.bigram_word_cloud || [];
  } else if (selectedWordCloudGraph === 'Trigram') {
    wordCloudData = data?.trigram_word_cloud || [];
  }

  /* if (data?.word_cloud?.unigram) {
    if (selectedWordCloudGraph === 'Unigram') {
      wordCloudData = data?.word_cloud.unigram;
    } else if (selectedWordCloudGraph === 'Bigram') {
      wordCloudData = data?.word_cloud.bigram;
    } else if (selectedWordCloudGraph === 'Trigram') {
      wordCloudData = data?.word_cloud.trigram;
    } else {
      //remove this else once changes made in chatter report
      wordCloudData = [];
    }
  } else {
    if (!data) {
      wordCloudData = data?.word_cloud;
    }
  } */

  const words1 = wordCloudData.map(({ word, count }) => ({
    text: word,
    value: count,
  }));

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleWordCloudList = (option) => {
    setSelectedWordCloudGraph(option);
  };

  return (
    <WordContainer>
      <Title>Word cloud Analysis</Title>
      <SubTitle>
        Word cloud depicts the most frequently used words and the prominence of
        the words is shown using the font sizes
      </SubTitle>
      <ImageWrapper>
        <SelectorWrapper>
          <OptionWrapper>
            <Option
              selected={selectedOption === 'wordCloud'}
              onClick={() => handleOptionClick('wordCloud')}
            >
              Word Cloud
            </Option>
            <Option
              selected={selectedOption === 'wordList'}
              onClick={() => handleOptionClick('wordList')}
            >
              Word List
            </Option>
          </OptionWrapper>
          {selectedOption === 'wordCloud' ? (
            <GraphDropdown
              options={['Unigram', 'Bigram', 'Trigram']}
              onSelectGraph={handleWordCloudList}
            />
          ) : (
            ''
          )}
        </SelectorWrapper>
        {selectedOption === 'wordCloud' ? (
          <WordCloudComponent words={words1} />
        ) : (
          <WordListGraph data={data?.word_list} />
        )}
      </ImageWrapper>
      <Span>
        This data is observed from {startDate} to {endDate}
      </Span>
    </WordContainer>
  );
};

export default WordCloud;
