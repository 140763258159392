import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';

import TenantSettingService from '../services/tenantSetting.service';

export const getHomepageDetails = createAsyncThunk(
  'getHomepageDetails',
  async (thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TenantSettingService.getHomepageDetails(
        brandUser.tenant_id
      );
      return { data };
    } catch (error) {
      const message = error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPersonalDetails = createAsyncThunk(
  'getPersonalDetails',
  async (thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TenantSettingService.personalDetails(
        brandUser.tenant_id,
        brandUser.user_details.user_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPersonalDetailsOfTeamMember = createAsyncThunk(
  'getPersonalDetailsOfTeamMember',
  async (user_id, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TenantSettingService.personalDetails(
        brandUser.tenant_id,
        user_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePersonalDetails = createAsyncThunk(
  'updatePersonalDetails',
  async (body, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TenantSettingService.updatePersonalDetail(
        brandUser.tenant_id,
        brandUser.user_details.user_id,
        body
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePersonalDetailsOfTeamMember = createAsyncThunk(
  'updatePersonalDetailsOfTeamMember',
  async ({ user_id, ...body }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TenantSettingService.updatePersonalDetail(
        brandUser.tenant_id,
        user_id,
        body
      );
      thunkAPI.dispatch(setMessage('Successfully updated details'));
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const companyBasicDetails = createAsyncThunk(
  'companyBasicDetails',
  async (thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TenantSettingService.getCompanyBasicDetails(
        brandUser.tenant_id
      );
      return { data };
    } catch (error) {
      const message = error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const teamMemberList = createAsyncThunk(
  'teamMemberList',
  async (thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TenantSettingService.getTeamMembers(
        brandUser.tenant_id
      );
      return { data };
    } catch (error) {
      const message = error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  'deleteTeamMember',
  async ({ user_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      await TenantSettingService.deleteTeamMembers(
        brandUser.tenant_id,
        user_id
      );
      const data = await TenantSettingService.getTeamMembers(
        brandUser.tenant_id
      );
      return { data };
    } catch (error) {
      const message = error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addTeamMember = createAsyncThunk(
  'addTeamMember',
  async (body, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));

      const updatedArray = body.map((element) => {
        const user_name = element.userName;
        const first_name = element.firstName;
        const last_name = element.lastName;
        const password = element.password;
        const email_id = element.email;
        const role_id = element.role === 'Brand User' ? 200 : 100;
        const tenant_id = brandUser.tenant_id;

        return {
          user_name,
          first_name,
          last_name,
          password,
          email_id,
          role_id,
          tenant_id,
        };
      });
      await TenantSettingService.addTeamMembers(
        updatedArray,
        brandUser.tenant_id
      );
      const data = await TenantSettingService.getTeamMembers(
        brandUser.tenant_id
      );
      return { data };
    } catch (error) {
      const message = error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPlansBillings = createAsyncThunk(
  'getPlansBillings',
  async (thunkAPI) => {
    try {
      const data = await TenantSettingService.getPlansBillings();
      return { data };
    } catch (error) {
      const message = error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  getHomepageDetailsData: null,
  personalDetailsData: null,
  getPersonalDetailsOfTeamMemberData: [],
  companyBasicDetailsData: null,
  teamMemberListData: null,
  plansBillingsData: null,
};

const ExecutivePortalTenantManagementSlice = createSlice({
  name: 'tenantSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonalDetails.fulfilled, (state, action) => {
        state.personalDetailsData = action.payload.data;
      })
      .addCase(getPersonalDetails.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(updatePersonalDetails.fulfilled, (state, action) => {
        state.personalDetailsData = action.payload.data;
      })
      .addCase(updatePersonalDetails.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(companyBasicDetails.fulfilled, (state, action) => {
        state.companyBasicDetailsData = action.payload.data;
      })
      .addCase(companyBasicDetails.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(teamMemberList.fulfilled, (state, action) => {
        state.teamMemberListData = action.payload.data;
      })
      .addCase(teamMemberList.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(deleteTeamMember.fulfilled, (state, action) => {
        state.teamMemberListData = action.payload.data;
      })
      .addCase(deleteTeamMember.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(addTeamMember.fulfilled, (state, action) => {
        state.teamMemberListData = action.payload.data;
      })
      .addCase(addTeamMember.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(getPlansBillings.fulfilled, (state, action) => {
        state.plansBillingsData = action.payload.data;
      })
      .addCase(getPlansBillings.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(getHomepageDetails.fulfilled, (state, action) => {
        state.getHomepageDetailsData = action.payload.data;
      })
      .addCase(getHomepageDetails.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(getPersonalDetailsOfTeamMember.fulfilled, (state, action) => {
        state.getPersonalDetailsOfTeamMemberData.push(action.payload.data);
      })
      .addCase(getPersonalDetailsOfTeamMember.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(updatePersonalDetailsOfTeamMember.fulfilled, (state, action) => {
        const usernameToReplace = action.payload.data?.user_name;

        state.getPersonalDetailsOfTeamMemberData =
          state.getPersonalDetailsOfTeamMemberData.map((user) =>
            user.user_name === usernameToReplace ? action.payload.data : user
          );
        state.teamMemberListData = state.teamMemberListData.map((user) => {
          if (user.user_name === usernameToReplace) {
            user.first_name = action.payload.data?.first_name;
            user.email_id = action.payload.data?.email_id;
            return user;
          } else {
            return user;
          }
        });
      })
      .addCase(updatePersonalDetailsOfTeamMember.rejected, (state, action) => {
        console.log('rejected');
      });
  },
});

export default ExecutivePortalTenantManagementSlice.reducer;
