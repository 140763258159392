import axios from 'axios';
import { isTokenValid } from '../../../utils/index';

// Create an Axios instance with base URL and any other default config
const axiosInstance = axios.create();

// Add an interceptor to attach the authentication header
axiosInstance.interceptors.request.use((config) => {
  const authToken = JSON.parse(localStorage.getItem('user'))?.access_token;
  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const authToken = JSON.parse(localStorage.getItem('user'))?.access_token;
    if (!isTokenValid(authToken)) {
      localStorage.removeItem('user');
      window.location.href = '/brandAstra/login';
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
