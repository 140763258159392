import React, { useState } from 'react';
import styled from 'styled-components';
import {
  AmazonIconSmall,
  FlipkartIconSmall,
  FlipkartLogo,
  StarIcon,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
// import PieChartComponent from "./SentimentCharts";
import DonutChart from '../../../../../Common/SentimentCharts/SentimentCharts';
import GenderBarGraph from './GenderGraph';
import AverageRatingGraph from '../../../../../Common/LineGraph/LineGraph';
import RatingDonutChart from '../../../../../Common/RatingDonutChart/DonutChart';
import GraphDropdown from '../../../../../Common/GraphDropDown/GraphDropdown';
import Lottie from 'lottie-react';
import FunnelGraph from '../../../../../Common/FunnelGraph/FunnelGraph';
import VerifiedNonVerifiedUserGraph from '../../../../../Common/VerifiedNonVerifiedUserGraph/VerifiedNonVerifiedUserGraph';
import TrendsInUserActivity from '../../../../../Common/TendsInUserActivity/TrendsInUser_activity';
import GenderDemoGraphics from '../../../../../components/GenderDemoGraphics/GenderDemoGraphics';
import GenderRating from '../../../../../components/GenderRating/GenderRating';
import GraphHOC from '../../../../../Common/GraphHOC/GraphHOC';
import BarGraph from '../../../../../Common/BarGraph/BarGraph';
import VerticalBarGraph from '../../../../../Common/BarGraph/VarticalBargraph';
import Loader from '../../../../../assests/animation/Loader2.json';
import { useParsedEndDate, useParsedStartDate } from '../../../../../utils/ParseDate';

const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 95%;
  flex-direction: column;
  margin-bottom: 10px;
  /*  background:red; */
`;

const FlexWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /*  gap: 45px 10px;  */
  margin: 40px 0px 0px 20px;
  gap:15px;
`;
const ContentWrapperAvg = styled.div`
// width: 50%;
// height: 380px;

  width: 47%;
  height: 335px;
flex-shrink: 0;

border-radius: 15px;
border: 1px solid #d9d9d9;
justify-content: space-between;
display: grid;
    grid-template-rows: 50px 1fr 30px;
    grid-template-columns: 1fr;


p {
  // padding: 0.5em 0 0 2em;
  align-items: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin:0px
  
  height: 43px;
  /* text-align: center; */
  display: flex;
  align-items: end;
  margin-left: 15px;
}


`;
const ContentWrapperOverview = styled.div`
  // width: 50%;
  // height: 380px;
  
    width: 47%;
    height: 335px;
  flex-shrink: 0;

  border-radius: 15px;
  border: 1px solid #d9d9d9;
  justify-content: space-between;

  p {
    // padding: 0.5em 0 0 2em;
    align-items: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin:0px
    
    height: 43px;
    /* text-align: center; */
    display: flex;
    align-items: end;
    margin-left: 15px;
  }

  
`;
const ContentWrapper1 = styled.div`
  // width: 50%;
  // height: 380px;
  
    width: 47%;
    height: 335px;
  flex-shrink: 0;

  border-radius: 15px;
  border: 1px solid #d9d9d9;
  justify-content: space-between;

  p {
    // padding: 0.5em 0 0 2em;
    align-items: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin:0px
  }

  &>span: first-child {
    /* display: flex; */
    /* margin: 20px 0px 0px 20px; */
    color: #636363;
    font-size: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
  }
`;

const ContentWrapper2 = styled.div`
  display: flex;
  // margin-left: 15px;
  flex-direction: column;
  width: 48%;
  // height: 380px;
  
    height: 335px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  p {
    // padding: 0.532em 0 0 2em;
    // margin-bottom: 50px;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
  }

  &>span: first-child {
    /* display:flex; */
    color: #636363;
    /* margin: 20px 0px 0px 20px; */
    font-size: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
  }
  &>div: first-child {
    flex: 1;
  }
`;

const FlexWrapper2 = styled(FlexWrapper1)`
  margin-top: 20px;
`;

const ContentWrapperFlipkart = styled(ContentWrapper1)`
  width: 96%;
`;

export const TextWrapper = styled.div`
  width: 389px;
  display: flex;
  height: 230px;
  margin: 40px 0px 0px 55px;

  border-radius: 36px 36px 0px 36px;
  background: rgba(111, 98, 254, 0.22);

  span {
    //display: flex;
    margin: 80px 0px 0px 20px;
    width: 230px;
    height: 85px;
    flex-direction: column;

    color: #000;
    text-align: center;
    font-size: 17px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
  }

  div {
    width: 90px;
    height: 86px;
    flex-shrink: 0;
    margin: -20px 0px 0px -30px;

    border-radius: 90px;
    border: 1px solid rgba(85, 172, 238, 0.84);
    background: #fff;
    box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 30px;
  }
`;

const Span = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: flex-end;
  text-align: right;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const GraphContainer2 = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`;

const GraphContainer = styled.div`
  flex: 1;
`;
const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1em;
`;
const GraphWrapper = styled.div`
  /* display: flex; */
  width: 97%;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  margin: 20px 0px 0px 20px;
`;

const ChildContainer = styled.div`
  /* flex: 1; */
  height: 100%;
  border-radius: 15px;
  width: 48%;
  border: 1px solid #d9d9d9;

  /* display: flex; */
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  // margin-left: 15px;
  margin:0px;
  p {
    // padding: 0.5em 0 0 2em;
    align-items: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin:0px
  }
`;

const Title = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0px 0px 10px;
`;

const Div = styled.div`
  justify-content: center;
  margin-top: 20px;
`;

function OverViewTab({
  chatterData,
  plan,
  selectedReport,
  filteredData,
  reset,
  filterOption,
  view,
  endDate,
  startDate,
  formatValue = "dd/yyyy/MM",
  filteredStartDate,
  filteredEndDate,
}) {
  /* const [timeSpan, setTimeSpan] = useState('day'); */
  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];
  const [selectedGraph, setSelectedGraph] = useState('Bar Graph');

  let planSpecificData;
  if (selectedReport) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = selectedReport?.data?.overview_tab;
    } else if (plan === 'BrandEssence') {
      // planSpecificData = selectedReport?.BrandAstraEssence.overview_tab;
      planSpecificData = selectedReport?.data?.overview_tab;
    } else if (plan === 'BrandAstraElite') {
      // planSpecificData = selectedReport?.BrandAstraElite;
      planSpecificData = selectedReport?.data?.overview_tab;
    } else {

      planSpecificData = selectedReport?.data?.overview_tab;
    }
  } else if (chatterData) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = chatterData?.data?.overview_tab;
    } else if (plan === 'BrandEssence') {
      // planSpecificData = chatterData?.BrandAstraEssence.overview_tab;
      planSpecificData = chatterData?.data?.overview_tab;
    } else {
      // planSpecificData = chatterData?.BrandAstraElite;

      planSpecificData = chatterData?.data?.overview_tab;
    }
  }
  // console.log(planSpecificData, selectedReport, chatterData, "planSpecificData");
  const averageRating =
    planSpecificData?.overall_customer_satisfaction?.average_rating || 0;

  const percentageValue = filteredData
    ? filteredData?.gender_based_avg_rating
    : planSpecificData?.gender_based_avg_rating;

  const genderBasedRatingData = [
    {
      name: 'Male ',
      value: parseFloat(percentageValue?.male_percentage),
      fill: '#6640B8',
    },
    {
      name: 'Female',
      value: parseFloat(percentageValue?.female_percentage),
      fill: '#EAAABD',
    },
    {
      name: 'Unknown',
      value: parseFloat(percentageValue?.unknown_percentage),
      fill: '#D2D2D4',
    },
  ];

  const verifiedAndNonVerifiedData = filteredData
    ? filteredData?.verified_non_verified_users

    : planSpecificData?.verified_non_verified_users
    ;

  const verifiedPurchaseRating =
    chatterData?.data?.sentiments?.verified_purchase_impact
      .verified_average_rating ||
    planSpecificData?.overall_customer_satisfaction?.verified_purchase;

  const nonVerifiedPurchaseRating =
    chatterData?.data?.sentiments?.verified_purchase_impact
      .non_verified_avg_rating ||
    planSpecificData?.overall_customer_satisfaction?.non_verified_purchase;

  const renderGenderBasedRatingGraph = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={genderBasedRatingData}
          type={StarIcon}
          starRating={true}
        />
      ) : (
        <BarGraph
          data={genderBasedRatingData}
          type={StarIcon}
          starRating={true}
          /* borderRadius={'0px 12px 12px 0px'} */
          barHeight={'32px'}
          maxWidth={'80%'}
        />
      ),
    'Pie Chart': <RatingDonutChart data={genderBasedRatingData} />,
    'Donut Chart': <DonutChart data={genderBasedRatingData} align="left" />,
    'Funnel Chart': <FunnelGraph data={genderBasedRatingData} />,
  };

  const customerSatisfaction = [
    {
      name: 'Average Rating ',
      value: averageRating,
      fill: '#6640B8',
    },
    {
      name: 'Verified Purchase',
      value: verifiedPurchaseRating,
      fill: '#5FE495',
    },
    {
      name: 'Non Verified Purchase',
      value: nonVerifiedPurchaseRating,
      fill: '#353cf7'
    },
  ];


  const renderCustomerSatisfaction = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={customerSatisfaction}
          type={StarIcon}
          starRating={true}
        />
      ) : (
        <BarGraph
          data={customerSatisfaction}
          type={StarIcon}
          starRating={true}
          /* borderRadius={'0px 12px 12px 0px'} */
          barHeight={'25px'}
          maxWidth={'90%'}
        />
      ),
    'Pie Chart': <RatingDonutChart data={customerSatisfaction} />,
    'Donut Chart': <DonutChart data={customerSatisfaction} align="left" />,
    'Funnel Chart': <FunnelGraph data={customerSatisfaction} />,
  };

  const genderBasedAverageRatingGraph =
    renderGenderBasedRatingGraph[filterOption];

  const userRatingValue = filteredData
    ? filteredData?.customer_ratings
    : planSpecificData?.customer_ratings;
  const userRating = [
    {
      name: '5 star ',
      value: parseFloat(userRatingValue?.five_star_ratings),
      fill: '#676DFF',
    },
    {
      name: '4 star',
      value: parseFloat(userRatingValue?.four_star_ratings),
      fill: '#898DFB',
    },
    {
      name: '3 star',
      value: parseFloat(userRatingValue?.three_star_ratings),
      fill: '#ACAFFC',
    },
    {
      name: '2 star',
      value: parseFloat(userRatingValue?.two_star_ratings),
      fill: '#C5C6F3',
    },
    {
      name: '1 star',
      value: parseFloat(userRatingValue?.one_star_ratings),
      fill: '#D5D5DC',
    },
  ];

  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={userRating} type={'%'} />
      ) : (
        <BarGraph
          data={userRating}
          type={'%'}
          borderRadius={'0px 0px 0px 0px'}
          barHeight={'30px'}
          maxWidth={'95%'}
        />
      ),
    'Pie Chart': (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // flex: 1,
        }}
      >
        <RatingDonutChart data={userRating} type='percentage' />
      </div>
    ),
    'Donut Chart': (
      /*  <div
         style={{
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           // flex: 1,
         }}
       > */
      <DonutChart data={userRating} type='percentage' />
      // </div>
    ),
    'Funnel Chart': (
      <FunnelGraph data={userRating} type={'%'} maxWidth={'95%'} />
    ),
  };
  // console.log(reset, selectedReport, filteredStartDate, startDate, formatValue, "reset, filteredStartDate, startDate, formatValue");

  const startDateParsed = useParsedStartDate(reset, filteredStartDate, startDate, formatValue);
  const endDateParsed = useParsedEndDate(reset, filteredEndDate, endDate, formatValue);

  const handleGraphSelect = (selectedGraph) => {
    // Your logic to handle the selected graph in the parent component
    setSelectedGraph(selectedGraph);
  };
  const customerRatingGraph = renderScreen[filterOption];


  const totalNoOfReviews = reset === true && filteredData ? filteredData?.total_count :
    planSpecificData?.total_count ? planSpecificData?.total_count : 0;

  return (
    <>
      {reset === true && filteredData === undefined ? (
        <div style={{ marginTop: '-80px' }}>
          <Lottie
            data-testid='loader'
            animationData={Loader}
            loop={true}
            width='100px'
            height='100px'
          />
        </div>
      ) : (
        <Wrapper>
          <FlexWrapper1>
            <ContentWrapperOverview>
              <p>Overall Reviews</p>
              <TextWrapper>
                {chatterData?.channel === 'amazon' ||
                  selectedReport?.channel === 'amazon' ? (
                  <IconWrapper>{AmazonIconSmall}</IconWrapper>
                ) :
                  chatterData?.channel === 'flipkart' ||
                    selectedReport?.channel === 'flipkart' ?

                    (
                      <IconWrapper>{FlipkartLogo}</IconWrapper>
                    )
                    : <></>
                }

                <span>
                  {`${totalNoOfReviews} Relevant reviews were observed from ${startDateParsed} to ${endDateParsed}`}

                </span>
              </TextWrapper>
            </ContentWrapperOverview>
            {/* <CustomerRating
          data={planSpecificData}
          startDate={chatterData?.start_date}
          endDate={chatterData?.end_date}
        /> */}
            {chatterData?.channel === 'flipkart' ||
              selectedReport?.channel === 'flipkart' ? (
              <ContentWrapperAvg>
                <p>Overall Customer Satisfaction</p>
                <GraphContainer2>
                  <AverageRatingGraph
                    rating={averageRating}
                    backgroundColor='#6640B8'
                    title='Average Rating'
                  />
                </GraphContainer2>

                <Span>
                  This data is observed from{' '}
                  {startDateParsed}{' '}
                  to{' '}
                  {endDateParsed}
                </Span>
              </ContentWrapperAvg>
            ) : (
              <ContentWrapper2>
                {/* <p>Overall Customer Satisfaction</p> */}
                <GraphHOC
                  title={'Overall Customer Satisfaction'}
                  options={options}
                  renderScreen={renderCustomerSatisfaction}
                  option={filterOption}
                  startDate={startDateParsed}
                  endDate={endDateParsed}
                // {
                //   reset
                //     ? filteredEndDate
                //     : chatterData?.end_date || selectedReport?.end_date
                // }
                />
                {/* <GraphContainer>
                  <AverageRatingGraph
                    rating={averageRating}
                    backgroundColor='#6640B8'
                    title='Average Rating'
                  />
                  <AverageRatingGraph
                    rating={verifiedPurchaseRating}
                    backgroundColor='#5FE495'
                    title='Verified Purchase'
                  />
                  <AverageRatingGraph
                    rating={nonVerifiedPurchaseRating}
                    title='Non Verified Purchase'
                  />
                </GraphContainer> */}

                {/* <Span>
                  This data is observed from{' '}
                  {reset
                    ? filteredStartDate
                    : chatterData?.start_date ||
                    selectedReport?.start_date}{' '}
                  to{' '}
                  {reset
                    ? filteredEndDate
                    : chatterData?.end_date || selectedReport?.end_date}
                </Span> */}
              </ContentWrapper2>
            )}
          </FlexWrapper1>
          {chatterData?.channel === 'flipkart' ||
            selectedReport?.channel === 'flipkart' ? (
            <FlexWrapper2>
              {/* {filterOption ? (
                <ContentWrapperFlipkart>
                  <Title>Customer Rating</Title>
                  <div style={{marginTop: 20}}>
                    <GraphDropdown
                      options={options}
                      onSelectGraph={handleGraphSelect}
                      defaultOption='Bar Graph'
                    />
                  </div>
                  <Div>{customerRatingGraph}</Div>
                </ContentWrapperFlipkart>
              ) : ( */}
              <ContentWrapperFlipkart>
                <GraphHOC
                  title={'Customer Rating'}
                  options={options}
                  renderScreen={renderScreen}
                  option={filterOption}

                  startDate={startDateParsed}
                  endDate={endDateParsed}
                  alignLeft={true}
                />
              </ContentWrapperFlipkart>
              {/* )} */}
            </FlexWrapper2>
          ) : (
            <FlexWrapper2>
              {/* {filterOption ? (
                <ContentWrapper1>
                <LineWrapper>
                  <Title>Customer Rating</Title>
                  <div style={{marginTop: 20}}>
                    <GraphDropdown
                      options={options}
                      onSelectGraph={handleGraphSelect}
                      defaultOption='Bar Graph'
                    />
                  </div>
                </LineWrapper>
                  <Div>{customerRatingGraph}</Div>
                </ContentWrapper1>
              ) : ( */}
              <ContentWrapper1 style={{ height: '430px' }}>
                <GraphHOC
                  title={'Customer Rating'}
                  options={options}
                  renderScreen={renderScreen}
                  option={filterOption}

                  startDate={startDateParsed}
                  endDate={endDateParsed}
                />
              </ContentWrapper1>
              {/* )} */}

              <VerifiedNonVerifiedUserGraph
                data={verifiedAndNonVerifiedData}

                startDate={startDateParsed}
                endDate={endDateParsed}
              />
            </FlexWrapper2>
          )}

          <FlexWrapper2>
            <GenderDemoGraphics
              options={options}
              data={filteredData ? filteredData : planSpecificData}

              startDate={startDateParsed}
              endDate={endDateParsed}
              filterOption={filterOption}
              view={view}
            />
            {/* {filterOption ? (
              <ChildContainer>
                <Title>Gender Based Average Rating</Title>
                <Div>{genderBasedAverageRatingGraph}</Div>
              </ChildContainer>
            ) : ( */}
            <ChildContainer>
              <GraphHOC
                title={'Gender Based Average Rating'}
                options={options}
                renderScreen={renderGenderBasedRatingGraph}
                option={filterOption}

                startDate={startDateParsed}
                endDate={endDateParsed}
              />
            </ChildContainer>
            {/* )} */}
          </FlexWrapper2>
          <GenderRating
            data={filteredData ? filteredData : planSpecificData}

            startDate={startDateParsed}
            endDate={endDateParsed}
          />
          <GraphWrapper>
            <TrendsInUserActivity
              data1={filteredData ? filteredData : planSpecificData}
              title='Trends In User Activity'
              ylabel='Review'

              startDate={startDateParsed}
              endDate={endDateParsed}
            />
          </GraphWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default OverViewTab;