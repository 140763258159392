import react, { useState } from 'react';
import styled from 'styled-components';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import SmallSelect from '../../../Common/Select/SmallSelect';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';

export const PieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0px 0px 30px;
  width: 95%;
  height: auto;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

      padding: 10px 20px;
    margin-left: 25px;
    width: 92%;
`;

export const PieDiv = styled.div`
  display: flex;
  width: 100%;

  div {
    margin: 10px 0px 0px 20px;
  }
`;

export const HeadingSpan = styled.span`
  display: flex;
  margin: 30px 0px 0px 30px;
  color: #636363;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopPostTopicWrapper = styled.div`
  margin-left: 40px;

  .topicList {
    padding-left: 35px;
    padding-bottom: 10px;
  }

  .positiveTopic {
    color: #40bb72;

    &:before {
      background: linear-gradient(180deg, #a1fcc6 0%, #5fe495 100%);
    }
  }
  .neutralTopic {
    color: #dfae30;
    &:before {
      background: linear-gradient(180deg, #ffe092 0%, #f9d16d 100%);
    }
  }
  .negativeTopic {
    color: #fc615a;
    &:before {
      background: linear-gradient(180deg, #ff8c86 0%, #fa6961 100%);
    }
  }
`;

const TopPostTopicHeading = styled.div`
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 20px;

  &:before {
    display: inline-block;
    content: '';
    width: 17px;
    height: 17px;
  }
`;

const SelectWrapper = styled.div`
  width: 100px;
  padding: 20px 0 10px 35px;
`;

const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 30px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 15px;
  }

`;

const SentimentDrillDown = ({ data, startDate, endDate, filterOption, view, hideDropdown }) => {
    const [selectedGraph, setSelectedGraph] = useState('Donut Chart');
    const [topTopicCount, setTopTopicCount] = useState({
        positive: 'Topic 1',
        negative: 'Topic 1',
        neutral: 'Topic 1',
    });

    const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

    const sentimentsData = [
        {
            name: 'Positive ',
            value: parseFloat(
                data?.sentiments_tab?.sentiment_drilldown?.positive_sentiment 
            ),
            fill: '#A1FCC6',
        },
        {
            name: 'Negative',
            value: parseFloat(
                data?.sentiments_tab?.sentiment_drilldown?.negative_sentiment

            ),
            fill: '#FF8C86',
        },
        {
            name: 'Neutral',
            value: parseFloat(
                data?.sentiments_tab?.sentiment_drilldown?.neutral_sentiment
            ),
            fill: '#FFE092',
        },
    ];

    const sentimentsRenderScreen = {
        'Bar Graph': view === 'vertical' ? (
            <VerticalBarGraph
                data={sentimentsData}
                maxWidth={'60%'}
                type={'%'}
            />
        ) : (
            <BarGraph
                data={sentimentsData}
                maxWidth={'60%'}
                type={'%'}
            />
        ),
        'Pie Chart': (
            <RatingDonutChart
                data={sentimentsData}
                type='percentage'
            />
        ),
        'Donut Chart': (
            <DonutChart
                data={sentimentsData}
                sentiment={true} type='percentage'
            />
        ),
        'Funnel Chart': (
            <FunnelGraph
                data={sentimentsData}
                maxWidth={'50%'}
                type={'%'}
            />
        ),
    };

    //   const filteredSentimentDrillDown = sentimentsRenderScreen[filterOption];
    const handleTopTopicCountChange = (topic, topicType) => {
        if (topicType === 'positive') {
            setTopTopicCount({ ...topTopicCount, positive: topic });
        } else if (topicType === 'neutral') {
            setTopTopicCount({ ...topTopicCount, neutral: topic });
        } else if (topicType === 'negative') {
            setTopTopicCount({ ...topTopicCount, negative: topic });
        }
    };

    const renderTopics = (topicStrings, type) => {
        
        if (topicStrings) {
            const topicData = Object.keys(topicStrings);
            if (topicData.length === 0) return null;
    
            const topicKeys = topicData.filter(
                (key, index) => index < topTopicCount[type].split(' ')[1]
            );
            return topicKeys.map((key, index) => (
                <div key={key}>
                    {index + 1}. {topicStrings[key]}
                </div>
            ));
        }else{

        }
        
    };

    const totalTopicsColumn = (topicStrings) => {
        if (topicStrings) {
            const topicData = Object.keys(topicStrings);
            if (topicData.length === 0) return ['No Topic'];

            const columns = [];
            topicData.forEach((_, index) => {
                columns.push('Topic ' + (index + 1));
            });

            return columns;
            // Use topicData here
        } else {
            console.error("topicStrings is undefined or null");
        }
        // const topicData = Object.keys(topicStrings);

    };



    return (
        <PieWrapper>
            {/* filterOption ? (
          <FilterContainer>
            <span>Sentiment Drilldown</span>
            <div style={{ display: 'flex',margin: '15px 0px 0px 20px' }}>{filteredSentimentDrillDown}</div>
          </FilterContainer>
        ) : */}
            {(
                <GraphHOC
                    title={'Sentiment Drilldown'}
                    options={options}
                    renderScreen={sentimentsRenderScreen}
                    graphWidth={'100%'}
                    margin={'20px 0px 20px 40px'}
                    hideDropdown={hideDropdown}
                />)}
            <TopPostTopicWrapper>
                <div>
                    <TopPostTopicHeading className='positiveTopic'>
                        Positive Sentiments The posts were majorly related to:
                    </TopPostTopicHeading>
                    <SelectWrapper>
                        <SmallSelect
                            onChange={(topic) =>
                                handleTopTopicCountChange(topic, 'positive')
                            }
                            options={
                                totalTopicsColumn(
                                    data?.sentiments_tab?.topic_analysis?.positive_topics
                                ) || []
                            }
                        />
                    </SelectWrapper>
                    <div className='topicList'>
                        {renderTopics(
                            data?.sentiments_tab?.topic_analysis?.positive_topics,
                            'positive'
                        )|| 'No Data Avaliable'}
                    </div>
                </div>
            </TopPostTopicWrapper>
            <TopPostTopicWrapper>
                <div>
                    <TopPostTopicHeading className='neutralTopic'>
                        Neutral Sentiments The posts were majorly related to:
                    </TopPostTopicHeading>
                    <SelectWrapper>
                        <SmallSelect
                            onChange={(topic) =>
                                handleTopTopicCountChange(topic, 'neutral')
                            }
                            options={totalTopicsColumn(
                                data?.sentiments_tab?.topic_analysis?.neutral_topics
                            )}
                        />
                    </SelectWrapper>
                    <div className='topicList'>
                        {renderTopics(
                            data?.sentiments_tab?.topic_analysis?.neutral_topics,
                            'neutral'
                        )|| 'No Data Avaliable'}
                    </div>
                </div>
            </TopPostTopicWrapper>
            <TopPostTopicWrapper>
                <div>
                    <TopPostTopicHeading className='negativeTopic'>
                        Negative Sentiments The posts were majorly related to:
                    </TopPostTopicHeading>
                    <SelectWrapper>
                        <SmallSelect
                            onChange={(topic) =>
                                handleTopTopicCountChange(topic, 'negative')
                            }
                            options={totalTopicsColumn(
                                data?.sentiments_tab?.topic_analysis?.negative_topics
                            )}
                        />
                    </SelectWrapper>
                    <div className='topicList'>
                        {renderTopics(
                            data?.sentiments_tab?.topic_analysis?.negative_topics,
                            'negative'
                        )|| 'No Data Avaliable'}
                    </div>
                </div>
            </TopPostTopicWrapper>
            <Footer>
                This data is observed from {startDate} to {endDate}
            </Footer>
        </PieWrapper>
    );
};

export default SentimentDrillDown;
