import React from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.div`
  position: absolute;
  top: ${(p) => (p.pos?.bottom ? 'auto' : '6px')};
  bottom: ${(p) => (p.pos?.bottom ? p.pos?.bottom : 'auto')};

  right: ${(p) => (p.pos?.right ? p.pos?.right : '6px')};

  border-radius: 50px;
  background: #353cf7;
  color: #fff;
  padding: 1px 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  z-index: 10;

  min-width: 50px;

  font-size: 16px;

  flex-direction: ${(p) => (p.orActive === 'OR' ? 'row' : 'row-reverse')};

  transition: flex-direction 0.9s ease-in;
  span {
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50px;
  }
`;

const Toggle = ({ pos, toggleValue, toggleIndividualGroup, toggleGroup }) => {
  return (
    <ToggleWrapper
      onClick={toggleIndividualGroup}
      orActive={toggleValue}
      pos={pos}
    >
      <span></span>
      <div>{toggleValue}</div>
    </ToggleWrapper>
  );
};

export default Toggle;
