import React, { useState, useEffect } from 'react';
import { ItemListWrapper, Item } from './ToggleList.styled';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const StyledSwitch = styled(Switch)(() => ({
  width: 62,
  height: 20,
  padding: 6,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: 'red',
      transform: 'translateX(40px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#B4B7FC',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#353CF7',
  },
}));

const ItemList = ({ exclusion = false, items, setData = () => {} }) => {
  const [toggleList, setToggle] = useState(items);

  const handleToggle = (toggleIndex) => {
    items[toggleIndex].isActive = !items[toggleIndex].isActive;
    setToggle([...items]);
  };

  useEffect(() => {
    setData(toggleList);
  }, [toggleList]);

  return (
    <ItemListWrapper exclusion={exclusion}>
      {toggleList.map((item, index) => (
        <Item exclusion={exclusion} key={item.id}>
          <span>{item.name}</span>
          <StyledSwitch
            checked={item.isActive}
            onClick={() => handleToggle(index)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Item>
      ))}
    </ItemListWrapper>
  );
};

export default ItemList;
