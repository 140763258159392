import { Close, Download } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreatedTopic } from '../../store/redux/slices/topicSlice';
import DownloadPDFReport from './DownloadPDFReport';
import styled, { css, keyframes } from 'styled-components';
import DownloadFile from './Download/Download';
import { exportMultipleChartsToPdf } from './Download/Util';
import html2pdf from 'html2pdf.js';
import { convertToTimeZone } from '../../utils';
import { useParsedStartDate } from '../../utils/ParseDate';
import DownladInsights from './DownladInsights';
import { X_LOGO } from '../../Common/SvgIcon/CommonSvgIcons';

const PDFWrapper = styled.div`
  position: absolute;
  width: 98vw;
  height: 100vh;

  top: 0;
`;

export const fadeInAnimation = keyframes`


  from {
    
    transform: translateY(100%) scaleY(1);
  }
  to {
    transform: translateY(0%) scaleY(1);
  }
`;

const SplitScreenWrapper = styled.div`

height: 185px;
padding: 20px 30px;
border: 1px solid black;
border-radius: 10px 10px 0px 0px;
box-shadow:6px 3px 19px #080808;
overflow: auto;

animation: ${fadeInAnimation} 0.3s ease-in-out; /* Use the fade-in animation */
`;



const SplitScreenReports = ({
    showci,
    data,
    setShowSplitScreen,
    formatValue,
    getAllReportData,
    personalDetailsData,
    startDate = '16/10/2024'
}) => {
    let formattedDate = '';

    const parsedStartDate = useParsedStartDate(false, '', startDate, formatValue);

    if (showci) {
        let newDate = convertToTimeZone(
            startDate,
            personalDetailsData?.app_preference?.time_zone,
            personalDetailsData?.app_preference?.time_format === '12 Hour',
            personalDetailsData?.app_preference?.date_format
        );
        formattedDate = newDate?.formattedDate;

    } else {
        formattedDate = parsedStartDate;
    }
    // console.log(formattedDate, "formattedDate");

    const [download, setDownload] = useState(false)
    const contentDivInsightsRef = useRef(null);
    const contentDivRef = useRef(null);

    const handleGeneratePdf = (refsArray) => {
        if (showci) {

            setDownload(true)
            const element = document.getElementById('element-to-convert-insights');

            const options = {
                filename: 'myfile.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
            };
            setTimeout(() => {
                // Call the html2pdf function with the selected element and options
                html2pdf().from(element).set(options).save()
                    .then((data) => setDownload(false))


            }, 5000);
        } else if (getAllReportData?.length > 0) {
            setDownload(true)
            const element = document.getElementById('element-to-convert');

            const options = {
                filename: 'myfile.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
            };
            setTimeout(() => {
                html2pdf().from(element).set(options).save()
                    .then((data) => setDownload(false))


            }, 5000);

        }

    }

    return (
        <SplitScreenWrapper>
            <div style={{
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '25px'
            }}>
                <h3 style={{
                    fontSize: '18px',
                    margin: '0px',
                    fontWeight: '600'
                }}>
                    {
                        showci ?
                            'Competitive Intelligence Report'
                            :
                            'Brand Intelligence Report'
                    }
                </h3>
                <Close style={{
                    border: '1px solid black',
                    borderRadius: '50%',
                    padding: '2px'
                }}
                    onClick={() => setShowSplitScreen(false)}


                />
            </div>

            <div style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: 'baseline'
            }}>
                <h5 style={{
                    fontSize: '18px',
                    margin: '0px',
                    fontWeight: '600',
                    marginBottom: '5px'

                }}>{
                        showci ?
                            data?.title_name || ''
                            :
                            getAllReportData.length > 0 && getAllReportData[0]?.report?.is_topic ?
                                getAllReportData.length > 0 && getAllReportData[0]?.report?.title == 'twitter' ? X_LOGO : getAllReportData[0]?.report?.title
                                :
                                // getAllReportData.length > 0 && getAllReportData[0]?.report?.is_topic == false ?
                                getAllReportData.length > 0 && getAllReportData[0]?.report?.title
                        // :
                        // ''

                    }
                </h5>
                <p style={{
                    fontSize: '15px',
                    margin: '0px',
                    fontWeight: '400',
                    marginBottom: '5px',
                    textAlign:'justify'
                }}>
                    {showci ? '' : getAllReportData.length > 0 && getAllReportData[0]?.report?.description}
                </p>

                <h6 style={{
                    margin: '0px',
                    fontSize: '15px',
                    fontWeight: '600',
                    marginBottom: '5px'
                }}>{
                        showci ?
                            data?.subtitle || ''
                            :
                            getAllReportData.length > 0 && getAllReportData[0]?.report?.channel ?
                            getAllReportData[0]?.report?.channel == 'twitter' ? 
                            `Channel : X`
                             :
                                `Channel : ${getAllReportData[0]?.report?.channel.replace(/{|}/g, '')}`
                                :
                                getAllReportData.length > 0 && getAllReportData[0]?.report?.channel &&
                                `Channel : ${getAllReportData[0]?.report?.channel.replace(/{|}/g, '')}`

                    }</h6>
                <p style={{
                    margin: '0px',
                    fontSize: '16px',
                    color: '#7e7eef',
                    fontWeight: '500',
                    marginBottom: '5px',
                    textAlign: 'start',
                    wordBreak: 'break-all',cursor:'pointer',textDecoration:'underline'
                }}>{getAllReportData.length > 0 && getAllReportData[0]?.report?.hyperlink &&
                    `${getAllReportData[0]?.report?.hyperlink
                    }`
                    }</p>
                <p style={{
                    margin: '0px',
                    fontSize: '16px',
                    // color: '#7e7eef',
                    fontWeight: '500',
                    marginBottom: '5px'
                }}>{getAllReportData.length > 0 && getAllReportData[0]?.report?.query &&
                    `Query Used: ${getAllReportData[0]?.report?.query}`
                    }</p>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '25px'
                }}>
                    <p style={{
                        fontSize: '15px',
                        margin: '0px',
                        fontWeight: '400'
                    }}>
                        {
                            showci &&

                            `Competitive Intelligence Report Created on ${formattedDate}`
                        }
                        {getAllReportData.length > 0 && getAllReportData[0]?.report?.start_date &&
                            `${getAllReportData[0]?.report?.is_topic ? 'Topic' : 'Chatter'} Created on ${formattedDate}`}</p>
                    <div style={{
                        width: '265px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: "center"
                    }}>
                        <button style={{
                            width: '110px',
                            height: '39.66px',
                            border: '1px solid rgb(53, 60, 247)',
                            background: "transparent",
                            fontSize: '15px',
                            fontWeight: '500',
                            color: 'rgb(53, 60, 247)',
                            borderRadius: '5px'
                        }}

                            onClick={() => setShowSplitScreen(false)}
                        >Cancel</button>
                        {
                            getAllReportData?.length > 0 &&

                            <button style={{
                                width: '135px',
                                background: 'rgb(53, 60, 247)',
                                border: '1px solid rgb(53, 60, 247)',
                                borderRadius: '5px',
                                margin: '0px',
                                padding: '0px',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                padding: '9px',
                                fontSize: "15px"
                            }}
                                onClick={() => handleGeneratePdf(contentDivRef)}
                            >{
                                    download ?
                                        '...downloading' :
                                        <>Download <Download style={{

                                            fontSize: '20px'
                                        }}
                                        /></>
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>
            {
                showci &&
                <div style={{ visibility: 'hidden', position: 'absolute', top: '-9999px' }}>
                    {/* <div style={{ display: 'none' }}> */}
                    <div ref={contentDivInsightsRef} id="element-to-convert-insights">
                        <DownladInsights
                            ci_id={data?.competitive_intelligence_outlook_id || 0}
                        // email_id={data?.email_id + ''}
                        // created_at={data.creation_time}
                        // is_topic={data.is_topic}
                        // data={data}

                        // formatValue={formatValue}
                        />
                    </div>
                </div>
            }

            {
                // getAllReportData?.length > 0 && getAllReportData[0]?.render_string != null && getAllReportData[0]?.render_string != undefined &&

                // <div style={{ visibility: 'hidden', position: 'absolute', top: '-9999px' }}>

                    <div ref={contentDivRef} id="element-to-convert" >
                        <DownloadFile
                            AnalysisFilter={getAllReportData?.length > 0 && JSON?.parse(getAllReportData[0]?.render_field_string)}
                            email_id={data?.email_id + ''}
                            created_at={data.creation_time}
                            is_topic={data.is_topic}
                            data={data}
                            formatValue={formatValue}
                        />
                    </div>
                // </div>
            }
        </SplitScreenWrapper>
    );
};

export default SplitScreenReports;
