import React, { useState } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';
import styled from 'styled-components';
import ReactSelect from 'react-select';

const Container = styled.ul`

display: flex;
    align-items: center;
    justify-content: center;
list-style-type: none;
`;

const ReactSelctOption = styled(ReactSelect)`
min-width: 65px;
width: 85px;
`;

const ReactPaginationDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 13px;

`;

const ItemContainer = styled.li`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;

  &.dots:hover {
    background-color: transparent;
    cursor: default;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  &.selected {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .arrow {
    &::before {
      position: relative;
      content: '';
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.12em solid rgba(0, 0, 0, 0.87);
      border-top: 0.12em solid rgba(0, 0, 0, 0.87);
    }

    &.left {
      transform: rotate(-135deg) translate(-50%);
    }

    &.right {
      transform: rotate(45deg);
    }
  }

  &.disabled {
    pointer-events: none;

    .arrow::before {
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid rgba(0, 0, 0, 0.43);
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;


const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    reviewsData,
    className
  } = props;
  const [number, setNumber] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };


  let lastPage = paginationRange[paginationRange.length - 1];


  const createDynamicArray = (start, end) => {
    if (start > end) {
      throw new Error("Start number must be less than or equal to end number");
    }
    const array = [];
    for (let i = start; i <= end; i++) {
      array.push(

        { value: i, label: i }
      );
    }
    return array;
  };
  let dropdownOptions = createDynamicArray(1, lastPage);

  // const handleInputChange = (newValue) => {
  //   if(newValue){

  //     setSelectedOption(newValue);
  //   }
  //   // setInputValue(newValue);
  // };

  // const handleChange = (val) => {
  //   setSelectedOption(val.value);
  //   // setInputValue(selectedOption ? selectedOption.label : '');
  // };
  const handleInputChange = (newValue) => {
    if (newValue) {
      let number = new Number(newValue)
      if (number > 0 && number < lastPage + 1) {

        setInputValue(number);
        setSelectedOption(number);
      }
    }

  };

  const handleChange = (option) => {
    setSelectedOption(option);
    setInputValue(option ? option.label : '');
    onPageChange(option.label)
  };


  return (
    <Container
      className={classnames('pagination-container', { [className]: className })}
    >
      {
        paginationRange &&
        <ReactPaginationDiv >
          <h3>Page</h3>


          {paginationRange &&
            <ReactSelctOption
              onChange={handleChange}
              options={dropdownOptions}
              // value={selectedOption}
              // inputValue={inputValue}
              isSearchable
              onInputChange={handleInputChange}
              // onChange={handleChange}
              // options={dropdownOptions}
              // value={selectedOption}
              // inputValue={selectedOption}
              // isClearable
              placeholder="1"

            />}
          <h3>{lastPage && `of ${lastPage}`}</h3>

        </ReactPaginationDiv>
      }

      {/* <ItemContainer
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </ItemContainer> */}


      {/* {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <ItemContainer
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </ItemContainer>
        );
      })} */}
      {/* <ItemContainer
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      > */}

      {/* <div className="arrow right" /> */}
      {/* </ItemContainer> */}
    </Container>
  );
};

export default Pagination;