import React from 'react';
import { 
    TextWrapper,
    TableWrapper,
    ThHeader,
    ThHeader2,
    TdCells,
    TdCells2,
    StyledRow

 } from './MapTableView.styled.js';




const MapTableView = ({ data, countType }) => {
    let entries = [];
    let top5Entries = []
    if (data) {
        entries = Object.entries(data);
        const sortedEntries = entries.sort((a, b) => b[1].count - a[1].count);

        // Take the top 5 entries
        top5Entries = sortedEntries.slice(0, 5);
    } else {
        entries = []
        top5Entries = []
    }
    return (
        <div className="pdf-page" style={{  width: '500px',pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', }}>
            <TextWrapper>Top 5 States {countType}</TextWrapper>
            <TableWrapper>
                <thead>
                    <tr>
                        <ThHeader>State</ThHeader>
                        <ThHeader2>{countType}</ThHeader2>
                    </tr>
                </thead>
                <tbody>
                    {top5Entries.map((item, id) => (
                        <StyledRow key={id}>
                            <TdCells data-label="State">{item[0]}</TdCells>
                            <TdCells2 data-label="count">{item[1].count}</TdCells2>
                        </StyledRow>
                    ))}
                </tbody>


            </TableWrapper >
        </div>
    )
};


export default MapTableView;