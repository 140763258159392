import styled from "styled-components";



export const Wrapper = styled.div`
  margin: 40px 25px;
  width: 96%;
  height: 100%;
  font-family: Open Sans;

  & > div {
    margin-bottom: 40px;
  }
`;

export const PieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0px 0px 0px;
  width: 100%;
  height: 300px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  p {
    /* width: 411px;
    margin: 100px 0px 0px 20px; */

    color: #636363;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`;

export const HeadingSpan = styled.p`
  display: flex;
  margin: 30px 0px 10px 30px;
  color: #636363;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const EmotionAnalysisWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  .titleContainer {
    margin: 25px 20px;
  }
  .correlationGraphContainer {
    padding: 1px 20px 20px 20px;
  }
  & > p:first-child {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    margin: 20px 0px 0px 10px;
  }
`;

export const FilteredGraphContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 30px;
  align-items: center;
`;

export const Div = styled.div`
  margin: 20px 0px 0px 30px;
`;


export const MapContainer = styled.div`
  /* width: 100%; */
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  padding: 20px 40px;
  border-radius: 15px;
    border: 1px solid rgb(217, 217, 217);
  & > p:first-child {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const Container1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 10px;
  justify-content: space-evenly;
  margin-bottom: 10px;
  margin-top: 13px;
`;