import React, { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from '../../store/redux/slices/alertMessageSlice';
import { CrossIconWhite } from '../../Common/SvgIcon/CommonSvgIcons';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  min-width: 200px;
  width: max-content;
  left: 45%;
  top: 80px;
  background-color: #fff;
  z-index: 20;
  box-shadow: 0px 0px 8px 0px rgba(99, 99, 99, 0.24);
  border-radius: 3px;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;

  ${({ type }) => {
    if (type === 'rejected') {
      return `
      border: 1px solid #fc6758;
      background: rgba(255, 211, 209, 0.9);
      color: #fc6758;
      `;
    }
  }};

  animation: alertAnimation 0.3s linear;

  & > div {
    padding: 10px;
    font-weight: 600;
    padding-right: 30px;
  }

  & > p {
    background-color: rgb(53, 60, 247);
    ${({ type }) => {
      if (type === 'rejected') {
        return `background-color: #fc6758;`;
      }
    }};
    margin: 0px;
    height: 3px;
    animation: alertTimeAnimation 5s linear;
  }

  & > span {
    position: absolute;
    right: 5px;
    top: 29%;
    cursor: pointer;
    transform: scale(0.8);

    & > svg {
      stroke: #000000;
    }

    &:hover {
      transform: scale(1);
    }
  }
`;

function AlertPopUp() {
  const dispatch = useDispatch();

  const alertPopUp = useSelector((state) => state.message);
  if (alertPopUp.message && alertPopUp.timer !== 'off') {
    setTimeout(() => dispatch(clearMessage()), alertPopUp.timer);
  }

  return (
    <Wrapper type={alertPopUp.type}>
      <div>{alertPopUp.message}</div>
      <p></p>
      {alertPopUp.timer === 'off' && (
        <span onClick={() => dispatch(clearMessage())}>{CrossIconWhite}</span>
      )}
    </Wrapper>
  );
}

export default AlertPopUp;
