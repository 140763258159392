import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import CheckIcon from '@mui/icons-material/Check';
import { location } from '../../../utils/locationList';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const StyledSelect = styled(Select)`
  & > div > :nth-child(1) {
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const Input2 = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 21px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background: #fff;
  margin: 10px 0;

  color: #636363;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;

  ::placeholder {
    font-weight: 500 !important;
  }
`;

const Label2 = styled.span`
  color: #636363;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
`;

const RadiusWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 0px 0px 15px 15px;
  border: 1px solid #a2a2a2;
  background: #fff;
  box-sizing: border-box;
`;

const RangeSpan = styled.span`
  color: rgba(99, 99, 99, 0.49);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const LocationOrigin = ({ onChange, placeholder, onChangeRadius }) => {
  const optionStyle = {
    marginLeft: '5px',
    color: '#707070',
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '12px',
  };

  const IconStyle = {
    width: '25px',
    height: '25px',
    border: '1px solid rgba(152, 152, 152, 0.24)',
    margin: '8px 8px 0px 8px',
    borderRadius: '50%',
    color: '#fff',
    background: '#fff',
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: showSubItem ? '15px 15px 0px 0px' : '15px',
      border: '1px solid #A2A2A2',
      marginLeft: '0px',
      background: '#FFF',
      minHeight: '54px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#636363',
      fontSize: '16px',
      textAlign: 'left',
      marginLeft: '10px',
      //fontWeight:'500',
    }),
    option: (provided, state) => ({
      ...provided,
      width: '518px',
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Montserrat',
    }),
    singleValueLabel: (provided) => ({
      ...provided,
      color: '#636363',
    }),
    singleValue: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2FE',
      borderRadius: '40px',
      width: '120px',
      height: '30px',
      padding: '4px 0px 0px 10px',
    }),
    singleValueRemove: (provided) => ({
      ...provided,
      color: '#636363',
    }),
  };

  // Sample list of countries
  const [searchValue, setSearchValue] = useState('');
  const [showSubItem, setShowSubItem] = useState(false);
  const [textInputValue, setTextInputValue] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const handleChange = (e) => {
    const selectedValues = e ? e.map(option => option.value).join(',') : '';

  
    setSelectedCountries(e || []);
    onChange(selectedValues);
    setShowSubItem(true);
  };

  const handleTextInputChange = (event) => {
    if (event.target.value > 2500) {
      setTextInputValue(2500);
      onChangeRadius(2500);
      return;
    }
    setTextInputValue(event.target.value);
    onChangeRadius(event.target.value);
  };

  const Option = ({ children, isSelected, innerProps }) => (
    <div style={{ textAlign: 'left', display: 'flex' }} {...innerProps}>
      <CheckIcon active={selectedCountries} style={IconStyle} />
      <span style={optionStyle}>{children}</span>
    </div>
  );

  return (
    <Wrapper>
      <InputWrapper>
        <StyledSelect
          options={location}
          onChange={handleChange}
          value={selectedCountries}
          isMulti
          styles={customStyles}
          components={{ Option }}
          placeholder={placeholder}
        />
      </InputWrapper>
      {showSubItem && (
        <RadiusWrapper>
          <Label2>Specify the radius for geospatial sweeping</Label2>
          <Input2
            type='number'
            placeholder='Enter radius (in kms)'
            value={textInputValue}
            onChange={handleTextInputChange}
          />
          <RangeSpan>Range upto 2500 km</RangeSpan>
        </RadiusWrapper>
      )}
    </Wrapper>
  );
};

export default LocationOrigin;
