import React from 'react';
import styled from 'styled-components';

const CustomButton = styled.button`
  width: 49%;
  height: 48px;
  background: ${(props) => (props.disabled ? '#ccc' : '#353cf7')};
  border-radius: 100px;
  border: none;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.9)};
  }

  &:active {
    transform: ${(props) => (props.disabled ? 'none' : 'scale(0.98)')};
  }
`;

const Button = ({ value, onClick, style = {}, disabled=false }) => {
  return (
    <CustomButton onClick={onClick} style={style} disabled={disabled}>
      {value}
    </CustomButton>
  );
};

export default Button;
