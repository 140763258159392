import axiosInstance from '../utils/axiosInstance';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const tenantAnalytics = () => {
  return axiosInstance
    .get(API_URL + 'orchestration/tenant_analytics')
    .then((response) => {
      return response.data;
    });
};

const tenantManagementList = () => {
  return axiosInstance
    .get(API_URL + 'orchestration/tenant_management_list')
    .then((response) => {
      return response.data;
    });
};

const teamManagementList = () => {
  return axiosInstance
    .get(API_URL + 'tenantmaster/getallexecutives')
    .then((response) => {
      return response.data;
    });
};

const createExecutive = (body) => {
  return axiosInstance
    .post(API_URL + 'tenantmaster/createexecutive', body)
    .then((response) => {
      return response.data;
    });
};

const deleteExecutive = (user_id) => {
  return axiosInstance
    .delete(API_URL + `tenantmaster/deleteexecutive/${user_id}`)
    .then((response) => {
      return response.data;
    });
};

const createTenant = (body) => {
  return axiosInstance
    .post(API_URL + 'tenantmaster/createtenant', body)
    .then((response) => {
      return response.data;
    });
};

const getAllUserOf_A_Tenant = (tenant_id) => {
  return axiosInstance
    .get(API_URL + `/get/userall/${tenant_id}`)
    .then((response) => {
      return response.data;
    });
};

const createUser = (body) => {
  return axiosInstance
    .post(API_URL + 'tenant/createuser', body)
    .then((response) => {
      return response.data;
    });
};

const deleteUser = ({ tenant_id, user_id }) => {
  return axiosInstance
    .delete(API_URL + `tenant/deleteuser/${tenant_id}/${user_id}`)
    .then((response) => {
      console.log(`deleteExecutive`, response.data);
    });
};

const deleteTenant = (tenant_id) => {
  return axiosInstance
    .delete(API_URL + `tenantmaster/deletetenant/${tenant_id}`)
    .then((response) => {
      return response.data;
    });
};

const ExecutivePortalService = {
  tenantAnalytics,
  tenantManagementList,
  teamManagementList,
  createExecutive,
  deleteExecutive,
  createTenant,
  getAllUserOf_A_Tenant,
  createUser,
  deleteUser,
  deleteTenant,
};

export default ExecutivePortalService;
