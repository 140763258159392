import React from 'react';
export default (
  <svg
    width='50'
    height='30'
    viewBox='0 0 26 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='-2.5'
      y='1.8'
      width='38'
      height='26'
      rx='3'
      stroke='#353CF7'
      strokeWidth='1'
    />
    <rect
      x='10'
      y='7'
      width='6'
      height='6'
      rx='1'
      fill='white'
      stroke='#353CF7'
      strokeWidth='1.3'
    />
    <rect
      x='19'
      y='7'
      width='6'
      height='6'
      rx='1'
      fill='white'
      stroke='#353CF7'
      strokeWidth='1.3'
    />
    <rect
      x='10'
      y='16'
      width='6'
      height='6'
      rx='1'
      fill='white'
      stroke='#353CF7'
      strokeWidth='1.3'
    />
    <rect
      x='19'
      y='16'
      width='6'
      height='6'
      rx='1'
      fill='white'
      stroke='#353CF7'
      strokeWidth='1.3'
    />
  </svg>
);
