import { Legend } from 'chart.js';
import React, { useState, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Label, CartesianGrid } from 'recharts';

const ReviewLineChart = ({ data, height =280 ,ylabel}) => {
  const parentRef = useRef(null);
  const [numericWidth, setNumericWidth] = useState(680);

  const maxYValue = Math.max(...data?.map((item) => item.y));
  const minYValue = Math.min(...data?.map((item) => item.y));

  const domainPadding = 5;

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const parentWidth = parentRef?.current?.offsetWidth ;
      setNumericWidth(parentWidth);
    });

    resizeObserver.observe(parentRef?.current);

    return () => resizeObserver.disconnect();
  }, []);



  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%' }}>
      <LineChart
        width={numericWidth}
        height={height}
        data={data}
        margin={{ top: 20, right: 40, left: 10, bottom: 20 }}

      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey='date'
          dy={8}
          interval={data.length > 20 ? Math.ceil(data.length / 20) : 0}
          tick={{ fontSize: 9, fontWeight: 'bold', angle: -35 }}



        >
          <Label
            value='Timeline'
            offset={-20}
            position='insideBottom'
            fontWeight={500}
            fill='#222'
            fontSize={14}
          />
        </XAxis>
        <YAxis
          type='number'
          /* domain={[minYValue - domainPadding, maxYValue + domainPadding]} */
          tick={{ fontSize: 12 }}
          tickCount={6}
        >
          <Label
            value={ylabel}
            offset={5}
            fontSize={16}
            position='insideLeft'
            angle={-90}
            fontWeight={500}
            fill='#222'
          />
          {/* <Label
            value='Reviews'
            offset={15}
            fontSize={16}
            position='insideLeft'
            angle={-90}
            fontWeight={500}
            fill='#222'
          /> */}
        </YAxis>
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={ylabel?.toLowerCase() || 'reviews'} stroke="#2E5CD2" />
        
        {/* <Line type="monotone" dataKey="reviews" stroke="#2E5CD2" /> */}
        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </LineChart>
    </div>
  );
};

export default ReviewLineChart;
