import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';

import ExecutivePortalService from '../services/executivePortal.service';

export const tenantAnalytics = createAsyncThunk(
  'tenantAnalytics',
  async (_, thunkAPI) => {
    try {
      const data = await ExecutivePortalService.tenantAnalytics();
      //   const data2 = await ExecutivePortalService.tenantManagementList();

      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const tenantManagementList = createAsyncThunk(
  'tenantManagementList',
  async (_, thunkAPI) => {
    try {
      const data = await ExecutivePortalService.tenantManagementList();
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const teamManagementList = createAsyncThunk(
  'teamManagementList',
  async (_, thunkAPI) => {
    try {
      const data = await ExecutivePortalService.teamManagementList();
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createTenant = createAsyncThunk(
  'createTenant',
  async (createTenantData, thunkAPI) => {
    try {
      await ExecutivePortalService.createTenant(createTenantData);
      const data = await ExecutivePortalService.tenantManagementList();
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteTenant = createAsyncThunk(
  'deleteTenant',
  async ({tenant_id,tenant_name}, thunkAPI) => {
    try {
      await ExecutivePortalService.deleteTenant(tenant_id);
      thunkAPI.dispatch(setMessage(`Tenant "${tenant_name}" deleted successfully`));
      const data = await ExecutivePortalService.tenantManagementList();
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createExecutive = createAsyncThunk(
  'createExecutive',
  async (body, thunkAPI) => {
    try {
      const updatedArray = body.map((element) => {
        const user_name = element.userName;
        const first_name = element.firstName;
        const last_name = element.lastName;
        const password = element.password;
        const email_id = element.email;
        const role_id = element.role === 'BrandAstra Support' ? 2 : 1;

        return {
          user_name,
          first_name,
          last_name,
          password,
          email_id,
          role_id,
        };
      });
      await ExecutivePortalService.createExecutive(updatedArray);
      const data = await ExecutivePortalService.teamManagementList();
      thunkAPI.dispatch(
        setMessage(
          `Successfully added new team members`
        )
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteExecutive = createAsyncThunk(
  'deleteExecutive',
  async (user_id, thunkAPI) => {
    try {
      await ExecutivePortalService.deleteExecutive(user_id);
      const data = await ExecutivePortalService.teamManagementList();
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  tenantAnalyticsData: null,
  tenantManagementListData: null,
  teamManagementListData: null,
};

const executivePortalSlice = createSlice({
  name: 'executivePortal',
  initialState,
  reducers: {
    updateTeamManagementListData(user_id) {
      console.log('updateTeamManagementListData', user_id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tenantAnalytics.fulfilled, (state, action) => {
        state.tenantAnalyticsData = action.payload;
      })
      .addCase(tenantAnalytics.rejected, (state, action) => {
        state.tenantAnalyticsData = null;
        console.log('rejected');
      })
      .addCase(tenantManagementList.fulfilled, (state, action) => {
        state.tenantManagementListData = action.payload;
      })
      .addCase(tenantManagementList.rejected, (state, action) => {
        state.tenantManagementListData = null;
        console.log('rejected');
      })
      .addCase(teamManagementList.fulfilled, (state, action) => {
        state.teamManagementListData = action.payload;
      })
      .addCase(teamManagementList.rejected, (state, action) => {
        state.teamManagementListData = null;
        console.log('rejected');
      })
      .addCase(createTenant.fulfilled, (state, action) => {
        state.tenantManagementListData = action.payload;
        console.log('fulfilled');
      })
      .addCase(createTenant.rejected, (state, action) => {
        // state.teamManagementListData = null;
        console.log('rejected');
      })
      .addCase(deleteExecutive.fulfilled, (state, action) => {
        state.teamManagementListData = action.payload;
        console.log('User deleted successfully');
      })
      .addCase(deleteExecutive.rejected, (state, action) => {
        console.log('User deleted failed');
      })
      .addCase(createExecutive.fulfilled, (state, action) => {
        state.teamManagementListData = action.payload;
        console.log('User Created successfully');
      })
      .addCase(createExecutive.rejected, (state, action) => {
        console.log('User Creation failed');
      })
      .addCase(deleteTenant.fulfilled, (state, action) => {
        state.tenantManagementListData = action.payload;
        console.log('Delete Tenant successfully');
      })
      .addCase(deleteTenant.rejected, (state, action) => {
        console.log('Delete Tenant failed');
      });
  },
});

export default executivePortalSlice.reducer;
