import axios from 'axios';
import { isTokenValid } from '../../../utils/index';

// Create an Axios instance with base URL and any other default config
const tenantAxiosInstance = axios.create();

// Add an interceptor to attach the authentication header
tenantAxiosInstance.interceptors.request.use((config) => {
  const authToken = JSON.parse(localStorage.getItem('brandUser'))?.access_token;
  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }
  return config;
});

tenantAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const authToken = JSON.parse(
      localStorage.getItem('brandUser')
    )?.access_token;
    if (!isTokenValid(authToken)) {
      localStorage.removeItem('brandUser');
      window.location.href = `/${window.location.pathname.split('/')[1]}/login`; // /tenantName/login
    }
    return Promise.reject(error);
  }
);

export default tenantAxiosInstance;
