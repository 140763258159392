import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MyDatePicker = ({value,dateHandler,format='dd-MM-yyyy',min,max,name}) => {
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const validateDate = (date) => {
    // Convert date strings to Date objects
    const start = new Date(min); // Explicitly parse the initialStartDate
    const end = new Date(max);     // Explicitly parse the initialEndDate
    const datedate = new Date(date);          // Explicitly parse the date to be validated

    if (!isValidDate(start) || !isValidDate(end) || !isValidDate(datedate)) return false;

    const isAfterMin = datedate >= start;
    const isBeforeMax = datedate <= end;

    // console.log("Start Date Range:", start);
    // console.log("End Date Range:", end);
    // console.log("Date Being Validated:", datedate);

    return isAfterMin && isBeforeMax;
  };

  const changeDate = (date) => {
    if(validateDate(date)){
      dateHandler({value:date,name})
    }else{
      if(name == 'firstPostDate'){
        dateHandler({value:min,name})
      }else{
        dateHandler({value:max,name})
      }
     
    }
  }



  return (
    <div>
      <DatePicker
        selected={value}
        onChange={(date) => changeDate(date)}
        dateFormat={format} // This is how it will display in the input field
        minDate={min}  // Set minimum date
        maxDate={max}  // Set maximum date
      />
    </div>
  );
};

export default MyDatePicker;
