import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import {
  ExportIcon,
  GreenEllipseIcon,
  NeutralEllipseIcon,
  NeutralIcon,
  RedEllipseIcon,
} from '../../../Common/SvgIcon/CommonSvgIcons';
import SentimentDrillDown from './SentimentDrillDown';
import {
  Wrapper,
  TopRepostWrapper,
  PostCountWrapper,
  TopPostWrapper,
  PositiveCircle,
  NegativeCircle,
  NeutralCircle,
  Footer
} from './SentimentsTab.styled'
import CollapsibleText from '../../../components/ReviewText/collapsibleText';

function SentimentsTab({
  plan,
  selectedReport,
  hideDropdown
}) {

  let planSpecificData;
  if (selectedReport) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = selectedReport?.data;
    } else if (plan === 'BrandEssence') {
      planSpecificData = selectedReport?.data;
      // planSpecificData = selectedReport?.BrandAstraEssence;
    } else if (plan === 'BrandAstraElite') {
      planSpecificData = selectedReport?.BrandAstraElite;
    } else {

      planSpecificData = selectedReport?.data;
    }
  }

  return (
    <Wrapper className="pdf-page" style={{ pageBreakInside: 'avoid' }}>

      <SentimentDrillDown
        data={planSpecificData}
        startDate={selectedReport?.start_date}
        endDate={selectedReport?.end_date}
        hideDropdown={hideDropdown}
      // filterOption={filterOption}
      // view={view}
      />
      {/* (plan === 'BrandAstraPro' || plan === 'AstraLite') && */}
      {(
        selectedReport?.channel === 'flipkart' ?
          <>
            <TopRepostWrapper>
              <p className='title'>Top Liked Reviews</p>

              <PostCountWrapper>
                <div>
                  <PositiveCircle />
                  {`Total Positive Liked Reviews - ${planSpecificData?.sentiments_tab?.top_liked_reviews
                    ?.total_positive_liked_reviews
                    }`}
                </div>
                <div>
                  <NegativeCircle />
                  Total Negative Liked Reviews -{' '}
                  {planSpecificData?.sentiments_tab?.top_liked_reviews
                    ?.total_negative_liked_reviews}
                </div>
                <div>
                  <NeutralCircle />
                  Total Neutral Liked Reviews -{' '}
                  {planSpecificData?.sentiments_tab?.top_liked_reviews
                    ?.total_neutral_liked_reviews}
                </div> 
              </PostCountWrapper>
              <TopPostWrapper>
                <div className='positiveReview'>
                  <PositiveCircle />
                  <div className='reviewText'>
                    Most Positive Liked Review
                    <br />
                    <br />
                    <CollapsibleText text={planSpecificData?.sentiments_tab?.top_liked_reviews
                      ?.top_positive_liked_reviews?.most_positive_liked_review || 'No Data Avaliable'} background='rgba(92, 227, 144, 0.2)'/>
             
                    
                  </div>
                  <div className='reviewCount'>
                    {planSpecificData?.sentiments_tab?.top_liked_reviews
                      ?.top_positive_liked_reviews?.most_positive_liked_review_count}
                    <div className='peopleLiked'>
                      People Liked
                    </div>
                  </div>
                </div>
                <div className='negativeReview'>
                  <NegativeCircle />
                  <div className='reviewText'>
                    Most Negative Liked Review
                    <br />
                    <br />
                    <CollapsibleText text={planSpecificData?.sentiments_tab?.top_liked_reviews
                      ?.top_negative_liked_reviews?.most_negative_liked_review || 'No Data Avaliable'} background='rgba(249, 98, 89, 0.18)' />
                  </div>
                  <div className='reviewCount'>
                    {planSpecificData?.sentiments_tab?.top_liked_reviews
                      ?.top_negative_liked_reviews?.most_negative_liked_review_count}
                    <div className='peopleLiked'>
                      People Liked
                    </div>
                  </div>
                </div>
                <div className='neutralReview'>
                  <NeutralCircle />
                  <div className='reviewText'>
                    Most Neutral Liked Review
                    <br />
                    <br />
                    <CollapsibleText text={planSpecificData?.sentiments_tab?.top_liked_reviews
                      ?.top_neutral_liked_reviews?.most_neutral_liked_review || 'No Data Avaliable'} background='rgba(254, 222, 141, 0.37)'/>
              
                  </div>
                  <div className='reviewCount'>
                    {planSpecificData?.sentiments_tab?.top_liked_reviews
                      ?.top_neutral_liked_reviews?.most_neutral_liked_review_count}
                    <div className='peopleLiked'>
                      People Liked
                    </div>
                  </div>
                </div>
              </TopPostWrapper>
              <Footer>
                This data is observed from{' '}
                { selectedReport?.start_date}{' '}
                to{' '}
                {selectedReport?.end_date}
              </Footer>
            </TopRepostWrapper>
          </>
          :
          <>
            <TopRepostWrapper>
              <p className='title'>Top Helpful Reviews</p>
              <PostCountWrapper>
                <div>
                  <PositiveCircle />
                  {`Total Positive Helpful Reviews - ${planSpecificData?.sentiments_tab?.top_helpfull_reviews
                    ?.no_of_positive_helpful_reviews
                    }`}
                </div>
                <div>
                  <NegativeCircle />
                  Total Negative Helpful Reviews -{' '}
                  {planSpecificData?.sentiments_tab?.top_helpfull_reviews
                    ?.no_of_negative_helpful_reviews}
                </div>
                <div>
                  <NeutralCircle />
                  Total Neutral Helpful Reviews -{' '}
                  {planSpecificData?.sentiments_tab?.top_helpfull_reviews
                    ?.no_of_neutral_helpful_reviews}
                </div>
              </PostCountWrapper>
              <TopPostWrapper>
                <div className='positiveReview'>
                  <PositiveCircle />
                  <div className='reviewText'>
                    Most Positive Helpful Review
                    <br />
                    <br />
                    
                <CollapsibleText text={planSpecificData?.sentiments_tab?.top_helpfull_reviews?.top_positive_helpful_reviews?.most_positive_helpful_review || 'No Data Avaliable'} background='rgba(92, 227, 144, 0.2)' />
                  </div>
                  <div className='reviewCount'>
                    {planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_positive_helpful_reviews?.most_positive_helpful_review_count}
                  </div>
                </div>
                <div className='negativeReview'>
                  <NegativeCircle />
                  <div className='reviewText'>
                    Most Negative Helpful Review
                    <br />
                    <br />
                    
                <CollapsibleText text={planSpecificData?.sentiments_tab?.top_helpfull_reviews?.top_negative_helpful_reviews?.most_negative_helpful_review || 'No Data Avaliable'} background='rgba(249, 98, 89, 0.18)'/>
                    
                  </div>
                  <div className='reviewCount'>
                    {planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_negative_helpful_reviews?.most_negative_helpful_review_count}
                  </div>
                </div>
                <div className='neutralReview'>
                  <NeutralCircle />
                  <div className='reviewText'>
                    Most Neutral Helpful Review
                    <br />
                    <br />
                    
                <CollapsibleText text={planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_neutral_helpful_reviews?.most_neutral_helpful_review || 'No Data Avaliable'} background='rgba(254, 222, 141, 0.37)'/>
                    
                  </div>
                  <div className='reviewCount'>
                    {planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_neutral_helpful_reviews?.most_neutral_helpful_review_count}
                  </div>
                </div>
              </TopPostWrapper>
              <Footer>
                This data is observed from{' '}
                {selectedReport?.start_date}{' '}
                to{' '}
                {selectedReport?.end_date}
              </Footer>
            </TopRepostWrapper>
          </>
      )}
    </Wrapper>
  );
}

export default SentimentsTab;
