import React, { useState } from 'react';
import styled from 'styled-components';
import {
  download_icon,
  report_icon,
} from '../../Common/SvgIcon/CommonSvgIcons';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PopUp from '../../Common/PopUp/PopUp';
import ViewReport from './ViewReport';
import DownloadPDFReport from './DownloadPDFReport';

const Wrapper = styled.div`
  border-left: 1px solid rgba(99, 99, 99, 0.2);
  text-align: left;
  padding: 0 20px;
  padding-bottom: 100px;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #353cf7;
    border-radius: 10px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
`;

const Subject = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 20px 0;
`;
const DownloadButton = styled.button`
  border-radius: 10px;
  background: #353cf7;
  color: #fff;

  font-size: 20px;
  font-weight: 600;

  padding: 5px 15px;
  border: none;

  display: flex;
  justify=-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

const MailPreview = styled.div`
  border-radius: 10px;
  background: rgba(174, 177, 252, 0.16);
  min-height: 70%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;

  color: #000;
  font-size: 24px;
  font-weight: 600;

  & > span {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 400;
  }
`;

const OpenButton = styled(DownloadButton)`
  font-size: 15px;
  padding: 9px 50px;
`;

const ReportWrapper = styled.div`
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #a2a2a2;
  position: relative;

  .fullScreenIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 20;
    cursor: pointer;
  }
`;

const PopupReportWrapper = styled.div`
  overflow: scroll !important;
  width: 100%;
`;

const PDFWrapper = styled.div`
  position: absolute;
  width: 98vw;
  height: 100vh;

  top: 0;
`;

const MailReport = ({ data = {} }) => {
  const [showReport, setShowReport] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);

  const handleCancelClick = () => {
    setShowReport(false);
    setOpenPopup(false);
  };

  if (openPDF) {
    return (
      <PDFWrapper>
        <DownloadPDFReport onClick={() => setOpenPDF(false)}/>
      </PDFWrapper>
    );
  }
  return (
    <Wrapper>
      <Title>
        {data?.user_name} has generated {data.channel}{' '}
        {data.is_topic ? 'Topic' : 'Chatter Tracker'} Report
      </Title>
      <Subject>
        Subject: {data?.channel} {data?.is_topic ? 'Topic' : 'Chatter Tracker'}{' '}
        {data?.title_name}
        {!showReport && (
          <DownloadButton onClick={() => setOpenPDF(true)}>
            Download
            {download_icon}
          </DownloadButton>
        )}
      </Subject>
      <MailPreview>
        {report_icon}
        {data?.user_name} has generated <br />
        {data.channel} {data.is_topic ? 'Topic' : 'Chatter Tracker'} Report{' '}
        <span>Here's the report that {data?.user_name} generated </span>
        <OpenButton onClick={() => setShowReport((prev) => !prev)}>
          Open
        </OpenButton>
      </MailPreview>
      {showReport && (
        <ReportWrapper>
          <div className='fullScreenIcon' onClick={() => setOpenPopup(true)}>
            <FullscreenIcon />
          </div>
          <ViewReport
            email_id={data?.email_id + ''}
            handleCancelClick={handleCancelClick}
            created_at={data.creation_time}
          />
        </ReportWrapper>
      )}
      {openPopup && (
        <PopUp onClose={() => setOpenPopup(false)}>
          <PopupReportWrapper>
            <ViewReport
              email_id={data?.email_id + ''}
              handleCancelClick={handleCancelClick}
              created_at={data.creation_time}
              is_topic={data.is_topic}
            />
          </PopupReportWrapper>
        </PopUp>
      )}
    </Wrapper>
  );
};

export default MailReport;
