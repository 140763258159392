
import styled from 'styled-components';


export const Sentiment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 20px;
  line-height: 25px;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &> div: first-child {
    margin: 0px 0px 10px 30px;
  }

 /*  button {
    border-radius: 6px;
    border: 1px solid #a2a2a2;

    background: ${(props) => (props.selected ? '#00ff00' : '#353cf7')};

    width: 155px;
    height: 41px;
    color: #fff;

    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  } */
  /* button + button {
    margin-left: 0px; /* Adjust margin between buttons */
    background: ${(props) => (props.button2Clicked ? '#353cf7' : '#ffff')};
  } */
`;

export const Button = styled.button`
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #a2a2a2;

  background: ${(props) => (props.selected ? '#353cf7' : '#ffff')};

  width: 155px;
  height: 41px;
  color: ${(props) => (props.selected ? '#ffff' : '#fffff')};

  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 15px 0px 10px 35px;

  input[type='checkbox'] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #A2A2A2;  // Change the border color
    background-color: ${(props) => (props.selected ? 'blue' : 'transparent')};
    border-radius: 3px;
    vertical-align: middle;
    margin-right: 10px;

    &:checked {
      background-color: blue;

       &::before {
        content: '✔';  // Unicode character for checkmark
        display: block;
        margin-top: 2px;
        width: 15px;
        height: 15px;
        text-align: center;
        line-height: 15px;
        color: white;  // Set the color of the tick mark
      }
    }

    &:focus {
      outline: none;
      border-color: #0000ff;
    }
`;

export const Title = styled.span`
  color: #636363;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0px 20px 20px;
`;
