// import React, { useState, useRef, useEffect } from 'react';

// const CollapsibleText = ({ text, background }) => {
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [isTruncated, setIsTruncated] = useState(false);
//   const textRef = useRef(null);
//   const [maxHeight, setMaxHeight] = useState('none');

//   useEffect(() => {
//     if (textRef.current) {
//       const computedStyle = getComputedStyle(textRef.current);
//       let lineHeight = computedStyle.lineHeight;

//       // If lineHeight is 'normal', we need to calculate it
//       if (lineHeight === 'normal') {
//         const fontSize = parseFloat(computedStyle.fontSize);
//         lineHeight = fontSize * 1.2; // assuming normal line height is 1.2 times the font size
//       } else {
//         lineHeight = parseFloat(lineHeight);
//       }

//       const height = textRef.current.scrollHeight;
//       const lines = height / lineHeight;
//       if (lines > 4) {
//         setIsTruncated(true);
//         setMaxHeight(`${lineHeight * 4}px`);
//       } else {
//         setIsTruncated(false);
//         setMaxHeight('none');
//       }
//     }
//   }, [text]);

//   const toggleExpanded = () => {
//     setIsExpanded(!isExpanded);
//   };
//   return (
//     <div>
//       <div
//         ref={textRef}
//         style={{
//           maxHeight: isExpanded ? 'none' : maxHeight,
//           overflow: 'hidden',
//           transition: 'max-height 0.3s ease',
//           lineHeight: '1.5', // set an explicit line-height
//           position: 'relative'
//         }}
//       > 
//         {isTruncated && !isExpanded ? `${text?.slice(0, 400)}...` : text}
//         {isTruncated && !isExpanded && (
//           <span
//             style={{
//               position: 'absolute',
//               bottom: 0,
//               right: 0,
//               background: background ? background :'white', // To prevent text overlap
//               cursor: 'pointer',
//               textDecoration: 'underline',
//               color: 'black',
//             }}
//             onClick={toggleExpanded}
//           >
//             ... Read More
//           </span>
//         )}
//       </div>
//       {isTruncated && isExpanded && (
//         <span
//           style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black' }}
//           onClick={toggleExpanded}
//         >
//           Read Less
//         </span>
//       )}
//     </div>
//   );
// };

// export default CollapsibleText;

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';


const TextHoverLink = styled.div`
  color:blue ;
   cursor:pointer;
     
  &:hover {
    /* Define hover styles here */
    text-decoration: underline;
  }
`;

const CollapsibleText = ({ text, background = 'white',link }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('none');

  useEffect(() => {
    if (textRef.current) {
      const computedStyle = getComputedStyle(textRef.current);
      let lineHeight = computedStyle.lineHeight;

      // If lineHeight is 'normal', we need to calculate it
      if (lineHeight === 'normal') {
        const fontSize = parseFloat(computedStyle.fontSize);
        lineHeight = fontSize * 1.2; // assuming normal line height is 1.2 times the font size
      } else {
        lineHeight = parseFloat(lineHeight);
      }

      const height = textRef.current.scrollHeight;
      const lines = height / lineHeight;
      if (lines > 4) {
        setIsTruncated(true);
        setMaxHeight(`${lineHeight * 4}px`);
      } else {
        setIsTruncated(false);
        setMaxHeight('none');
      }
    }
  }, [text]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const newWindowFunc = () => {
    if(link){
      window.open(link, '_blank');
    }
  }
  return (
    <div>
      <div
        ref={textRef}
        style={{
          maxHeight: isExpanded ? 'none' : maxHeight,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease',
          lineHeight: '1.5', // set an explicit line-height
          position: 'relative',
          paddingBottom: isTruncated && !isExpanded ? '1.5em' : '0', // Add padding for Read More
        }}
      > 
        {isTruncated && !isExpanded ? `${text?.slice(0, 350)}` : text}
        {isTruncated && !isExpanded && (
          <div style={{ position: 'absolute', bottom: 0, right: 0, padding: '0 0.2em' }}>
            <span
              style={{
                background,
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'black',
                fontSize: 13
              }}
              onClick={toggleExpanded}
            >
              ... Read More
            </span>
          </div>
        )}
      </div>
      {isTruncated && isExpanded && (
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black', fontSize: 13 }}
          onClick={toggleExpanded}
        >
          Read Less
        </span>
      )}
      <TextHoverLink onClick={()=>newWindowFunc()}  >
        {link}
      </TextHoverLink>
    </div>
  );
};

export default CollapsibleText;