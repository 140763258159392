import React, { useState } from 'react';
import Select from 'react-select';
import './DropDown.css';

function Dropdown({
  options,
  selectedTab,
  selectedOption,
  handleSelectChange,
  setSelectedTab,
  setSelectedOption
}) {

  return (
    <Select
      closeMenuOnSelect={false}
      // options={options}
      options={options}
      value={selectedOption}
      // defaultValue={[options[0], options[1]]}
      onChange={handleSelectChange}
      className='custom-select'
      classNamePrefix='react-select'
      isMulti
    />
  );
}

export default Dropdown;
