import { Container } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  font-family: Open Sans;
  width: 100%;

  //change this to your own
  margin: 30px 0px 0px 0px; 
`

const BarsWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 15% 80% 5%;
  gap: 5px;
  margin-left: -35px;
  padding-top:1px;

  color: #636363;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;

`;

const Bar = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;

 & > div: last-child {
    margin: 5px 0px 0px 5px;
  }
`;

const FilledBar = styled.div`
  flex: ${(props) => props.percentage};
  height: 15px;
  background-color: #6640B8;
  border-radius: ${(props) =>
    props.percentage === 100 ? '3px' : '3px 0 0 3px'};
  margin: 8px 0px 0px 55px;
`;

const Label = styled.span`
  width: 80%;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  text-align: right;
  margin: 10px 0px 0px 75px;
`;
 
const WordListGraph = ({data = []}) => {
  const data1 = [
    { text: 'IPhone', value: 50 },
    { text: 'SmartPhone', value: 35 },
    { text: 'Brand', value: 30 },
    { text: 'Mobile', value: 30 },
    { text: 'Device', value: 25 },
    { text: 'ios', value: 25 },
    { text: 'Mac', value: 25 },
    { text: 'communication', value: 10 },
    { text: 'battery', value: 20},
    { text: 'noise', value: 50},
    { text: 'best', value: 35},
    {text: 'valuable', value:15}

  ];

  const sortedData = [...data].sort((a, b) => b.count - a.count);

  const maxValue = Math.max(...sortedData.map((item) => item.count));
  const multiplier = 500 / maxValue;

  return (
    <Wrapper>
      {sortedData?.map((item, index) => (
        <BarsWrapper key={index}>
          <Label>{item.word}</Label>
          <Bar>
          <FilledBar style={{ width: Math.floor(item.count * multiplier) }}></FilledBar>
          <div style={{marginLeft:'5px'}}>{`${item.count}`}</div>
          </Bar>
          {/* <PercentageLabel>{`${percentage}%`}</PercentageLabel> */}
          
        </BarsWrapper>
      ))}
    </Wrapper>
  )
}

export default WordListGraph