import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';

import AuthService from '../services/auth.service';

const user = () => JSON.parse(localStorage.getItem('user')) || false;

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      localStorage.setItem('user', JSON.stringify(data));
      return { ...data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ currentPassword, newPassword }, thunkAPI) => {
    try {
      let currentUser = user();
      const data = await AuthService.changePassword(
        currentPassword,
        newPassword,
        currentUser.user_details.user_id
      );
      currentUser.access_token = data.access_token;
      localStorage.setItem('user', JSON.stringify(currentUser));
      thunkAPI.dispatch(setMessage('Password change successful'));
      return { ...data };
    } catch (error) {
      const message =
        error.response.data.detail || 'Something went wrong, Try again';
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ token, new_password }, thunkAPI) => {
    try {
      const data = await AuthService.resetPassword(new_password, token);
      thunkAPI.dispatch(setMessage(data.message));
      return { ...data };
    } catch (error) {
      const message =
        error.response.data.detail || 'Something went wrong, Try again';
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const forgotPassword_api = createAsyncThunk(
  'auth/forgotPassword',
  async (email, thunkAPI) => {
    try {
      const data = await AuthService.forgotPassword(email);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthService.logout();
});

const initialState = {
  user: user().user_details,
  isLoggedIn: user().access_token ? true : false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.user_details;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        console.log('change password successful');
      })
      .addCase(changePassword.rejected, (state, action) => {
        console.log('Rejected');
      });
  },
});

export default authSlice.reducer;
