import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: #222;

  position: relative;

  & > img {
    height: 99.8vh;
    width: 99.8vw;
    z-index: 1;
  }

  & > button {
    position: absolute;
    bottom: 10%;
    right: 9%;
    width: 250px;
    height: 60px;
    z-index: 2;
    cursor: pointer;

    opacity: 0;
  }
`;

const FirstPage = (props) => {
  const handleClick = () => {
    window.open('https://www.copperpod.digital/', '_blank');
  };

  return (
    <Wrapper>
      <img
        src={`${process.env.PUBLIC_URL}/images/BrandAstraFirstPage.png`}
        alt='BrandAstraFirstPage'
      />
      <button onClick={handleClick}></button>
    </Wrapper>
  );
};

export default FirstPage;
