// utils/chartHelpers.js


// components/LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend } from 'chart.js';
// import { createMasterArray, mapSentiments } from '../utils/chartHelpers';
// import { chartColors } from '../constants/colors';

// Register the required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);


const createMasterArray = (...dateArrays) => {
    const combinedDates = dateArrays.flat();
    const uniqueDates = new Set(combinedDates);
    return Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
};

const mapSentiments = (masterDates, individualDates, sentiments) => {
    return masterDates.map(date => {
        const index = individualDates.indexOf(date);
        return index !== -1 ? sentiments[index] : undefined;
    });
};

// constants/colors.js
const chartColors = {
    red: 'rgba(118, 237, 166, 1)',
    lightRed: 'rgba(255, 99, 132, 0.2)',
    blue: 'rgba(253, 127, 120, 1)',
    lightBlue: 'rgba(54, 162, 235, 0.2)',
    green: 'rgba(252, 216, 126, 1)',
    lightGreen: 'rgba(75, 192, 192, 0.2)',
    yellow: 'rgba(118, 237, 166, 1)',
    pink: 'rgba(253, 127, 120, 1)',
    orange: 'rgba(252, 216, 126, 1)',
};


const TrendsInSentiments = ({
    
    topic1Filter,
    topic2Filter,
    width=1000,
    positive_date_1,
    title_2='',
    title_1='',
    negative_date_1,
    neutral_date_1,
    positive_date_2,
    negative_date_2,
    neutral_date_2,
    
    positive_value_1,
    negative_value_1,
    neutral_value_1,
    positive_value_2,
    negative_value_2,
    neutral_value_2

}) => {
    
    const masterDates = createMasterArray(positive_date_1, negative_date_1, neutral_date_1);
    const masterDates2 = createMasterArray(positive_date_2, negative_date_2, neutral_date_2);

    // Map sentiments to master dates
    const mappedPositive1 = mapSentiments(masterDates, positive_date_1, positive_value_1);
    const mappedNegative1 = mapSentiments(masterDates, negative_date_1, negative_value_1);
    const mappedNeutral1 = mapSentiments(masterDates, neutral_date_1, neutral_value_1);
    const mappedPositive2 = mapSentiments(masterDates2, positive_date_2, positive_value_2);
    const mappedNegative2 = mapSentiments(masterDates2, negative_date_2, negative_value_2);
    const mappedNeutral2 = mapSentiments(masterDates2, neutral_date_2, neutral_value_2);

    // Helper function to create datasets
    const createDataset = (label, data, borderColor, pointBackgroundColor, xAxisID = 'x1',borderDash) => ({
        label,
        data,
        borderColor,
        pointBackgroundColor,
        fill: false,
        spanGaps: true,
        xAxisID,
        pointRadius: 4,
        borderDash: borderDash
    });
    const sentimentTrendsDataset =[
        topic1Filter.positive && createDataset('Positive 1', mappedPositive1, chartColors.red, chartColors.red,'x1',[3, 5]),
        topic1Filter.negative && createDataset('Negative 1', mappedNegative1, chartColors.blue, chartColors.blue,'x1',[3, 5]),
        topic1Filter.neutral && createDataset('Neutral 1', mappedNeutral1, chartColors.green, chartColors.green,'x1',[3, 5]),
        topic2Filter.positive && createDataset('Positive 2', mappedPositive2, chartColors.yellow, chartColors.yellow, 'x2',[3, 0]),
        topic2Filter.negative && createDataset('Negative 2', mappedNegative2, chartColors.pink, chartColors.pink, 'x2',[3, 0]),
        topic2Filter.neutral && createDataset('Neutral 2', mappedNeutral2, chartColors.orange, chartColors.orange, 'x2',[3, 0]),
    ]
    // Prepare the data for the chart
    const data = {
        datasets: sentimentTrendsDataset
    };

    // Options for the chart, including multiple x-axes
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x1: {
                type: 'category',
                labels: (topic1Filter.positive || topic1Filter.negative || topic1Filter.neutral) && masterDates,
                grid: {
                    display: true,
                    drawOnChartArea: false,
                    tickColor: 'black',
                    drawBorder: true,
                },
                title: {
                    display: true,
                    text: title_1,
                    color: '#1f1f1f',
                },
            },
            x2: {
                type: 'category',
                position: 'top',
                labels: (topic2Filter.positive || topic2Filter.negative || topic2Filter.neutral) && masterDates2,
               
                grid: {
                    display: true,
                    drawOnChartArea: false,
                    tickColor: 'black',
                    drawBorder: true,
                },
                title: {
                    display: true,
                    text: title_2,
                    color: '#1f1f1f',
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: true,
                    tickColor: 'black',
                    drawBorder: true,
                },
                title: {
                    display: true,
                    text: 'Reviews',
                },
                ticks: {
                    color: 'black',
                },
            }
        },
        

        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        // Customize the title if needed
                        return tooltipItems[0].label;
                    },
                    // label: (tooltipItem) => {
                    //     // console.log(tooltipItem, "tooltip");
                    //     const datasetIndex = tooltipItem.datasetIndex;
                    //     const dataIndex = tooltipItem.dataIndex;
                    //     const dataset = sentimentTrendsDataset[datasetIndex];
                    //     const value = dataset.data[dataIndex];
                    //     // return `Value: ${value}`;
                    //     if (tooltipItem.datasetIndex === 0) {
                    //         const value = positive1_values[tooltipItem.dataIndex];
                    //         return `Google Pixel 8 Timeline: ${value}`;
                    //     } else if (tooltipItem.datasetIndex === 1) {
                    //         const value = negative1_values[tooltipItem.dataIndex];
                    //         return `Google Pixel 8 Timeline: ${value}`;
                    //     } else if (tooltipItem.datasetIndex === 2) {
                    //         const value = neutral1_values[tooltipItem.dataIndex];
                    //         return `Google Pixel 8 Timeline: ${value}`;
                    //     } else if (tooltipItem.datasetIndex === 3) {
                    //         const value = postive2_values[tooltipItem.dataIndex];
                    //         return `iPhone 15 Timeline: ${value}`;
                    //     } else if (tooltipItem.datasetIndex === 4) {
                    //         const value = negative2_values[tooltipItem.dataIndex];
                    //         return `iPhone 15 Timeline: ${value}`;
                    //     } else if (tooltipItem.datasetIndex === 5) {

                    //         const value = neutral2_values[tooltipItem.dataIndex];
                    //         return `iPhone 15 Timeline: ${value}`;
                    //     }
                    // },
                    // afterLabel: (tooltipItem) => {
                    //     // Example: Adding extra information to the tooltip
                    //     // Assuming you want to show `positive1_values` for the first dataset



                    //     return '';
                    // }
                },
                enabled: true,
            },
        },
    };

    return (
        // <div style={{ width: '100%', height: 500, marginBottom: '10px' }}>
            <Line
                data={data}
                options={options}
                width={1000}
                height={500}
            />
        // </div>
    );
};

export default TrendsInSentiments;
