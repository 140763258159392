import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  ${({ style }) =>
    style.border && `border: 3px solid rgba(55, 125, 255, 0.18)`};

  & > img {
    width: 100%;
    height: 100%;
    margin: 0;
    background: red;
  }
  & > div {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ bgColor }) => bgColor};
    cursor: pointer;
    color: #fff;
  }

  & .addUser {
    font-size: 25px;
    color: #000;
    font-weight: 700;
  }
`;

const UserProfileIcon = ({
  picture = '',
  userName = '',
  bgColor,
  style = {},
  onClick = () => {},
}) => {
  const getRandomColor = () => {
    // Generate random number based on the component's id
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  };

  return (
    <Wrapper
      className='user-profile-icon'
      bgColor={bgColor ? bgColor : getRandomColor()}
      style={style}
      onClick={onClick}
    >
      {picture ? (
        <img src={picture} alt='Profile' className='user-profile-picture' />
      ) : (
        <div
          className={`${userName === '+' && 'addUser'} user-profile-initials`}
        >
          {userName[0]?.toUpperCase()}
        </div>
      )}
    </Wrapper>
  );
};

export default UserProfileIcon;
