import React from 'react';
import styled from 'styled-components';
import { DeleteIcon } from '../../Common/SvgIcon/CommonSvgIcons';
import { useDispatch, useSelector } from 'react-redux';

import { deleteReport,hardDeleteReport } from '../../store/redux/slices/sentinelHubSlice';

const TopicCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-bottom: 70px;
  padding-right: 0px;
  margin-right: 0px;

  overflow-y: scroll;

  & .selectedReport {
    background: #e9e9f5;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #353cf7;
    border-radius: 10px;
  }
`;

const RecentTopicWrapper = styled.div`
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
  padding: 10px;
  padding-right: 4px;
  cursor: pointer;

  &:hover {
    background: #e9e9f5;
  }
`;

const TopicUserName = styled.div`
  font-size: 12px;
  font-weight: 400;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    font-size: 10px;
  }
`;

const ChannelType = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  // padding-right: 5px;

  & > span {
    display: none;
    & > svg {
      height: 15px;
    }
  }

  &:hover {
    & > span {
      display: unset;
    }
  }
`;

const ReportType = styled.div`
  font-size: 11px;
`;

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero based
  const year = date.getFullYear();

  // Pad single digit day/month with leading zero
  const formattedDay = String(day).padStart(2, '0');
  const formattedMonth = String(month).padStart(2, '0');

  return `${formattedDay}/${formattedMonth}/${year}`;
}

function formatNumber(val) {
  if (val >= 1000000) {
    return (val / 1000000).toFixed(1) + 'M'; // Convert to million
  } else if (val >= 1000) {
    return (val / 1000).toFixed(1) + 'k';
  } else if (val > 0) {
    return val.toString();
  } else {
    return '';
  }
}

function formatDateTimeToTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12; // 0 becomes 12

  // Pad single digit hours/minutes with leading zero
  const formattedHoursStr = String(formattedHours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Construct formatted time string
  const formattedTime = `${formattedHoursStr}:${formattedMinutes} ${ampm}`;

  return formattedTime;
}

const RecentReports = ({
  allReports = {},
  selectedReport,
  setSelectedReport,
}) => {
  const dispatch = useDispatch();

  const brandUser = JSON.parse(localStorage.getItem('brandUser'));
  const handleDeleteReport = (report) => {
    if (report?.deleted_id === 1) {
      dispatch(hardDeleteReport(report.email_id));
    } else {
      dispatch(deleteReport(report.email_id));
    }
  };

  return (
    <TopicCard>
      {allReports?.map((el) => (
        <RecentTopicWrapper
          key={el?.email_id}
          className={
            el?.email_id === selectedReport?.email_id ? 'selectedReport' : ''
          }
          onClick={() =>{
            setSelectedReport(el)}}
        >
          <TopicUserName>
            {el?.user_name}
            <span>
              {formatDate(el.creation_time)}{' '}
              {formatDateTimeToTime(el.creation_time)}
            </span>
          </TopicUserName>
          <div>{el?.title_name}</div>
          <ChannelType>
            {el.channel}
            {brandUser?.user_details.user_id === el.shared_user_id && (
              <span onClick={() => handleDeleteReport(el)}>{DeleteIcon}</span>
            )}
          </ChannelType>
          {/* <ChannelType>Twitter</ChannelType> */}
          <ReportType>
            {el.is_topic ? 'Topic Report' : 'Chatter Tracker Report'}
          </ReportType>
        </RecentTopicWrapper>
      ))}
    </TopicCard>
  );
};

export default RecentReports;
