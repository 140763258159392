import React, { useEffect, useMemo, useState } from 'react';
import {
    Wrapper,
    HeaderWrapper,
    Span1,
    Span2,
    ChannelsText,
    TwitterText,
    DateWrapper,
    StyledContainer,
    Container,
    Container2,
    ChildContainer,
    MainComponent,
    SubMainComponent,
    SubComponent1,
    SubComponent2,
    TopicBDiv,
    TopicADiv,
    ButtonDiv,
    ButtonStyle,
    EmotionContainer,
    TabHeading,
    TabWrapper,
    ChildContainerForCustom,
    CancelButtonStyle
} from '../CompetitiveIntelligence/ViewCompetitive.styled';
import { formatDateFilter } from '../../utils';
import MyDatePicker from '../../components/DatePicker/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonalDetails } from '../../store/redux/slices/tenantSettingSlice';
import { competitiveIntelligenceReport, competitiveIntelligenceShareReport } from '../../store/redux/slices/competitiveIntelligenceSlice';
import TopicMentions from '../CompetitiveIntelligence/TopicMentions';
import { AmazonIconSmall, FacebookIcon, FlipkartIconSmall, InstagramLogo, X_icon } from '../../Common/SvgIcon/CommonSvgIcons';
import Percentage3BarComp from '../../components/Competitive/Percentage3Bar';
import TrendsMainContainer from '../CompetitiveIntelligence/TrendsMainContainer';
import PercentageBarComp from '../../components/Competitive/PercentageBar';


const DownladInsights = ({ci_id}) => {

    const [loader, setLoader] = useState(false)
    const [compData, setCompData] = useState([])
    const [analysisData, setAnalysisData] = useState()
    const dispatch = useDispatch();
    const { personalDetailsData } = useSelector((state) => state.tenantSetting) || {};
    const transformText = (inputText) => {

        const words = inputText?.split(' ');
        const transformedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));
        return transformedWords?.join(' ')
    };

    useEffect(() => {
        setLoader(true)


        dispatch(competitiveIntelligenceShareReport(ci_id))
        // dispatch(competitiveIntelligenceReport(ci_id))
            .then((res) => {

                setTimeout(() => {

                    setLoader(false)
                    setAnalysisData(res?.payload?.data?.report?.ci_analysis || '')
                    setCompData(res?.payload?.data?.report?.ci_report || [])
                }, 1000);
            })
            .catch((err) => {

                setLoader(false)
                setAnalysisData('')
                setCompData([])
            })


        if (personalDetailsData === null) {
            dispatch(getPersonalDetails());
        }

    }, [ci_id,dispatch]);

    const formatValue = personalDetailsData?.app_preference?.date_format === "dd/mm/yyyy" ? 'dd-MM-yyyy' :
        personalDetailsData?.app_preference?.date_format === "mm/dd/yyyy" ? 'MM-dd-yyyy' :
            personalDetailsData?.app_preference?.date_format === "yyyy/mm/dd" ? 'yyyy-MM-dd' :
                'dd-MM-yyyy';


    const graphData = useMemo(() => {
        return [{
            "label": compData?.[0]?.chatter_topic_title,
            "channel": compData?.[0]?.channel,
            "male": compData?.[0]?.ci_report?.gender_demographics?.male_percentage,
            "female": compData?.[0]?.ci_report?.gender_demographics?.female_percentage,
            "unknown": compData?.[0]?.ci_report?.gender_demographics?.unknown_percentage

        },
        {
            "label": compData?.[1]?.chatter_topic_title,
            "channel": compData?.[1]?.channel,
            "male": compData?.[1]?.ci_report?.gender_demographics?.male_percentage,
            "female": compData?.[1]?.ci_report?.gender_demographics?.female_percentage,
            "unknown": compData?.[1]?.ci_report?.gender_demographics?.unknown_percentage

        }];
    }, [compData]);

    const allValues = graphData.flatMap(item => [item.male, item.female, item.unknown]);

    // Find the maximum value among all values
    const maxValue = Math.max(...allValues);
    const multiplier = 100 / maxValue;

    const emotionsData = [
        {
          "label": 'Joy',
          "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.joy || 0,
          "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.joy || 0,
    
        },
        {
          "label": 'Neutral',
          "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.neutral || 0,
          "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.neutral || 0,
        },
    
        {
          "label": 'Surprise',
          "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.surprise || 0,
          "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.surprise || 0,
        },
        {
          "label": 'Fear',
          "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.fear || 0,
          "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.fear || 0,
        },
        {
          "label": 'Sadness',
          "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.sadness || 0,
          "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.sadness || 0,
        },
    
        {
          "label": 'Anger',
          "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.anger || 0,
          "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.anger || 0,
        },
    
        {
          "label": 'Disgust',
          "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.disgust || 0,
          "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.disgust || 0,
        }
    
      ];
      
      const graphDataSentiments = useMemo(() => {
        return [{
          "label": compData?.[0]?.chatter_topic_title || '',
          "channel": compData?.[0]?.channel || '',
          "positive": compData?.[0]?.ci_report?.sentiment_metrics?.positive_sentiment || 0,
          "negitive": compData?.[0]?.ci_report?.sentiment_metrics?.negative_sentiment || 0,
          "neutral": compData?.[0]?.ci_report?.sentiment_metrics?.neutral_sentiment || 0
    
        },
        {
          "label": compData?.[1]?.chatter_topic_title || '',
          "channel": compData?.[1]?.channel || '',
          "positive": compData?.[1]?.ci_report?.sentiment_metrics?.positive_sentiment || 0,
          "negitive": compData?.[1]?.ci_report?.sentiment_metrics?.negative_sentiment || 0,
          "neutral": compData?.[1]?.ci_report?.sentiment_metrics?.neutral_sentiment || 0
        }];
      }, [compData]);

      
    const sentimentTrendsDataSet = [
        {
            fill: false,
            data: 'topic1_values',
            borderColor: 'rgba(54, 162, 235, 1)', // Line color for topic 1
            pointBackgroundColor: 'green', // Marker color for topic 1
            pointBorderWidth: 0,
            pointRadius: 5, // Size of the markers
        },
        {
            fill: false,
            data: 'topic2_values',
            borderColor: 'rgba(255, 99, 132, 1)', // Line color for topic 1
            pointBackgroundColor: 'red', // Marker color for topic 2

            borderDash: [10, 5], // Dotted line (10px line, 5px gap)
            pointBorderWidth: 0,
            pointRadius: 5, // Size of the markers
            xAxisID: 'topic2_dates', // Use a secondary x-axis for energy
        }
    ];
    const trendsDataSet = [
        {
            fill: false,
            data: 'topic1_values',
            borderColor: 'rgba(54, 162, 235, 1)', // Line color for topic 1
            pointBackgroundColor: 'green', // Marker color for topic 1
            pointBorderWidth: 0,
            pointRadius: 5, // Size of the markers
        },
        {
            fill: false,
            data: 'topic2_values',
            borderColor: 'rgba(255, 99, 132, 1)', // Line color for topic 1
            pointBackgroundColor: 'red', // Marker color for topic 2

            pointBorderWidth: 0,
            pointRadius: 5, // Size of the markers
            xAxisID: 'topic2_dates', // Use a secondary x-axis for energy
        }
    ];

    
  const emotionsDataAllValues = emotionsData.flatMap(item => [item.topicA, item.topicB]);

  const emotionsValue = Math.max(...emotionsDataAllValues);
  const emotionMultiplier = 100 / emotionsValue;


  const allValueSentiments = graphDataSentiments.flatMap(item => [item.positive, item.negitive, item.neutral]);

  // Find the maximum value among all values
  const maxValueSentiments = Math.max(...allValueSentiments);
  const multiplierSentiments = 100 / maxValueSentiments;

  const avgRatingData = [{
    label: 'Average rating',
    topicA: compData?.[0]?.ci_report?.average_rating || 0,
    topicB: compData?.[1]?.ci_report?.average_rating || 0
  }]


  const avgEngagementData = [{
    label: 'Likes',
    topicA: compData?.[0]?.ci_report?.engagement_metrics?.likes || 0,
    topicB: compData?.[1]?.ci_report?.engagement_metrics?.likes || 0
  },
  {
    label: 'Comments',
    topicA: compData?.[0]?.ci_report?.engagement_metrics?.comments || 0,
    topicB: compData?.[1]?.ci_report?.engagement_metrics?.comments || 0
  }
  ]

  
  const avgRatingAllVAlues = avgRatingData.flatMap(item => [item.topicA, item.topicB]);

  const avgRatingValue = Math.max(...avgRatingAllVAlues);
  const avgRatingMultiplier = 100 / avgRatingValue;


  
  const avgEngageVAlues = avgEngagementData.flatMap(item => [item.topicA, item.topicB]);

  const avgEngageValue = Math.max(...avgEngageVAlues);
  const avgEnagageMultiplier = 100 / avgEngageValue;

    return (
        <Wrapper>

            <HeaderWrapper>

                <MainComponent>
                    <SubMainComponent >
                        <SubComponent1>
                            <StyledContainer>
                                <ChannelsText>Channel:</ChannelsText>
                                <TwitterText>
                                    {/* <Icon>{topicPreviewData?.channel === 'twitter' ? X_icon : InstagramIconSmall}</Icon> */}
                                    {transformText(compData?.[0]?.channel || '')}
                                </TwitterText>
                            </StyledContainer>
                            <TopicADiv >
                                <Span1>Topic A : </Span1>
                                <Span2>&nbsp; {compData?.[0]?.chatter_topic_title || ''}</Span2>

                            </TopicADiv>
                            <DateWrapper>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <div>Start Date:</div>
                                    <MyDatePicker
                                        name='firstPostDate'
                                        value={formatDateFilter(compData?.[0]?.start_date) || ''}
                                        // dateHandler={dateHandler}
                                        format={
                                            formatValue
                                        }
                                        min={formatDateFilter(compData?.[0]?.start_date) || ''}
                                        max={formatDateFilter(compData?.[0]?.start_date) || ''}
                                    />
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <div>End Date:</div>
                                    <MyDatePicker
                                        name='firstPostDate'
                                        value={formatDateFilter(compData?.[0]?.end_date) || ''}
                                        // dateHandler={dateHandler}
                                        format={
                                            formatValue
                                        }
                                        min={formatDateFilter(compData?.[0]?.end_date) || ''}
                                        max={formatDateFilter(compData?.[0]?.end_date) || ''}
                                    />
                                </div>
                            </DateWrapper>
                        </SubComponent1>
                        <SubComponent2 >
                            <StyledContainer>
                                <ChannelsText>Channel:</ChannelsText>
                                <TwitterText>
                                    {transformText(compData?.[1]?.channel || '')}
                                </TwitterText>
                            </StyledContainer>
                            <TopicBDiv>
                                <Span1>Topic B : </Span1>
                                <Span2>&nbsp; {compData?.[1]?.chatter_topic_title || ''}</Span2>

                            </TopicBDiv>
                            <DateWrapper>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <div>Start Date:</div>
                                    <MyDatePicker
                                        name='firstPostDate'
                                        value={formatDateFilter(compData?.[1]?.start_date) || ''}
                                        format={
                                            formatValue
                                        }
                                        min={formatDateFilter(compData?.[1]?.start_date) || ''}
                                        max={formatDateFilter(compData?.[1]?.start_date) || ''}
                                    />

                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <div>End Date:</div>
                                    <MyDatePicker
                                        name='firstPostDate'
                                        value={formatDateFilter(compData?.[1]?.end_date) || ''}
                                        format={
                                            formatValue
                                        }
                                        min={formatDateFilter(compData?.[1]?.end_date) || ''}
                                        max={formatDateFilter(compData?.[1]?.end_date) || ''}
                                    />

                                </div>
                            </DateWrapper>
                        </SubComponent2>
                    </SubMainComponent>
                </MainComponent>
            </HeaderWrapper>

            <Wrapper >
                <TabWrapper>
                    <TabHeading>
                        <div
                            className='activePlanTab'
                        // onClick={() => handleTabClick('overViewTab')}
                        >
                            Overviews
                        </div>
                        {/* <div
                            className={''}
                        // onClick={() => handleTabClick('CustomQuery')}
                        >
                            Custom Queries
                        </div> */}

                    </TabHeading>
                </TabWrapper>

                <Container>
                    <ChildContainer>
                        <TopicMentions
                            data={compData}
                            iconA={
                                compData?.[0]?.channel === "twitter" ? X_icon :
                                    compData?.[0]?.channel === "instagram" ? InstagramLogo :
                                        compData?.[0]?.channel === "facebook" ? FacebookIcon :
                                            compData?.[0]?.channel === "amazon" ? AmazonIconSmall :
                                                compData?.[0]?.channel === "flipkart" ? FlipkartIconSmall :
                                                    ''
                            }
                            iconB={
                                compData?.[1]?.channel === "twitter" ? X_icon :
                                    compData?.[1]?.channel === "instagram" ? InstagramLogo :
                                        compData?.[1]?.channel === "facebook" ? FacebookIcon :
                                            compData?.[1]?.channel === "amazon" ? AmazonIconSmall :
                                                compData?.[1]?.channel === "flipkart" ? FlipkartIconSmall :
                                                    ''
                            }
                        />
                    </ChildContainer>

                    <Percentage3BarComp
                        analysisData={analysisData}
                        compData={compData}
                        title={'Gender Demographics'}
                        data={graphData}
                        multiplier={multiplier}
                        summary1={analysisData?.gender_demographics?.data_1 || ''}
                        summary2={analysisData?.gender_demographics?.data_2 || ''}
                    />


                </Container>

                <Container2  className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
        }}>
                    <TrendsMainContainer
                        compData={compData}
                        showGraphLabel={false}
                        trendsDataSet={trendsDataSet}
                        analysisData={analysisData}
                        summary1={analysisData?.trends?.data_1}
                        summary2={analysisData?.trends?.data_2}
                        showsummary={true}
                    />
                </Container2>


                <Container2  className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
        }}>

                    <TrendsMainContainer
                        compData={compData}
                        showGraphLabel={true}
                        trendsDataSet={sentimentTrendsDataSet}
                        analysisData={analysisData}
                        summary1={analysisData?.sentiment_trends}
                        summary2={''}
                        showsummary={false}
                        showFilter={false}
                    />

                </Container2>

                <Container  className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
        }}>
                    {
                        compData?.[0]?.channel === 'amazon' || compData?.[0]?.channel === 'flipkart' ?
                            <PercentageBarComp
                                analysisData={analysisData}
                                title={'Average Rating'}
                                data={avgRatingData}
                                multiplier={avgRatingMultiplier}
                                compData={compData}
                                summary1={analysisData?.rating_engagement_metrics?.average_rating || ''}
                            // summary2={analysisData?.gender_demographics?.data_2 || ''}
                            />

                            :
                            <PercentageBarComp
                                analysisData={analysisData}
                                title={'Engagement Metrics'}
                                data={avgEngagementData}
                                multiplier={avgEnagageMultiplier}
                                compData={compData}

                                summary1={analysisData?.rating_engagement_metrics?.likes || ''}
                                summary2={analysisData?.rating_engagement_metrics?.comments || ''}
                            />

                    }

                    <Percentage3BarComp
                        analysisData={analysisData}
                        compData={compData}
                        title={'Sentiments'}
                        data={graphDataSentiments}
                        showSentiment={true}
                        multiplier={multiplierSentiments}

                        summary1={''}
                        summary2={''}
                    />


                </Container>

                <EmotionContainer  className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
        }}>

                    <PercentageBarComp
                        title={'Emotions'}
                        data={emotionsData}
                        multiplier={emotionMultiplier}
                        compData={compData}

                        summary1={analysisData?.emotion?.data_1 || ''}
                        summary2={analysisData?.emotion?.data_2 || ''}
                    />


                </EmotionContainer>
            </Wrapper>

        </Wrapper>
    );
};


export default DownladInsights;