import React from 'react';
import styled from 'styled-components';

import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 0.9px solid #636363;

  display: flex;
  padding-left: 15px;

  & input {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
  }
`;

const SearchBar = ({
  value,
  onChange,
  placeholder = 'Search...',
  height = '50px',
  onClick = () => {},
}) => {
  return (
    <Wrapper>
      <IconButton
        color='primary'
        type='button'
        sx={{ p: '0px' }}
        aria-label='search'
        onClick={onClick}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1, width: '85%', height }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default SearchBar;
