import React from 'react';
import {
    OverallPosts,
    Title,
    RelevantPost,
    MainDivForOverview,
    Logo,
    TextDiv,
    DateFontStyle
} from './ViewCompetitive.styled';


const TopicMentions = ({data,iconA,iconB}) => {
    return (
       
        <OverallPosts>
        <Title>{'Topics Mentions'}</Title>
        <RelevantPost>
          <MainDivForOverview>
            <Logo>
              {iconA}
            </Logo>
            <TextDiv>
              <h5 style={{ margin: '0px' }}>Topic A :</h5>
              <p style={{ margin: '0px' }}>{data?.[0]?.chatter_topic_title || ''} - {data?.[0]?.channel === 'amazon' || data?.[0]?.channel === 'flipkart' ? data?.[0]?.ci_report?.overall_reviews && `${data?.[0]?.ci_report?.overall_reviews} Mentions` :data?.[0]?.ci_report?.topic_mentions && `${data?.[0]?.ci_report?.topic_mentions} Mentions`}</p>
            </TextDiv>
          </MainDivForOverview>

          <MainDivForOverview>
            <Logo>
              {iconB}
            </Logo>
            <TextDiv>
              <h5 style={{ margin: '0px' }}>Topic B :</h5>
              <p style={{ margin: '0px' }}>{data?.[1]?.chatter_topic_title || ''} - {data?.[1]?.channel === 'amazon' || data?.[1]?.channel === 'flipkart' ? data?.[1]?.ci_report?.overall_reviews && `${data?.[1]?.ci_report?.overall_reviews} Mentions` :data?.[1]?.ci_report?.topic_mentions && `${data?.[1]?.ci_report?.topic_mentions} Mentions`}</p>
            </TextDiv>
          </MainDivForOverview>
        </RelevantPost>
        <DateFontStyle>
          {/* This data is observed from 22/03/2023 to 22/04/2023 */}
        </DateFontStyle>
      </OverallPosts>
    );
};

export default TopicMentions;