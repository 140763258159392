import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import tenantAuthReducer from './slices/tenantAuthSlice';
import tenantSettingReducer from './slices/tenantSettingSlice';
import executivePortalReducer from './slices/executivePortalSlice';
import executivePortalTenantManagementReducer from './slices/executivePortalTenantManagementSlice';
import chatterTrackerReducer from './slices/chatterSlice';
import alertMessageReducer from './slices/alertMessageSlice';
import previewReducer from './slices/previewDataSlice';
import topicReducer from './slices/topicSlice';
import sentinelHubReducer from './slices/sentinelHubSlice';
import competitiveIntelligenceReducer from './slices/competitiveIntelligenceSlice';

const rootReducer = combineReducers({
  // Authentication reducers
  auth: authReducer,
  tenantAuth: tenantAuthReducer,

  // Settings and portal-related reducers
  tenantSetting: tenantSettingReducer,
  executivePortal: executivePortalReducer,
  executivePortalTenantManagement: executivePortalTenantManagementReducer,

  // Data handling reducers
  chatterTracker: chatterTrackerReducer,
  preview: previewReducer,
  topicBuilder: topicReducer,
  sentinelHub: sentinelHubReducer,

  // Competitive intelligence reducer
  competitiveIntelligence: competitiveIntelligenceReducer,

  // Alerts and messaging
  message: alertMessageReducer,
});

export default rootReducer;
