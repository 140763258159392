import axios from 'axios';
import tenantAxiosInstance from '../utils/tenantAxiosInstance';

const API_URL = process.env.REACT_APP_BACKEND_URL; 
const loginTenant = (username, password, tenant_id) => {
  return axios
    .post(API_URL + `${tenant_id}/token`, { username, password })
    .then((response) => {
      return response.data;
    });
};


const tenantChangePassword = (oldpassword, newpassword, user_id, tenant_id) => {
  return tenantAxiosInstance
    .post(API_URL + `${tenant_id}/security/${user_id}`, {
      oldpassword,
      newpassword,
    })
    .then((response) => {
      return response.data;
    });
};

const tenantResetPassword = (new_password, token) => {
  return axios
    .post(API_URL + `brand_user/reset_password/${token}`, { new_password })
    .then((response) => {
      return response.data;
    });
};

const tenantForgotPassword = (email_id, tenant_name) => {
  return axios
    .post(API_URL + `brand_user/forgot_password/${tenant_name}`, {
      email_id,
    })
    .then((response) => {
      return response.data;
    });
};

const logoutTenant = () => {
  localStorage.removeItem('brandUser');
  return tenantAxiosInstance.get(API_URL + 'logout').then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('brandUser'));
};

const TenantAuthService = {
  loginTenant,
  logoutTenant,
  tenantChangePassword,
  getCurrentUser,
  tenantResetPassword,
  tenantForgotPassword,
};

export default TenantAuthService;
