import styled from 'styled-components';
import NewSideBar from '../../components/NewSideBar/NewSideBar';
import { useParams } from 'react-router-dom';
import HomePage from './homePage';
// import TopicBuilder from './MainContent/QueryBuilder/TopicBuilder';
import ActiveTopic from '../ActiveTopic/ActiveTopic';

import { useDispatch, useSelector } from 'react-redux';
import { activeTopicList } from '../../store/redux/slices/topicSlice';
// import AddNewTopic from '../TopicBuilder/MainContent/AddNewTopic/AddNewTopic';
import { CreateChatterIcon } from '../../Common/SvgIcon/CommonSvgIcons';
import CreateComparison from './createComparison';
import { useEffect, useState } from 'react';
import { competitiveIntelligenceListTopic, competitiveIntelligenceListChatter } from '../../store/redux/slices/competitiveIntelligenceSlice';
import ViewCompetitive from './viewCompetitive';
import { chatterList } from '../../store/redux/slices/chatterSlice';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: auto;
  // background:red;
`;

const CompetitiveIntelligence = () => {
  const { screenId } = useParams();
  const [AnalysisListTopic, setAnalysisListTopic] = useState([])
  const [AnalysisListChatter, setAnalysisListChatter] = useState([])

  const dispatch = useDispatch();


  useEffect(() => {

    dispatch(activeTopicList());
    dispatch(chatterList());
    dispatch(competitiveIntelligenceListTopic()).then(
      (res) => {
        setAnalysisListTopic(res.payload.data)
      }
    );
    dispatch(competitiveIntelligenceListChatter()).then(
      (res) => {
        setAnalysisListChatter(res.payload.data)
      }
    );

  }, []);

  /* const subTabs = [
    {
      id: '1a',
      val: 'New Topic',
      type: 'add', //If you want to add icon in the subTab then add type: 'add'
      path: '/topic/create',
    },
  ]; */

  const activeSubTabs = [];
  const hibernatedSubTabs = [];
  const TabScreen = {
    home: <HomePage topicList={AnalysisListTopic} chatterList={AnalysisListChatter} />,
    create: <CreateComparison
      setAnalysisListTopic={setAnalysisListTopic}
      setAnalysisListChatter={setAnalysisListChatter}
    />,
    view: <ViewCompetitive />,
    //   view: <ActiveTopic />, //replace with analysis view
    // };

    // AnalysisList?.forEach((item) => {
    //   // TabScreen[`job_${item.job_id}`] = <Activeitem job_id={item.job_id} />;

    //   const subTab = {
    //     id: item.ci_id,
    //     val: item.chatter_topic_title_1,
    //     path: `/competitiveIntelligence/ci-${item.ci_id}`,
    // channel:analysis.channel

  };

  AnalysisListTopic?.forEach((topic) => {
    TabScreen[`ci_${topic.competitive_intelligence_id}`] = <ViewCompetitive job_id={topic.competitive_intelligence_id} />;
    /*   subTabs.push({
        id: topic.job_id,
        val: topic.topic_title,
        path: `/topic/job_${topic.job_id}`,
      }); */

    const subTab = {
      id: topic.competitive_intelligence_id,
      val: `${topic.chatter_topic_title_1}-${topic.chatter_topic_title_2}`,
      path: `/competitiveIntelligence/ci_${topic.competitive_intelligence_id}`,
      // channel:analysis.channel
    };
    // if (topic.is_active === 0) {
    activeSubTabs.push(subTab);
    // } else if (topic.is_active === 1) {
    // hibernatedSubTabs.push(subTab);
    // }
  });

  AnalysisListChatter?.forEach((topic) => {
    TabScreen[`ci_${topic.competitive_intelligence_id}`] = <ViewCompetitive job_id={topic.competitive_intelligence_id} />;
    /*   subTabs.push({
        id: topic.job_id,
        val: topic.topic_title,
        path: `/topic/job_${topic.job_id}`,
      }); */

    const subTab = {
      id: topic.competitive_intelligence_id,
      val: `${topic.chatter_topic_title_1}-${topic.chatter_topic_title_2}`,
      path: `/competitiveIntelligence/ci_${topic.competitive_intelligence_id}`,
      // channel:analysis.channel
    };
    // if (topic.is_active === 0) {
    // activeSubTabs.push(subTab);
    // } else if (topic.is_active === 1) {
    hibernatedSubTabs.push(subTab);
    // }
  });
  const tabs = [
    {
      id: 1,
      val: 'Competitive Topics',
      /* path: '/chatter/home', */
      subTabs: activeSubTabs,
    },
    {
      id: 1,
      val: 'Competitive Chatters',
      subTabs: hibernatedSubTabs,
    }
  ];

  //To set active tab of the sidebar
  const generateActiveTab = () => {
    if (screenId === 'home') {
      return 1;
    } else if (screenId === 'create') {
      return { tab: 1, subTab: '1a' };
    } else if (screenId === 'view') {
      return { tab: 1, subTab: '1a' };
    }
    for (let i = 0; i < activeSubTabs.length; i++) {
      if (screenId === `job_${activeSubTabs[i].id}`) {
        return { tab: 1, subTab: activeSubTabs[i].id };
      }
    }
    for (let i = 0; i < hibernatedSubTabs.length; i++) {
      if (screenId === `job_${hibernatedSubTabs[i].id}`) {
        return { tab: 2, subTab: hibernatedSubTabs[i].id };
      }
    }
  };

  return (
    <Wrapper>
      <NewSideBar
        headerName='Competitive Analysis'
        tabs={tabs}
        initialActiveTab={generateActiveTab()}
        buttonTitle={'Compare'}
      />
      {TabScreen[screenId]}
    </Wrapper>
  );
};

export default CompetitiveIntelligence;
