import { X_icon } from "../../../Common/SvgIcon/CommonSvgIcons";

// Define any required variables
const overview = { genderDemographicsGraph: [] }; // Example data
const sentiments = {
  percentageValue: [],
  top_five_hashtag: [],
  Top_retweets: [],
};
const top_posts = [];
const insights = {
  insights_data: [],
  most_mentioned: [],
  emotion_analysis: [],
  correlation_analysis: [],
};
const sentiment_data = {
  'X-axis': [],
  'Y-axis': [],
  sentiment: [],
  reviews: [],
  user_name: '',
};
const sentimentLabel = '';
const sentimentColor = '';
const total_positive_posts = 0;
const total_negative_posts = 0;
const total_neutral_posts = 0;
const post_text = '';
const post_interaction = 0;
const download = () => { }; // Example function
const reviewsForTopicData = [];


export const channelObject =[
{
  posts_name:'post',
  channel: 'Twitter',
  description: null,
  is_topic: true,
  job_id: null,
  keywords_string: null,
  topic_title: null,
  startDate:null,
  endDate:null,

  tabs: [
    {

      name: 'Overviews',

      resetFlag: true,

      child: [{



        blocks: [
          {

            component: 'OverAllReviews',
            title:'Overall Posts',
            icon: X_icon,

            data: null,
            message: 'message',

          },
          {

            component: 'GraphHOC',

            title: 'Gender Demographgics',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          }]

      },
      {



        blocks: [{

          component: "TrendsInUserActivity",

          title: 'Trends In User Activity',

          // FilterOptions: '',

          hideDropdown: false,

          data: null,

          ylabel: 'Reviews'

        }]

      },


      ]
    },


    {

      name: 'Sentiments',

      resetFlag: true,

      child: [{



        blocks: [
          //   {

          //   component: 'SentimentDrilldown',

          //   data: null
          //   // data: top_posts,

          // },
          // {

          //   component: 'GraphHOC',

          //   title: 'Sentiment Drilldown',

          //   FilterOptions: '',

          //   hideDropdown: false,

          //   // data: sentiments.percentageValue,
          //   data: null,
          //   view: 'null',
          //   graphStyles: {

          //     width: 500,
          //     FunnelWidth: '50%',
          //     barWidth: '60%'
          //   },
           

          // },
          {

            component: 'SentimentDrilldown',

            title: 'Sentiment Drilldown',

            FilterOptions: '',

            hideDropdown: false,

            // noBorder: true,
            // data: sentiments.percentageValue,
            data: null,
            view: 'null',
            graphStyles: {

              width: 500,
              FunnelWidth: '50%',
              barWidth: '60%',
              
              margin:'20px 0px 20px 50px'
            },
          }
        
        ]

    },
    {



      blocks: [{

        component: 'GraphHOC',

        title: 'Top Hashtags',

        // FilterOptions: '',

        hideDropdown: false,

        // noBorder: true,
        // data: sentiments.top_five_hashtag,

        view: 'Vertical',
        graphStyles: {

          width: 500,
          FunnelWidth: '50%',
          barWidth: '60%',
          margin:'20px 0px 20px 50px'
        },

      }]

    },
    {



      blocks: [{

        component: 'TopRetweets',

        title: 'Top Retweets',

        // FilterOptions: '',

        // noBorder: true,
        hideDropdown: false,

        // data: sentiments.Top_retweets,
        data: null,
        view: 'Vertical',

        type: 'Retweets',
        people_active: 'Retweeted',


      }]

    },


  ]
},


  {

    name: 'Insights',

    resetFlag: true,

    child: [{



      blocks: [{

        component: 'WordCloudAnalysis',

        FilterOptions: '',

        hideDropdown: false,
        data: null

          // data: insights.insights_data,

        }]

    },
      {



        blocks: [{

          component: 'GraphHOC',

          title: 'Most Mentioned',

          FilterOptions: '',

          hideDropdown: false,

          data: null,
          // data: insights.most_mentioned,

          view: 'null',
          graphStyles: {

            barHeight: '32px',
            verticalBarWidth: '70%',
            width: 500,
            FunnelWidth: '80%',
            barWidth: '70%',
            
          margin:'20px 0px 20px 50px'
          },

        }]

      },


      {



        blocks: [{

          component: 'GraphHOC',

          title: 'Emotion Analysis',

          FilterOptions: '',

          hideDropdown: false,

          // data: insights.emotion_analysis,

          view: 'null',
          graphStyles: {
            showemojiList: true,
            barHeight: '32px',
            verticalBarWidth: '70%',
            width: 500,
            FunnelWidth: '80%',
            barWidth: '70%',
            margin:'20px 0px 20px 50px'
          },

        }]

      },


      {



        blocks: [{

          component: 'CorrelationGraph',

          ratingLabels: [
            'Engagement',
          'Anger',
          'Neutral',
          'Sadness',
          'Disgust',
          'Fear',
          'Joy',
          'Surprise',
          ],


        FilterOptions: '',

        hideDropdown: false,

        view: 'null',

      }]

  },


      ]
    },


{

  name: 'Cluster Analysis',

    resetFlag: true,

      child: [{



        blocks: [{
          data: null,

          component: 'ClusterAnalysisGraph',

          Title: 'Sentiments',

          type: 'Sentiments',
          noBorder: true

          // xAxisValues: sentiment_data['X-axis'],

          // yAxisValues: sentiment_data['Y-axis'],

          // sentimentLabel: sentimentLabel,

          // sentimentColor: sentimentColor,

          // sentiment: sentiment_data.sentiment,

          // reviews: sentiment_data.reviews,

          // name: sentiment_data?.user_name,

          // likes: '',

          // circle: ''

        }]

      },
      {



        blocks: [

          {

            component: 'ClusterAnalysisGraph',

            Title: 'Emotions',

            type: 'Emotions',
            noBorder: true
            // xAxisValues: sentiment_data['X-axis'],

            // yAxisValues: sentiment_data['Y-axis'],

            // sentimentLabel: sentimentLabel,

            // sentimentColor: sentimentColor,

            // sentiment: sentiment_data.sentiment,

            // reviews: sentiment_data.reviews,

            // name: sentiment_data?.user_name,

            // likes: '',

            // circle: ''

          }]

      },


      ]
},


{

  name: 'Custom Analysis',

    resetFlag: true,

      child: [{



        blocks: [{
          job_id:'',
          preview_uuid:'',
          onUpdateSearchQuery:null,
          channel:'',
          component: 'CustomAnalysis',
          noBorder: true
        }]

      },


      ]
},

{

  name: 'Posts',

    resetFlag: true,

      child: [{



        blocks: [{

          component: 'MessagesTab',
          noBorder: true,
          download: download,


          hideDropdown: false,
          email_id: null,
          showReport: false,
          startDate: '',
          endDate: '',
          preview_uuid: '',
          job_id: '',
          filteredData: '',
          channel: '',
          columns: '',
          // jobid,channel,preview_uuid,
          // reviews: reviewsForTopicData,

          filterCol: [2, 3, 4],
          data: null,

          columns: ['Posts', 'Profile', 'Followers', 'Engagement']

        }]

      },


      ]
}

  ]

},
{

  posts_name:'post',
  channel: 'Instagram',
  description: null,
  is_topic: true,
  job_id: null,
  keywords_string: null,
  topic_title: null,
  startDate:null,
  endDate:null,

  tabs: [
    {

      name: 'Overviews',

      resetFlag: true,

      child: [{



        blocks: [
          {

            component: 'OverAllReviews',
            title:'Overall Posts',

            icon: X_icon,

            data: null,
            message: 'message',

          },
          {

            component: 'GraphHOC',

            title: 'Engagement',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          }
        ]

      },
      {
        blocks: [
          {

            component: 'GraphHOC',

            title: 'Gender Demographics',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          },
          {

            component: 'GraphHOC',

            title: 'Verified and Non verified Users',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          }
        ]
      },
      {



        blocks: [{

          component: "TrendsInUserActivity",

          title: 'Trends In User Activity',

          // FilterOptions: '',

          hideDropdown: false,

          data: null,
          option : 'Years',

          ylabel: 'Engagement'

        }]

      },
      {



        blocks: [{

          component: "TrendsInUserActivity",

          // FilterOptions: '',

          hideDropdown: false,

          data: null,

          option : 'Years',
          title:`Likes`,
          ylabel:'Likes'

        }]

      },
      {



        blocks: [{

          component: "TrendsInUserActivity",

          title: 'Comments',

          // FilterOptions: '',

          hideDropdown: false,

          data: null,
          option : 'Years',
          ylabel: 'Comments'

        }]

      },
     


      ]
    },


    {

      name: 'Sentiments',

      resetFlag: true,

      child: [{



        blocks: [
          //   {

          //   component: 'SentimentDrilldown',

          //   data: null
          //   // data: top_posts,

          // },
          // {

          //   component: 'GraphHOC',

          //   title: 'Sentiment Drilldown',

          //   FilterOptions: '',

          //   hideDropdown: false,

          //   // data: sentiments.percentageValue,
          //   data: null,
          //   view: 'null',
          //   graphStyles: {

          //     width: 500,
          //     FunnelWidth: '50%',
          //     barWidth: '60%'
          //   },
           

          // },
          {

            component: 'SentimentDrilldown',

            title: 'Sentiment Drilldown',

            FilterOptions: '',

            hideDropdown: false,

            // noBorder: true,
            // data: sentiments.percentageValue,
            data: null,
            view: 'null',
            graphStyles: {

              width: 500,
              FunnelWidth: '50%',
              barWidth: '60%',
              
              margin:'20px 0px 20px 50px'
            },
          }
        
        ]

    },
    {



      blocks: [{

        component: 'GraphHOC',

        title: 'Top Hashtags',

        // FilterOptions: '',

        hideDropdown: false,

        // noBorder: true,
        // data: sentiments.top_five_hashtag,

        view: 'Vertical',
        graphStyles: {

          width: 500,
          FunnelWidth: '50%',
          barWidth: '60%',
          
          margin:'20px 0px 20px 50px'
        },

      }]

    },
    {



      blocks: [{

        component: 'TopRetweets',

        title: 'Top Retweets',

        // FilterOptions: '',

        // noBorder: true,
        hideDropdown: false,

        // data: sentiments.Top_retweets,
        data: null,
        view: 'Vertical',

        type: 'Posts',
        people_active: ' Liked',
        

      }]

    },


  ]
},


  {

    name: 'Insights',

    resetFlag: true,

    child: [{



      blocks: [{

        component: 'WordCloudAnalysis',

        FilterOptions: '',

        hideDropdown: false,
        data: null

          // data: insights.insights_data,

        }]

    },
      {



        blocks: [{

          component: 'GraphHOC',

          title: 'Most Mentioned',

          FilterOptions: '',

          hideDropdown: false,

          data: null,
          // data: insights.most_mentioned,

          view: 'null',
          graphStyles: {

            barHeight: '32px',
            verticalBarWidth: '70%',
            width: 500,
            FunnelWidth: '80%',
            barWidth: '70%',
            margin:'20px 0px 20px 50px'
          },

        }]

      },


      {



        blocks: [{

          component: 'GraphHOC',

          title: 'Emotion Analysis',

          FilterOptions: '',

          hideDropdown: false,

          // data: insights.emotion_analysis,

          view: 'null',
          graphStyles: {
            showemojiList: true,
            barHeight: '32px',
            verticalBarWidth: '70%',
            width: 500,
            FunnelWidth: '80%',
            barWidth: '70%',
            margin:'20px 0px 20px 50px'
          },

        }]

      },


      {



        blocks: [{

          component: 'CorrelationGraph',

          ratingLabels: [
            'Engagement',
          'Anger',
          'Neutral',
          'Sadness',
          'Disgust',
          'Fear',
          'Joy',
          'Surprise',
          ],


        FilterOptions: '',

        hideDropdown: false,

        view: 'null',

      }]

  },


      ]
    },


{

  name: 'Cluster Analysis',

    resetFlag: true,

      child: [{



        blocks: [{
          data: null,

          component: 'ClusterAnalysisGraph',

          Title: 'Sentiments',

          type: 'Sentiments',
          noBorder: true

          // xAxisValues: sentiment_data['X-axis'],

          // yAxisValues: sentiment_data['Y-axis'],

          // sentimentLabel: sentimentLabel,

          // sentimentColor: sentimentColor,

          // sentiment: sentiment_data.sentiment,

          // reviews: sentiment_data.reviews,

          // name: sentiment_data?.user_name,

          // likes: '',

          // circle: ''

        }]

      },
      {



        blocks: [

          {

            component: 'ClusterAnalysisGraph',

            Title: 'Emotions',

            type: 'Emotions',
            noBorder: true
            // xAxisValues: sentiment_data['X-axis'],

            // yAxisValues: sentiment_data['Y-axis'],

            // sentimentLabel: sentimentLabel,

            // sentimentColor: sentimentColor,

            // sentiment: sentiment_data.sentiment,

            // reviews: sentiment_data.reviews,

            // name: sentiment_data?.user_name,

            // likes: '',

            // circle: ''

          }]

      },


      ]
},


{

  name: 'Custom Analysis',

    resetFlag: true,

      child: [{



        blocks: [{
          job_id:'',
          preview_uuid:'',
          onUpdateSearchQuery:null,
          channel:'',
          component: 'CustomAnalysis',
          noBorder: true
        }]

      },


      ]
},

{

  name: 'Posts',

    resetFlag: true,

      child: [{



        blocks: [{

          component: 'MessagesTab',
          noBorder: true,
          download: download,


          hideDropdown: false,
          email_id: null,
          showReport: false,
          startDate: '',
          endDate: '',
          preview_uuid: '',
          job_id: '',
          filteredData: '',
          channel: '',
          columns: '',
          // jobid,channel,preview_uuid,
          // reviews: reviewsForTopicData,

          filterCol: [2, 3, 4],
          data: null,

          columns: ['Posts', 'Profile', 'Followers', 'Engagement']

        }]

      },


      ]
}

  ]

},
{

  posts_name:'review',
  channel: 'Amazon',
  description: null,
  is_topic: true, 
  job_id: null,
  keywords_string: null,
  topic_title: null,
  startDate:null,
  endDate:null,

  tabs: [
    {

      name: 'Overviews',

      resetFlag: true,

      child: [{



        blocks: [
          {

            component: 'OverAllReviews',
            title:'Overall Reviews',

            icon: X_icon,

            data: null,
            message: 'message',

          },
          {

            component: 'GraphHOC',

            title: 'Overall Customer Satisfaction',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          }
        ]

      },
      {
        blocks: [
          {

            component: 'GraphHOC',

            title: 'Customer Rating',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          },
          {

            // component: 'GraphHOC',

            title: 'Verified and Non-Verified Reviews',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          }
        ]
      },
      {
        blocks: [
          {

            component: 'GraphHOC',

            title: 'Gender Demographics',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          },
          {

            component: 'GraphHOC',

            title: 'Gender Based Average Rating',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          }
        ]
      },

      {
        blocks: [
          {

            component: 'GraphHOC',

            title: 'Gender Based Rating',

            FilterOptions: '',

            hideDropdown: false,

            data: null,
            graphStyles: {
              barHeight: '32px',
              width: 450,
            },
            view: null,


          }
        ]
      },
     
      {



        blocks: [{

          component: "TrendsInUserActivity",

          title: 'Trends In User Activity',

          // FilterOptions: '',

          hideDropdown: false,

          data: null,
          option : 'Years',

          ylabel: 'Engagement'

        }]

      }
     


      ]
    },


    {

      name: 'Sentiments',

      resetFlag: true,

      child: [{



        blocks: [
          //   {

          //   component: 'SentimentDrilldown',

          //   data: null
          //   // data: top_posts,

          // },
          // {

          //   component: 'GraphHOC',

          //   title: 'Sentiment Drilldown',

          //   FilterOptions: '',

          //   hideDropdown: false,

          //   // data: sentiments.percentageValue,
          //   data: null,
          //   view: 'null',
          //   graphStyles: {

          //     width: 500,
          //     FunnelWidth: '50%',
          //     barWidth: '60%'
          //   },
           

          // },
          {

            component: 'SentimentDrilldown',

            title: 'Sentiment Drilldown',

            FilterOptions: '',

            hideDropdown: false,

            // noBorder: true,
            // data: sentiments.percentageValue,
            data: null,
            view: 'null',
            graphStyles: {

              width: 500,
              FunnelWidth: '50%',
              barWidth: '60%'
            },
          }
        
        ]

    },
    {
      blocks: [
{
  name:'null',
      resetFlag: true,
      child:[]
}
      ]
      
    },
   
    {



      blocks: [{

        component: 'TopRetweets',

        title: 'Top Helpful Reviews',

        // FilterOptions: '',

        // noBorder: true,
        hideDropdown: false,

        // data: sentiments.Top_retweets,
        data: null,
        view: 'Vertical',

        type: 'Helpful Reviews',
        people_active: ' Helpful',
        

      }]

    },


  ]
},


  {

    name: 'Insights',

    resetFlag: true,

    child: [{



      blocks: [{

        component: 'WordCloudAnalysis',

        FilterOptions: '',

        hideDropdown: false,
        data: null

          // data: insights.insights_data,

        }]

    },
    
      {



        blocks: [{

          component: 'GraphHOC',

          title: 'Review Helpfulness',

          FilterOptions: '',

          hideDropdown: false,

          data: null,
          // data: insights.most_mentioned,

          view: 'null',
          graphStyles: {

            barHeight: '32px',
            verticalBarWidth: '70%',
            width: 500,
            FunnelWidth: '80%',
            barWidth: '70%'
          },

        }]

      },


      {



        blocks: [{

          component: 'GraphHOC',

          title: 'Emotion Analysis',

          FilterOptions: '',

          hideDropdown: false,

          // data: insights.emotion_analysis,

          view: 'null',
          graphStyles: {
            showemojiList: true,
            barHeight: '32px',
            verticalBarWidth: '70%',
            width: 500,
            FunnelWidth: '80%',
            barWidth: '70%'
          },

        }]

      },


      {



        blocks: [{

          component: 'CorrelationGraph',

          ratingLabels: [
            'Engagement',
          'Anger',
          'Neutral',
          'Sadness',
          'Disgust',
          'Fear',
          'Joy',
          'Surprise',
          ],


        FilterOptions: '',

        hideDropdown: false,

        view: 'null',

      }]

  },


      ]
    },


{

  name: 'Cluster Analysis',

    resetFlag: true,

      child: [{



        blocks: [{
          data: null,

          component: 'ClusterAnalysisGraph',

          Title: 'Sentiments',

          type: 'Sentiments',
          noBorder: true

          // xAxisValues: sentiment_data['X-axis'],

          // yAxisValues: sentiment_data['Y-axis'],

          // sentimentLabel: sentimentLabel,

          // sentimentColor: sentimentColor,

          // sentiment: sentiment_data.sentiment,

          // reviews: sentiment_data.reviews,

          // name: sentiment_data?.user_name,

          // likes: '',

          // circle: ''

        }]

      },
      {



        blocks: [

          {

            component: 'ClusterAnalysisGraph',

            Title: 'Emotions',

            type: 'Emotions',
            noBorder: true
            // xAxisValues: sentiment_data['X-axis'],

            // yAxisValues: sentiment_data['Y-axis'],

            // sentimentLabel: sentimentLabel,

            // sentimentColor: sentimentColor,

            // sentiment: sentiment_data.sentiment,

            // reviews: sentiment_data.reviews,

            // name: sentiment_data?.user_name,

            // likes: '',

            // circle: ''

          }]

      },


      ]
},


{

  name: 'Custom Analysis',

    resetFlag: true,

      child: [{



        blocks: [{
          job_id:'',
          preview_uuid:'',
          onUpdateSearchQuery:null,
          channel:'',
          component: 'CustomAnalysis',
          noBorder: true
        }]

      },


      ]
},

{

  name: 'Reviews',

    resetFlag: true,

      child: [{



        blocks: [{

          component: 'MessagesTab',
          noBorder: true,
          download: download,


          hideDropdown: false,
          email_id: null,
          showReport: false,
          startDate: '',
          endDate: '',
          preview_uuid: '',
          job_id: '',
          filteredData: '',
          channel: '',
          columns: '',
          // jobid,channel,preview_uuid,
          // reviews: reviewsForTopicData,

          filterCol: [2, 3, 4],
          data: null,

          columns: ['Reviews', 'Profile', 'Helpful', 'Verified']

        }]

      },


      ]
}

  ]

}
]

export const reviewsObj =
{

  name: 'Reviews',

  resetFlag: true,

  child: [{



    blocks: [{

      // component: MessagesTab,

      download: download,

      // reviews: reviewsForTopicData,

      filterCol: [2, 3, 4],
      data: null,

      columns: ['Posts', 'Profile', 'Followers', 'Engagement']

    }]

  },


  ]
}



export const channelManipulation = (data) => {

  const defaultChannelObject = channelObject[0];
  // let modifiedObject =  JSON.parse(JSON.stringify(channelObject[2]))
  let modifiedObject =  data?.channel === "instagram" ?  JSON.parse(JSON.stringify(channelObject[1])) :  JSON.parse(JSON.stringify(channelObject[0]));

  let totalNoOfPosts = data?.data?.overview_tab?.total_posts || data?.data?.overview_tab?.overall_reviews  || null;
  let startDate = data?.start_date || null;
  let endDate = data?.end_date || null;

  const genderDemographicsData = [
    {
      name: 'Male',
      value: data?.data?.overview_tab?.gender_demographics?.male_percentage || 0,
      fill: '#6640B8',
    },
    {
      name: 'Female',
      value: data?.data?.overview_tab?.gender_demographics?.female_percentage || 0,
      fill: '#EAAABD',
    },
    {
      name: 'Unknown',
      value: data?.data?.overview_tab?.gender_demographics?.unknown_percentage || 0,
      fill: '#E4E4E4',
    },
  ];

  const sentimentsData = [
    {
      name: 'Positive',
      value: parseFloat(data?.data?.sentiments_tab?.sentiment_drilldown?.graph_data?.postive_percentage),
      fill: '#A1FCC6',
    },
    {
      name: 'Negative',
      value: parseFloat(data?.data?.sentiments_tab?.sentiment_drilldown?.graph_data?.negative_percentage),
      fill: '#FF8C86',
    },
    {
      name: 'Neutral',
      value: parseFloat(data?.data?.sentiments_tab?.sentiment_drilldown?.graph_data?.neutral_percentage),
      fill: '#FFE092',
    },
  ];

  const topic_analysis = data?.data?.sentiments_tab?.sentiment_drilldown?.topic_analysis || null;

  const ratingColors = ['#676DFF', '#898DFB', '#ACAFFC', '#C5C6F3', '#D5D5DC']

  function convertObjectToBarGraphData(object = {}) {
    const barGraphData = Object.entries(object).map(([name, value]) => ({
      name,
      value: value,
    }));
    const sortData = barGraphData?.sort((a, b) => b.value - a.value);
    const dataWithColors = sortData.map((item, index) => ({
      ...item,
      fill: ratingColors[index]
    }));
    return dataWithColors;
  }

  const topHastagsData =
    convertObjectToBarGraphData(
      data?.data?.sentiments_tab?.top_five_hashtag
    ) || [];


  const mostMentionedData =
    convertObjectToBarGraphData(
      data?.data?.insights?.most_mentioned
    ) || [];


  const emotionAnalysis = [
    {
      name: 'Neutral',
      value: data?.data?.insights?.emotion_analysis?.[0]?.neutral,
      fill: '#F5CC61',
    },
    {
      name: 'Surprise',
      value: data?.data?.insights?.emotion_analysis?.[0]?.surprise,
      fill: '#B7DC68',
    },
    {
      name: 'Joy',
      value: data?.data?.insights?.emotion_analysis?.[0]?.joy,
      fill: '#FE00D5',
    },
    {
      name: 'Fear',
      value: data?.data?.insights?.emotion_analysis?.[0]?.fear,
      fill: '#FC9E58',
    },
    {
      name: 'Sadness',
      value: data?.data?.insights?.emotion_analysis?.[0]?.sadness,
      fill: '#4B9AE9',
    },
    {
      name: 'Anger',
      value: data?.data?.insights?.emotion_analysis?.[0]?.anger,
      fill: '#F74945',
    },
    {
      name: 'Disgust',
      value: data?.data?.insights?.emotion_analysis?.[0]?.disgust,
      fill: '#9071CE',
    },
  ];


  const sentimentColor = {
    1: '#73EBA4',
    0: '#FBD77D',
    '-1': '#FC6758',
  };
  const sentimentLabel = {
    '-1': 'Negative',
    1: 'Positive',
    0: 'Neutral',
  };

  const emotionLabel = {
    fear: 'Fear',
    surprise: 'Surprise',
    neutral: 'Neutral',
    anger: 'anger',
    disgust: 'Disgust',
    sadness: 'Sadness',
    joy: 'Joy',
  };

  const emotionColor = {
    fear: '#FC9E58',
    surprise: '#B7DC68',
    neutral: '#F5CC61',
    anger: '#F74945',
    disgust: '#9071CE',
    sadness: '#4B9AE9',
    joy: '#FE00D5',
  };

  const engagementData = [
    {
      name: 'Likes',
      value: data?.data?.overview_tab?.engagement?.likes || 0, 
      fill: '#6640B8',
    },
    {
      name: 'Comments',
      value:  data?.data?.overview_tab?.engagement?.comments || 0,
      fill: '#5FE495',
    },
    // {
    //   name: 'Shares',
    //   // value: 869,
    //   value: resetFlag === true && filteredData ? filteredData?.engagement?.shares : overview_tab?.engagement?.shares || 0,
    //   fill: '#6F62FE',
    // },
    // {
    //     name:'Saved',
    //     value:resetFlag === true && filteredData ? filteredData?.engagement.saved : overview_tab?.engagement?.saved || 0,
    //     fill:'#AB71CE'
    // }
  ];

  const verifiedAndNonVerified = [
    {
        name:'Verified',
        value:data?.data?.overview_tab?.verified_nonverified_users?.verified || 0,
        fill:'#6F62FE'
    },
    {
        name:'Non Verified',
        value:  data?.data?.overview_tab?.verified_nonverified_users?.nonverified || 0,
        fill:'#5FE495'
    }
  ]

  
  const customerSatisfaction = [
    {
      name: 'Average Rating ',
      value: data?.data?.overview_tab?.overall_customer_satisfaction?.average_rating || 0,
      fill: '#6640B8',
    },
    {
      name: 'Verified Purchase',
      value: data?.data?.overview_tab?.overall_customer_satisfaction?.verified_purchase || 0,
      fill: '#5FE495',
    },
    {
      name: 'Non Verified Purchase',
      value: data?.data?.overview_tab?.overall_customer_satisfaction?.non_verified_purchase || 0,
      fill: '#353cf7'
    },
  ];

  const userRating = [
    {
      name: '5 star ',
      value: parseFloat(data?.data?.overview_tab?.customer_ratings?.five_star_ratings),
      fill: '#676DFF',
    },
    {
      name: '4 star',
      value: parseFloat(data?.data?.overview_tab?.customer_ratings?.four_star_ratings),
      fill: '#898DFB',
    },
    {
      name: '3 star',
      value: parseFloat(data?.data?.overview_tab?.customer_ratings?.three_star_ratings),
      fill: '#ACAFFC',
    },
    {
      name: '2 star',
      value: parseFloat(data?.data?.overview_tab?.customer_ratings?.two_star_ratings),
      fill: '#C5C6F3',
    },
    {
      name: '1 star',
      value: parseFloat(data?.data?.overview_tab?.customer_ratings?.one_star_ratings),
      fill: '#D5D5DC',
    },
  ];

  const genderBasedRatingData = [
    {
      name: 'Male ',
      value: parseFloat(data?.data?.overview_tab?.gender_based_avg_rating?.male_percentage),
      fill: '#6640B8',
    },
    {
      name: 'Female',
      value: parseFloat(data?.data?.overview_tab?.gender_based_avg_rating?.female_percentage),
      fill: '#EAAABD',
    },
    {
      name: 'Unknown',
      value: parseFloat(data?.data?.overview_tab?.gender_based_avg_rating?.unknown_percentage),
      fill: '#D2D2D4',
    },
  ];

  modifiedObject['channel'] = data['channel'] || null;
  modifiedObject['description'] = data['description'] || null;
  modifiedObject['is_topic'] = data['is_topic'] || null;
  modifiedObject['job_id'] = data['job_id'] || null;
  modifiedObject['keywords_string'] = data['keywords_string'] || null;
  modifiedObject['title'] = data['title'] || null;
  modifiedObject['startDate'] = startDate || null;
  modifiedObject['endDate'] = endDate || null;
  modifiedObject['hyperlink'] = data['hyperlink'] || null;
  // 450 Relevant reviews were observed from
  modifiedObject['tabs'][0].child[0].blocks[0].message = `${totalNoOfPosts} Relevant ${ modifiedObject?.posts_name == 'post' ? 'posts':'reviews'} were observed From ${startDate} to ${endDate}`;
 if(data?.channel === "instagram"){
  modifiedObject['tabs'][0].child[0].blocks[1].startDate = startDate;
  modifiedObject['tabs'][0].child[0].blocks[1].endDate = endDate;
  modifiedObject['tabs'][0].child[0].blocks[1].type = '%';
  modifiedObject['tabs'][0].child[0].blocks[1].data = engagementData || null;

  
  modifiedObject['tabs'][0].child[1].blocks[0].startDate = startDate;
  modifiedObject['tabs'][0].child[1].blocks[0].endDate = endDate;
  modifiedObject['tabs'][0].child[1].blocks[0].type = '%';
  modifiedObject['tabs'][0].child[1].blocks[0].data = genderDemographicsData || null;

  modifiedObject['tabs'][0].child[1].blocks[1].startDate = startDate;
  modifiedObject['tabs'][0].child[1].blocks[1].endDate = endDate;
  modifiedObject['tabs'][0].child[1].blocks[1].type = '%';
  modifiedObject['tabs'][0].child[1].blocks[1].data = verifiedAndNonVerified || null;



  modifiedObject['tabs'][0].child[2].blocks[0].data = data?.data?.overview_tab?.trends_in_user_activity || null;


  modifiedObject['tabs'][0].child[3].blocks[0].data = data?.data?.overview_tab?.likes_trend_activity || null;


  modifiedObject['tabs'][0].child[4].blocks[0].data = data?.data?.overview_tab?.comments_trend_activity || null;

 }else if(data?.channel === "twitter"){
  modifiedObject['tabs'][0].child[0].blocks[1].startDate = startDate;
  modifiedObject['tabs'][0].child[0].blocks[1].endDate = endDate;
  modifiedObject['tabs'][0].child[0].blocks[1].type = '%';
  modifiedObject['tabs'][0].child[0].blocks[1].data = genderDemographicsData || null;

  modifiedObject['tabs'][0].child[1].blocks[0].data = data?.data?.overview_tab?.trends_in_user_activity || null;

 }else{
  
  modifiedObject['tabs'][0].child[0].blocks[1].startDate = startDate;
  modifiedObject['tabs'][0].child[0].blocks[1].endDate = endDate;
  modifiedObject['tabs'][0].child[0].blocks[1].type = '%';
  modifiedObject['tabs'][0].child[0].blocks[1].data = customerSatisfaction || null;

  
  modifiedObject['tabs'][0].child[1].blocks[0].startDate = startDate;
  modifiedObject['tabs'][0].child[1].blocks[0].endDate = endDate;
  modifiedObject['tabs'][0].child[1].blocks[0].type = '%';
  modifiedObject['tabs'][0].child[1].blocks[0].data = userRating || null;
  
  modifiedObject['tabs'][0].child[1].blocks[1].startDate = startDate;
  modifiedObject['tabs'][0].child[1].blocks[1].endDate = endDate;
  modifiedObject['tabs'][0].child[1].blocks[1].type = '%';
  modifiedObject['tabs'][0].child[1].blocks[1].data = data?.data?.overview_tab?.Verified_non_verified_users || null;

  
  modifiedObject['tabs'][0].child[2].blocks[0].startDate = startDate;
  modifiedObject['tabs'][0].child[2].blocks[0].endDate = endDate;
  modifiedObject['tabs'][0].child[2].blocks[0].type = '%';
  modifiedObject['tabs'][0].child[2].blocks[0].data = genderDemographicsData || null;


  modifiedObject['tabs'][0].child[2].blocks[1].startDate = startDate;
  modifiedObject['tabs'][0].child[2].blocks[1].endDate = endDate;
  modifiedObject['tabs'][0].child[2].blocks[1].type = '%';
  modifiedObject['tabs'][0].child[2].blocks[1].data = genderBasedRatingData || null;

  modifiedObject['tabs'][0].child[3].blocks[0].startDate = startDate;
  modifiedObject['tabs'][0].child[3].blocks[0].endDate = endDate;
  modifiedObject['tabs'][0].child[3].blocks[0].type = '%';
  modifiedObject['tabs'][0].child[3].blocks[0].data = genderBasedRatingData || null;

  modifiedObject['tabs'][0].child[4].blocks[0].startDate = startDate;
  modifiedObject['tabs'][0].child[4].blocks[0].endDate = endDate;
  modifiedObject['tabs'][0].child[4].blocks[0].type = '%';
  modifiedObject['tabs'][0].child[4].blocks[0].data = data?.data?.overview_tab?.trends_in_user_activity || null;

 }

  // modifiedObject['tabs'][1].child[0].blocks[0].startDate = startDate;
  // modifiedObject['tabs'][1].child[0].blocks[0].endDate = endDate;
  // modifiedObject['tabs'][1].child[0].blocks[0].type = '%';
  // modifiedObject['tabs'][1].child[0].blocks[0].data = sentimentsData || null;


  modifiedObject['tabs'][1].child[0].blocks[0].startDate = startDate;
  modifiedObject['tabs'][1].child[0].blocks[0].endDate = endDate;
  modifiedObject['tabs'][1].child[0].blocks[0].type = '%';
  modifiedObject['tabs'][1].child[0].blocks[0].data = {
    sentimentsData,
    topic_analysis} || null;
  // modifiedObject['tabs'][1].child[0].blocks[1].startDate = startDate;
  // modifiedObject['tabs'][1].child[0].blocks[1].endDate = endDate;
  // modifiedObject['tabs'][1].child[0].blocks[1].type = '%';
  // modifiedObject['tabs'][1].child[0].blocks[1].data = sentimentsData|| null;


  modifiedObject['tabs'][1].child[1].blocks[0].startDate = startDate;
  modifiedObject['tabs'][1].child[1].blocks[0].endDate = endDate;
  modifiedObject['tabs'][1].child[1].blocks[0].type = '%';
  modifiedObject['tabs'][1].child[1].blocks[0].data = topHastagsData || null;


  modifiedObject['tabs'][1].child[2].blocks[0].startDate = startDate;
  modifiedObject['tabs'][1].child[2].blocks[0].endDate = endDate;
  // modifiedObject['tabs'][1].child[2].blocks[0].type = '%';
  modifiedObject['tabs'][1].child[2].blocks[0].data = {
    total_positive_posts: data?.data?.sentiments_tab?.top_reposts?.total_positive_posts ||  data?.data?.sentiments_tab?.top_posts?.total_positive_posts   || null,
    total_negative_posts: data?.data?.sentiments_tab?.top_reposts?.total_negative_posts || data?.data?.sentiments_tab?.top_posts?.total_negative_posts || null,
    total_neutral_posts: data?.data?.sentiments_tab?.top_reposts?.total_neutral_posts || data?.data?.sentiments_tab?.top_posts?.total_neutral_posts ||  null,
    top_neutral_posts: data?.data?.sentiments_tab?.top_reposts?.top_neutral_posts || data?.data?.sentiments_tab?.top_posts?.top_neutral_posts || null,
    top_negative_posts: data?.data?.sentiments_tab?.top_reposts?.top_negative_posts || data?.data?.sentiments_tab?.top_posts?.top_negative_posts || null,
    top_positive_posts: data?.data?.sentiments_tab?.top_reposts?.top_positive_posts || data?.data?.sentiments_tab?.top_posts?.top_positive_posts || null,

  } || null;



  modifiedObject['tabs'][2].child[0].blocks[0].startDate = startDate;
  modifiedObject['tabs'][2].child[0].blocks[0].endDate = endDate;
  modifiedObject['tabs'][2].child[0].blocks[0].data = data?.data?.insights || null;



  modifiedObject['tabs'][2].child[1].blocks[0].startDate = startDate;
  modifiedObject['tabs'][2].child[1].blocks[0].endDate = endDate;
  modifiedObject['tabs'][2].child[1].blocks[0].data = mostMentionedData || null;


  modifiedObject['tabs'][2].child[2].blocks[0].startDate = startDate;
  modifiedObject['tabs'][2].child[2].blocks[0].endDate = endDate;
  modifiedObject['tabs'][2].child[2].blocks[0].data = emotionAnalysis || null;


  modifiedObject['tabs'][2].child[3].blocks[0].startDate = startDate;
  modifiedObject['tabs'][2].child[3].blocks[0].endDate = endDate;
  modifiedObject['tabs'][2].child[3].blocks[0].data = data?.data?.insights?.correlation_analysis || null;

  modifiedObject['tabs'][3].child[0].blocks[0].startDate = startDate;
  modifiedObject['tabs'][3].child[0].blocks[0].endDate = endDate;
  modifiedObject['tabs'][3].child[0].blocks[0].data =
    {
      'X-axis': data?.data?.cluster_analysis?.sentiment_data?.['X-axis'],
      'Y-axis': data?.data?.cluster_analysis?.sentiment_data?.['Y-axis'],
      'circle': data?.data?.cluster_analysis?.sentiment_data?.circle,
      'date': data?.data?.cluster_analysis?.sentiment_data?.date,
      "count": data?.data?.cluster_analysis?.sentiment_data?.retweet_count,
      "reviews": data?.data?.cluster_analysis?.sentiment_data?.reviews,
      'labels': sentimentLabel,
      'colors': sentimentColor,
      "values": data?.data?.cluster_analysis?.sentiment_data?.sentiment,

      "user_name": data?.data?.cluster_analysis?.sentiment_data?.user_name

    } || null;


  modifiedObject['tabs'][3].child[1].blocks[0].startDate = startDate;
  modifiedObject['tabs'][3].child[1].blocks[0].endDate = endDate;
  modifiedObject['tabs'][3].child[1].blocks[0].data = {

    'X-axis': data?.data?.cluster_analysis?.emotion_data?.['X-axis'],
    'Y-axis': data?.data?.cluster_analysis?.emotion_data?.['Y-axis'],
    'circle': data?.data?.cluster_analysis?.emotion_data?.circle,
    'date': data?.data?.cluster_analysis?.emotion_data?.date,
    'values': data?.data?.cluster_analysis?.emotion_data?.emotion,
    'count': data?.data?.cluster_analysis?.emotion_data?.retweet_count,
    'reviews': data?.data?.cluster_analysis?.emotion_data?.reviews,
    'user_name': data?.data?.cluster_analysis?.emotion_data?.user_name,
    'labels': emotionLabel,
    'colors': emotionColor,

  } || null;
  
  modifiedObject['tabs'][4].child[0].blocks[0].job_id = data['job_id'] || null;
  modifiedObject['tabs'][4].child[0].blocks[0].preview_uuid = data?.preview_uuid || null;
  modifiedObject['tabs'][4].child[0].blocks[0].channel = data['channel'] || null;


  modifiedObject['tabs'][5].child[0].blocks[0].startDate = startDate || null;

  modifiedObject['tabs'][5].child[0].blocks[0].endDate = endDate || null;
  modifiedObject['tabs'][5].child[0].blocks[0].email_id = data?.email_id || null;
  modifiedObject['tabs'][5].child[0].blocks[0].preview_uuid = data?.preview_uuid || null;
  modifiedObject['tabs'][5].child[0].blocks[0].job_id = data['job_id'] || null;
  modifiedObject['tabs'][5].child[0].blocks[0].filteredData = [] || null;
  modifiedObject['tabs'][5].child[0].blocks[0].channel = data['channel'] || null;
  modifiedObject['tabs'][5].child[0].blocks[0].columns = ['Posts', 'Profile', 'Followers', 'Engagement'] || null;


  return modifiedObject;


}


export const channelReviewManipulation = (data) => {

  let modifiedReviewObj = { ...reviewsObj };

  modifiedReviewObj.child[0].blocks[0].data = data?.reviews || null;

  return modifiedReviewObj;
}