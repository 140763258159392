import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 95%;
  flex-direction: column;
  margin-bottom: 10px;
  /*  background:red; */
`;

export const FlexWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /*  gap: 45px 10px;  */
  margin: 40px 0px 0px 20px;
`;

export const ContentWrapper1 = styled.div`
  width: 50%;
  height: 380px;
  flex-shrink: 0;

  border-radius: 15px;
  border: 1px solid #d9d9d9;
  justify-content: space-between;

  p {
    padding: 0.5em 0 0 2em;
    align-items: center;
    color: #636363;

    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &>span: first-child {
    /* display: flex; */
    /* margin: 20px 0px 0px 20px; */
    color: #636363;
    font-size: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
  }
`;

export const ContentWrapper2 = styled.div`
  display: flex;
  margin-left: 15px;
  flex-direction: column;
  width: 50%;
  height: 380px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  p {
    padding: 0.532em 0 0 2em;
    color: #636363;
margin-bottom: 50px;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &>span: first-child {
    /* display:flex; */
    color: #636363;
    /* margin: 20px 0px 0px 20px; */
    font-size: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
  }
  &>div: first-child {
    flex: 1;
  }
`;

export const FlexWrapper2 = styled(FlexWrapper1)`
  margin-top: 20px;
`;


export const ContentWrapperFlipkart = styled(ContentWrapper1)`
  width: 100%;
`;


export const TextWrapper = styled.div`
  width: 389px;
  display: flex;
  height: 230px;
  margin: 40px 0px 0px 55px;

  border-radius: 36px 36px 0px 36px;
  background: rgba(111, 98, 254, 0.22);

  span {
    //display: flex;
    margin: 80px 0px 0px 20px;
    width: 230px;
    height: 85px;
    flex-direction: column;

    color: #000;
    text-align: center;
    font-size: 17px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
  }

  div {
    width: 90px;
    height: 86px;
    flex-shrink: 0;
    margin: -20px 0px 0px -30px;

    border-radius: 90px;
    border: 1px solid rgba(85, 172, 238, 0.84);
    background: #fff;
    box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 30px;
  }
`;

export const Span = styled.div`
  color: rgba(0, 0, 0, 0.87);

  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: flex-end;

  text-align: right;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 5px;
`;

export const GraphContainer = styled.div`
  flex: 1;
`;

export const GraphWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  margin: 20px 0px 0px 20px;
`;

export const ChildContainer = styled.div`
  /* flex: 1; */
  height: 100%;
   min-height:380px;
  border-radius: 15px;
  width: 50%;
  border: 1px solid #d9d9d9;

  /* display: flex; */
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-left: 15px;
`;

export const ChildContainerGenderBasedAvg = styled.div`
  /* flex: 1; */
  height: 380px;
   min-height:380px;
  border-radius: 15px;
  width: 50%;
  border: 1px solid #d9d9d9;

  /* display: flex; */
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-left: 15px;
`;

export const Title = styled.p`
  color: #636363;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  margin: 10px 0px 0px 10px;
`;

export const Div = styled.div`
  justify-content: center;
  margin-top: 20px;
`;