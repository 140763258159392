// BarGraph.js
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import styled from 'styled-components';
import { StarIcon } from '../../../Common/SvgIcon/CommonSvgIcons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: max-height;
`;

const BarWrapper1 = styled.div`
 display: flex;
 flex-direction: row;
 font-family: Open Sans;

 div {
  height: 50px; 
  margin: 30px 0px 0px 10px;
  border-radius: 0px 12px 12px 0px;
  background: ${(props) =>
    props.gender === 'female'
      ? '#EAAABD'
      : props.gender === 'male'
      ? '#6640B8'
      : props.gender === 'unknown'
      ? '#D2D2D4'
      : '#EAAABD'};
 }
   
 & > span:first-child {
  width: 65px;
  color: #000;
  font-weight: 400;
  margin: 45px 0px 0px 15px;
  text-align: right;
  font-size: 12px;
  color: #636363;
}
& > span:last-child {
  color: #000;
  font-weight: 400;
  margin: 50px 0px 0px 15px;
}
`;


const GenderBarGraph = ({ malePercentage, femalePercentage, unknownPercentage,context }) => {
 
  const renderSymbol = (gender) => {
    if (context === 'demographics') {
      return '%'; // Display % symbol for demographics
    } else if (context === 'ratings') {
      return <span className="symbol">{StarIcon}</span>; // Display * symbol for ratings
    } else {
      return null; // Handle other cases or provide a default
    }
  };
  return (
    <Wrapper>
      <BarWrapper1 gender = 'female'>
       <span>Female</span>
       <div style={{width:`${femalePercentage + 1}`*3}}></div>
       <span>{femalePercentage}{renderSymbol('female')}</span>
      </BarWrapper1>
      <BarWrapper1 gender = 'male'>
       <span>Male</span>
       <div style={{width:`${malePercentage + 1}`* 3}}></div>
       <span>{malePercentage}{renderSymbol('male')}</span>
      </BarWrapper1>
      <BarWrapper1 gender = 'unknown'>
       <span>Unknown</span>
       <div style={{width:`${unknownPercentage + 1}`* 3}}></div>
       <span>{unknownPercentage}{renderSymbol('unknown')}</span>
      </BarWrapper1>
    </Wrapper>
  )
}

export default GenderBarGraph;
