import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { color } from '@mui/system';

// Register the required components
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const TrendsInUserActivity = ({title1='',title2='',width,topic1_dates,topic2_dates,topic1_values,topic2_values}) => {

  const [viewportHeight, setViewportHeight] = useState(window.innerWidth);
  // const divRef = useRef(null); // Step 1: Create a ref
  // const [divWidth, setDivWidth] = useState(0); // Step 2: State to store width
  
  // useEffect(() => {
  //   // Step 3: Access the width after the component mounts
  //   if (divRef.current) {
  //     setDivWidth(divRef.current.offsetWidth); // Get the width
  //   }

  //   const handleResize = () => {
  //     if (divRef.current) {
  //       setDivWidth(divRef.current.offsetWidth); // Update width on resize
  //     }
  //   };

  //   window.addEventListener('resize', handleResize); // Handle window resize
  //   return () => {
  //     window.removeEventListener('resize', handleResize); // Cleanup
  //   };
  // }, []);


  // console.log(viewportHeight,divWidth, typeof(divWidth),"viewportHeight");
  // Sample wavelength data (dates)


  // Corresponding energy values (dates)

  // Absorbance data for green and red lines



  // Data and options for the chart
  const data = {
    labels: topic1_dates,
    datasets: [
      {
        fill: false,
        data: topic1_values,
        borderColor: 'rgba(54, 162, 235, 1)', // Line color for topic 1
        pointBackgroundColor: 'green', // Marker color for topic 1
        pointBorderWidth: 0,
        pointRadius: 3, // Size of the markers
      },
      {
        fill: false,
        data: topic2_values,
        borderColor: 'rgba(255, 99, 132, 1)', // Line color for topic 1
        pointBackgroundColor: 'red', // Marker color for topic 2

        // borderDash: [10, 5], // Dotted line (10px line, 5px gap)
        pointBorderWidth: 0,
        pointRadius: 3, // Size of the markers
        xAxisID: 'topic2_dates', // Use a secondary x-axis for energy
      },
    ],
  };

  const options = {
    responsive: true,

    // maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        labels: topic1_dates, // Main x-axis for wavelength
        grid: {
          display: true, // Display grid lines
          drawOnChartArea: false, // Prevent drawing grid lines for all ticks
          drawTicks: true, // Draw ticks for all labels
          tickColor: 'black',
          drawBorder: true, // Draw the border line
          color: (context) => {
            return context.tick.value === topic1_dates[topic1_dates.length - 1] ? 'purple' : 'transparent';
          },
        },
        title: {
          display: true,
          text: title1,
          color: '#1f1f1f'
          // color: 'rgba(54, 162, 235, 1)'
        },
        ticks: {
          color: 'rgba(54, 162, 235, 1)' // Alternate colors for tick labels
        },
      },
      topic2_dates: {
        type: 'category',
        position: 'top',
        labels: topic2_dates, // Secondary x-axis for energy
        grid: {
          display: true, // Display grid lines
          drawOnChartArea: false, // Prevent drawing grid lines for all ticks
          drawTicks: true, // Draw ticks for all labels
          tickColor: 'red',

          drawBorder: true, // Draw the border line
          color: (context) => {
            return context.tick.value === topic2_dates[topic2_dates.length - 1] ? 'red' : 'transparent';
          },
        },
        title: {
          display: true,
          text: title2,
          color: '#1f1f1f'
          // color:'#e50232'
        },
        ticks: {
          color: '#e50232' // Alternate colors for tick labels
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true, // Display grid lines
          drawTicks: true, // Draw ticks for all labels
          tickColor: 'black',
          drawBorder: true, // Draw the border line
          color: (context) => {
            const maxValue = Math.max(...topic1_values, ...topic2_values); // Find the maximum value
            return context.tick.value === maxValue ? 'black' : 'transparent';
          },
        },
        title: {
          display: true,
          text: 'Reviews',
        },
        ticks: {
          color: 'black' // Default color for Y-axis labels
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div style={{
      width: '100%', height: 500, marginBottom: '10px',
    }}>
      {/* <Line data={data} options={options} /> minWidth:'1163px', */}
      <Line
      // onResize={}
        data={data}
        options={options}
        width={width} // Default behavior for responsive
        height={500} // Set height in pixels
      />
    </div>
  );
};

export default TrendsInUserActivity;
