

import React from 'react';
import {
    SplitWrapper,
    CloseIconDiv,

    CustomDivSplit,
    NameH3,
    PostH6,
    ParaOneSentence,
    ParaTwoSentence,
    HelplessText
} from './PostReport.styled'
import CloseIcon from '@mui/icons-material/Close';

const PostsSlpitScreen = ({ setShowSplit, popUpData }) => {
    return (

        <SplitWrapper>
            <CloseIconDiv>
                <CloseIcon style={{ fontSize: '30px', cursor: 'pointer' }} onClick={() => setShowSplit(false)} />

            </CloseIconDiv>
            <CustomDivSplit>
                <NameH3>{popUpData?.name}</NameH3>
                <PostH6>Post on {popUpData?.date_and_time}</PostH6>
                {/* <ParaOneSentence>{`Colour: Black Titanium Size: 256 GB`}</ParaOneSentence> */}
                <ParaTwoSentence>
                    {popUpData?.posts}
                </ParaTwoSentence>
                <HelplessText>{popUpData?.helpful_like_count} Helpfulness</HelplessText>
            </CustomDivSplit>

        </SplitWrapper>
    );
};


export default PostsSlpitScreen;