import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import SmallSelect from '../../../Common/Select/SmallSelect';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import CollapsibleText from '../../../components/ReviewText/collapsibleText';
import { format, parse } from 'date-fns';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';

const Wrapper = styled.div`
  padding: 30px;

  & .title {
    /* color: #636363; */
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
  }
`;

const TopHashtagsWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  &> p: first-child {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0px 0px 10px;
  }
`;

const TopRepostWrapper = styled.div`
  margin-top: 30px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  align-items: center;
`;

const FilteredGraphContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 50px;
  align-items: center;
`;

const PostCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  padding: 0 18px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3em;
  }
`;
const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;

  border: 2px solid #fff;
`;

const PositiveCircle = styled(Circle)`
  background: #5ce390;
`;

const NeutralCircle = styled(Circle)`
  background: #f5cc61;
`;

const NegativeCircle = styled(Circle)`
  background: #fc6758;
`;

const TopPostWrapper = styled.div`
  & > div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 15px;
    padding: 20px;

    .reviewText {
    word-break: break-word;
      flex: 1;
      border-right: 1px solid #d9d9d9;
      padding: 0 10px;
    }
    .reviewCount {
      width: 10%;
      text-align: center;

      .peopleLiked {
        font-size: 10px;
      }
    }
  }

  .positiveReview {
    background: rgba(92, 227, 144, 0.2);
  }

  .negativeReview {
    background: rgba(249, 98, 89, 0.18);
  }

  .neutralReview {
    background: rgba(254, 222, 141, 0.37);
  }
`;

const SentimentDrilldownWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  padding-top: 0px;
  align-items: center;
`;

const TopPostTopicWrapper = styled.div`
  margin-left: 40px;

  .topicList {
    padding-left: 35px;
    padding-bottom: 10px;
  }

  .positiveTopic {
    color: #40bb72;

    &:before {
      background: linear-gradient(180deg, #a1fcc6 0%, #5fe495 100%);
    }
  }
  .neutralTopic {
    color: #dfae30;
    &:before {
      background: linear-gradient(180deg, #ffe092 0%, #f9d16d 100%);
    }
  }
  .negativeTopic {
    color: #fc615a;
    &:before {
      background: linear-gradient(180deg, #ff8c86 0%, #fa6961 100%);
    }
  }
`;

const TopPostTopicHeading = styled.div`
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 20px;

  &:before {
    display: inline-block;
    content: '';
    width: 17px;
    height: 17px;
  }
`;

const SelectWrapper = styled.div`
  width: 100px;
  padding: 20px 0 10px 35px;
`;

const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 30px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 0px 10px;
  }
`;

const Sentiments = ({
  sentiments_tab = {},
  startDate = '',
  endDate = '',
  filterOption,
  filteredData,
  filteredStartDate,
  filteredEndDate,
  view,
  resetFlag,
  channel,
  hideDropdown,

  formatValue = "dd-yyyy-MM"
}) => {
  const [topTopicCount, setTopTopicCount] = useState({
    positive: '1 Topic',
    negative: '1 Topic',
    neutral: '1 Topic',
  });



  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  const percentageValue = resetFlag === true && filteredData
    ? filteredData?.sentiment_drilldown
    : sentiments_tab?.sentiment_drilldown;

  const sentimentsData = [
    {
      name: 'Positive',
      value: parseFloat(percentageValue?.positive_sentiment),
      fill: '#A1FCC6',
    },
    {
      name: 'Negative',
      value: parseFloat(percentageValue?.negative_sentiment),
      fill: '#FF8C86',
    },
    {
      name: 'Neutral',
      value: parseFloat(percentageValue?.neutral_sentiment),
      fill: '#FFE092',
    },
  ];
  const ratingColors = ['#676DFF', '#898DFB', '#ACAFFC', '#C5C6F3', '#D5D5DC']

  function convertObjectToBarGraphData(object = {}) {
    const barGraphData = Object.entries(object).map(([name, value]) => ({
      name,
      value: value,
    }));
    const sortData = barGraphData.sort((a, b) => b.value - a.value);
    const dataWithColors = sortData.map((item, index) => ({
      ...item,
      fill: ratingColors[index]
    }));
    return dataWithColors;
  }
  function checkAndConvert(obj) {
    // Check if there's only one key and that key is an empty string, with a value of 0
    if (obj && typeof obj === 'object' && Object.keys(obj).length === 1 && obj.hasOwnProperty("") && obj[""] === 0) {
      return []; // Return an empty array
    }
    return convertObjectToBarGraphData(obj); // Otherwise, return the original object
  }


  const topHastagsData =
    checkAndConvert(
      resetFlag === true && filteredData
        ? filteredData?.top_five_hashtag
        : sentiments_tab?.top_five_hashtag
    ) || [];
  // console.log(topHastagsData, "topHastagsData");

  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={topHastagsData} />
      ) : (
        <BarGraph data={topHastagsData} maxWidth={'60%'} />
      ),
    'Pie Chart': <RatingDonutChart data={topHastagsData} width={500} />,
    'Donut Chart': <DonutChart data={topHastagsData} />,
    'Funnel Chart': <FunnelGraph data={topHastagsData} maxWidth={'50%'} />,
  };

  const filteredTopHastag = renderScreen[filterOption];

  const sentimentsRenderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={sentimentsData} type={'%'} />
      ) : (
        <BarGraph data={sentimentsData} maxWidth={'60%'} type={'%'} />
      ),
    'Pie Chart': (
      <RatingDonutChart data={sentimentsData} width={500} type='percentage' />
    ),
    'Donut Chart': (
      <DonutChart data={sentimentsData} sentiment={true} type='percentage' />
    ),
    'Funnel Chart': (
      <FunnelGraph data={sentimentsData} maxWidth={'50%'} type={'%'} />
    ),
  };

  const filteredSentimentDrillDown = sentimentsRenderScreen[filterOption];

  const handleTopTopicCountChange = (topic, topicType) => {
    if (topicType === 'positive') {
      setTopTopicCount({ ...topTopicCount, positive: topic });
    } else if (topicType === 'neutral') {
      setTopTopicCount({ ...topTopicCount, neutral: topic });
    } else if (topicType === 'negative') {
      setTopTopicCount({ ...topTopicCount, negative: topic });
    }
  };

  const renderTopics = (topicStrings, type) => {
    const topicData = Object.keys(topicStrings);
    // if (topicData.length === 0) return null;
    if (!topicStrings || Object.keys(topicStrings).length === 0) return null;

    const topicCount = parseInt(topTopicCount[type]);
    const topicKeys = topicData.slice(0, topicCount);

    // const topicKeys = topicData.filter(
    //   (key, index) => index < topTopicCount[type].split(' ')[1]
    // );
    return topicKeys.map((key, index) => (
      <div key={key}>
        {index + 1}. {topicStrings[key]}
      </div>
    ));
  };

  const totalTopicsColumn = (topicStrings) => {
    const topicData = Object.keys(topicStrings);
    if (topicData.length === 0) return ['No Topic'];

    // const columns = [];
    // topicData.forEach((_, index) => {
    //   columns.push('Topic ' + (index + 1));
    // });
    const columns = topicData.map((_, index) => `${index + 1} Topic${index + 1 > 1 ? 's' : ''}`);

    return columns;
  };

  function formatNumber(val) {
    if (val >= 1000000) {
      return (val / 1000000).toFixed(1) + 'M';
    } else if (val >= 1000) {
      return (val / 1000).toFixed(1) + 'k';
    } else if (val > 0) {
      return val.toString();
    } else {
      return '';
    }
  }





  const startDateParsed = useParsedStartDate(resetFlag, filteredStartDate, startDate, formatValue);
  const endDateParsed = useParsedEndDate(resetFlag, filteredEndDate, endDate, formatValue);



  if (Object.keys(sentiments_tab).length === 0) {
    return (
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        No data available
      </h2>
    );
  }
  return (
    <Wrapper>
      <SentimentDrilldownWrapper>
        {/* {filterOption ? (
          <FilterContainer>
            <span>Sentiment Drilldown</span>
            <div style={{ display: 'flex' }}>{filteredSentimentDrillDown}</div>
          </FilterContainer>
        ) : ( */}
        <GraphHOC
          title={'Sentiment Drilldown'}
          options={options}
          renderScreen={sentimentsRenderScreen}
          graphWidth={'100%'}
          margin={'20px 0px 20px 50px'}
          option={filterOption}
          hideDropdown={hideDropdown}
          startDate={startDateParsed}
          endDate={endDateParsed}

          formatValue={formatValue}
          showDate={false}
        />
        {/* )} */}

        <TopPostTopicWrapper>
          <div>
            <TopPostTopicHeading className='positiveTopic'>
              Positive Sentiments the posts were majorly related to:
            </TopPostTopicHeading>
            <SelectWrapper>
              <SmallSelect
                onChange={(topic) =>
                  handleTopTopicCountChange(topic, 'positive')
                }
                options={
                  totalTopicsColumn(
                    sentiments_tab?.topic_analysis
                      ?.positive_topics
                  ) || []
                }
              />
            </SelectWrapper>
            <div className='topicList'>
              {renderTopics(
                sentiments_tab?.topic_analysis
                  ?.positive_topics,
                'positive'
              )|| `No Data Available`}
            </div>
          </div>
        </TopPostTopicWrapper>
        <TopPostTopicWrapper>
          <div>
            <TopPostTopicHeading className='neutralTopic'>
              Neutral Sentiments the posts were majorly related to:
            </TopPostTopicHeading>
            <SelectWrapper>
              <SmallSelect
                onChange={(topic) =>
                  handleTopTopicCountChange(topic, 'neutral')
                }
                options={totalTopicsColumn(
                  sentiments_tab?.topic_analysis
                    ?.neutral_topics
                )}
              />
            </SelectWrapper>
            <div className='topicList'>
              {renderTopics(
                sentiments_tab?.topic_analysis
                  ?.neutral_topics,
                'neutral'
              ) || `No Data Available`}
            </div>
          </div>
        </TopPostTopicWrapper>
        <TopPostTopicWrapper>
          <div>
            <TopPostTopicHeading className='negativeTopic'>
              Negative Sentiments the posts were majorly related to:
            </TopPostTopicHeading>
            <SelectWrapper>
              <SmallSelect
                onChange={(topic) =>
                  handleTopTopicCountChange(topic, 'negative')
                }
                options={totalTopicsColumn(
                  sentiments_tab?.topic_analysis
                    ?.negative_topics
                )}
              />
            </SelectWrapper>
            <div className='topicList'>
              {renderTopics(
                sentiments_tab?.topic_analysis
                  ?.negative_topics,
                'negative'
              ) || `No Data Available`}
            </div>
          </div>
        </TopPostTopicWrapper>
        <Footer>
          {` This data is observed from ${startDateParsed} to ${endDateParsed}`}

          {/* This data is observed from {resetFlag ? filteredStartDate : startDate} to {resetFlag ? filteredEndDate : endDate} */}
        </Footer>
      </SentimentDrilldownWrapper>
      {/* {filterOption ? (
        <TopHashtagsWrapper>
          <p> Top Hashtags</p>
          <FilteredGraphContainer>{filteredTopHastag}</FilteredGraphContainer>
        </TopHashtagsWrapper>
      ) : ( */}
      {
        channel == 'facebook' ? ''
          :
          <TopHashtagsWrapper>
            <GraphHOC
              title={`Top Hashtags`}
              options={options}
              renderScreen={renderScreen}
              graphWidth={'100%'}
              margin={(filteredData?.top_five_hashtag || sentiments_tab?.top_five_hashtag) ? '20px 0px 20px 50px' : '20px 0px 20px 50px'}
              startDate={startDateParsed}
              endDate={endDateParsed}
              option={filterOption}
              hideDropdown={hideDropdown}
              formatValue={formatValue}
            />
          </TopHashtagsWrapper>
      }

      {/* )} */}

      <TopRepostWrapper>
        <p className='title'>{(channel === 'instagram' || channel == 'facebook') ? 'Top Posts' : 'Top Retweets'}</p>
        <PostCountWrapper>
          <div>
            <PositiveCircle />
            {(channel === 'instagram' || channel == 'facebook') ? `Total Positive Posts - ${filteredData
              ? filteredData?.top_posts?.total_positive_posts || 0
              : sentiments_tab?.top_posts?.total_positive_posts || 0
              }` : `Total Positive Retweets - ${filteredData
                ? filteredData?.top_reposts?.total_positive_posts || 0
                : sentiments_tab?.top_reposts?.total_positive_posts || `0`
              }`}
          </div>
          <div>
            <NegativeCircle />
            {(channel === 'instagram' || channel == 'facebook') ? 'Total Negative Posts -' : 'Total Negative Retweets -'}
            {(channel === 'instagram' || channel == 'facebook') ? filteredData
              ? filteredData?.top_posts?.total_negative_posts || 0
              : sentiments_tab?.top_posts?.total_negative_posts || 0
              :
              filteredData
                ? filteredData?.top_reposts?.total_negative_posts || 0
                : sentiments_tab?.top_reposts?.total_negative_posts || 0
            }
          </div>
          <div>
            <NeutralCircle />
            {(channel === 'instagram' || channel == 'facebook') ? 'Total Neutral Posts -' : 'Total Neutral Retweets -'}
            {(channel === 'instagram' || channel == 'facebook') ? filteredData
              ? filteredData?.top_posts?.total_neutral_posts || 0
              : sentiments_tab?.top_posts?.total_neutral_posts : filteredData || 0
              ? filteredData?.top_reposts?.total_neutral_posts || 0
              : sentiments_tab?.top_reposts?.total_neutral_posts || 0}
          </div>
        </PostCountWrapper>
        <TopPostWrapper>
          <div className='positiveReview'>
            <PositiveCircle />
            <div className='reviewText'>
              {(channel === 'instagram' || channel == 'facebook') ? 'Top Positive Post' : 'Top Positive Retweet'}
              <br />
              <br />
              <CollapsibleText background='rgba(92, 227, 144, 0.2)' text={(channel === 'instagram' || channel == 'facebook') ?
                filteredData ? filteredData?.top_posts?.top_positive_posts?.post_text || `No Data Available`
                  : sentiments_tab?.top_posts?.top_positive_posts?.post_text || `No Data Available`
                : filteredData ? filteredData?.top_reposts?.top_positive_posts?.post_text || `No Data Available`
                  : sentiments_tab?.top_reposts?.top_positive_posts?.post_text || `No Data Available`}
                link={(channel === 'instagram' || channel == 'facebook') &&
                  filteredData ?
                  filteredData?.top_posts?.top_positive_posts?.post_link :
                  sentiments_tab?.top_posts?.top_positive_posts?.post_link

                }

              />
            </div>
            <div className='reviewCount'>
              {(channel === 'instagram' || channel == 'facebook') ? formatNumber(
                filteredData
                  ? filteredData?.top_posts?.top_positive_posts
                    ?.likes_count || 0
                  : sentiments_tab?.top_posts?.top_positive_posts
                    ?.likes_count || 0
              ) : formatNumber(
                filteredData
                  ? filteredData?.top_reposts?.top_positive_posts
                    ?.post_interaction 
                  : sentiments_tab?.top_reposts?.top_positive_posts
                    ?.post_interaction
              ) || ''}
              <div className='peopleLiked'>
                People <br /> {(channel === 'instagram' || channel == 'facebook') ? 'Liked' : 'Retweeted'}
              </div>
            </div>
          </div>
          <div className='negativeReview'>
            <NegativeCircle />
            <div className='reviewText'>
              {(channel === 'instagram' || channel == 'facebook') ? 'Top Negative Post' : 'Top Negative Retweet'}
              <br />
              <br />
              <CollapsibleText background='rgba(249, 98, 89, 0.18)' text={(channel === 'instagram' || channel == 'facebook') ? filteredData
                ? filteredData?.top_posts?.top_negative_posts?.post_text || `No Data Available`
                : sentiments_tab?.top_posts?.top_negative_posts?.post_text || `No Data Available` : filteredData
                ? filteredData?.top_reposts?.top_negative_posts?.post_text || `No Data Available`
                : sentiments_tab?.top_reposts?.top_negative_posts?.post_text || `No Data Available`}

                link={(channel === 'instagram' || channel == 'facebook') &&
                  filteredData ?
                  filteredData?.top_posts?.top_negative_posts?.post_link :
                  sentiments_tab?.top_posts?.top_negative_posts?.post_link

                }


              />
            </div>
            <div className='reviewCount'>
              {(channel === 'instagram' || channel == 'facebook') ? formatNumber(
                filteredData
                  ? filteredData?.top_posts?.top_negative_posts
                    ?.likes_count || 0
                  : sentiments_tab?.top_posts?.top_negative_posts
                    ?.likes_count || 0
              ) : formatNumber(
                filteredData
                  ? filteredData?.top_reposts?.top_negative_posts
                    ?.post_interaction
                  : sentiments_tab?.top_reposts?.top_negative_posts
                    ?.post_interaction
              ) || ''}
              <div className='peopleLiked'>
                People <br /> {(channel === 'instagram' || channel == 'facebook') ? 'Liked' : 'Retweeted'}
              </div>
            </div>
          </div>
          <div className='neutralReview'>
            <NeutralCircle />
            <div className='reviewText'>
              {(channel === 'instagram' || channel == 'facebook') ? 'Top Neutral Post' : 'Top Neutral Retweets'}
              <br />
              <br />
              <CollapsibleText background='rgba(254, 222, 141, 0.37)' text={(channel === 'instagram' || channel == 'facebook') ? 
              filteredData
                ? filteredData?.top_posts?.top_neutral_posts?.post_text || `No Data Available`
                : sentiments_tab?.top_posts?.top_neutral_posts?.post_text || `No Data Available` 
                : filteredData
                ? filteredData?.top_reposts?.top_neutral_posts?.post_text || `No Data Available`
                : sentiments_tab?.top_reposts?.top_neutral_posts?.post_text || `No Data Available`}
                link={(channel === 'instagram' || channel == 'facebook') &&
                  filteredData ?
                  filteredData?.top_posts?.top_neutral_posts?.post_link :
                  sentiments_tab?.top_posts?.top_neutral_posts?.post_link

                }

              />
            </div>
            <div className='reviewCount'>
              {(channel === 'instagram' || channel == 'facebook') ? formatNumber(
                filteredData
                  ? filteredData?.top_posts?.top_neutral_posts
                    ?.likes_count || 0
                  : sentiments_tab?.top_posts?.top_neutral_posts
                    ?.likes_count || 0
              ) : formatNumber(
                filteredData
                  ? filteredData?.top_reposts?.top_neutral_posts
                    ?.post_interaction || 0
                  : sentiments_tab?.top_reposts?.top_neutral_posts
                    ?.post_interaction || 0
              ) || ''}
              <div className='peopleLiked'>
                People <br /> {(channel === 'instagram' || channel == 'facebook') ? 'Liked' : 'Retweeted'}
              </div>
            </div>
          </div>
        </TopPostWrapper>
        <Footer>
          {` This data is observed from ${startDateParsed} to ${endDateParsed}`}

          {/* This data is observed from  ${resetFlag ? filteredStartDate ?  format(filteredStartDate,formatValue) : '' : startDate  &&  format(startDate,formatValue)} to ${resetFlag ? filteredEndDate && format(filteredEndDate,formatValue) : endDate && format(endDate,formatValue)} */}
        </Footer>
      </TopRepostWrapper>
    </Wrapper>
  );
};

export default Sentiments;
