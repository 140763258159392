import React, { lazy, Suspense, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import TopicListener from './Pages/TopicBuilder/Topic';

import ChatterTracker from './Pages/ChatterTracker';

// import ActiveTopic from './Pages/ActiveTopic/ActiveTopic';

import BrandAstraAdminHeader from './Pages/BrandAstraAdmin/Header/BrandAstraAdminHeader';

import PageNotfound from './utils/404';

import Header2 from './components/Header/Header2';
import BarGraph from './Common/BarGraph/BarGraph';
import SentinelHub from './Pages/SentinelHub/SentinelHub';
import FirstPage from './components/FirstPage/FirstPage';
import SentineHubHome from './Pages/SentinelHub/SentineHubHome';
import AlertConfigure from './Pages/AlertConfigure/AlertConfigure';
import CreateComparison from './Pages/CompetitiveIntelligence/createComparison';
import CompetitiveIntelligence from './Pages/CompetitiveIntelligence/competitiveIntelligence';
import { useSelector } from 'react-redux';

const Login = lazy(() => import('./Pages/LoginPage/Login'));

const Setting = lazy(() => import('./Pages/SettingPage'));

const HomePage = lazy(() => import('./Pages/HomePage'));

const BrandAstraLogin = lazy(() =>
  import('./Pages/BrandAstraAdmin/Login/Login')
);

const ExecutivePortal = lazy(() =>
  import('./Pages/BrandAstraAdmin/ExecutivePortal/ExecutivePortal')
);

// CustomRoutes component
const CustomRoutes = () => {
  let currentPathname = []
  // const currentPathname = window.location.pathname.split('/');
  const tenantName = useSelector((state) => state.tenantAuth.tenant_name);

  let basePath = `/`;
  const env = process.env.REACT_APP_ENV;
  if (env === 'QA' || env === 'LOCAL') {
    let splitVal = window.location.pathname.split('/');
    // currentPathname = 'abc'
    currentPathname = splitVal[1]
    basePath = `/${splitVal[1]}`
  } else {
    let splitVal = window.location.host.split('.')
    currentPathname = splitVal[0]
    basePath = '/'
  }

  if (currentPathname === 'brandAstra') {
    return (
      <Suspense fallback={<p>Loading...</p>}>
        <Router basename='/brandAstra'>
          <Routes>
            <Route path='/login' element={<BrandAstraLogin />} />
            <Route
              path='/executivePortal/:screenID'
              element={
                <>
                  <BrandAstraAdminHeader />
                  <ExecutivePortal />
                </>
              }
            />
            <Route path='*' element={<h1>404 Not Found</h1>} />
          </Routes>
        </Router>
      </Suspense>
    );
  } else if (currentPathname === tenantName) {
    return (
      <Suspense fallback={<p>Loading...</p>}>
        <Router basename={basePath}>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route
              path='/homepage'
              element={
                <>
                  <Header2 />
                  <HomePage />
                </>
              }
            />
            <Route
              path='/alert'
              element={
                <>
                  <Header2 />
                  <AlertConfigure />
                </>
              }
            />
            <Route
              path='/setting/:screenID'
              element={
                <>
                  <Header2 />
                  <Setting />
                </>
              }
            />
            <Route
              path='/topic/:screenId'
              element={
                <>
                  <Header2 />
                  <TopicListener />
                </>
              }
            />
            <Route
              path='/chatter/:component'
              element={
                <>
                  <Header2 />
                  <ChatterTracker />
                </>
              }
            />
            <Route
              path='/sentinelHub'
              element={
                <>
                  <Header2 />
                  <SentineHubHome />
                </>
              }
            />
            <Route
              path='/competitiveIntelligence/:screenId'
              element={
                <>
                  <Header2 />
                  <CompetitiveIntelligence />
                </>
              }
            />
            <Route path='/'  element={<Login />} />
            {/* <Route path='/' element={<FirstPage />} /> */}
            <Route path='/temp' element={
              <BarGraph
                label={'Verified Customer reviews'}
                data={{
                  totalRating: 16,
                  avgRating: 3.3,
                  rating: [28, 0, 25, 40, 50],
                }}
              />
            } />
            <Route path='*' element={<PageNotfound />} />
          </Routes>
        </Router>
      </Suspense>
    );
  } else {
    return (
      <Router basename={basePath}>
        <Routes>
          <Route path='*' element={<Login />} />
        </Routes>
      </Router>
    );
  }
}

export default CustomRoutes;