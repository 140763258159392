import axios from 'axios';
import axiosInstance from '../utils/axiosInstance';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const login = (username, password) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  return axios
    .post(API_URL + 'token', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    });
};

const changePassword = (currentPassword, newPassword, executiveID) => {
  const formData = new FormData();
  formData.append('oldpassword', currentPassword);
  formData.append('newpassword', newPassword);

  return axiosInstance
    .post(API_URL + `security/${executiveID}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    });
};

const resetPassword = (new_password, token) => {
  return axios
    .post(API_URL + `reset_password/${token}`, { new_password })
    .then((response) => {
      return response.data;
    });
};

const forgotPassword = (email_id) => {
  return axios
    .post(API_URL + 'forgot_password', {
      email_id,
    })
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
  return axiosInstance.post(API_URL + 'logout').then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const AuthService = {
  login,
  changePassword,
  logout,
  getCurrentUser,
  forgotPassword,
  resetPassword,
};

export default AuthService;
