import { useIntl } from 'react-intl';

export const useFormattedMessage = () => {
  const intl = useIntl();
  /**
   * id: id corresponding to the text to be translated from the translation files.
   * values: optional, only needed if positional value need to pass in translation corresponding to translation file
   * descriptors: optional, only need to pass for defaultMessage, description is needed.
   */
  return (id, values, descriptors) =>
    intl.formatMessage({ id, ...descriptors }, values);
};
export default useFormattedMessage;
