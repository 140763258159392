import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  timer: 5000,
  type: 'none',
};

const messageSlice = createSlice({
  name: 'alertMessage',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      if (typeof action.payload === 'string') {
        return { ...initialState, message: action.payload };
      }

      state.timer = action.payload?.timer || initialState.timer;
      state.type = action.payload?.type || initialState.type;
      state.message = action.payload?.message;
      state.onConfirm = action.payload?.onConfirm || null;

      return state;
    },
    clearMessage: () => {
      return { ...initialState };
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;
