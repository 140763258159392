
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import styled, { css, keyframes } from 'styled-components';

import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


export const TableHeaderFlexWrapper = styled.p`
margin: 0px;
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;

`;

export const CustomTableRow = styled(TableRow)`
  &:hover {
    background: rgba(242, 242, 254, 1);
  }
`;

export const EllipsisText = styled.div`

overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
width: 100%; /* Adjust the width as needed */
`;

export const Wrapper = styled.div`
  height: calc(100vh - 60px);
  overflow: hidden;
`;

export const SearchWrapper = styled.div`

gap:30px;
  padding: 10px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid rgba(99, 99, 99, 0.25);
  width: 98% !important;
  margin: auto;
  margin-bottom: 10px;

  & .searchInput {
    width: 410px;
    // border-left: 1px solid rgba(162, 162, 162, 0.35);
    // padding-left: 30px;
  }

  & .dateIcon {
    margin: 0 20px 0 10px;
  }
`;


export const CustomTableContainer = styled(TableContainer)`
  width: 98% !important;
  margin-left: auto;
  margin-right: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
 ${({ showsplit }) => showsplit === 'true' && css`
    max-height: calc(100vh - 450px );
  `}

  ${({ showsplit }) => showsplit === 'false' && css`
    max-height: calc(100vh - 225px);
  `}




`;

export const TableCellEllipsis = styled(TableCell)`
width:200px
`;

export const CustomTableCell = styled(TableCell)`
  min-width: 110px; /* Note: Use px for unit */
  background-color: rgb(208, 204, 255) !important; /* Note: No space between background and color */
  font-family: 'Open Sans' !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: black !important;
`;



export const HelplessText = styled.h3`
    margin: 0px;
    font-size: 14px;
    font-weight: 600;

`;

export const ParaTwoSentence = styled.p`
    margin: 0px;
    text-align: justify;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 15px;
    gap: 9px;
    display: flex;
    line-height: 1.8;
    word-spacing: 0.16em;
`;

export const ParaOneSentence = styled.p`

    margin: 0px;
    text-align: justify;
    font-size: 13px;
    font-weight: 400;
    word-spacing: 0.16em;
    margin-bottom: 2px;
`;

export const PostH6 = styled.h6`    
    margin: 0px;
    margin-bottom: 10px;
    font-size: 11px;
    font-weight: 500;
`;

export const NameH3 = styled.h3`
    margin: 0px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;

`;

export const CustomDivSplit = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0px 70px;
    width: 85%;
    
    overflow: scroll;
    max-height: 30%;

    margin-right: auto;


    
  &::-webkit-scrollbar {
    color:rgb(53 60 247)
  }


`;

export const CloseIconDiv = styled.div`

display: flex;
flex-direction: column;
align-items: end;

padding: 11px;
}
`;

export const fadeInAnimation = keyframes`
  from {
    
    transform: translateY(100%) scaleY(1);
  }
  to {
    transform: translateY(0%) scaleY(1);
  }
`;

export const SplitWrapper = styled.div`
    height: 100%;
    background: rgba(174, 177, 252, 0.16);
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    
animation: ${fadeInAnimation} 0.5s ease-in-out; /* Use the fade-in animation */

`;




export const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  overflow:hidden;
`;


// position: fixed;

export const StyledTableHeader = styled(TableHead)`

width: 98%;
margin-left: auto;
margin-right: auto;



`;




export const FlexWrapper = styled.div`
  
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap:7px;
`;

export const DropDownWrapper = styled.div`
  background: white;
  position: absolute;
  
  left: 0%;
  margin-left: 16px;
  top:95%;
  box-shadow: 4px 3px 7px 2px #989898;
  padding: 11px;
  text-align:left;
  // margin-top: 2vh;
  min-width: 125px;
  // top: 103%;
  // transform: screenId == 2 ? 'translateX(50%)' : 'translateX(5%)', // Center horizontally
   // top: '103%';

`;

export const DropDownContentDiv = styled.div`
    padding: 7px;
    font-size: 18px;
    display: flex;
    align-items: center
`;

export const InputDropDownCheckBox = styled.input`
  height: 16px;
  margin: 0px;
  margin-right: 10px;
  width: 16px;
`;

export const TableAction = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
`;

export const TableActionWrapper = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;

export const ParaWithMargin = styled.p`
margin: 11px;

`;

