import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import TitleWithInfo from '../../../Common/TitleWithInfo/TitleWithInfo';
import CorrelationGraph from '../../../components/CorrelationGraph/CorrelationGraph';
import WordCloudAnalysis from '../../../components/InsightsWordCloud/WordCloudAnalysis';
import {
  emoji_neutral,
  emoji_surprise,
  emoji_joy,
  emoji_fear,
  emoji_sadness,
  emoji_anger,
  emoji_disgust,
} from '../../../Common/SvgIcon/CommonSvgIcons';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import SmallSelect from '../../../Common/Select/SmallSelect';
import { parse, format } from 'date-fns';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';

const Wrapper = styled.div`
  padding: 30px;

  & .title {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
  }
`;

const EmotionAnalysisWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  .titleContainer {
    margin: 25px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .correlationGraphContainer {
    padding: 1px 20px 20px 20px;
  }

  & > p:first-child {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    margin: 20px 0px 0px 10px;
  }
`;

const FilteredGraphContainer = styled.div`
flex: 1;
display: flex;
width: 100%;
margin: 20px 0px 20px 50px;
align-items: center;
`;

const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  margin: 28px 0 20px 0;
  text-align: center;
`;

const FunnelGraphContaining = styled.div`
  width: 80%;
  height: 100%;
  margin: 20px;
`;

const GraphContainer = styled.div`
  margin: 10px 0px 0px 20px;
`;
const Insight = ({
  insights_data = {},
  startDate = '',
  endDate = '',
  filteredData,
  filterOption,
  filteredStartDate,
  filteredEndDate,
  view,
  resetFlag,
  channel,
  hideDropdown,
  formatValue = "dd-yyyy-MM"
}) => {
  const [selectedGraph, setSelectedGraph] = useState('Bar Graph');
  
  
 
  const startDateParsed = useParsedStartDate(resetFlag, filteredStartDate, startDate, formatValue);
  const endDateParsed = useParsedEndDate(resetFlag, filteredEndDate, endDate, formatValue);

  
  
  if (Object.keys(insights_data).length === 0) {
    return (
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        No data available
      </h2>
    );
  }

  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  const emotionAnalysis = [
    {
      name: 'Neutral',
      value: resetFlag === true && filteredData
        ? filteredData?.emotion_analysis[0].neutral
        : insights_data?.emotion_analysis[0].neutral,
      // fill: '#F5CC61',
      fill: 'rgb(245, 188, 44)'
    },
    {
      name: 'Surprise',
      value: resetFlag === true && filteredData
        ? filteredData?.emotion_analysis[0].surprise
        : insights_data?.emotion_analysis[0].surprise,
      fill: '#B7DC68',
    },
    {
      name: 'Joy',
      value: resetFlag === true && filteredData
        ? filteredData?.emotion_analysis[0].joy
        : insights_data?.emotion_analysis[0].joy,
      fill: '#FE00D5',
    },
    {
      name: 'Fear',
      value: resetFlag === true && filteredData
        ? filteredData?.emotion_analysis[0].fear
        : insights_data?.emotion_analysis[0].fear,
      fill: '#FC9E58',
    },
    {
      name: 'Sadness',
      value: resetFlag === true && filteredData
        ? filteredData?.emotion_analysis[0].sadness
        : insights_data?.emotion_analysis[0].sadness,
      fill: '#4B9AE9',
    },
    {
      name: 'Anger',
      value: resetFlag === true && filteredData
        ? filteredData?.emotion_analysis[0].anger
        : insights_data?.emotion_analysis[0].anger,
      fill: '#F74945',
    },
    {
      name: 'Disgust',
      value: resetFlag === true && filteredData
        ? filteredData?.emotion_analysis[0].disgust
        : insights_data?.emotion_analysis[0].disgust,
      fill: '#9071CE',
    },
  ];


  const handleGraphSelect = (selectedOption) => {
    // Your logic to handle the selected graph in the parent component
    setSelectedGraph(selectedOption);
  };

  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={emotionAnalysis} maxWidth={'70%'} type={'%'} />
      ) : (
        <BarGraph
          data={emotionAnalysis}
          maxWidth={'70%'}
          type={'%'}
          barHeight={'32px'}
          emojiList={{
            neutral: emoji_neutral,
            surprise: emoji_surprise,
            joy: emoji_joy,
            fear: emoji_fear,
            sadness: emoji_sadness,
            anger: emoji_anger,
            disgust: emoji_disgust,
            length: 2,
          }}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart data={emotionAnalysis} width={500} type='percentage' />
    ),
    'Donut Chart': <DonutChart data={emotionAnalysis} type='percentage' />,
    'Funnel Chart': (
      <FunnelGraph data={emotionAnalysis} maxWidth={'80%'} type={'%'} />
    ),
  };

  const emotionAnalysisGraph = renderScreen[filterOption];
  const ratingColors = ['#676DFF', '#898DFB', '#ACAFFC', '#C5C6F3', '#D5D5DC']

  function convertObjectToBarGraphData(object = {}) {
    const barGraphData = Object.entries(object).map(([name, value]) => ({
      name,
      value: value || 0,
    }));
    const sortData = barGraphData.sort((a, b) => b.value - a.value);
    const dataWithColors = sortData.map((item, index) => ({
      ...item,
      fill: ratingColors[index]
    }));
    return dataWithColors;
  }
  

  function checkAndConvert(obj) {
    // Check if there's only one key and that key is an empty string, with a value of 0
    if ( obj && typeof obj === 'object' && Object.keys(obj)?.length === 1 && (obj?.hasOwnProperty("") || obj?.hasOwnProperty(" ")) && (obj[""] === 0 || obj[" "] === 0)) {
      return []; // Return an empty array
    }
    return convertObjectToBarGraphData(obj); // Otherwise, return the original object
  }
// console.log(insights_data?.most_authoritative,"insights_data?.most_authoritative");
  const mostAuthoritativeData =
    checkAndConvert(
      resetFlag === true && filteredData
        ? filteredData?.most_authoritative
        : insights_data?.most_authoritative
    ) || [];


    const mostMentionedData =
  checkAndConvert(
      resetFlag === true && filteredData
        ? filteredData?.most_authoritative
        : insights_data?.most_mentioned
    ) || [];

    const renderMostAuthoritative = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={mostAuthoritativeData}
          maxWidth={'70%'}
          barHeight={'32px'}
        />
      ) : (
        <BarGraph
          data={mostAuthoritativeData}
          maxWidth={mostAuthoritativeData && mostAuthoritativeData.length > 0 ? '70%' : '80%'}
          barHeight={'32px'}
        />
      ),

    'Pie Chart': <RatingDonutChart data={mostAuthoritativeData} width={500}
      maxWidth={mostAuthoritativeData && mostAuthoritativeData.length > 0 ? '100%' : '80%'} />,
    'Donut Chart': <DonutChart data={mostAuthoritativeData}
      maxWidth={mostAuthoritativeData && mostAuthoritativeData.length > 0 ? '100%' : '80%'} />,
    'Funnel Chart':
      <FunnelGraph data={mostAuthoritativeData} maxWidth={'80%'} />,

    //  'Pie Chart':
    //   return <RatingDonutChart data={mostAuthoritativeData} />;
    // // Add more cases for other graph options
    //  'Funnel Chart':
    //   return (
    //     <FunnelGraphContaining>
    //       <FunnelGraph data={mostAuthoritativeData} />
    //     </FunnelGraphContaining>
    //   );

  };
  const renderMostmentioned = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={mostMentionedData}
          maxWidth={mostMentionedData && mostMentionedData.length > 0 ? '70%' : '80%'}
          barHeight={'32px'}
        />
      ) : (
        <BarGraph
          data={mostMentionedData}
          maxWidth={mostMentionedData && mostMentionedData.length > 0 ? '70%' : '80%'}
          barHeight={'32px'}
        />
      ),
    'Pie Chart': <RatingDonutChart data={mostMentionedData} width={500}
      maxWidth={mostMentionedData && mostMentionedData.length > 0 ? '100%' : '80%'} />,
    'Donut Chart': <DonutChart data={mostMentionedData}
      maxWidth={mostMentionedData && mostMentionedData.length > 0 ? '100%' : '80%'} />,
    'Funnel Chart': <FunnelGraph data={mostMentionedData} maxWidth={'80%'} />,
  };
  const mostMentionedGraph = renderMostmentioned[filterOption];





  return (
    <Wrapper>
      <WordCloudAnalysis
        showSwitchTab={false}
        insightsData={resetFlag === true && filteredData ? filteredData : insights_data}
        hideDropdown={hideDropdown}
        startDate={startDateParsed}
        endDate={endDateParsed}
        formatValue={formatValue}
      />
      {/* {view === 'vertical' ? (
        <EmotionAnalysisWrapper>
          <div className='titleContainer'>
            <TitleWithInfo
              title={'Most Authoritative'}
              infoText={[
                'Popularity Score = Retweet Count + Likes',
                'Reach Score = Followers – Following ',
                'Authoritative Score = Popularity Score + Reach Score',
              ]}
            />
          </div>

          <VerticalBarGraph data={mostAuthoritativeData} />
          <Footer> Post handles with highest Authoritative* score</Footer>
        </EmotionAnalysisWrapper>
      ) : (
        <EmotionAnalysisWrapper>
          <div className='titleContainer'>
            <TitleWithInfo
              title={'Most Authoritative'}
              infoText={[
                'Popularity Score = Retweet Count + Likes',
                'Reach Score = Followers – Following ',
                'Authoritative Score = Popularity Score + Reach Score',
              ]}
            />
            {/* {!filterOption && ( 
              <SmallSelect
                options={[
                  'Bar Graph',
                  'Pie Chart',
                  'Donut Chart',
                  'Funnel Chart',
                ]}
                onChange={handleGraphSelect}
                option={filterOption}
              />
            {/* )} 
          </div>
          <div>{renderMostAuthoritative()}</div>*/}
      {/* <BarGraph data={mostAuthoritativeData} maxWidth={'95%'} /> */}
      {/*  <Footer> Post handles with highest Authoritative* score</Footer>
        </EmotionAnalysisWrapper>
      )}
      {/* {filterOption ? (
        <EmotionAnalysisWrapper>
          <p>Most Mentioned</p>
          <FilteredGraphContainer>{mostMentionedGraph}</FilteredGraphContainer>
          <Footer>Most mentioned post handles</Footer>
        </EmotionAnalysisWrapper>
      ) : ( */}
      {

        channel == 'instagram' ? '' :
          <EmotionAnalysisWrapper>
            <GraphHOC
              formatValue={formatValue}
              title={'Most Authoritative'}
              options={options}
              renderScreen={renderMostAuthoritative}
              graphWidth={'100%'}
              margin={'20px 0px 20px 50px'}
              option={filterOption}
              hideDropdown={hideDropdown}
              startDate={startDateParsed}
              endDate={endDateParsed}
            />
          </EmotionAnalysisWrapper>
      }

      {
        channel == 'facebook' ? '' :
          <EmotionAnalysisWrapper>
            <GraphHOC
              formatValue={formatValue}
              title={'Most Mentioned'}
              options={options}
              renderScreen={renderMostmentioned}
              graphWidth={'100%'}
              margin={'20px 0px 20px 50px'}
              option={filterOption}
              hideDropdown={hideDropdown}
              startDate={startDateParsed}
              endDate={endDateParsed}
            />
          </EmotionAnalysisWrapper>
      }

      {/* )} */}

      {/* {filterOption ? (
        <EmotionAnalysisWrapper>
          <p>Emotion Analysis</p>
          <FilteredGraphContainer>{emotionAnalysisGraph}</FilteredGraphContainer>
        </EmotionAnalysisWrapper>
      ) : ( */}
      <EmotionAnalysisWrapper>
        <GraphHOC
          formatValue={formatValue}
          title={'Emotion Analysis'}
          options={options}
          renderScreen={renderScreen}
          graphWidth={'100%'}
          margin={'20px 0px 20px 50px'}
          startDate={startDateParsed}
          endDate={endDateParsed}
          option={filterOption}
          hideDropdown={hideDropdown}
        />
      </EmotionAnalysisWrapper>
      {/* )} */}

      <div style={{ marginTop: '30px' }}>
        <CorrelationGraph
          RatingLabels={[
            'Engagement',
            'Anger',
            'Neutral',
            'Sadness',
            'Disgust',
            'Fear',
            'Joy',
            'Surprise',
          ]}
          correlationAnalysisData={
            filteredData && resetFlag === true
              ? filteredData?.correlation_analysis
              : insights_data?.correlation_analysis
          }
          hideDropdown={hideDropdown}
          startDate={startDateParsed}
          endDate={endDateParsed}
          formatValue={formatValue}
        />
      </div>
    </Wrapper>
  );
};

export default Insight;
