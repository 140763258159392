// Dropdown.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { DownArrowIcon } from '../SvgIcon/CommonSvgIcons';

const Title = styled.div`
  color: #636363;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectWrapper = styled.div`
  display: flex;
  min-width: 120px;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  border: 1px solid #d9d9d9;
  padding: 5px 8px;

  color: #636363;
  font-family: Open Sans;
  font-weight: 400;
  cursor: pointer;

  position: relative;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 105%;
  left: 0;

  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
  width: 100%;
  z-index: 3;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.22);
`;

// ${(props) => props.bgColor && 'background:#6F62FE; color:#fff;'}
const Option = styled.div`
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: #6f62fe;
    color: #fff;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  text-align: ${({ placement }) => (placement === 'right' ? 'right' : 'left')};
  /* width: 20%; */
  top: 10px;
  right: 10px;
  height: 40px;

  border-radius: 3px;
  border: 1px solid #d9d9d9;
`;

const DropdownButton = styled.div`
  padding: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;

  span {
    margin-left: 20px;
  }

  color: #636363;

  text-align: center;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  margin: 0px 15px 0px 0px;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  width: 100%;
  ${({ placement }) => (placement === 'right' ? 'right: 0;' : 'left: 0px')};
  list-style: none;
  padding: 0;
  margin-top: 0;
  border: 1px solid #d9d9d9;
  border-top: none;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.22);
  z-index: 1;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  ${({ placement }) =>
    placement === 'right' ? 'text-align: right;' : 'text-align: left;'}
`;

const DropdownItem = styled.li`
  padding: 0px;
  cursor: pointer;
  height: 33px;
  color: #636363;

  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  &:hover {
    background-color: #6f62fe;
    color: #fff;
  }
`;

const GraphDropdown = ({ onSelectGraph, options = [], defaultOption }) => {
  const [selectedGraph, setSelectedGraph] = useState(
    defaultOption || options[0]
  );
  const [selectedGraphTab, setSelectedGraphTab] = useState(false);

  /* const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];
   */
  const handleSelectedGraph = (option) => {
    setSelectedGraph(option);
    onSelectGraph(option);
  };

  const handleSelectGraph = () => {
    setSelectedGraphTab((prev) => !prev);
  };

  return (
    <Title>
      {/*  <p>{title}</p> */}
      <SelectWrapper onClick={handleSelectGraph}>
        <div>{selectedGraph}</div>
        <span>{DownArrowIcon}</span>
        {selectedGraphTab && (
          <OptionsWrapper>
            {options.map((option) => (
              <Option
                bgColor={selectedGraph === option}
                key={option}
                onClick={() => handleSelectedGraph(option)}
              >
                {option}
              </Option>
            ))}
          </OptionsWrapper>
        )}
      </SelectWrapper>
    </Title>
  );
};

export default GraphDropdown;
