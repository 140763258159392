import React, { useState } from "react";
import styled from "styled-components";
import { AustraliaFLagIcon, CanadaFlagIcon, IndiaFlagIcon, UKFlagIcon, USAFlagIcon,DownArrowIcon } from "../../../../../Common/SvgIcon/CommonSvgIcons";

const CustomSelect = styled.div`
  // width: 15%;
    width: 100%;
  min-height: 48px;
  border-radius: 15px;
  // margin: 8px 0px 0px 10px;
  /* border: 1px solid #a2a2a2; */
  
    margin-bottom: 0px;
    padding: 0px;
    border: 1px solid rgb(162, 162, 162);
    border-radius: 15px;
`;

const DropdownHeader = styled.div`
  cursor: pointer;
  // padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
height:48px;
  p {
    margin: 0px 0px 0px 5px;
    color: black;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600; 
    gap:20px;
  }
`;

const DropdownOptions = styled.ul`
  //width: 100%;
  height: 94px;
  overflow-y: auto;
  padding: 0;
  border-radius:15px;
  background-color: white;
  /* border: 1px solid #A2A2A2; */
  border-top:none;
  margin:0px 0px 5px 0px;
  z-index: 1;
  overflow-y:scroll;
  display: none;
  cursor: pointer;
  
  ${(props) =>
    props.isOpen &&
    `
    display: block;
  `}
  `;

  const Text = styled.div`
  display: flex;
  gap:20px;
  `;

  const DropdownOption = styled.li`
  //cursor: pointer;
  width:90%;
  padding: 5px;
  height: 30px;
  display: flex;
  align-items: left;
  margin: 5px 0px 0px 0px; 
  border-radius: 15px;
  border: ${(props) =>
    props.isSelected ? '1px solid #377DFF' : '1px solid #d9d9d9'};
  background: ${(props) =>
    props.isSelected ? 'rgba(55, 125, 255, 0.14)' : 'inherit'};

  img {
    margin-left: 10px;
    margin-right: 15px;
  }

  p {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin:5px 0px 5px 20px;
  }
`;


export const options = [
    { value: 'USA', label: 'USA', imageSrc: USAFlagIcon },
    { value: 'India', label: 'India', imageSrc: IndiaFlagIcon },
    { value: 'UK', label: 'UK', imageSrc: UKFlagIcon },
    { value: 'Canada', label: 'Canada', imageSrc: CanadaFlagIcon},
    { value: 'Australia', label: 'Australia', imageSrc: AustraliaFLagIcon },
  ];


const CountrySelect = ({ countries, onSelect }) => {
  const [selectedCountries, setSelectedCountries] = useState();
  const [selectedOptionImage, setSelectedOptionImage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    /*  onOptionSelect(option.value); */
    setSelectedCountries(option.value);
    setSelectedOptionImage(option.imageSrc);
    setIsOpen(false);
    onSelect(option.value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CustomSelect>
      <DropdownHeader isOpen={isOpen} onClick={toggleDropdown} data-testid="selected-country">
        {selectedCountries ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // margin: '0px 0px 0px 20px',?
              gap: '10px',
              justifyContent: 'space-between',
              padding: '2px 10px',
              width: '100%'
            }}
          >
            <p>{selectedOptionImage}</p>
            <p>{selectedCountries}</p>
            <p>{DownArrowIcon}</p>
          </div>
        ) : (
          <Text>
            <p style={{ color: '#a1a1a1'}}>Select country</p>
            <p>{DownArrowIcon}</p>
          </Text>
        )}
      </DropdownHeader>
      <DropdownOptions isOpen={isOpen}>
        {options.map((option) => (
          <DropdownOption
            isSelected={option.value === selectedCountries}
            key={option.value}
            onClick={() => handleOptionClick(option)}
            data-testid={`country-option-${option.value}`}
          >
            <p style={{ marginTop: '0px' }}>{option.imageSrc}</p>
            <p>{option.label}</p>
          </DropdownOption>
        ))}
      </DropdownOptions>
    </CustomSelect>
  );
};

export default CountrySelect;