import React, { useState } from 'react';
import styled from 'styled-components';

import ReviewLineChart from './LineChart';
import AreaGraph from './AreaGraph';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 25px;
  box-sizing: border-box;
`;
const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;
const Title = styled.div`
  color: #636363;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;

  color: #fff;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid #d9d9d9;

  & :not(:first-child) {
    border-left: 1px solid #e4e4e4;
    border-radius: 0px;
  }
`;

const Option = styled.span`
  white-space: nowrap;
  cursor: pointer;
  color: #222;
  border-radius: 0px;
  ${(props) =>
        props.selected &&
        `
      color: #fff;
      background:#353CF7;
      border-radius: 4px !important;
      font-weight: 500;
  `};
  padding: ${(props) => props.padding};
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  width: ${(props) => props.graphWidth};
  margin: ${(props) => props.margin};
  align-items: center;
  margin-top: 10px;
  min-height: 300px;
`;

const ToggleButton = ({
    options = [],
    onClick = () => { },
    padding = '5px 20px',
}) => {
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const handleSelectOption = (option) => {
        setSelectedOption(option);
        onClick(option);
    };

    return (
        <ToggleButtonWrapper>
            {options.map((option) => (
                <Option
                    selected={selectedOption === option}
                    onClick={() => handleSelectOption(option)}
                    padding={padding}
                    key={option}
                >
                    {option}
                </Option>
            ))}
        </ToggleButtonWrapper>
    );
};

const TrendsInUserActivity = ({ data1,
    title,
    ylabel,
    showLineGraph,
    option,
    hideDropdown,
    startDate,
    endDate
}) => {
    const [timeSpan, setTimeSpan] = useState(option || 'Days');
    const [lineGraph, setLineGraph] = useState(true);
    let trendsInUserActivity;
    if (timeSpan === 'Days') {
        trendsInUserActivity = data1?.trends_in_user_activity?.days || {};
    } else if (timeSpan === 'Weeks') {
        trendsInUserActivity = data1?.trends_in_user_activity?.week || {};
    } else if (timeSpan === 'Months') {
        trendsInUserActivity = data1?.trends_in_user_activity?.month || {};
    }
    else if (timeSpan === 'Years') {
        trendsInUserActivity = data1?.trends_in_user_activity?.month || {}; //post API integration, update with year attribute
    }
    const timeline = trendsInUserActivity?.timeline || [];
    const reviewsCount = trendsInUserActivity?.count || [];
    const userActivityData = timeline?.map((date, index) => ({
        date,
        [`${ylabel?.toLowerCase() || 'reviews'}`]: reviewsCount[index] || 0,
    }));


    const handleTimeSpanClick = (option) => {
        setTimeSpan(option);
    };

    const handleGraphSelection = (option) => {
        setLineGraph((prev) => !prev);
    };

    return (
        <Wrapper>
            <Title>
                <p>{title}</p>
                {
                    !hideDropdown &&
                    <ToggleButton
                        options={['Line Graph', 'Area Graph']}
                        onClick={handleGraphSelection}
                    />
                }
            </Title>
            {

                !hideDropdown &&
                <ToggleButton
                    options={['Days', 'Weeks', 'Months', 'Years']}
                    onClick={handleTimeSpanClick}
                    padding='3px 30px'
                    option={option}
                />
            }

            {
                userActivityData &&
                <MainContainer>
                    {
                        showLineGraph == 'Line Graph' ?
                            (<ReviewLineChart height={300} data={userActivityData} ylabel={ylabel} />) :
                            (<AreaGraph height={300} data={userActivityData} ylabel={ylabel} />)
                    }
                </MainContainer>
            }
            <Span>
                This data is observed from {startDate} to {endDate}
            </Span>
        </Wrapper>
    );
};

export default TrendsInUserActivity;