import React from 'react';
import styled from 'styled-components';

const OverallPosts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const RelevantPost = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: 90%;

  padding: 20%;
  height: 100%;

  border-radius: 36px 36px 0px 36px;
  background: rgba(111, 98, 254, 0.22);

  margin-top: 20px;
  margin-left: 30px;

  color: #000;

  text-align: center;
  font-family: Open Sans;
  font-size: 17px;
`;

const Logo = styled.div`
  position: absolute;
  top: -7%;
  left: -7%;

  width: 90px;
  height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: #fff;
  border: 1px solid rgba(85, 172, 238, 0.84);

  box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
`;

const OverallReview = ({ title = 'Overall Posts', icon,message="" }) => {
  return (
    <OverallPosts>
      <Title>{title}</Title>
      <RelevantPost>
        <Logo>{icon}</Logo>
        {message}
      </RelevantPost>
    </OverallPosts>
  );
};

export default OverallReview;
