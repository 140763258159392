import styled from "styled-components";


export const KeywordWrapper = styled.div`
  font-family: Montserrat;
  

  text-align: left;
  width: 48%;
  margin-bottom: 20px;
  margin-top: 20px;
`;
export const TopicText = styled.div`
  color: #353cf7;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  margin-left:8px;
`;


export const SubLabel = styled.span`
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 8px;

  color: #636363;

  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.15px;
`;

export const ErrorMessage = styled.div`
  padding: 2px 10px;
  color: #d74748;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

export const Label = styled.span`
  color: #000000;
  margin-left: 8px;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;

  & > span {
    color: #fc6758;
  }
`;

export const ShowAlertWrapper = styled.div`
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width:80%;
  margin-left:auto;
  margin-right:auto;

`;



export const FlexAlertWrapper = styled.div` 
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content:space-between;
`;


export const LabelStyle = styled.span`
color: rgb(0, 0, 0);
margin-left: 8px;
font-family: Montserrat;
font-size:16px;
font-weight: 500
`;


export const LabelParaStyle = styled.span`
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 8px;
  color: rgb(99, 99, 99);
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400
`;

export const SwitchTabWrapper = styled.div`

    display: flex;
    justify-content: start;
    flex-direction:row;

    & > .chatter_switch_tab {
      border-radius:8px 0px 0px 8px;
      width: 130px;
      padding: 10px;
      border: 1px solid rgba(162, 162, 162, 1);
    }

    & > .topic_switch_tab {
      border-radius:0px 8px 8px 0px;
      width: 130px;
      padding: 10px;
      border: 1px solid rgba(162, 162, 162, 1);
      border-left:none;
    }

    & > .active {
      background: rgba(53, 60, 247, 1);
      color:white;
    }

`;

export const SaveButton = styled.button`
    height: 56px;
    width: 121px;
    border: 1px solid rgba(162, 162, 162, 0.6);
    color: rgba(99, 99, 99, 1);
    background: white;
    font-size: 17px;
    border-radius: 50px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 4px);

`;



export const AlertSaveButtonWrapper = styled.div`

    text-align: left;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;


`;

// export const SwitchTab = styled.div`
//     width: 130px;
//     padding: 10px;
//     border: 1px solid rgba(162, 162, 162, 1);



// `;