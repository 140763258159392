import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ChatsIcon } from '../../../../Common/SvgIcon/CommonSvgIcons';
import { useDispatch, useSelector } from 'react-redux';
import useFormattedMessage from '../../../../hooks/useFormattedMessage';
import { chatterList, getReport } from '../../../../store/redux/slices/chatterSlice';

const TopicDiv = styled.span`
  height: 25px;
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: 40px;
  // margin-left: 35px;
  text-align: left;
  font: 600 16px 'Montserrat', sans-serif;
  color: #636363;
`;

const NewTopic = styled.div`
  width: 85%;
  height: 300px;
  dispaly: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 30px;
  // margin-left: 35px;
  padding-left: 20px;
  background: #f2f3ff;
  border-radius: 15px;
`;

const NewButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-left: 15px;
  margin-bottom:10px;

  width: 150px;
  height: 38px;
  font-family: Montserrat;
  font-size: 18px;
  color: #ffff;

  background: #353cf7;
  border-radius: 100px;
  border: none;
`;

const ActiveTopic = styled.div`
  // width: 100%;
  height: 100%;
  dispaly: flex;
  margin-top: 20px;
  // margin-left: 20px;
  // padding-left: 20px;
  border-radius: 10px;

`;

const Span = styled.p`
  //display;flex;
  text-align: left;
  color: #2a2929;
  font: 600 16px 'Montserrat', sans-serif;
  
  margin-top: 30px;
  margin-bottom: 22px;
`;

const ActiveTopicDiv = styled.div`
  display: flex;
  background: #aefffd;
  width: 145px;
  height: 84px;
  border-radius: 6px;
  border: 6px solid #e1fffe;

  align-items: center;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  justify-content: center;
  display: grid;
  grid-template-columns: 30px 1fr;


  span {
    // margin-top: 30px;
    padding: 5px;

  //    width: 131px;                   /* Fixed width */
  // height: 84px;                   /* Fixed height */
  // line-height: 84px;              /* Centers text vertically */
  text-align: left;             /* Centers text horizontally */
  overflow: hidden;               /* Clips overflowed text */
  white-space: nowrap;            /* Prevents text from wrapping */
  text-overflow: ellipsis;        /* Adds ellipsis (…) for overflow */
  
  }
`;

const NewTopicSpan = styled.p`
  box-sizing: border-box;
  //height:5px;
  font: 600 18px/5px 'Montserrat', sans-serif;
  color: #353cf7;
  text-align: left;
  padding: 15px 0px 0px 15px;
`;

const NewTopicDesc = styled.p`
  color: #626262;
  font-size: 16px;
  //font-family: Open Sans;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  padding: 10px 0px 0px 15px;
`;

const Wrapper = styled.div`
  display: flex;
  height: auto;
  flex: 85%;
  flex-direction: column;
  padding-left:35px;
`;

const Desc = styled.div`
  margin-top: 0px;
  // margin-left: 35px;
  height: 25px;
  width: 912px;
  display: flex;
  position: relative;
  font: 400 16px 'Open Sans', sans-serif;
  line-height: 24.51px;
  color: #000000;
`;

function ChatterHomePage({ setSelectedReport }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const t = useFormattedMessage();

  const { chatterListDate } =
    useSelector((state) => state.chatterTracker) || [];

  useEffect(() => {
    if (chatterListDate === null) {
      dispatch(chatterList());
    }
  }, []);


  /* const chatterId = activeChatterList?.job_id; */

  const handleChatterClick = (chatterId) => {
    dispatch(getReport(chatterId)).then((data) => {
      setSelectedReport(data?.payload?.data);
      // Navigate to the view route after getting the report
      navigate('/chatter/job_' + chatterId);
    })
  }

  const handleClick = () => {
    navigate('/chatter/create');
  };

  const activeChatters = chatterListDate?.filter((chatter) => chatter.is_active === 0)

  return (
    <>
      <div style={{ display: 'flex', height: 'auto', overflowY: 'scroll' }}>
        <Wrapper>
          <div style={{ height: '15%' }}>
            <TopicDiv>{t('TITLE')}</TopicDiv>
            <Desc>{t('DESC')}</Desc>
          </div>
          <NewTopic>
            <NewTopicSpan>{t('SUBHEADING')}</NewTopicSpan>
            <NewTopicDesc>
              {t('CHATTER_DESCRIPTION')}
            </NewTopicDesc>
            <NewButton onClick={handleClick}>New Chatter</NewButton>
          </NewTopic>
          <ActiveTopic>
            <Span>Active Chatter</Span>
            <div style={{ display: 'flex', flexDirection: 'row' ,flexWrap:'wrap',width:'100%',gap:'14px',marginBottom:'30px'}}>
              {activeChatters?.map((chatter) => (
                <ActiveTopicDiv key={chatter.job_id} onClick={() => handleChatterClick(chatter.job_id)}>
                  <span>{ChatsIcon}</span>
                  <span>{chatter.chatter_title}</span>
                </ActiveTopicDiv>
              ))}
            </div>
          </ActiveTopic>
        </Wrapper>
      </div>
    </>
  );
}

export default ChatterHomePage;
