import React, { useState } from 'react';
import BarGraph from '../../../../Common/BarGraph/BarGraph';
import GraphHOC from '../../../../Common/GraphHOC/GraphHOC';
import RatingDonutChart from '../../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../../Common/FunnelGraph/FunnelGraph';
import TitleWithInfo from '../../../../Common/TitleWithInfo/TitleWithInfo';
import CorrelationGraph from '../../../../components/CorrelationGraph/CorrelationGraph';
import WordCloudAnalysis from './WordCloudAnalysis';
import {
    emoji_neutral,
    emoji_surprise,
    emoji_joy,
    emoji_fear,
    emoji_sadness,
    emoji_anger,
    emoji_disgust,
} from '../../../../Common/SvgIcon/CommonSvgIcons';
import VerticalBarGraph from '../../../../Common/BarGraph/VarticalBargraph';
import SmallSelect from '../../../../Common/Select/SmallSelect';
import {
    Wrapper,
    EmotionAnalysisWrapper, FilteredGraphContainer, Footer, FunnelGraphContaining, GraphContainer
} from '../ReportDownload/Insights.styled'
import { useParsedEndDate, useParsedStartDate } from '../../../../utils/ParseDate';


const Insight = ({
    insights_data = {},
    filteredData,
    filterOption,
    filteredStartDate,
    filteredEndDate,
    view,
    resetFlag,
    startDate = '',
    endDate = '',
    formatValue,
    child,
    hideDropdown
}) => {

    const formattedDate = useParsedStartDate(false, '', startDate, formatValue);
    const endDateParsed = useParsedEndDate(false, '', endDate, formatValue);


    const [selectedGraph, setSelectedGraph] = useState('Bar Graph');
    if (Object.keys(insights_data).length === 0) {
        return (
            <h2
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '30px',
                }}
            >
                No data available
            </h2>
        );
    }


    const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

    const emotionAnalysis = [
        {
            name: 'Neutral',
            value: resetFlag === true && filteredData
                ? filteredData?.emotion_analysis[0].neutral
                : insights_data?.emotion_analysis[0].neutral,
            fill: '#F5CC61',
        },
        {
            name: 'Surprise',
            value: resetFlag === true && filteredData
                ? filteredData?.emotion_analysis[0].surprise
                : insights_data?.emotion_analysis[0].surprise,
            fill: '#B7DC68',
        },
        {
            name: 'Joy',
            value: resetFlag === true && filteredData
                ? filteredData?.emotion_analysis[0].joy
                : insights_data?.emotion_analysis[0].joy,
            fill: '#FE00D5',
        },
        {
            name: 'Fear',
            value: resetFlag === true && filteredData
                ? filteredData?.emotion_analysis[0].fear
                : insights_data?.emotion_analysis[0].fear,
            fill: '#FC9E58',
        },
        {
            name: 'Sadness',
            value: resetFlag === true && filteredData
                ? filteredData?.emotion_analysis[0].sadness
                : insights_data?.emotion_analysis[0].sadness,
            fill: '#4B9AE9',
        },
        {
            name: 'Anger',
            value: resetFlag === true && filteredData
                ? filteredData?.emotion_analysis[0].anger
                : insights_data?.emotion_analysis[0].anger,
            fill: '#F74945',
        },
        {
            name: 'Disgust',
            value: resetFlag === true && filteredData
                ? filteredData?.emotion_analysis[0].disgust
                : insights_data?.emotion_analysis[0].disgust,
            fill: '#9071CE',
        },
    ];

    const handleGraphSelect = (selectedOption) => {
        // Your logic to handle the selected graph in the parent component
        setSelectedGraph(selectedOption);
    };

    const renderScreen = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph data={emotionAnalysis} maxWidth={'70%'} type={'%'} />
            ) : (
                <BarGraph
                    data={emotionAnalysis}
                    maxWidth={'70%'}
                    type={'%'}
                    barHeight={'32px'}
                    emojiList={{
                        neutral: emoji_neutral,
                        surprise: emoji_surprise,
                        joy: emoji_joy,
                        fear: emoji_fear,
                        sadness: emoji_sadness,
                        anger: emoji_anger,
                        disgust: emoji_disgust,
                        length: 2,
                    }}
                />
            ),
        'Pie Chart': (
            <RatingDonutChart data={emotionAnalysis} width={500} type='percentage' />
        ),
        'Donut Chart': <DonutChart data={emotionAnalysis} type='percentage' />,
        'Funnel Chart': (
            <FunnelGraph data={emotionAnalysis} maxWidth={'80%'} type={'%'} />
        ),
    };

    const emotionAnalysisGraph = renderScreen[filterOption];

    function convertObjectToBarGraphData(object = {}) {
        const barGraphData = Object.entries(object).map(([name, value]) => ({
            name,
            value: value,
            fill: '#353cf7',
        }));

        return barGraphData;
    }

    const mostAuthoritativeData =
        convertObjectToBarGraphData(
            insights_data?.most_authoritative
        ) || [];

    const mostMentionedData =
        convertObjectToBarGraphData(
            resetFlag === true && filteredData
                ? filteredData?.most_authoritative
                : insights_data?.most_mentioned
        ) || [];
        
    const renderMostAuthoritative = {
        'Donut Chart': <DonutChart data={mostAuthoritativeData} />,
        'Bar Graph':
            (
                <BarGraph
                    data={mostAuthoritativeData}
                    maxWidth={'70%'}
                    barHeight={'32px'}
                />
            ),

        'Pie Chart': <RatingDonutChart data={mostAuthoritativeData} width={500} />,
        'Donut Chart': <DonutChart data={mostAuthoritativeData} />,
        'Funnel Chart': <FunnelGraph data={mostAuthoritativeData} maxWidth={'80%'} />,


    };

    const renderMostmentioned = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph
                    data={mostMentionedData}
                    maxWidth={'70%'}
                    barHeight={'32px'}
                />
            ) : (
                <BarGraph
                    data={mostMentionedData}
                    maxWidth={'70%'}
                    barHeight={'32px'}
                />
            ),
        'Pie Chart': <RatingDonutChart data={mostMentionedData} width={500} />,
        'Donut Chart': <DonutChart data={mostMentionedData} />,
        'Funnel Chart': <FunnelGraph data={mostMentionedData} maxWidth={'80%'} />,
    };


    const mostMentionedGraph = renderMostmentioned[filterOption];

    
    return (

        <Wrapper >
            {
                child?.length > 0 && child[0]?.value &&
                <div
                    className="pdf-page"
                    style={{
                        pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
                    }}
                >
                    <WordCloudAnalysis
                        checkboxData={child?.length > 0 && child[0]?.child}
                        selectedButton={child?.length > 0 && child[0]?.value && 'sentiments'}
                        child={child}
                        showSwitchTab={true}
                        insightsData={resetFlag === true && filteredData ? filteredData : insights_data}
                        hideDropdown={hideDropdown}
                        startDate={formattedDate}
                        endDate={endDateParsed}
                    />
                </div>
            }
            {
                child?.length > 0 && child[1]?.value &&
                <div
                    className="pdf-page"
                    style={{
                        pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', width: '100%', marginTop: '50px'
                    }}
                >
                    <WordCloudAnalysis
                        checkboxData={child?.length > 0 && child[1]?.child}
                        selectedButton={child?.length > 0 && child[1]?.value && 'emotions'}
                        child={child}
                        showSwitchTab={true}
                        insightsData={resetFlag === true && filteredData ? filteredData : insights_data}
                        hideDropdown={hideDropdown}
                        startDate={formattedDate}
                        endDate={endDateParsed}
                    />
                </div>
            }
            {
                // filterOption ? (
                //     <EmotionAnalysisWrapper className="pdf-page" style={{ 
                //         pageBreakBefore: 'auto',pageBreakAfter:'auto',pageBreakInside:'avoid' }}>
                //         <p>Most  Mentioned</p>
                //         <FilteredGraphContainer>{mostMentionedGraph}</FilteredGraphContainer>
                //     </EmotionAnalysisWrapper>
                // ) : (

                child?.length > 0 && child[2]?.value && child[2]?.display &&
                <EmotionAnalysisWrapper className="pdf-page" style={{
                    pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                }}>
                    <GraphHOC
                        title={'Most Authoritative'}
                        options={options}
                        renderScreen={renderMostAuthoritative}
                        graphWidth={'100%'}
                        margin={'20px 0px 20px 50px'}
                        option={
                            child[2]?.child?.find(i => i?.value === true)?.name
                        }
                        // option={child[3]?.child?.find(i => i?.value === true)?.name}
                        hideDropdown={hideDropdown}
                        startDate={formattedDate}
                        endDate={endDateParsed}
                    />
                </EmotionAnalysisWrapper>
                // )
            }

            {
                // filterOption ? (
                //     <EmotionAnalysisWrapper className="pdf-page" style={{ 
                //         pageBreakBefore: 'auto',pageBreakAfter:'auto',pageBreakInside:'avoid' }}>
                //         <p>Most  Mentioned</p>
                //         <FilteredGraphContainer>{mostMentionedGraph}</FilteredGraphContainer>
                //     </EmotionAnalysisWrapper>
                // ) : (

                child?.length > 0 && child[3]?.value && child[3]?.display &&
                <EmotionAnalysisWrapper className="pdf-page" style={{
                    pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                }}>
                    <GraphHOC
                        title={'Most Mentioned'}
                        options={options}
                        renderScreen={renderMostmentioned}
                        graphWidth={'100%'}
                        margin={'20px 0px 20px 50px'}
                        // option={child[3]?.value == true}
                        option={child[3]?.child?.find(i => i?.value === true)?.name}
                        hideDropdown={hideDropdown}
                        startDate={formattedDate}
                        endDate={endDateParsed}
                    />
                </EmotionAnalysisWrapper>
                // )
            }

            {filterOption ? (
                <EmotionAnalysisWrapper className="pdf-page" style={{
                    pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                }}>
                    <p>Emotion Analysis</p>
                    <FilteredGraphContainer>{emotionAnalysisGraph}</FilteredGraphContainer>
                </EmotionAnalysisWrapper>
            ) : (

                child?.length > 0 && child[4]?.value == true &&
                <EmotionAnalysisWrapper className="pdf-page" style={{
                    pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                }}>
                    <GraphHOC
                        title={'Emotion Analysis'}
                        // options={options[0]}
                        renderScreen={renderScreen}
                        graphWidth={'100%'}
                        margin={'20px 0px 20px 50px'}
                        startDate={formattedDate}
                        endDate={endDateParsed}
                        option={child[4]?.child?.find(i => i?.value === true)?.name}
                        hideDropdown={hideDropdown}
                    />
                </EmotionAnalysisWrapper>
            )}
            {
                child?.length > 0 && child[5]?.value == true &&
                <div
                    className="pdf-page"
                    style={{
                        pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', marginTop: '30px'
                    }}
                >
                    <CorrelationGraph
                        showSwitchTab={true}
                        RatingLabels={[
                            'Engagement',
                            'Anger',
                            'Neutral',
                            'Sadness',
                            'Disgust',
                            'Fear',
                            'Joy',
                            'Surprise',
                        ]}
                        correlationAnalysisData={
                            filteredData && resetFlag === true
                                ? filteredData?.correlation_analysis
                                : insights_data?.correlation_analysis
                        }
                        hideDropdown={hideDropdown}
                        startDate={formattedDate}
                        endDate={endDateParsed}
                    />
                </div>
            }
        </Wrapper>
    );
};

export default Insight;
