import React, { useState } from 'react';
import {
    KeywordWrapper,
    SubLabel,
    ErrorMessage,
    Label,
    LabelStyle,
    LabelParaStyle,
    FlexAlertWrapper,
    TopicText,
    ShowAlertWrapper,
    // SwitchTab,
    AlertSaveButtonWrapper,
    SwitchTabWrapper,
    SaveButton
} from './AlertConfig.styled.js'
import MultipleCountriesSelect from '../../Common/MultiSelect/MultipleSelect.jsx';
import ConnectChannels from '../TopicBuilder/MainContent/ConnectedChannels/ConnectedChannels.jsx';


const AlertConfigure = () => {

    const [switchTab, setSwitchTab] = useState(false)
    const [channel, setChannel] = useState('');
    const [channelError, setChannelError] = useState('');
    const [selectedNotifyMethod, setSelectedNotifyMethod] = useState([{ label: "What's up", value: 'whatsup' }]);
    const [perForAlert, setPerForAlert] = useState([{ label: 'Mention Frequency', value: 'Mention Frequency' }]);
    const [scanFreq, setScanFreq] = useState([{ label: 'Daily', value: 'Daily' }]);

    const PerameterForAlert = [
        { label: 'User Engagement (Likes & Reshares)', value: 'User Engagement (Likes & Reshares)' },
        { label: 'Mention Frequency', value: 'Mention Frequency' },
        { label: 'Positive Sentiment Shift', value: 'Positive Sentiment Shift' },
        { label: 'Negative Sentiment Shift', value: 'Negative Sentiment Shift' },
    ];

    const ScanFreqValue = [
        { label: 'Hourly', value: 'Hourly' },
        { label: 'Daily', value: 'Daily' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Quaterly', value: 'Quaterly' },
        { label: 'Yearly', value: 'Yearly' },
    ];

    const notifyMethod = [
        { label: "What's up", value: 'whatsup' },
        { label: "Sms", value: 'sms' },

    ];

    const handleConnectChannelsToggle = (selected) => {
        if (channel === selected) {
            setChannel(null); // Deselect if the same channel is clicked again
        } else {
            setChannel(selected); // Select the new channel
        }

        if (!selected) {
            setChannelError('Select at least one channel');
        } else {
            setChannelError('');
        }
    };

    const handleSwitchTab = () => {
        setSwitchTab(!switchTab)
    }


    return (

        <ShowAlertWrapper >
            <AlertSaveButtonWrapper >
                <div>

                    <TopicText>Alert Configuration</TopicText>


                    <SubLabel>Configure notification thresholds for metric spikes</SubLabel>



                </div>
                <div>
                    <SaveButton>Save</SaveButton>
                </div>

            </AlertSaveButtonWrapper>
            <SwitchTabWrapper >
                <div
                    className={switchTab ? `chatter_switch_tab active` : 'chatter_switch_tab'}
                    onClick={handleSwitchTab}
                >Chatter</div>
                <div
                    className={!switchTab ? `topic_switch_tab active` : 'topic_switch_tab'}
                    onClick={handleSwitchTab}
                >Topic</div>
            </SwitchTabWrapper>
            <KeywordWrapper>
                <Label>
                    Channels
                </Label>
                <br />
                <SubLabel>Select interested channels for topic creation</SubLabel>
                <ConnectChannels

                    handleConnectChannelsToggle={handleConnectChannelsToggle}
                    channel={channel}
                />
                {channelError && <ErrorMessage>{channelError}</ErrorMessage>}
            </KeywordWrapper>
            <FlexAlertWrapper>
                <div style={{
                    width: '48%',
                    textAlign: 'left'
                }}>


                    <LabelStyle>Peremeter For Alerting</LabelStyle>
                    <br />
                    <LabelParaStyle
                    >
                        Configure alerts based on the following parameters
                    </LabelParaStyle>

                    <MultipleCountriesSelect
                        value={perForAlert}
                        setValue={setPerForAlert}
                        placeholder='Select Peremeter For Alerting'
                        language={PerameterForAlert}
                    />


                </div>
                <div style={{
                    width: '48%',
                    textAlign: 'left'
                }}>

                    <LabelStyle>Scan Frequency
                    </LabelStyle>

                    <br />
                    <LabelParaStyle
                        style={{
                            display: 'inline-block',
                            marginTop: "5px",
                            marginBottom: '10px',
                            marginLeft: '8px',
                            color: 'rgb(99, 99, 99)',
                            fontFamily: "Open Sans",
                            fontSize: '15px',
                            fontWeight: '400'
                        }}
                    >
                        Define the time interval to scan platform
                    </LabelParaStyle>

                    <MultipleCountriesSelect
                        value={scanFreq}
                        setValue={setScanFreq}
                        placeholder='Select Scan Frequency'
                        language={ScanFreqValue}
                    />
                </div>
            </FlexAlertWrapper>


            <div style={{
                marginTop: '20px',
                textAlign: 'left'
            }}>


                <LabelStyle>Threshold Configuration
                </LabelStyle>


                <br />
                <LabelParaStyle

                >
                    Enter a parameter limit that, once surpassed, will activate an alert
                </LabelParaStyle>


                <input
                    placeholder='Enter Parameter'
                    style={{
                        boxSizing: 'border-box',
                        padding: '14px 18px',
                        width: '100%',
                        background: 'rgb(255, 255, 255)',
                        border: '1px solid rgb(162, 162, 162)',
                        borderRadius: '15px',
                        outline: 'none',
                        marginTop: '6px',
                        font: 'inherit'
                    }} />

            </div>

            <div style={{
                marginTop: '20px',
                textAlign: 'left'
            }}>

                <LabelStyle>Alert selected users
                </LabelStyle>

                <input
                    placeholder='Search Users...'
                    style={{
                        boxSizing: 'border-box',
                        padding: '14px 18px',
                        width: '100%',
                        background: 'rgb(255, 255, 255)',
                        border: '1px solid rgb(162, 162, 162)',
                        borderRadius: '15px',
                        outline: 'none',
                        marginTop: '10px',
                        font: 'inherit'
                    }}

                />

            </div>

            <div style={{
                marginTop: '20px',
                textAlign: 'left'
            }}>

                <LabelStyle> Preferred notification method
                </LabelStyle>
                <div style={{ marginTop: '10px', width: '48%' }}>
                    <MultipleCountriesSelect
                        value={selectedNotifyMethod}
                        setValue={setSelectedNotifyMethod}
                        placeholder='Select your preferred notification method'
                        language={notifyMethod}
                    />
                </div>


            </div>

        </ShowAlertWrapper>


    );
};


export default AlertConfigure;