// 404Page.js
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
`;

const StatusCode = styled.h1`
  font-size: 5rem;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1.5rem;
`;

const NotFoundPage = () => {
  return (
    <Container>
      <StatusCode>404</StatusCode>
      <Message>Page Not Found</Message>
    </Container>
  );
};

export default NotFoundPage;
