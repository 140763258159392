import React, { useState } from 'react';
import { PDFViewer, Font, Document, Page, Text, PDFDownloadLink, StyleSheet, View, Svg, Path } from '@react-pdf/renderer';
import fontFamily from '../../assests/Fonts/OpenSans-VariableFont_wdth,wght.ttf';
import { formatDate } from '../../utils'

import { AmazonIconSmall, StarIcon, X_icon } from '../../Common/SvgIcon/CommonSvgIcons';


const AmazonIconSmallComp = () => (
  <Svg width="100" height="100" viewBox="0 0 48 48">
    <Path fill="#FFB300" d="M39.6,39c-4.2,3.1-10.5,5-15.6,5c-7.3,0-13.8-2.9-18.8-7.4c-0.4-0.4,0-0.8,0.4-0.6c5.4,3.1,11.5,4.9,18.3,4.9c4.6,0,10.4-1,15.1-3C39.7,37.7,40.3,38.5,39.6,39z M41.1,36.9c-0.5-0.7-3.5-0.3-4.8-0.2c-0.4,0-0.5-0.3-0.1-0.6c2.3-1.7,6.2-1.2,6.6-0.6c0.4,0.6-0.1,4.5-2.3,6.3c-0.3,0.3-0.7,0.1-0.5-0.2C40.5,40.4,41.6,37.6,41.1,36.9z" />
    <Path fill="#37474F" d="M36.9,29.8c-1-1.3-2-2.4-2-4.9v-8.3c0-3.5,0-6.6-2.5-9c-2-1.9-5.3-2.6-7.9-2.6C19,5,14.2,7.2,13,13.4c-0.1,0.7,0.4,1,0.8,1.1l5.1,0.6c0.5,0,0.8-0.5,0.9-1c0.4-2.1,2.1-3.1,4.1-3.1c1.1,0,3.2,0.6,3.2,3v3c-3.2,0-6.6,0-9.4,1.2c-3.3,1.4-5.6,4.3-5.6,8.6c0,5.5,3.4,8.2,7.8,8.2c3.7,0,5.9-0.9,8.8-3.8c0.9,1.4,1.3,2.2,3,3.7c0.4,0.2,0.9,0.2,1.2-0.1l0,0c1-0.9,2.9-2.6,4-3.5C37.4,30.9,37.3,30.3,36.9,29.8z M27,22.1L27,22.1c0,2-0.1,6.9-5,6.9c-3,0-3-3-3-3c0-4.5,4.2-5,8-5V22.1z" />
  </Svg>
);

const X_iconComp = ({ width, height }) => (
  <Svg width={width} height={height} viewBox="0 0 37 37">
    {/* // <Svg width="9" height="9" viewBox="0 0 37 37"> */}
    <Path
      d="M28.0679 3.46875H33.1678L22.0261 16.2029L35.1334 33.5312H24.869L16.8307 23.0217L7.63312 33.5312H2.53021L14.4473 19.9106L1.875 3.46875H12.3969L19.6628 13.0749L28.0679 3.46875ZM26.278 30.4788H29.1039L10.8629 6.36092H7.83046L26.278 30.4788Z"
      fill="black"
    />
  </Svg>
);





Font.register({
  family: 'Open Sans',
  src: fontFamily, // Replace with the actual font URL
});

Font.register({
  family: 'Montserrat',
  src: 'https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gnD-w.ttf' // Replace 'path/to/montserrat.ttf' with the actual path to your Montserrat font file
});


const styles = StyleSheet.create({
  page: {
    flexDirection: 'columns',
    // backgroundColor: '#E4E4E4'
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  Wrapper: {

    width: '100%',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    textAlign: 'left',
    fontFamily: 'Open Sans',
  },
  HeaderWrapper: {
    padding: '20px 0 20px 25px',
    display: 'flex',
    flexDirection: 'column',

    position: 'relative',
  },
  Span1: {
    color: '#636363',
    fontSize: '11',
    // fontFamily: 'Montserrat',
    fontFamily: 'Open Sans',
    fontWeight: '700',
  },
  desc_Span: {
    fontSize: '9',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    color: '#000000de',
    marginVertical: '2'
  },
  QueryPara: {
    display: 'flex',
    flexDirection: 'row',
    color: 'rgba(0, 0, 0, 0.84)',
    fontSize: '9',
    marginVertical: '2'
  },
  ChannelsText: {
    fontFamily: 'Open Sans',
    // display: 'flex',
    fontSize: '9',
    color: 'rgba(0, 0, 0, 0.84)',

    fontWeight: '600',
    marginRight: '2'
  },

  TwitterText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4',
    color: 'rgba(0, 0, 0, 0.84)',
    margin: '0',
    fontSize: '9',
    fontWeight: '400',

  },

  TwitterIcon: {
    margin: '0'
    // width: '4',
    // height: '4',
    // paddingTop: '5'
  },
  StyledContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0',
  },
  dowmload_cancel: {
    background: '#fff',
    color: '#353cf7',
    border: '1px solid #353cf7'
  },
  DownloadWrapper: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    marginVertical: '2'

    // padding: '0 25px',
    // paddingBottom: '10px'
  },
  created_at: {

    fontSize: '9'
  },
  DownloadWrapper_span: {
    marginLeft: '10px'
  },
  downloadWrapper_button: {
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: '600',
    background: '#353cf7',
    color: '#fff',

    height: '35px',
    padding: '5px 20px',
    borderRadius: '10px',

    border: '0px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '10px'
  },










  overviewWrapper: {
    display: 'flex',
    height: "auto",
    width: '95%',
    flexDirection: "column",
    marginVertical: "2",
  },
  FlexWrapper: {

    display: 'flex',
    flexDirection: 'row',
    width: "100%"
    // margin: 40px 0px 0px 20px;
  },
  ContentWrapper1: {
    width: '50%',
    height: '380px',
    flexShrink: '0',

    borderRadius: '15px',
    border: '1px solid #d9d9d9',
    justifyContent: 'space-between'
  },
  TextWrapper: {
    width: '389px',
    display: 'flex',
    height: '230px',
    // margin: 40px 0px 0px 55px;

    borderRadius: '36px 36px 0px 36px',
    background: 'rgba(111, 98, 254, 0.22)'

  },
  IconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ContentWrapper2: {
    display: 'flex',
    marginLeft: '15px',
    flexDirection: 'column',
    width: '50%',
    height: '380px',
    borderRadius: '15px',
    border: '1px solid #d9d9d9'
  },
  GraphContainer: {
    flex: '1'
  },
  LineGraphWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Open Sans',
    width: '80%'
    // margin: 30px 0px 0px 0px; 
  },
  LineGraphLabel: {
    width: '50px',
    margin: '10px 0px 0px 25px',
    color: '#636363',

    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  bar: {

    flexGrow: '1',
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 0px 30px',
  },
  filledBar: {
    // height: '15px',
    // background- color: ${(props) => props.backgroundColor || '#6F62FE'};
    /* border-radius: ${(props) =>
      props.percentage === 100 ? '3px' : '3px 0 0 3px'}; */
    // margin: 8px 0px 0px 0px;
  },
  rating: {
    margin: '5px 0px 0px 5px'
  },




  OverViewTwitterWrapper: {
    // padding: '30px',
    // display: 'flex',
    // flexDirection: 'column',
    // gap: '30px'

    padding: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  OverViewTwitterContainer: {
    // display: 'flex',
    // gap: '30px',
    // height: '380px'

    display: 'flex',
    flexDirection: 'row',
    height: 220,
    gap: 10,
  },
  OverViewChildContainer: {
    flex: 1,
    height: '100%',
    borderRadius: 8,
    border: '1px solid #d9d9d9',
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    boxSizing: 'border-box',
  },
  OverallPosts: {
    display: 'flex',
    flexDirection: 'column',
    // paddingVertical: 5,
    // paddingHorizontal: 0,
    boxSizing: 'border-box',
    width: '94%',
    marginLeft: 'auto',
    marginRight: 'auto',

  },
  Title: {
    color: '#636363',
    fontFamily: 'Montserrat',
    fontWeight: "heavy",
    fontSize: 9,
    fontStyle: "normal",
    marginTop: 10,
    marginLeft: 10,
    // lineHeight: 1, // Adjust as needed
  },


  RelevantPost: {

    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '92%',
    height: '83%',
    borderTopLeftRadius: 36,
    borderTopRightRadius: 36,
    borderBottomLeftRadius: 36,
    // borderRadius: '36px 36px 0px 36px',
    backgroundColor: 'rgb(223 221 255)',

    marginTop: 12,
    marginLeft: 10,
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: 9,

  },
  Logo: {
    position: 'absolute',
    top: -7,
    left: -7,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(85, 172, 238, 0.84)',
    boxShadow: '0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset',
  },

  GraphHoc_Wrapper: {

    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '10px 15px',
    boxSizing: 'border-box'
  },
  GraphHoc_Title: {

    color: '#636363',
    fontFamily: 'Montserrat',
    fontSize: 9,
    fontWeight: '500',

    display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center'
  },
  GraphHoc_p_Title: {

    color: '#636363',
    fontFamily: 'Montserrat',
    fontSize: 9,
    fontWeight: '500',

  },
  GraphHoc_MainContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    // width: (props) => props.graphWidth, // Use props.graphWidth to set dynamic width
    // margin: (props) => props.margin, // Use props.margin to set dynamic margin
  },
  GraphHoc_Span: {

    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Open Sans',
    fontSize: '6',
    fontWeight: '400',
    alignSelf: 'flex-end'
  },
  BarGraph_Wrapper: {

    fontFamily: 'Open Sans',
    width: '100%',
    // maxWidth: (props) => props.maxWidth,
  },
  BarGraphBarWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    paddingTop: 5,
    paddingHorizontal: 5,
    boxSizing: 'border-box',
    color: '#636363',
    fontSize: 14,
    fontWeight: '400',
    whiteSpace: 'nowrap',
    gap:1,
    
    display: 'grid',
    gridTemplateColumns: '20% 1fr min-content'
  },

  barLabel: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    margin: '5px 0',
    fontSize: 10, 
    textAlign:'right'
    // paddingLeft: 50,
  },
  baremoji: {
    // width: 28,
    // height: 28,
    textAlign:'right'
  },

  Bar: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection:'row'
    // width:'60px'
  },
  FilledBar: {
    // height: (props) => props.barHeight,
    minWidth: '1%',
    margin: '8px 0px 0px 0px',

    // background: (props) => props.color,
    // width: (props) => props.width,
    // borderRadius: props.percentage === 100 ? '3' :`3 0 0 3`,
    // borderRadius: (props) => {
    //   if (props.percentage === 100) {
    //     return 3; // Apply a fixed border radius of 3 if percentage is 100
    //   } else if (props.borderRadius) {
    //     return props.borderRadius; // Apply the provided borderRadius if it exists
    //   } else {
    //     return 0; // Otherwise, default to 0
    //   }
    // }

    // borderRadius:(props) => props.percentage === 100 ? 3 : 0, ...(props.borderRadius ? { borderRadius: props.borderRadius } : {}),
  },

});


const OverallReview = ({ title = 'Overall Posts', icon, message = "" }) => (
  <View style={styles.OverallPosts}>
    <Text style={styles.Title}>{title}</Text>
    <View style={styles.RelevantPost}>
      <View style={styles.Logo}>{icon == 'X_icon' ? <X_iconComp width={'17'} height={'17'} /> : <AmazonIconSmallComp style={styles.Logo} />}</View>
      <Text style={{ fontSize: 10, width: '70%' }}>{message}</Text>
    </View>

  </View>
)

const GraphHOC = ({
  title = '',
  renderScreen = () => { },
  options = [],
  graphWidth = '100%',
  margin = 0,
  startDate = '',
  endDate = '',
}) => (
  <View style={styles.GraphHoc_Wrapper} >
    <View style={styles.GraphHoc_Title} >
      <Text style={styles.GraphHoc_p_Title}>{title}</Text>
      {/* <SmallSelect onChange={handleSelectedGraph} options={options} /> */}
    </View >
    <View style={styles.GraphHoc_MainContainer} styles={{ width: graphWidth, margin: margin }} >
      {renderScreen[options[0]]}
    </View >
    {startDate && (
      <Text style={styles.GraphHoc_Span}>
        This data is observed from {startDate} to {endDate}
      </Text>
    )}
  </View>
)

const BarGraph = ({

  data,
  maxWidth = '100%',
  type = '',
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
  barHeight = '27',
  emojiList = {},
  starRating = false
}) => {
  const sortedData = data.sort((a, b) => b.value - a.value);

  const maxValue = Math.max(...sortedData.map((item) => item.value));
  const multiplier = 100 / maxValue;


  return (
    <View style={styles.BarGraph_Wrapper} styles={{ maxWidth: maxWidth }}>
      {sortedData?.map((rating, index) => (
        <View style={styles.BarGraphBarWrapper} key={index}>
          {emojiList.length > 0 ? (
            <View style={styles.barLabel} >
              <View style={styles.baremoji} >
                {emojiList[rating.name?.toLowerCase()]}
              </View>
              <Text>{rating.name}</Text>
            </View>
          ) : (
            <View style={{
              // width: '60px',
              textAlign: 'right'
          }}>
              <Text style={{
                // width: '50px',
                // height:barHeight,
                fontSize: 9,
                marginRight: 2,
                // alignItems: 'center',
                // justifyContent: 'center',
                // alignSelf: 'center',
                // display: 'flex'
              }}>{rating.name}</Text>
            </View>
          )}
          {/* <Text>{Math.floor(rating.value * multiplier)}</Text> */}

          <View style={styles.Bar}>

            <Text
              style={{
                // ...styles.FilledBar,

                width: `${Math.floor(rating.value * multiplier)}%`,
                height: barHeight,
                minWidth: 1,
                backgroundColor: rating.fill || '#353cf7',
                // borderRadius: 0,
                // borderRadius: borderRadius ? borderRadius : 0,

                borderTopLeftRadius: borderTopLeftRadius ? borderTopLeftRadius : 0,
                borderTopRightRadius: borderTopRightRadius ? borderTopRightRadius : 0,
                borderBottomRightRadius: borderBottomRightRadius ? borderBottomRightRadius : 0,
                borderBottomLeftRadius: borderBottomLeftRadius ? borderBottomLeftRadius : 0,
                margin: '8px 0px 0px 0px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                alignSelf: 'flex-start'
              }}

            ></Text>

            {/* <Text>{rating.value}</Text> */}
            <View style={{marginLeft: '5px' ,fontSize: 9, fontFamily: 'Montserrat',}}>
              <Text style={{fontSize: 9, fontFamily: 'Montserrat',}}>{`${ typeof rating?.value === 'number' && rating?.value }`}</Text>
              {starRating === true ? {StarIcon} : type}
              </View>
          </View>
        </View>
      ))}
    </View>
  )
}

const averageRating = 0;



{/* <Text>Section #1</Text> */ }

const convertDateTime = (inputDateTime) => {
  let dtObject = new Date(inputDateTime);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let formattedDate =
    monthNames[dtObject.getMonth()] +
    ' ' +
    dtObject.getDate() +
    ' at ' +
    (dtObject.getHours() % 12 || 12) +
    ':' +
    (dtObject.getMinutes() < 10 ? '0' : '') +
    dtObject.getMinutes() +
    ' ' +
    (dtObject.getHours() >= 12 ? 'PM' : 'AM');

  return formattedDate;
}

const LineGraph = ({ backgroundColor, rating, title }) => (
  <View style={styles.LineGraphWrapper}>
    <Text style={styles.LineGraphLabel}>{title}</Text>
    <View style={styles.bar}>
      <View style={{
        // ...styles.filledBar,
        height: '15px',
        margin: '8px 0px 0px 0px',
        width: `${rating * 15}%`,
        backgroundColor: backgroundColor || '#6F62FE'
      }}>
      </View>
      <Text style={styles.rating}>{`${rating} `}{StarIcon}</Text>
    </View>
    {/* <View style={{ width: `${rating * 15}%`, backgroundColor }}></View> */}

    {/* <div style={{ margin: '5px 0px 0px 5px' }}>{`${rating}`}{StarIcon}</div> */}

  </View>
)

const MyDocument = ({ renderScreen, options, endDateOverView, planSpecificData, topicPreviewData, is_topic, created_at, startDateOverView }) => (

  <Document>
    <Page size="A4">
      <View style={styles.page}>
        <View style={styles.Wrapper}>
          <View style={styles.HeaderWrapper}>
            <Text style={styles.Span1}>{topicPreviewData?.title}</Text>
            <Text style={styles.desc_Span}>{topicPreviewData?.description}</Text>
            <View style={styles.StyledContainer}>
              <Text style={styles.ChannelsText}>Channels:</Text>
              <View style={styles.TwitterText}>
                <View >{topicPreviewData?.is_topic ? <X_iconComp width={'9'} height={'9'} /> : <AmazonIconSmallComp style={styles.TwitterIcon} />}</View>
                <Text >{topicPreviewData?.channel?.replace(/{|}/g, '')}</Text>
              </View>
            </View>
            <View> {
              topicPreviewData?.is_topic === false ?
                <Text style={styles.Span1}>{topicPreviewData?.hyperlink}</Text>
                :
                <View style={styles.QueryPara}>
                  <Text >Query Used:&nbsp;</Text>
                  <Text >{topicPreviewData?.keywords_string}</Text>
                </View>
            }

            </View>
            <View style={styles.DownloadWrapper}>
              <Text style={styles.created_at}>
                {is_topic ? 'Topic ' : 'Chatter '}
                Created at {convertDateTime(created_at)}
              </Text>
            </View>

          </View>
          <View>
            <Text>Overview</Text>
          </View>
          <View>
            <View style={styles.OverViewTwitterWrapper}>
              <View style={styles.OverViewTwitterContainer}>
                <View style={styles.OverViewChildContainer}>
                  <OverallReview
                    title='Overall Posts'
                    icon={"X_icon"}
                    message={`${planSpecificData?.total_posts} Relevant posts were observed From ${startDateOverView} to ${endDateOverView}`}
                  />
                </View>
                <View style={styles.OverViewChildContainer}>
                  <GraphHOC
                    title={'Gender Demographics'}
                    options={options}
                    renderScreen={renderScreen}
                    startDate={startDateOverView}
                    endDate={endDateOverView}

                  />
                </View>
                {/* <ChildContainer>
                  <GraphHOC
                    title={'Gender Demographics'}
                    options={options}
                    renderScreen={renderScreen}
                    startDate={resetFlag ? filteredStartDate : startDate}
                    endDate={resetFlag ? filteredEndDate : endDate}
                  />
                </ChildContainer> */}
              </View>
              <View>
                {/* <TrendsInUserActivity
                  data1={{
                    trends_in_user_activity: resetFlag === true && filteredData ? filteredData?.trends_in_user_activity : overview_tab?.trends_in_user_activity,
                  }}
                /> */}
              </View>
            </View>

          </View>

        </View>
      </View>
    </Page>
  </Document >
);




const DownloadPDFReport = ({ topicPreviewData, onClick, is_topic, created_at }) => {


  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];
  let planSpecificOverViewData = topicPreviewData?.data?.overview_tab

  const [selectedGraph, setSelectedGraph] = useState(options[0]);
  // const averageRating = planSpecificOverViewData?.customer_rating?.customer_reviews?.average_rating_count ||    0;


  const startDateOverView = formatDate(topicPreviewData?.start_date) || '2024/01/01';
  const endDateOverView = formatDate(topicPreviewData?.end_date) || '2024/01/02';

  const genderDemographicsData = [
    {
      name: 'Male',
      value: planSpecificOverViewData?.gender_demographics?.male_percentage,
      fill: '#6640B8',
    },
    {
      name: 'Female',
      value: planSpecificOverViewData?.gender_demographics?.female_percentage,
      fill: '#EAAABD',
    },
    {
      name: 'Unknown',
      value: planSpecificOverViewData?.gender_demographics?.unknown_percentage,
      fill: '#E4E4E4',
    },
  ];

  const renderScreen = {
    'Bar Graph':
      (
        <BarGraph
          data={genderDemographicsData}
          type={'%'}
          borderRadius={'0 12 12 0'}
          barHeight={'25'}

          borderTopLeftRadius={0}
          borderTopRightRadius={6}
          borderBottomRightRadius={6}
          borderBottomLeftRadius={0}
        />
      ),
  };

  let genderDemographicsGraph = renderScreen['Bar Graph'];

  return (

    <>
      {/* //  */}
      {/* // <div style={{ height: '100vh' }}> */}
      {/* <button onClick={onClick}>Cancel</button> */}
      <MyDocument
        renderScreen={renderScreen}
        options={options}
        topicPreviewData={topicPreviewData}
        is_topic={is_topic}
        created_at={created_at}
        planSpecificData={planSpecificOverViewData}
        startDateOverView={startDateOverView}
        endDateOverView={endDateOverView}
        genderDemographicsGraph={genderDemographicsGraph}
      />

      <PDFDownloadLink document={
        <MyDocument
          renderScreen={renderScreen}
          options={options}
          planSpecificData={planSpecificOverViewData}
          topicPreviewData={topicPreviewData}
          is_topic={is_topic}
          created_at={created_at}
          startDateOverView={startDateOverView}
          endDateOverView={endDateOverView}
        />
      } fileName="map.pdf">
        {({ blob, url, loading, error }) => ("loading" ? 'Loading document...' : 'Download now!')}
      </PDFDownloadLink>

    </>
  );
}

export default DownloadPDFReport;







