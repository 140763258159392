import tenantAxiosInstance from '../utils/tenantAxiosInstance';
const API_URL = process.env.REACT_APP_BACKEND_URL;
const version = 'v1';

const tempPreviewData = {
  BrandastrPro: {
    overview_tab: {
      total_posts: 100,
      post_date: {
        start_date: '22/03/2023',
        end_date: '22/04/2023',
      },
      gender_demographics: {
        male_percentage: 22,
        female_percentage: 2,
        unknown_percentage: 76,
      },
      trends_in_user_activity: {
        days: {
          timeline: ['2024-02-14'],
          reviews_count: [100],
        },
        week: {
          timeline: ['2024-02-12'],
          reviews_count: [100],
        },
        month: {
          timeline: ['2024-02-01'],
          reviews_count: [100],
        },
      },
      word_cloud: {
        unigram_word_cloud: [
          {
            word: 'puma',
            count: 58,
          },
          {
            word: 'birthday',
            count: 18,
          },
          {
            word: 'check',
            count: 16,
          },
          {
            word: 'fellow',
            count: 16,
          },
          {
            word: 'global',
            count: 16,
          },
          {
            word: 'ambassador',
            count: 16,
          },
          {
            word: 'pinned',
            count: 15,
          },
          {
            word: 'davido',
            count: 15,
          },
          {
            word: 'performing',
            count: 15,
          },
          {
            word: 'party',
            count: 15,
          },
          {
            word: 'athletico',
            count: 15,
          },
          {
            word: 'madrid',
            count: 15,
          },
          {
            word: 'player',
            count: 15,
          },
          {
            word: 'memphis',
            count: 15,
          },
          {
            word: 'depay',
            count: 15,
          },
          {
            word: 'names',
            count: 13,
          },
          {
            word: 'turret',
            count: 10,
          },
          {
            word: 'common',
            count: 9,
          },
          {
            word: 'news',
            count: 8,
          },
          {
            word: 'generic',
            count: 6,
          },
          {
            word: 'like',
            count: 5,
          },
          {
            word: 'dont',
            count: 5,
          },
          {
            word: 'torc',
            count: 5,
          },
          {
            word: 'unmanned',
            count: 5,
          },
          {
            word: 'designed',
            count: 5,
          },
          {
            word: 'brazil',
            count: 5,
          },
          {
            word: 'brings',
            count: 5,
          },
          {
            word: 'vehicle',
            count: 5,
          },
          {
            word: 'level',
            count: 5,
          },
          {
            word: 'firepower',
            count: 5,
          },
          {
            word: 'comparable',
            count: 5,
          },
          {
            word: 'fake',
            count: 5,
          },
          {
            word: 'shoes',
            count: 4,
          },
          {
            word: 'reminder',
            count: 4,
          },
          {
            word: 'extinct',
            count: 4,
          },
          {
            word: 'animals',
            count: 4,
          },
          {
            word: 'need',
            count: 4,
          },
          {
            word: 'work',
            count: 4,
          },
          {
            word: 'perfectly',
            count: 4,
          },
          {
            word: 'fine',
            count: 4,
          },
          {
            word: 'live',
            count: 4,
          },
          {
            word: 'white',
            count: 4,
          },
          {
            word: 'gift',
            count: 3,
          },
          {
            word: 'dear',
            count: 3,
          },
          {
            word: 'well',
            count: 3,
          },
          {
            word: 'delivery',
            count: 3,
          },
          {
            word: 'code',
            count: 3,
          },
          {
            word: 'lamelo',
            count: 3,
          },
          {
            word: 'ball',
            count: 3,
          },
          {
            word: 'porsche',
            count: 3,
          },
          {
            word: 'nike',
            count: 3,
          },
          {
            word: 'ordered',
            count: 2,
          },
          {
            word: 'pair',
            count: 2,
          },
          {
            word: 'found',
            count: 2,
          },
          {
            word: 'flipkart',
            count: 2,
          },
          {
            word: 'delivered',
            count: 2,
          },
          {
            word: 'price',
            count: 2,
          },
          {
            word: 'shit',
            count: 2,
          },
          {
            word: 'ford',
            count: 2,
          },
          {
            word: 'kookie',
            count: 2,
          },
          {
            word: 'holding',
            count: 2,
          },
          {
            word: 'jinnies',
            count: 2,
          },
          {
            word: 'jacketso',
            count: 2,
          },
          {
            word: 'adveising',
            count: 2,
          },
          {
            word: 'photo',
            count: 2,
          },
          {
            word: 'shooting',
            count: 2,
          },
          {
            word: 'behind',
            count: 2,
          },
          {
            word: 'httpstcorexihih',
            count: 2,
          },
          {
            word: 'super',
            count: 2,
          },
          {
            word: 'happy',
            count: 2,
          },
          {
            word: 'valentines',
            count: 2,
          },
          {
            word: 'httpstcoiirruv',
            count: 2,
          },
          {
            word: 'nice',
            count: 2,
          },
          {
            word: 'drop',
            count: 2,
          },
          {
            word: 'beautiful',
            count: 2,
          },
          {
            word: 'hippopotamus',
            count: 2,
          },
          {
            word: 'long',
            count: 2,
          },
          {
            word: 'stewie',
            count: 2,
          },
          {
            word: 'sneakers',
            count: 2,
          },
          {
            word: 'step',
            count: 2,
          },
          {
            word: 'fast',
            count: 2,
          },
          {
            word: 'lanelink',
            count: 2,
          },
          {
            word: 'httpstcoolcsrsxqx',
            count: 2,
          },
          {
            word: 'available',
            count: 2,
          },
          {
            word: 'almost',
            count: 2,
          },
          {
            word: 'snswmns',
            count: 2,
          },
          {
            word: 'jordan',
            count: 2,
          },
          {
            word: 'retro',
            count: 2,
          },
          {
            word: 'high',
            count: 2,
          },
          {
            word: 'whitemetallic',
            count: 2,
          },
          {
            word: 'goldw',
            count: 2,
          },
          {
            word: 'httpstcoowfflyfzjxp',
            count: 2,
          },
          {
            word: 'miharu',
            count: 2,
          },
          {
            word: 'hanai',
            count: 2,
          },
          {
            word: 'wmns',
            count: 2,
          },
          {
            word: 'mayze',
            count: 2,
          },
          {
            word: 'thrifted',
            count: 2,
          },
          {
            word: 'little',
            count: 2,
          },
          {
            word: 'sister',
            count: 2,
          },
          {
            word: 'httpstcouppihntdn',
            count: 2,
          },
          {
            word: 'waiting',
            count: 2,
          },
          {
            word: 'score',
            count: 2,
          },
          {
            word: 'airdrop',
            count: 2,
          },
          {
            word: 'adidas',
            count: 2,
          },
          {
            word: 'size',
            count: 2,
          },
          {
            word: 'want',
            count: 2,
          },
          {
            word: 'eachmeet',
            count: 2,
          },
          {
            word: 'interested',
            count: 2,
          },
          {
            word: 'duck',
            count: 2,
          },
          {
            word: 'bungeopang',
            count: 2,
          },
          {
            word: 'niki',
            count: 2,
          },
          {
            word: 'nooits',
            count: 1,
          },
          {
            word: 'httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'enzo',
            count: 1,
          },
          {
            word: 'weave',
            count: 1,
          },
          {
            word: 'reduced',
            count: 1,
          },
          {
            word: 'httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'goldrandlösung',
            count: 1,
          },
          {
            word: 'cool',
            count: 1,
          },
          {
            word: 'crown',
            count: 1,
          },
          {
            word: 'stress',
            count: 1,
          },
          {
            word: 'freeze',
            count: 1,
          },
          {
            word: 'light',
            count: 1,
          },
          {
            word: 'sputnik',
            count: 1,
          },
          {
            word: 'ghosting',
            count: 1,
          },
          {
            word: 'back',
            count: 1,
          },
          {
            word: 'httpstcouzjmzrveq',
            count: 1,
          },
          {
            word: 'nigerian',
            count: 1,
          },
          {
            word: 'restaurant',
            count: 1,
          },
          {
            word: 'area',
            count: 1,
          },
          {
            word: 'filling',
            count: 1,
          },
          {
            word: 'station',
            count: 1,
          },
          {
            word: 'next',
            count: 1,
          },
          {
            word: 'zathu',
            count: 1,
          },
          {
            word: 'trading',
            count: 1,
          },
          {
            word: 'jollof',
            count: 1,
          },
          {
            word: 'phone',
            count: 1,
          },
          {
            word: 'apple',
            count: 1,
          },
          {
            word: 'iphonecameranikon',
            count: 1,
          },
          {
            word: 'sketcherstshirt',
            count: 1,
          },
          {
            word: 'zara',
            count: 1,
          },
          {
            word: 'paint',
            count: 1,
          },
          {
            word: 'hampm',
            count: 1,
          },
          {
            word: 'jacket',
            count: 1,
          },
          {
            word: 'treat',
            count: 1,
          },
          {
            word: 'promo',
            count: 1,
          },
          {
            word: 'dayhttpstcoizsgxesbh',
            count: 1,
          },
          {
            word: 'rinse',
            count: 1,
          },
          {
            word: 'repeat',
            count: 1,
          },
          {
            word: 'music',
            count: 1,
          },
          {
            word: 'incoming',
            count: 1,
          },
          {
            word: 'right',
            count: 1,
          },
          {
            word: 'foot',
            count: 1,
          },
          {
            word: 'package',
            count: 1,
          },
          {
            word: 'ruined',
            count: 1,
          },
          {
            word: 'cancelling',
            count: 1,
          },
          {
            word: 'refund',
            count: 1,
          },
          {
            word: 'saying',
            count: 1,
          },
          {
            word: 'open',
            count: 1,
          },
          {
            word: 'httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'yourshttpstcotalxjgacz',
            count: 1,
          },
          {
            word: 'goal',
            count: 1,
          },
          {
            word: 'japan',
            count: 1,
          },
          {
            word: 'gorilla',
            count: 1,
          },
          {
            word: 'octopus',
            count: 1,
          },
          {
            word: 'namesbut',
            count: 1,
          },
          {
            word: 'complex',
            count: 1,
          },
          {
            word: 'years',
            count: 1,
          },
          {
            word: 'campaigning',
            count: 1,
          },
          {
            word: 'cost',
            count: 1,
          },
          {
            word: 'german',
            count: 1,
          },
          {
            word: 'conglomerate',
            count: 1,
          },
          {
            word: 'dearly',
            count: 1,
          },
          {
            word: 'reputation',
            count: 1,
          },
          {
            word: 'projects',
            count: 1,
          },
          {
            word: 'forced',
            count: 1,
          },
          {
            word: 'even',
            count: 1,
          },
          {
            word: 'young',
            count: 1,
          },
          {
            word: 'adults',
            count: 1,
          },
          {
            word: 'taken',
            count: 1,
          },
          {
            word: 'considering',
            count: 1,
          },
          {
            word: 'case',
            count: 1,
          },
          {
            word: 'killing',
            count: 1,
          },
          {
            word: 'meter',
            count: 1,
          },
          {
            word: 'alligator',
            count: 1,
          },
          {
            word: 'land',
            count: 1,
          },
          {
            word: 'httpstcotygduzrbtry',
            count: 1,
          },
          {
            word: 'httpstcokpgcwaqho',
            count: 1,
          },
          {
            word: 'httpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'revealed',
            count: 1,
          },
          {
            word: 'updated',
            count: 1,
          },
          {
            word: 'giving',
            count: 1,
          },
          {
            word: 'greater',
            count: 1,
          },
          {
            word: 'connectivity',
            count: 1,
          },
          {
            word: 'larger',
            count: 1,
          },
          {
            word: 'infotainment',
            count: 1,
          },
          {
            word: 'screen',
            count: 1,
          },
          {
            word: 'enhanced',
            count: 1,
          },
          {
            word: 'digital',
            count: 1,
          },
          {
            word: 'drivers',
            count: 1,
          },
          {
            word: 'display',
            count: 1,
          },
          {
            word: 'find',
            count: 1,
          },
          {
            word: 'story',
            count: 1,
          },
          {
            word: 'httpstcozxmarnbsj',
            count: 1,
          },
          {
            word: 'httpstcoxdldxpwa',
            count: 1,
          },
          {
            word: 'httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'dash',
            count: 1,
          },
          {
            word: 'place',
            count: 1,
          },
          {
            word: 'order',
            count: 1,
          },
          {
            word: 'httpstcopwurqgmc',
            count: 1,
          },
          {
            word: 'socks',
            count: 1,
          },
          {
            word: 'women',
            count: 1,
          },
          {
            word: 'pack',
            count: 1,
          },
          {
            word: 'send',
            count: 1,
          },
          {
            word: 'visit',
            count: 1,
          },
          {
            word: 'website',
            count: 1,
          },
          {
            word: 'httpstcoixdocyvk',
            count: 1,
          },
          {
            word: 'httpstcomvnum',
            count: 1,
          },
          {
            word: 'httpstcopqbjaksddwt',
            count: 1,
          },
          {
            word: 'httpstcomkgmpurqniwmns',
            count: 1,
          },
          {
            word: 'amor',
            count: 1,
          },
          {
            word: 'httpstcomuvnkyhytry',
            count: 1,
          },
          {
            word: 'httpstconfymgs',
            count: 1,
          },
          {
            word: 'hoodie',
            count: 1,
          },
          {
            word: 'looks',
            count: 1,
          },
          {
            word: 'someone',
            count: 1,
          },
          {
            word: 'grabbed',
            count: 1,
          },
          {
            word: 'fill',
            count: 1,
          },
          {
            word: 'seat',
            count: 1,
          },
          {
            word: 'didi',
            count: 1,
          },
          {
            word: 'hamann',
            count: 1,
          },
          {
            word: 'arrive',
            count: 1,
          },
          {
            word: 'daejeon',
            count: 1,
          },
          {
            word: 'home',
            count: 1,
          },
          {
            word: 'away',
            count: 1,
          },
          {
            word: 'ktis',
            count: 1,
          },
          {
            word: 'httpstcodwrnlkbgov',
            count: 1,
          },
          {
            word: 'event',
            count: 1,
          },
          {
            word: 'future',
            count: 1,
          },
          {
            word: 'miss',
            count: 1,
          },
          {
            word: 'remember',
            count: 1,
          },
          {
            word: 'invitation',
            count: 1,
          },
          {
            word: 'boost',
            count: 1,
          },
          {
            word: 'fair',
            count: 1,
          },
          {
            word: 'point',
            count: 1,
          },
          {
            word: 'rollout',
            count: 1,
          },
          {
            word: 'scalewise',
            count: 1,
          },
          {
            word: 'reason',
            count: 1,
          },
          {
            word: 'seem',
            count: 1,
          },
          {
            word: 'befit',
            count: 1,
          },
          {
            word: 'goat',
            count: 1,
          },
          {
            word: 'sport',
            count: 1,
          },
          {
            word: 'imagine',
            count: 1,
          },
          {
            word: 'would',
            count: 1,
          },
          {
            word: 'pulling',
            count: 1,
          },
          {
            word: 'stops',
            count: 1,
          },
          {
            word: 'logo',
            count: 1,
          },
          {
            word: 'branding',
            count: 1,
          },
          {
            word: 'buzz',
            count: 1,
          },
          {
            word: 'social',
            count: 1,
          },
          {
            word: 'puts',
            count: 1,
          },
          {
            word: 'three',
            count: 1,
          },
          {
            word: 'decadelong',
            count: 1,
          },
          {
            word: 'erstwhile',
            count: 1,
          },
          {
            word: 'partnership',
            count: 1,
          },
          {
            word: 'httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'httpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'human',
            count: 1,
          },
          {
            word: 'toilet',
            count: 1,
          },
          {
            word: 'nasty',
            count: 1,
          },
          {
            word: 'sitting',
            count: 1,
          },
          {
            word: 'collect',
            count: 1,
          },
          {
            word: 'piss',
            count: 1,
          },
          {
            word: 'souvenir',
            count: 1,
          },
          {
            word: 'ultimate',
            count: 1,
          },
          {
            word: 'predator',
            count: 1,
          },
          {
            word: 'femdom',
            count: 1,
          },
          {
            word: 'beta',
            count: 1,
          },
          {
            word: 'cuck',
            count: 1,
          },
          {
            word: 'loser',
            count: 1,
          },
          {
            word: 'charlotte',
            count: 1,
          },
          {
            word: 'greensboro',
            count: 1,
          },
          {
            word: 'raleigh',
            count: 1,
          },
          {
            word: 'rock',
            count: 1,
          },
          {
            word: 'hill',
            count: 1,
          },
          {
            word: 'durham',
            count: 1,
          },
          {
            word: 'brattylatina',
            count: 1,
          },
          {
            word: 'latinaslut',
            count: 1,
          },
          {
            word: 'morena',
            count: 1,
          },
          {
            word: 'thick',
            count: 1,
          },
          {
            word: 'httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'suede',
            count: 1,
          },
          {
            word: 'within',
            count: 1,
          },
          {
            word: 'nairobi',
            count: 1,
          },
          {
            word: 'pickup',
            count: 1,
          },
          {
            word: 'mtaani',
            count: 1,
          },
          {
            word: 'services',
            count: 1,
          },
          {
            word: 'httpstcocyxgdzxlb',
            count: 1,
          },
          {
            word: 'peanits',
            count: 1,
          },
          {
            word: 'mouth',
            count: 1,
          },
          {
            word: 'concepm',
            count: 1,
          },
          {
            word: 'httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'always',
            count: 1,
          },
          {
            word: 'comfy',
            count: 1,
          },
          {
            word: 'httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'httpstcokcitcynua',
            count: 1,
          },
          {
            word: 'swede',
            count: 1,
          },
          {
            word: 'angel',
            count: 1,
          },
          {
            word: 'diamond',
            count: 1,
          },
          {
            word: 'angela',
            count: 1,
          },
          {
            word: 'aspen',
            count: 1,
          },
          {
            word: 'kinky',
            count: 1,
          },
          {
            word: 'pussyeating',
            count: 1,
          },
          {
            word: 'milf',
            count: 1,
          },
          {
            word: 'sexparty',
            count: 1,
          },
          {
            word: 'everything',
            count: 1,
          },
          {
            word: 'allowed',
            count: 1,
          },
          {
            word: 'httpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'httpstcoutmvbes',
            count: 1,
          },
          {
            word: 'hardchorus',
            count: 1,
          },
          {
            word: 'full',
            count: 1,
          },
          {
            word: 'european',
            count: 1,
          },
          {
            word: 'version',
            count: 1,
          },
          {
            word: 'anyone',
            count: 1,
          },
          {
            word: 'httpstcodulysnr',
            count: 1,
          },
          {
            word: 'quality',
            count: 1,
          },
          {
            word: 'tracksuit',
            count: 1,
          },
          {
            word: 'dmwhatsapp',
            count: 1,
          },
          {
            word: 'httpstcoopbczgsnx',
            count: 1,
          },
          {
            word: 'nationwide',
            count: 1,
          },
          {
            word: 'help',
            count: 1,
          },
          {
            word: 'repost',
            count: 1,
          },
          {
            word: 'httpstcosjfgrkrui',
            count: 1,
          },
          {
            word: 'peekaboo',
            count: 1,
          },
          {
            word: 'httpstcojnmcznni',
            count: 1,
          },
          {
            word: 'unveil',
            count: 1,
          },
          {
            word: 'highly',
            count: 1,
          },
          {
            word: 'anticipated',
            count: 1,
          },
          {
            word: 'collection',
            count: 1,
          },
          {
            word: 'launching',
            count: 1,
          },
          {
            word: 'february',
            count: 1,
          },
          {
            word: 'smash',
            count: 1,
          },
          {
            word: 'leather',
            count: 1,
          },
          {
            word: 'retail',
            count: 1,
          },
          {
            word: 'mandmdirect',
            count: 1,
          },
          {
            word: 'sale',
            count: 1,
          },
          {
            word: 'link',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'sizes',
            count: 1,
          },
          {
            word: 'httpstcowtcknauzu',
            count: 1,
          },
          {
            word: 'cook',
            count: 1,
          },
          {
            word: 'unethical',
            count: 1,
          },
          {
            word: 'consumption',
            count: 1,
          },
          {
            word: 'pretending',
            count: 1,
          },
          {
            word: 'official',
            count: 1,
          },
          {
            word: 'garbage',
            count: 1,
          },
          {
            word: 'dense',
            count: 1,
          },
          {
            word: 'photocardrm',
            count: 1,
          },
          {
            word: 'concepostage',
            count: 1,
          },
          {
            word: 'guys',
            count: 1,
          },
          {
            word: 'httpstcoevkevv',
            count: 1,
          },
          {
            word: 'enhypenas',
            count: 1,
          },
          {
            word: 'stars',
            count: 1,
          },
          {
            word: 'alligned',
            count: 1,
          },
          {
            word: 'dashing',
            count: 1,
          },
          {
            word: 'vampire',
            count: 1,
          },
          {
            word: 'appeared',
            count: 1,
          },
          {
            word: 'turned',
            count: 1,
          },
          {
            word: 'ever',
            count: 1,
          },
          {
            word: 'earth',
            count: 1,
          },
          {
            word: 'love',
            count: 1,
          },
          {
            word: 'solace',
            count: 1,
          },
          {
            word: 'hope',
            count: 1,
          },
          {
            word: 'resting',
            count: 1,
          },
          {
            word: 'httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
        bigram_word_cloud: [
          {
            word: 'fellow puma',
            count: 16,
          },
          {
            word: 'puma global',
            count: 16,
          },
          {
            word: 'global ambassador',
            count: 16,
          },
          {
            word: 'check pinned',
            count: 15,
          },
          {
            word: 'davido performing',
            count: 15,
          },
          {
            word: 'performing birthday',
            count: 15,
          },
          {
            word: 'birthday party',
            count: 15,
          },
          {
            word: 'party athletico',
            count: 15,
          },
          {
            word: 'athletico madrid',
            count: 15,
          },
          {
            word: 'madrid player',
            count: 15,
          },
          {
            word: 'player fellow',
            count: 15,
          },
          {
            word: 'ambassador memphis',
            count: 15,
          },
          {
            word: 'memphis depay',
            count: 15,
          },
          {
            word: 'common names',
            count: 8,
          },
          {
            word: 'generic names',
            count: 5,
          },
          {
            word: 'torc unmanned',
            count: 5,
          },
          {
            word: 'unmanned turret',
            count: 5,
          },
          {
            word: 'turret designed',
            count: 5,
          },
          {
            word: 'designed brazil',
            count: 5,
          },
          {
            word: 'brazil turret',
            count: 5,
          },
          {
            word: 'turret brings',
            count: 5,
          },
          {
            word: 'brings vehicle',
            count: 5,
          },
          {
            word: 'vehicle level',
            count: 5,
          },
          {
            word: 'level firepower',
            count: 5,
          },
          {
            word: 'firepower comparable',
            count: 5,
          },
          {
            word: 'fake news',
            count: 5,
          },
          {
            word: 'reminder extinct',
            count: 4,
          },
          {
            word: 'extinct animals',
            count: 4,
          },
          {
            word: 'animals dont',
            count: 4,
          },
          {
            word: 'dont need',
            count: 4,
          },
          {
            word: 'need common',
            count: 4,
          },
          {
            word: 'names generic',
            count: 4,
          },
          {
            word: 'names work',
            count: 4,
          },
          {
            word: 'work perfectly',
            count: 4,
          },
          {
            word: 'perfectly fine',
            count: 4,
          },
          {
            word: 'fine common',
            count: 4,
          },
          {
            word: 'lamelo ball',
            count: 3,
          },
          {
            word: 'ordered pair',
            count: 2,
          },
          {
            word: 'pair puma',
            count: 2,
          },
          {
            word: 'puma shoes',
            count: 2,
          },
          {
            word: 'shoes birthday',
            count: 2,
          },
          {
            word: 'birthday gift',
            count: 2,
          },
          {
            word: 'gift found',
            count: 2,
          },
          {
            word: 'found flipkart',
            count: 2,
          },
          {
            word: 'flipkart delivered',
            count: 2,
          },
          {
            word: 'kookie holding',
            count: 2,
          },
          {
            word: 'holding jinnies',
            count: 2,
          },
          {
            word: 'jinnies jacketso',
            count: 2,
          },
          {
            word: 'jacketso adveising',
            count: 2,
          },
          {
            word: 'adveising photo',
            count: 2,
          },
          {
            word: 'photo shooting',
            count: 2,
          },
          {
            word: 'shooting behind',
            count: 2,
          },
          {
            word: 'behind httpstcorexihih',
            count: 2,
          },
          {
            word: 'happy valentines',
            count: 2,
          },
          {
            word: 'valentines httpstcoiirruv',
            count: 2,
          },
          {
            word: 'puma stewie',
            count: 2,
          },
          {
            word: 'code sneakers',
            count: 2,
          },
          {
            word: 'news lamelo',
            count: 2,
          },
          {
            word: 'ball puma',
            count: 2,
          },
          {
            word: 'puma porsche',
            count: 2,
          },
          {
            word: 'porsche step',
            count: 2,
          },
          {
            word: 'step fast',
            count: 2,
          },
          {
            word: 'fast lanelink',
            count: 2,
          },
          {
            word: 'lanelink httpstcoolcsrsxqx',
            count: 2,
          },
          {
            word: 'almost live',
            count: 2,
          },
          {
            word: 'live snswmns',
            count: 2,
          },
          {
            word: 'snswmns jordan',
            count: 2,
          },
          {
            word: 'jordan retro',
            count: 2,
          },
          {
            word: 'retro high',
            count: 2,
          },
          {
            word: 'high whitemetallic',
            count: 2,
          },
          {
            word: 'whitemetallic goldw',
            count: 2,
          },
          {
            word: 'goldw httpstcoowfflyfzjxp',
            count: 2,
          },
          {
            word: 'miharu hanai',
            count: 2,
          },
          {
            word: 'hanai wmns',
            count: 2,
          },
          {
            word: 'wmns puma',
            count: 2,
          },
          {
            word: 'puma mayze',
            count: 2,
          },
          {
            word: 'mayze thrifted',
            count: 2,
          },
          {
            word: 'thrifted white',
            count: 2,
          },
          {
            word: 'white little',
            count: 2,
          },
          {
            word: 'little sister',
            count: 2,
          },
          {
            word: 'sister httpstcouppihntdn',
            count: 2,
          },
          {
            word: 'nike puma',
            count: 2,
          },
          {
            word: 'puma adidas',
            count: 2,
          },
          {
            word: 'dear duck',
            count: 2,
          },
          {
            word: 'duck puma',
            count: 2,
          },
          {
            word: 'puma bungeopang',
            count: 2,
          },
          {
            word: 'nooits puma',
            count: 1,
          },
          {
            word: 'puma httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'check puma',
            count: 1,
          },
          {
            word: 'puma enzo',
            count: 1,
          },
          {
            word: 'enzo weave',
            count: 1,
          },
          {
            word: 'weave price',
            count: 1,
          },
          {
            word: 'price reduced',
            count: 1,
          },
          {
            word: 'reduced httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'goldrandlösung cool',
            count: 1,
          },
          {
            word: 'cool shit',
            count: 1,
          },
          {
            word: 'shit like',
            count: 1,
          },
          {
            word: 'like puma',
            count: 1,
          },
          {
            word: 'crown dont',
            count: 1,
          },
          {
            word: 'dont stress',
            count: 1,
          },
          {
            word: 'stress freeze',
            count: 1,
          },
          {
            word: 'freeze light',
            count: 1,
          },
          {
            word: 'light dear',
            count: 1,
          },
          {
            word: 'dear sputnik',
            count: 1,
          },
          {
            word: 'sputnik ghosting',
            count: 1,
          },
          {
            word: 'ghosting back',
            count: 1,
          },
          {
            word: 'back puma',
            count: 1,
          },
          {
            word: 'puma httpstcouzjmzrveq',
            count: 1,
          },
          {
            word: 'like ford',
            count: 1,
          },
          {
            word: 'ford puma',
            count: 1,
          },
          {
            word: 'nigerian restaurant',
            count: 1,
          },
          {
            word: 'restaurant area',
            count: 1,
          },
          {
            word: 'area puma',
            count: 1,
          },
          {
            word: 'puma filling',
            count: 1,
          },
          {
            word: 'filling station',
            count: 1,
          },
          {
            word: 'station next',
            count: 1,
          },
          {
            word: 'next zathu',
            count: 1,
          },
          {
            word: 'zathu trading',
            count: 1,
          },
          {
            word: 'trading jollof',
            count: 1,
          },
          {
            word: 'phone apple',
            count: 1,
          },
          {
            word: 'apple iphonecameranikon',
            count: 1,
          },
          {
            word: 'iphonecameranikon shoes',
            count: 1,
          },
          {
            word: 'shoes sketcherstshirt',
            count: 1,
          },
          {
            word: 'sketcherstshirt zara',
            count: 1,
          },
          {
            word: 'zara paint',
            count: 1,
          },
          {
            word: 'paint hampm',
            count: 1,
          },
          {
            word: 'hampm jacket',
            count: 1,
          },
          {
            word: 'treat puma',
            count: 1,
          },
          {
            word: 'puma promo',
            count: 1,
          },
          {
            word: 'promo dayhttpstcoizsgxesbh',
            count: 1,
          },
          {
            word: 'ambassador birthday',
            count: 1,
          },
          {
            word: 'birthday rinse',
            count: 1,
          },
          {
            word: 'rinse repeat',
            count: 1,
          },
          {
            word: 'repeat music',
            count: 1,
          },
          {
            word: 'music incoming',
            count: 1,
          },
          {
            word: 'delivered shoes',
            count: 1,
          },
          {
            word: 'shoes right',
            count: 1,
          },
          {
            word: 'right foot',
            count: 1,
          },
          {
            word: 'foot package',
            count: 1,
          },
          {
            word: 'package gift',
            count: 1,
          },
          {
            word: 'gift ruined',
            count: 1,
          },
          {
            word: 'ruined cancelling',
            count: 1,
          },
          {
            word: 'cancelling refund',
            count: 1,
          },
          {
            word: 'refund well',
            count: 1,
          },
          {
            word: 'well saying',
            count: 1,
          },
          {
            word: 'saying open',
            count: 1,
          },
          {
            word: 'open delivery',
            count: 1,
          },
          {
            word: 'delivery httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'drop yourshttpstcotalxjgacz',
            count: 1,
          },
          {
            word: 'goal japan',
            count: 1,
          },
          {
            word: 'japan beautiful',
            count: 1,
          },
          {
            word: 'names hippopotamus',
            count: 1,
          },
          {
            word: 'hippopotamus gorilla',
            count: 1,
          },
          {
            word: 'gorilla octopus',
            count: 1,
          },
          {
            word: 'octopus puma',
            count: 1,
          },
          {
            word: 'puma common',
            count: 1,
          },
          {
            word: 'common generic',
            count: 1,
          },
          {
            word: 'generic namesbut',
            count: 1,
          },
          {
            word: 'namesbut generic',
            count: 1,
          },
          {
            word: 'names long',
            count: 1,
          },
          {
            word: 'long complex',
            count: 1,
          },
          {
            word: 'complex hippopotamus',
            count: 1,
          },
          {
            word: 'years campaigning',
            count: 1,
          },
          {
            word: 'campaigning cost',
            count: 1,
          },
          {
            word: 'cost german',
            count: 1,
          },
          {
            word: 'german conglomerate',
            count: 1,
          },
          {
            word: 'conglomerate dearly',
            count: 1,
          },
          {
            word: 'dearly reputation',
            count: 1,
          },
          {
            word: 'reputation projects',
            count: 1,
          },
          {
            word: 'projects forced',
            count: 1,
          },
          {
            word: 'even young',
            count: 1,
          },
          {
            word: 'young adults',
            count: 1,
          },
          {
            word: 'adults taken',
            count: 1,
          },
          {
            word: 'taken considering',
            count: 1,
          },
          {
            word: 'considering case',
            count: 1,
          },
          {
            word: 'case puma',
            count: 1,
          },
          {
            word: 'puma killing',
            count: 1,
          },
          {
            word: 'killing meter',
            count: 1,
          },
          {
            word: 'meter alligator',
            count: 1,
          },
          {
            word: 'alligator land',
            count: 1,
          },
          {
            word: 'live puma',
            count: 1,
          },
          {
            word: 'stewie httpstcotygduzrbtry',
            count: 1,
          },
          {
            word: 'httpstcotygduzrbtry code',
            count: 1,
          },
          {
            word: 'sneakers httpstcokpgcwaqho',
            count: 1,
          },
          {
            word: 'news httpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'ford revealed',
            count: 1,
          },
          {
            word: 'revealed updated',
            count: 1,
          },
          {
            word: 'updated puma',
            count: 1,
          },
          {
            word: 'puma giving',
            count: 1,
          },
          {
            word: 'giving greater',
            count: 1,
          },
          {
            word: 'greater connectivity',
            count: 1,
          },
          {
            word: 'connectivity larger',
            count: 1,
          },
          {
            word: 'larger infotainment',
            count: 1,
          },
          {
            word: 'infotainment screen',
            count: 1,
          },
          {
            word: 'screen well',
            count: 1,
          },
          {
            word: 'well enhanced',
            count: 1,
          },
          {
            word: 'enhanced digital',
            count: 1,
          },
          {
            word: 'digital drivers',
            count: 1,
          },
          {
            word: 'drivers display',
            count: 1,
          },
          {
            word: 'display find',
            count: 1,
          },
          {
            word: 'find news',
            count: 1,
          },
          {
            word: 'news story',
            count: 1,
          },
          {
            word: 'story httpstcozxmarnbsj',
            count: 1,
          },
          {
            word: 'httpstcozxmarnbsj httpstcoxdldxpwa',
            count: 1,
          },
          {
            word: 'httpstcoolcsrsxqx httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'httpstcoolcsrsxqx httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'dash puma',
            count: 1,
          },
          {
            word: 'puma place',
            count: 1,
          },
          {
            word: 'place order',
            count: 1,
          },
          {
            word: 'order httpstcopwurqgmc',
            count: 1,
          },
          {
            word: 'puma socks',
            count: 1,
          },
          {
            word: 'socks available',
            count: 1,
          },
          {
            word: 'available women',
            count: 1,
          },
          {
            word: 'women pack',
            count: 1,
          },
          {
            word: 'pack send',
            count: 1,
          },
          {
            word: 'send visit',
            count: 1,
          },
          {
            word: 'visit website',
            count: 1,
          },
          {
            word: 'website httpstcoixdocyvk',
            count: 1,
          },
          {
            word: 'news httpstcomvnum',
            count: 1,
          },
          {
            word: 'httpstcoowfflyfzjxp httpstcopqbjaksddwt',
            count: 1,
          },
          {
            word: 'httpstcopqbjaksddwt httpstcomkgmpurqniwmns',
            count: 1,
          },
          {
            word: 'httpstcomkgmpurqniwmns puma',
            count: 1,
          },
          {
            word: 'stewie amor',
            count: 1,
          },
          {
            word: 'amor httpstcomuvnkyhytry',
            count: 1,
          },
          {
            word: 'httpstcomuvnkyhytry code',
            count: 1,
          },
          {
            word: 'sneakers httpstconfymgs',
            count: 1,
          },
          {
            word: 'puma hoodie',
            count: 1,
          },
          {
            word: 'hoodie looks',
            count: 1,
          },
          {
            word: 'looks like',
            count: 1,
          },
          {
            word: 'like someone',
            count: 1,
          },
          {
            word: 'someone grabbed',
            count: 1,
          },
          {
            word: 'grabbed fill',
            count: 1,
          },
          {
            word: 'fill seat',
            count: 1,
          },
          {
            word: 'seat waiting',
            count: 1,
          },
          {
            word: 'waiting didi',
            count: 1,
          },
          {
            word: 'didi hamann',
            count: 1,
          },
          {
            word: 'hamann arrive',
            count: 1,
          },
          {
            word: 'daejeon home',
            count: 1,
          },
          {
            word: 'home away',
            count: 1,
          },
          {
            word: 'away ktis',
            count: 1,
          },
          {
            word: 'ktis puma',
            count: 1,
          },
          {
            word: 'puma httpstcodwrnlkbgov',
            count: 1,
          },
          {
            word: 'score airdrop',
            count: 1,
          },
          {
            word: 'airdrop event',
            count: 1,
          },
          {
            word: 'event future',
            count: 1,
          },
          {
            word: 'future airdrop',
            count: 1,
          },
          {
            word: 'airdrop miss',
            count: 1,
          },
          {
            word: 'miss remember',
            count: 1,
          },
          {
            word: 'remember invitation',
            count: 1,
          },
          {
            word: 'invitation code',
            count: 1,
          },
          {
            word: 'code boost',
            count: 1,
          },
          {
            word: 'boost score',
            count: 1,
          },
          {
            word: 'fair point',
            count: 1,
          },
          {
            word: 'point rollout',
            count: 1,
          },
          {
            word: 'rollout scalewise',
            count: 1,
          },
          {
            word: 'scalewise reason',
            count: 1,
          },
          {
            word: 'reason seem',
            count: 1,
          },
          {
            word: 'seem befit',
            count: 1,
          },
          {
            word: 'befit goat',
            count: 1,
          },
          {
            word: 'goat sport',
            count: 1,
          },
          {
            word: 'sport imagine',
            count: 1,
          },
          {
            word: 'imagine nike',
            count: 1,
          },
          {
            word: 'adidas would',
            count: 1,
          },
          {
            word: 'would pulling',
            count: 1,
          },
          {
            word: 'pulling stops',
            count: 1,
          },
          {
            word: 'stops logo',
            count: 1,
          },
          {
            word: 'logo branding',
            count: 1,
          },
          {
            word: 'branding buzz',
            count: 1,
          },
          {
            word: 'buzz social',
            count: 1,
          },
          {
            word: 'social puts',
            count: 1,
          },
          {
            word: 'puts three',
            count: 1,
          },
          {
            word: 'three decadelong',
            count: 1,
          },
          {
            word: 'decadelong erstwhile',
            count: 1,
          },
          {
            word: 'erstwhile partnership',
            count: 1,
          },
          {
            word: 'partnership nike',
            count: 1,
          },
          {
            word: 'nike httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'news httpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'human toilet',
            count: 1,
          },
          {
            word: 'toilet nasty',
            count: 1,
          },
          {
            word: 'nasty sitting',
            count: 1,
          },
          {
            word: 'sitting waiting',
            count: 1,
          },
          {
            word: 'waiting collect',
            count: 1,
          },
          {
            word: 'collect piss',
            count: 1,
          },
          {
            word: 'piss shit',
            count: 1,
          },
          {
            word: 'shit souvenir',
            count: 1,
          },
          {
            word: 'souvenir ultimate',
            count: 1,
          },
          {
            word: 'ultimate predator',
            count: 1,
          },
          {
            word: 'predator puma',
            count: 1,
          },
          {
            word: 'puma femdom',
            count: 1,
          },
          {
            word: 'femdom beta',
            count: 1,
          },
          {
            word: 'beta cuck',
            count: 1,
          },
          {
            word: 'cuck loser',
            count: 1,
          },
          {
            word: 'loser white',
            count: 1,
          },
          {
            word: 'white charlotte',
            count: 1,
          },
          {
            word: 'charlotte greensboro',
            count: 1,
          },
          {
            word: 'greensboro raleigh',
            count: 1,
          },
          {
            word: 'raleigh rock',
            count: 1,
          },
          {
            word: 'rock hill',
            count: 1,
          },
          {
            word: 'hill durham',
            count: 1,
          },
          {
            word: 'durham brattylatina',
            count: 1,
          },
          {
            word: 'brattylatina latinaslut',
            count: 1,
          },
          {
            word: 'latinaslut morena',
            count: 1,
          },
          {
            word: 'morena thick',
            count: 1,
          },
          {
            word: 'thick httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'httpstcoldfmmz httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'puma suede',
            count: 1,
          },
          {
            word: 'suede size',
            count: 1,
          },
          {
            word: 'size delivery',
            count: 1,
          },
          {
            word: 'delivery within',
            count: 1,
          },
          {
            word: 'within nairobi',
            count: 1,
          },
          {
            word: 'nairobi pickup',
            count: 1,
          },
          {
            word: 'pickup mtaani',
            count: 1,
          },
          {
            word: 'mtaani services',
            count: 1,
          },
          {
            word: 'services available',
            count: 1,
          },
          {
            word: 'available httpstcocyxgdzxlb',
            count: 1,
          },
          {
            word: 'like want',
            count: 1,
          },
          {
            word: 'want puma',
            count: 1,
          },
          {
            word: 'puma peanits',
            count: 1,
          },
          {
            word: 'peanits mouth',
            count: 1,
          },
          {
            word: 'puma eachmeet',
            count: 1,
          },
          {
            word: 'eachmeet concepm',
            count: 1,
          },
          {
            word: 'concepm interested',
            count: 1,
          },
          {
            word: 'interested httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'always puma',
            count: 1,
          },
          {
            word: 'puma super',
            count: 1,
          },
          {
            word: 'super comfy',
            count: 1,
          },
          {
            word: 'comfy httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'puma httpstcokcitcynua',
            count: 1,
          },
          {
            word: 'puma swede',
            count: 1,
          },
          {
            word: 'swede angel',
            count: 1,
          },
          {
            word: 'angel long',
            count: 1,
          },
          {
            word: 'long diamond',
            count: 1,
          },
          {
            word: 'diamond angela',
            count: 1,
          },
          {
            word: 'angela aspen',
            count: 1,
          },
          {
            word: 'aspen kinky',
            count: 1,
          },
          {
            word: 'kinky pussyeating',
            count: 1,
          },
          {
            word: 'pussyeating milf',
            count: 1,
          },
          {
            word: 'milf sexparty',
            count: 1,
          },
          {
            word: 'sexparty everything',
            count: 1,
          },
          {
            word: 'everything allowed',
            count: 1,
          },
          {
            word: 'news httpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'httpstcoutmvbes puma',
            count: 1,
          },
          {
            word: 'puma hardchorus',
            count: 1,
          },
          {
            word: 'hardchorus full',
            count: 1,
          },
          {
            word: 'full european',
            count: 1,
          },
          {
            word: 'european version',
            count: 1,
          },
          {
            word: 'version anyone',
            count: 1,
          },
          {
            word: 'anyone interested',
            count: 1,
          },
          {
            word: 'news httpstcodulysnr',
            count: 1,
          },
          {
            word: 'quality puma',
            count: 1,
          },
          {
            word: 'puma tracksuit',
            count: 1,
          },
          {
            word: 'tracksuit size',
            count: 1,
          },
          {
            word: 'size dmwhatsapp',
            count: 1,
          },
          {
            word: 'dmwhatsapp httpstcoopbczgsnx',
            count: 1,
          },
          {
            word: 'httpstcoopbczgsnx nationwide',
            count: 1,
          },
          {
            word: 'nationwide delivery',
            count: 1,
          },
          {
            word: 'delivery help',
            count: 1,
          },
          {
            word: 'help repost',
            count: 1,
          },
          {
            word: 'repost httpstcosjfgrkrui',
            count: 1,
          },
          {
            word: 'peekaboo httpstcojnmcznni',
            count: 1,
          },
          {
            word: 'ball unveil',
            count: 1,
          },
          {
            word: 'unveil highly',
            count: 1,
          },
          {
            word: 'highly anticipated',
            count: 1,
          },
          {
            word: 'anticipated porsche',
            count: 1,
          },
          {
            word: 'porsche collection',
            count: 1,
          },
          {
            word: 'collection launching',
            count: 1,
          },
          {
            word: 'launching february',
            count: 1,
          },
          {
            word: 'puma smash',
            count: 1,
          },
          {
            word: 'smash leather',
            count: 1,
          },
          {
            word: 'leather white',
            count: 1,
          },
          {
            word: 'white retail',
            count: 1,
          },
          {
            word: 'retail mandmdirect',
            count: 1,
          },
          {
            word: 'mandmdirect sale',
            count: 1,
          },
          {
            word: 'sale price',
            count: 1,
          },
          {
            word: 'price drop',
            count: 1,
          },
          {
            word: 'drop link',
            count: 1,
          },
          {
            word: 'link httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq sizes',
            count: 1,
          },
          {
            word: 'sizes httpstcowtcknauzu',
            count: 1,
          },
          {
            word: 'cook unethical',
            count: 1,
          },
          {
            word: 'unethical consumption',
            count: 1,
          },
          {
            word: 'consumption pretending',
            count: 1,
          },
          {
            word: 'pretending like',
            count: 1,
          },
          {
            word: 'like official',
            count: 1,
          },
          {
            word: 'official nike',
            count: 1,
          },
          {
            word: 'adidas garbage',
            count: 1,
          },
          {
            word: 'garbage dense',
            count: 1,
          },
          {
            word: 'puma photocardrm',
            count: 1,
          },
          {
            word: 'photocardrm eachmeet',
            count: 1,
          },
          {
            word: 'eachmeet concepostage',
            count: 1,
          },
          {
            word: 'concepostage want',
            count: 1,
          },
          {
            word: 'want guys',
            count: 1,
          },
          {
            word: 'guys httpstcoevkevv',
            count: 1,
          },
          {
            word: 'bungeopang niki',
            count: 1,
          },
          {
            word: 'niki enhypenas',
            count: 1,
          },
          {
            word: 'enhypenas stars',
            count: 1,
          },
          {
            word: 'stars alligned',
            count: 1,
          },
          {
            word: 'alligned dashing',
            count: 1,
          },
          {
            word: 'dashing vampire',
            count: 1,
          },
          {
            word: 'vampire appeared',
            count: 1,
          },
          {
            word: 'appeared turned',
            count: 1,
          },
          {
            word: 'turned beautiful',
            count: 1,
          },
          {
            word: 'beautiful ever',
            count: 1,
          },
          {
            word: 'ever live',
            count: 1,
          },
          {
            word: 'live earth',
            count: 1,
          },
          {
            word: 'earth dear',
            count: 1,
          },
          {
            word: 'bungeopang love',
            count: 1,
          },
          {
            word: 'love solace',
            count: 1,
          },
          {
            word: 'solace niki',
            count: 1,
          },
          {
            word: 'niki hope',
            count: 1,
          },
          {
            word: 'hope resting',
            count: 1,
          },
          {
            word: 'resting well',
            count: 1,
          },
          {
            word: 'well httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
        trigram_word_cloud: [
          {
            word: 'fellow puma global',
            count: 16,
          },
          {
            word: 'puma global ambassador',
            count: 16,
          },
          {
            word: 'davido performing birthday',
            count: 15,
          },
          {
            word: 'performing birthday party',
            count: 15,
          },
          {
            word: 'birthday party athletico',
            count: 15,
          },
          {
            word: 'party athletico madrid',
            count: 15,
          },
          {
            word: 'athletico madrid player',
            count: 15,
          },
          {
            word: 'madrid player fellow',
            count: 15,
          },
          {
            word: 'player fellow puma',
            count: 15,
          },
          {
            word: 'global ambassador memphis',
            count: 15,
          },
          {
            word: 'ambassador memphis depay',
            count: 15,
          },
          {
            word: 'torc unmanned turret',
            count: 5,
          },
          {
            word: 'unmanned turret designed',
            count: 5,
          },
          {
            word: 'turret designed brazil',
            count: 5,
          },
          {
            word: 'designed brazil turret',
            count: 5,
          },
          {
            word: 'brazil turret brings',
            count: 5,
          },
          {
            word: 'turret brings vehicle',
            count: 5,
          },
          {
            word: 'brings vehicle level',
            count: 5,
          },
          {
            word: 'vehicle level firepower',
            count: 5,
          },
          {
            word: 'level firepower comparable',
            count: 5,
          },
          {
            word: 'reminder extinct animals',
            count: 4,
          },
          {
            word: 'extinct animals dont',
            count: 4,
          },
          {
            word: 'animals dont need',
            count: 4,
          },
          {
            word: 'dont need common',
            count: 4,
          },
          {
            word: 'need common names',
            count: 4,
          },
          {
            word: 'common names generic',
            count: 4,
          },
          {
            word: 'names generic names',
            count: 4,
          },
          {
            word: 'generic names work',
            count: 4,
          },
          {
            word: 'names work perfectly',
            count: 4,
          },
          {
            word: 'work perfectly fine',
            count: 4,
          },
          {
            word: 'perfectly fine common',
            count: 4,
          },
          {
            word: 'fine common names',
            count: 4,
          },
          {
            word: 'ordered pair puma',
            count: 2,
          },
          {
            word: 'pair puma shoes',
            count: 2,
          },
          {
            word: 'puma shoes birthday',
            count: 2,
          },
          {
            word: 'shoes birthday gift',
            count: 2,
          },
          {
            word: 'birthday gift found',
            count: 2,
          },
          {
            word: 'gift found flipkart',
            count: 2,
          },
          {
            word: 'found flipkart delivered',
            count: 2,
          },
          {
            word: 'kookie holding jinnies',
            count: 2,
          },
          {
            word: 'holding jinnies jacketso',
            count: 2,
          },
          {
            word: 'jinnies jacketso adveising',
            count: 2,
          },
          {
            word: 'jacketso adveising photo',
            count: 2,
          },
          {
            word: 'adveising photo shooting',
            count: 2,
          },
          {
            word: 'photo shooting behind',
            count: 2,
          },
          {
            word: 'shooting behind httpstcorexihih',
            count: 2,
          },
          {
            word: 'happy valentines httpstcoiirruv',
            count: 2,
          },
          {
            word: 'news lamelo ball',
            count: 2,
          },
          {
            word: 'lamelo ball puma',
            count: 2,
          },
          {
            word: 'ball puma porsche',
            count: 2,
          },
          {
            word: 'puma porsche step',
            count: 2,
          },
          {
            word: 'porsche step fast',
            count: 2,
          },
          {
            word: 'step fast lanelink',
            count: 2,
          },
          {
            word: 'fast lanelink httpstcoolcsrsxqx',
            count: 2,
          },
          {
            word: 'almost live snswmns',
            count: 2,
          },
          {
            word: 'live snswmns jordan',
            count: 2,
          },
          {
            word: 'snswmns jordan retro',
            count: 2,
          },
          {
            word: 'jordan retro high',
            count: 2,
          },
          {
            word: 'retro high whitemetallic',
            count: 2,
          },
          {
            word: 'high whitemetallic goldw',
            count: 2,
          },
          {
            word: 'whitemetallic goldw httpstcoowfflyfzjxp',
            count: 2,
          },
          {
            word: 'miharu hanai wmns',
            count: 2,
          },
          {
            word: 'hanai wmns puma',
            count: 2,
          },
          {
            word: 'wmns puma mayze',
            count: 2,
          },
          {
            word: 'puma mayze thrifted',
            count: 2,
          },
          {
            word: 'mayze thrifted white',
            count: 2,
          },
          {
            word: 'thrifted white little',
            count: 2,
          },
          {
            word: 'white little sister',
            count: 2,
          },
          {
            word: 'little sister httpstcouppihntdn',
            count: 2,
          },
          {
            word: 'nike puma adidas',
            count: 2,
          },
          {
            word: 'dear duck puma',
            count: 2,
          },
          {
            word: 'duck puma bungeopang',
            count: 2,
          },
          {
            word: 'nooits puma httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'check puma enzo',
            count: 1,
          },
          {
            word: 'puma enzo weave',
            count: 1,
          },
          {
            word: 'enzo weave price',
            count: 1,
          },
          {
            word: 'weave price reduced',
            count: 1,
          },
          {
            word: 'price reduced httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'goldrandlösung cool shit',
            count: 1,
          },
          {
            word: 'cool shit like',
            count: 1,
          },
          {
            word: 'shit like puma',
            count: 1,
          },
          {
            word: 'crown dont stress',
            count: 1,
          },
          {
            word: 'dont stress freeze',
            count: 1,
          },
          {
            word: 'stress freeze light',
            count: 1,
          },
          {
            word: 'freeze light dear',
            count: 1,
          },
          {
            word: 'light dear sputnik',
            count: 1,
          },
          {
            word: 'dear sputnik ghosting',
            count: 1,
          },
          {
            word: 'sputnik ghosting back',
            count: 1,
          },
          {
            word: 'ghosting back puma',
            count: 1,
          },
          {
            word: 'back puma httpstcouzjmzrveq',
            count: 1,
          },
          {
            word: 'like ford puma',
            count: 1,
          },
          {
            word: 'nigerian restaurant area',
            count: 1,
          },
          {
            word: 'restaurant area puma',
            count: 1,
          },
          {
            word: 'area puma filling',
            count: 1,
          },
          {
            word: 'puma filling station',
            count: 1,
          },
          {
            word: 'filling station next',
            count: 1,
          },
          {
            word: 'station next zathu',
            count: 1,
          },
          {
            word: 'next zathu trading',
            count: 1,
          },
          {
            word: 'zathu trading jollof',
            count: 1,
          },
          {
            word: 'phone apple iphonecameranikon',
            count: 1,
          },
          {
            word: 'apple iphonecameranikon shoes',
            count: 1,
          },
          {
            word: 'iphonecameranikon shoes sketcherstshirt',
            count: 1,
          },
          {
            word: 'shoes sketcherstshirt zara',
            count: 1,
          },
          {
            word: 'sketcherstshirt zara paint',
            count: 1,
          },
          {
            word: 'zara paint hampm',
            count: 1,
          },
          {
            word: 'paint hampm jacket',
            count: 1,
          },
          {
            word: 'treat puma promo',
            count: 1,
          },
          {
            word: 'puma promo dayhttpstcoizsgxesbh',
            count: 1,
          },
          {
            word: 'global ambassador birthday',
            count: 1,
          },
          {
            word: 'ambassador birthday rinse',
            count: 1,
          },
          {
            word: 'birthday rinse repeat',
            count: 1,
          },
          {
            word: 'rinse repeat music',
            count: 1,
          },
          {
            word: 'repeat music incoming',
            count: 1,
          },
          {
            word: 'flipkart delivered shoes',
            count: 1,
          },
          {
            word: 'delivered shoes right',
            count: 1,
          },
          {
            word: 'shoes right foot',
            count: 1,
          },
          {
            word: 'right foot package',
            count: 1,
          },
          {
            word: 'foot package gift',
            count: 1,
          },
          {
            word: 'package gift ruined',
            count: 1,
          },
          {
            word: 'gift ruined cancelling',
            count: 1,
          },
          {
            word: 'ruined cancelling refund',
            count: 1,
          },
          {
            word: 'cancelling refund well',
            count: 1,
          },
          {
            word: 'refund well saying',
            count: 1,
          },
          {
            word: 'well saying open',
            count: 1,
          },
          {
            word: 'saying open delivery',
            count: 1,
          },
          {
            word: 'open delivery httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'goal japan beautiful',
            count: 1,
          },
          {
            word: 'common names hippopotamus',
            count: 1,
          },
          {
            word: 'names hippopotamus gorilla',
            count: 1,
          },
          {
            word: 'hippopotamus gorilla octopus',
            count: 1,
          },
          {
            word: 'gorilla octopus puma',
            count: 1,
          },
          {
            word: 'octopus puma common',
            count: 1,
          },
          {
            word: 'puma common generic',
            count: 1,
          },
          {
            word: 'common generic namesbut',
            count: 1,
          },
          {
            word: 'generic namesbut generic',
            count: 1,
          },
          {
            word: 'namesbut generic names',
            count: 1,
          },
          {
            word: 'generic names long',
            count: 1,
          },
          {
            word: 'names long complex',
            count: 1,
          },
          {
            word: 'long complex hippopotamus',
            count: 1,
          },
          {
            word: 'years campaigning cost',
            count: 1,
          },
          {
            word: 'campaigning cost german',
            count: 1,
          },
          {
            word: 'cost german conglomerate',
            count: 1,
          },
          {
            word: 'german conglomerate dearly',
            count: 1,
          },
          {
            word: 'conglomerate dearly reputation',
            count: 1,
          },
          {
            word: 'dearly reputation projects',
            count: 1,
          },
          {
            word: 'reputation projects forced',
            count: 1,
          },
          {
            word: 'even young adults',
            count: 1,
          },
          {
            word: 'young adults taken',
            count: 1,
          },
          {
            word: 'adults taken considering',
            count: 1,
          },
          {
            word: 'taken considering case',
            count: 1,
          },
          {
            word: 'considering case puma',
            count: 1,
          },
          {
            word: 'case puma killing',
            count: 1,
          },
          {
            word: 'puma killing meter',
            count: 1,
          },
          {
            word: 'killing meter alligator',
            count: 1,
          },
          {
            word: 'meter alligator land',
            count: 1,
          },
          {
            word: 'live puma stewie',
            count: 1,
          },
          {
            word: 'puma stewie httpstcotygduzrbtry',
            count: 1,
          },
          {
            word: 'stewie httpstcotygduzrbtry code',
            count: 1,
          },
          {
            word: 'httpstcotygduzrbtry code sneakers',
            count: 1,
          },
          {
            word: 'code sneakers httpstcokpgcwaqho',
            count: 1,
          },
          {
            word: 'fake news httpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'ford revealed updated',
            count: 1,
          },
          {
            word: 'revealed updated puma',
            count: 1,
          },
          {
            word: 'updated puma giving',
            count: 1,
          },
          {
            word: 'puma giving greater',
            count: 1,
          },
          {
            word: 'giving greater connectivity',
            count: 1,
          },
          {
            word: 'greater connectivity larger',
            count: 1,
          },
          {
            word: 'connectivity larger infotainment',
            count: 1,
          },
          {
            word: 'larger infotainment screen',
            count: 1,
          },
          {
            word: 'infotainment screen well',
            count: 1,
          },
          {
            word: 'screen well enhanced',
            count: 1,
          },
          {
            word: 'well enhanced digital',
            count: 1,
          },
          {
            word: 'enhanced digital drivers',
            count: 1,
          },
          {
            word: 'digital drivers display',
            count: 1,
          },
          {
            word: 'drivers display find',
            count: 1,
          },
          {
            word: 'display find news',
            count: 1,
          },
          {
            word: 'find news story',
            count: 1,
          },
          {
            word: 'news story httpstcozxmarnbsj',
            count: 1,
          },
          {
            word: 'story httpstcozxmarnbsj httpstcoxdldxpwa',
            count: 1,
          },
          {
            word: 'lanelink httpstcoolcsrsxqx httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'lanelink httpstcoolcsrsxqx httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'dash puma place',
            count: 1,
          },
          {
            word: 'puma place order',
            count: 1,
          },
          {
            word: 'place order httpstcopwurqgmc',
            count: 1,
          },
          {
            word: 'puma socks available',
            count: 1,
          },
          {
            word: 'socks available women',
            count: 1,
          },
          {
            word: 'available women pack',
            count: 1,
          },
          {
            word: 'women pack send',
            count: 1,
          },
          {
            word: 'pack send visit',
            count: 1,
          },
          {
            word: 'send visit website',
            count: 1,
          },
          {
            word: 'visit website httpstcoixdocyvk',
            count: 1,
          },
          {
            word: 'fake news httpstcomvnum',
            count: 1,
          },
          {
            word: 'goldw httpstcoowfflyfzjxp httpstcopqbjaksddwt',
            count: 1,
          },
          {
            word: 'httpstcoowfflyfzjxp httpstcopqbjaksddwt httpstcomkgmpurqniwmns',
            count: 1,
          },
          {
            word: 'httpstcopqbjaksddwt httpstcomkgmpurqniwmns puma',
            count: 1,
          },
          {
            word: 'httpstcomkgmpurqniwmns puma stewie',
            count: 1,
          },
          {
            word: 'puma stewie amor',
            count: 1,
          },
          {
            word: 'stewie amor httpstcomuvnkyhytry',
            count: 1,
          },
          {
            word: 'amor httpstcomuvnkyhytry code',
            count: 1,
          },
          {
            word: 'httpstcomuvnkyhytry code sneakers',
            count: 1,
          },
          {
            word: 'code sneakers httpstconfymgs',
            count: 1,
          },
          {
            word: 'puma hoodie looks',
            count: 1,
          },
          {
            word: 'hoodie looks like',
            count: 1,
          },
          {
            word: 'looks like someone',
            count: 1,
          },
          {
            word: 'like someone grabbed',
            count: 1,
          },
          {
            word: 'someone grabbed fill',
            count: 1,
          },
          {
            word: 'grabbed fill seat',
            count: 1,
          },
          {
            word: 'fill seat waiting',
            count: 1,
          },
          {
            word: 'seat waiting didi',
            count: 1,
          },
          {
            word: 'waiting didi hamann',
            count: 1,
          },
          {
            word: 'didi hamann arrive',
            count: 1,
          },
          {
            word: 'daejeon home away',
            count: 1,
          },
          {
            word: 'home away ktis',
            count: 1,
          },
          {
            word: 'away ktis puma',
            count: 1,
          },
          {
            word: 'ktis puma httpstcodwrnlkbgov',
            count: 1,
          },
          {
            word: 'score airdrop event',
            count: 1,
          },
          {
            word: 'airdrop event future',
            count: 1,
          },
          {
            word: 'event future airdrop',
            count: 1,
          },
          {
            word: 'future airdrop miss',
            count: 1,
          },
          {
            word: 'airdrop miss remember',
            count: 1,
          },
          {
            word: 'miss remember invitation',
            count: 1,
          },
          {
            word: 'remember invitation code',
            count: 1,
          },
          {
            word: 'invitation code boost',
            count: 1,
          },
          {
            word: 'code boost score',
            count: 1,
          },
          {
            word: 'fair point rollout',
            count: 1,
          },
          {
            word: 'point rollout scalewise',
            count: 1,
          },
          {
            word: 'rollout scalewise reason',
            count: 1,
          },
          {
            word: 'scalewise reason seem',
            count: 1,
          },
          {
            word: 'reason seem befit',
            count: 1,
          },
          {
            word: 'seem befit goat',
            count: 1,
          },
          {
            word: 'befit goat sport',
            count: 1,
          },
          {
            word: 'goat sport imagine',
            count: 1,
          },
          {
            word: 'sport imagine nike',
            count: 1,
          },
          {
            word: 'imagine nike puma',
            count: 1,
          },
          {
            word: 'puma adidas would',
            count: 1,
          },
          {
            word: 'adidas would pulling',
            count: 1,
          },
          {
            word: 'would pulling stops',
            count: 1,
          },
          {
            word: 'pulling stops logo',
            count: 1,
          },
          {
            word: 'stops logo branding',
            count: 1,
          },
          {
            word: 'logo branding buzz',
            count: 1,
          },
          {
            word: 'branding buzz social',
            count: 1,
          },
          {
            word: 'buzz social puts',
            count: 1,
          },
          {
            word: 'social puts three',
            count: 1,
          },
          {
            word: 'puts three decadelong',
            count: 1,
          },
          {
            word: 'three decadelong erstwhile',
            count: 1,
          },
          {
            word: 'decadelong erstwhile partnership',
            count: 1,
          },
          {
            word: 'erstwhile partnership nike',
            count: 1,
          },
          {
            word: 'partnership nike httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'fake news httpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'human toilet nasty',
            count: 1,
          },
          {
            word: 'toilet nasty sitting',
            count: 1,
          },
          {
            word: 'nasty sitting waiting',
            count: 1,
          },
          {
            word: 'sitting waiting collect',
            count: 1,
          },
          {
            word: 'waiting collect piss',
            count: 1,
          },
          {
            word: 'collect piss shit',
            count: 1,
          },
          {
            word: 'piss shit souvenir',
            count: 1,
          },
          {
            word: 'shit souvenir ultimate',
            count: 1,
          },
          {
            word: 'souvenir ultimate predator',
            count: 1,
          },
          {
            word: 'ultimate predator puma',
            count: 1,
          },
          {
            word: 'predator puma femdom',
            count: 1,
          },
          {
            word: 'puma femdom beta',
            count: 1,
          },
          {
            word: 'femdom beta cuck',
            count: 1,
          },
          {
            word: 'beta cuck loser',
            count: 1,
          },
          {
            word: 'cuck loser white',
            count: 1,
          },
          {
            word: 'loser white charlotte',
            count: 1,
          },
          {
            word: 'white charlotte greensboro',
            count: 1,
          },
          {
            word: 'charlotte greensboro raleigh',
            count: 1,
          },
          {
            word: 'greensboro raleigh rock',
            count: 1,
          },
          {
            word: 'raleigh rock hill',
            count: 1,
          },
          {
            word: 'rock hill durham',
            count: 1,
          },
          {
            word: 'hill durham brattylatina',
            count: 1,
          },
          {
            word: 'durham brattylatina latinaslut',
            count: 1,
          },
          {
            word: 'brattylatina latinaslut morena',
            count: 1,
          },
          {
            word: 'latinaslut morena thick',
            count: 1,
          },
          {
            word: 'morena thick httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'thick httpstcoldfmmz httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'puma suede size',
            count: 1,
          },
          {
            word: 'suede size delivery',
            count: 1,
          },
          {
            word: 'size delivery within',
            count: 1,
          },
          {
            word: 'delivery within nairobi',
            count: 1,
          },
          {
            word: 'within nairobi pickup',
            count: 1,
          },
          {
            word: 'nairobi pickup mtaani',
            count: 1,
          },
          {
            word: 'pickup mtaani services',
            count: 1,
          },
          {
            word: 'mtaani services available',
            count: 1,
          },
          {
            word: 'services available httpstcocyxgdzxlb',
            count: 1,
          },
          {
            word: 'like want puma',
            count: 1,
          },
          {
            word: 'want puma peanits',
            count: 1,
          },
          {
            word: 'puma peanits mouth',
            count: 1,
          },
          {
            word: 'puma eachmeet concepm',
            count: 1,
          },
          {
            word: 'eachmeet concepm interested',
            count: 1,
          },
          {
            word: 'concepm interested httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'always puma super',
            count: 1,
          },
          {
            word: 'puma super comfy',
            count: 1,
          },
          {
            word: 'super comfy httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'puma swede angel',
            count: 1,
          },
          {
            word: 'swede angel long',
            count: 1,
          },
          {
            word: 'angel long diamond',
            count: 1,
          },
          {
            word: 'long diamond angela',
            count: 1,
          },
          {
            word: 'diamond angela aspen',
            count: 1,
          },
          {
            word: 'angela aspen kinky',
            count: 1,
          },
          {
            word: 'aspen kinky pussyeating',
            count: 1,
          },
          {
            word: 'kinky pussyeating milf',
            count: 1,
          },
          {
            word: 'pussyeating milf sexparty',
            count: 1,
          },
          {
            word: 'milf sexparty everything',
            count: 1,
          },
          {
            word: 'sexparty everything allowed',
            count: 1,
          },
          {
            word: 'fake news httpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'httpstcoutmvbes puma hardchorus',
            count: 1,
          },
          {
            word: 'puma hardchorus full',
            count: 1,
          },
          {
            word: 'hardchorus full european',
            count: 1,
          },
          {
            word: 'full european version',
            count: 1,
          },
          {
            word: 'european version anyone',
            count: 1,
          },
          {
            word: 'version anyone interested',
            count: 1,
          },
          {
            word: 'fake news httpstcodulysnr',
            count: 1,
          },
          {
            word: 'quality puma tracksuit',
            count: 1,
          },
          {
            word: 'puma tracksuit size',
            count: 1,
          },
          {
            word: 'tracksuit size dmwhatsapp',
            count: 1,
          },
          {
            word: 'size dmwhatsapp httpstcoopbczgsnx',
            count: 1,
          },
          {
            word: 'dmwhatsapp httpstcoopbczgsnx nationwide',
            count: 1,
          },
          {
            word: 'httpstcoopbczgsnx nationwide delivery',
            count: 1,
          },
          {
            word: 'nationwide delivery help',
            count: 1,
          },
          {
            word: 'delivery help repost',
            count: 1,
          },
          {
            word: 'help repost httpstcosjfgrkrui',
            count: 1,
          },
          {
            word: 'lamelo ball unveil',
            count: 1,
          },
          {
            word: 'ball unveil highly',
            count: 1,
          },
          {
            word: 'unveil highly anticipated',
            count: 1,
          },
          {
            word: 'highly anticipated porsche',
            count: 1,
          },
          {
            word: 'anticipated porsche collection',
            count: 1,
          },
          {
            word: 'porsche collection launching',
            count: 1,
          },
          {
            word: 'collection launching february',
            count: 1,
          },
          {
            word: 'puma smash leather',
            count: 1,
          },
          {
            word: 'smash leather white',
            count: 1,
          },
          {
            word: 'leather white retail',
            count: 1,
          },
          {
            word: 'white retail mandmdirect',
            count: 1,
          },
          {
            word: 'retail mandmdirect sale',
            count: 1,
          },
          {
            word: 'mandmdirect sale price',
            count: 1,
          },
          {
            word: 'sale price drop',
            count: 1,
          },
          {
            word: 'price drop link',
            count: 1,
          },
          {
            word: 'drop link httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'link httpstcozapjuehdq sizes',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq sizes httpstcowtcknauzu',
            count: 1,
          },
          {
            word: 'cook unethical consumption',
            count: 1,
          },
          {
            word: 'unethical consumption pretending',
            count: 1,
          },
          {
            word: 'consumption pretending like',
            count: 1,
          },
          {
            word: 'pretending like official',
            count: 1,
          },
          {
            word: 'like official nike',
            count: 1,
          },
          {
            word: 'official nike puma',
            count: 1,
          },
          {
            word: 'puma adidas garbage',
            count: 1,
          },
          {
            word: 'adidas garbage dense',
            count: 1,
          },
          {
            word: 'puma photocardrm eachmeet',
            count: 1,
          },
          {
            word: 'photocardrm eachmeet concepostage',
            count: 1,
          },
          {
            word: 'eachmeet concepostage want',
            count: 1,
          },
          {
            word: 'concepostage want guys',
            count: 1,
          },
          {
            word: 'want guys httpstcoevkevv',
            count: 1,
          },
          {
            word: 'puma bungeopang niki',
            count: 1,
          },
          {
            word: 'bungeopang niki enhypenas',
            count: 1,
          },
          {
            word: 'niki enhypenas stars',
            count: 1,
          },
          {
            word: 'enhypenas stars alligned',
            count: 1,
          },
          {
            word: 'stars alligned dashing',
            count: 1,
          },
          {
            word: 'alligned dashing vampire',
            count: 1,
          },
          {
            word: 'dashing vampire appeared',
            count: 1,
          },
          {
            word: 'vampire appeared turned',
            count: 1,
          },
          {
            word: 'appeared turned beautiful',
            count: 1,
          },
          {
            word: 'turned beautiful ever',
            count: 1,
          },
          {
            word: 'beautiful ever live',
            count: 1,
          },
          {
            word: 'ever live earth',
            count: 1,
          },
          {
            word: 'live earth dear',
            count: 1,
          },
          {
            word: 'earth dear duck',
            count: 1,
          },
          {
            word: 'puma bungeopang love',
            count: 1,
          },
          {
            word: 'bungeopang love solace',
            count: 1,
          },
          {
            word: 'love solace niki',
            count: 1,
          },
          {
            word: 'solace niki hope',
            count: 1,
          },
          {
            word: 'niki hope resting',
            count: 1,
          },
          {
            word: 'hope resting well',
            count: 1,
          },
          {
            word: 'resting well httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
      },
      word_list: [
        {
          word: 'puma',
          count: 58,
        },
        {
          word: 'birthday',
          count: 18,
        },
        {
          word: 'check',
          count: 16,
        },
        {
          word: 'fellow',
          count: 16,
        },
        {
          word: 'global',
          count: 16,
        },
        {
          word: 'ambassador',
          count: 16,
        },
        {
          word: 'pinned',
          count: 15,
        },
        {
          word: 'davido',
          count: 15,
        },
        {
          word: 'performing',
          count: 15,
        },
        {
          word: 'party',
          count: 15,
        },
        {
          word: 'athletico',
          count: 15,
        },
        {
          word: 'madrid',
          count: 15,
        },
        {
          word: 'player',
          count: 15,
        },
        {
          word: 'memphis',
          count: 15,
        },
        {
          word: 'depay',
          count: 15,
        },
        {
          word: 'names',
          count: 13,
        },
        {
          word: 'turret',
          count: 10,
        },
        {
          word: 'common',
          count: 9,
        },
        {
          word: 'news',
          count: 8,
        },
        {
          word: 'generic',
          count: 6,
        },
      ],
    },
    sentiments_tab: {
      sentiment_drilldown: {
        graph_data: {
          postive_percentage: '25.00%',
          negative_percentage: '7.00%',
          neutral_percentage: '68.00%',
        },
        topic_analysis: {
          positive_topics: {
            topic1:
              "Celebrate Valentine's Day with a happy and generous airdrop.",
            topic2: 'A person in a Puma jacket holds a shooting photo.',
            topic3:
              'Order Puma, super flair, and dash with http link. DM to place and GHCSend.',
            topic4:
              'Updated car screen revealed with greater display, larger than before.',
            topic5: 'A man appeared, resting, duck, Puma, and bungeopang.',
          },
          negative_topics: {
            topic1:
              'Companies like Nike, Adidas, and Puma promote consumption while pretending to care.',
            topic2:
              'Gift a shoe package on birthday from Flipkart saying happy birthday.',
            topic3:
              'A costly German project forced conglomerate to campaign for reputation.',
            topic4:
              "Awaiting morena latina, rock Charlotte hill with puma's nasty surprises.",
            topic5:
              "Nike's partnership with NPDN generates buzz around logo and sports.",
          },
          neutral_topics: {
            topic1:
              "Generic HTTP turret pinned to bro, check URL. Yep, it's common!",
            topic2:
              'Puma athlete Memphis Depay performing well in Madrid birthday match.',
          },
        },
      },
      top_five_hashtag: {
        '#UITheMovie': 5,
        '#stockmarketcrash': 5,
        '#SaiPallavi': 5,
        '#HariHaraVeeraMallu': 5,
        '#ElvishYadav': 5,
      },
      top_reposts: {
        total_positive_posts: 25,
        total_negative_posts: 7,
        total_neutral_posts: 68,
        top_positive_posts: {
          post_text: 'PUMA fam ❤️❤️ https://t.co/KCItCy4nua',
          post_interaction: 37,
        },
        top_negative_posts: {
          post_text:
            'I ordered a Pair of puma shoes for my father’s birthday gift and what i found was that flipkart delivered both the shoes of right foot in the package. My gift got ruined. They are cancelling the refund as well saying that it was an open box delivery. #fraud #customer #flipkart https://t.co/OFCTEBFrqJ',
          post_interaction: 1,
        },
        top_neutral_posts: {
          post_text:
            'just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hippopotamus, Gorilla, Octopus, Puma, etc. all these are both the common and generic names"but some generic names are too long and complex" ...Hippopotamus.',
          post_interaction: 13,
        },
      },
    },
    insights: {
      most_authoritative: {
        SNKR_TWITR: 1,
        SOLELINKS: 0.9932030372107858,
        'Timeless Albert Berry👿🌟': 0.7614353836620864,
        '🍃𝕊𝕙𝕚𝕚𝕟𝔹𝕚𝕚 🐰🍀🍃': 0.5120561138337008,
        '.*.': 0.4925742681815061,
      },
      most_mentioned: {
        '@MissRozapepper': 15,
        '@PUMA': 9,
        '@GoalJP_Official': 5,
        '@Pion_2S7': 5,
        '@Tiomoney1308': 5,
      },
      emotion_analysis: [
        {
          disgust: 0,
          anger: 7,
          fear: 9,
          sadness: 1,
          joy: 33,
          surprise: 5,
          neutral: 45,
        },
      ],
      correlation_analysis: {
        engagement: {
          engagement: 1,
          anger: -0.24,
          neutral: -0.18,
          sadness: 0.38,
          disgust: -0.1,
          fear: -0.21,
          joy: -0.27,
          surprise: 0.07,
        },
        anger: {
          engagement: -0.24,
          anger: 1,
          neutral: -0.3,
          sadness: 0.02,
          disgust: 0.32,
          fear: 0.83,
          joy: -0.19,
          surprise: -0.13,
        },
        neutral: {
          engagement: -0.18,
          anger: -0.3,
          neutral: 1,
          sadness: -0.6,
          disgust: -0.06,
          fear: -0.22,
          joy: -0.13,
          surprise: 0.04,
        },
        sadness: {
          engagement: 0.38,
          anger: 0.02,
          neutral: -0.6,
          sadness: 1,
          disgust: 0,
          fear: -0.02,
          joy: -0.32,
          surprise: -0.49,
        },
        disgust: {
          engagement: -0.1,
          anger: 0.32,
          neutral: -0.06,
          sadness: 0,
          disgust: 1,
          fear: 0.75,
          joy: -0.17,
          surprise: -0.12,
        },
        fear: {
          engagement: -0.21,
          anger: 0.83,
          neutral: -0.22,
          sadness: -0.02,
          disgust: 0.75,
          fear: 1,
          joy: -0.19,
          surprise: -0.16,
        },
        joy: {
          engagement: -0.27,
          anger: -0.19,
          neutral: -0.13,
          sadness: -0.32,
          disgust: -0.17,
          fear: -0.19,
          joy: 1,
          surprise: -0.22,
        },
        surprise: {
          engagement: 0.07,
          anger: -0.13,
          neutral: 0.04,
          sadness: -0.49,
          disgust: -0.12,
          fear: -0.16,
          joy: -0.22,
          surprise: 1,
        },
      },
      sentiment_wordcloud: {
        positive_unigram: [
          {
            word: 'check',
            count: 1,
          },
          {
            word: 'puma',
            count: 22,
          },
          {
            word: 'enzo',
            count: 1,
          },
          {
            word: 'weave',
            count: 1,
          },
          {
            word: 'price',
            count: 1,
          },
          {
            word: 'reduced',
            count: 1,
          },
          {
            word: 'httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'ebay',
            count: 2,
          },
          {
            word: 'alexluck',
            count: 1,
          },
          {
            word: 'daveisfree',
            count: 1,
          },
          {
            word: 'goldrandlösung',
            count: 1,
          },
          {
            word: 'cool',
            count: 1,
          },
          {
            word: 'shit',
            count: 1,
          },
          {
            word: 'like',
            count: 1,
          },
          {
            word: 'yeobosbaby',
            count: 2,
          },
          {
            word: 'kookie',
            count: 2,
          },
          {
            word: 'holding',
            count: 2,
          },
          {
            word: 'jinnies',
            count: 2,
          },
          {
            word: 'jacketso',
            count: 2,
          },
          {
            word: 'advertising',
            count: 2,
          },
          {
            word: 'photo',
            count: 2,
          },
          {
            word: 'shooting',
            count: 2,
          },
          {
            word: 'behind',
            count: 2,
          },
          {
            word: 'httpstcorexihih',
            count: 2,
          },
          {
            word: 'goaljpofficial',
            count: 4,
          },
          {
            word: 'super',
            count: 2,
          },
          {
            word: 'treat',
            count: 1,
          },
          {
            word: 'promo',
            count: 1,
          },
          {
            word: 'dayhttpstcoizsgxesbh',
            count: 1,
          },
          {
            word: 'pumakorea',
            count: 2,
          },
          {
            word: 'happy',
            count: 2,
          },
          {
            word: 'valentines',
            count: 2,
          },
          {
            word: 'pumaxkd',
            count: 2,
          },
          {
            word: 'kangdaniel',
            count: 2,
          },
          {
            word: '강다니엘',
            count: 2,
          },
          {
            word: 'rsxcube',
            count: 2,
          },
          {
            word: 'rsx큐브',
            count: 2,
          },
          {
            word: 'httpstcoiirruv',
            count: 2,
          },
          {
            word: 'tiomoney',
            count: 5,
          },
          {
            word: 'nice',
            count: 2,
          },
          {
            word: 'goal',
            count: 1,
          },
          {
            word: 'japan',
            count: 1,
          },
          {
            word: 'beautiful',
            count: 2,
          },
          {
            word: 'ford',
            count: 1,
          },
          {
            word: 'revealed',
            count: 1,
          },
          {
            word: 'updated',
            count: 1,
          },
          {
            word: 'giving',
            count: 1,
          },
          {
            word: 'greater',
            count: 1,
          },
          {
            word: 'connectivity',
            count: 1,
          },
          {
            word: 'larger',
            count: 1,
          },
          {
            word: 'infotainment',
            count: 1,
          },
          {
            word: 'screen',
            count: 1,
          },
          {
            word: 'well',
            count: 2,
          },
          {
            word: 'enhanced',
            count: 1,
          },
          {
            word: 'digital',
            count: 1,
          },
          {
            word: 'drivers',
            count: 1,
          },
          {
            word: 'display',
            count: 1,
          },
          {
            word: 'find',
            count: 1,
          },
          {
            word: 'news',
            count: 1,
          },
          {
            word: 'story',
            count: 1,
          },
          {
            word: 'httpstcozxmarnbsj',
            count: 1,
          },
          {
            word: 'httpstcoxdldxpwa',
            count: 1,
          },
          {
            word: 'thegreysole',
            count: 1,
          },
          {
            word: 'dash',
            count: 1,
          },
          {
            word: 'place',
            count: 1,
          },
          {
            word: 'order',
            count: 1,
          },
          {
            word: 'httpstcopwurqgmc',
            count: 1,
          },
          {
            word: 'score',
            count: 2,
          },
          {
            word: 'airdrop',
            count: 3,
          },
          {
            word: 'event',
            count: 1,
          },
          {
            word: 'future',
            count: 1,
          },
          {
            word: 'miss',
            count: 1,
          },
          {
            word: 'remember',
            count: 1,
          },
          {
            word: 'invitation',
            count: 1,
          },
          {
            word: 'code',
            count: 1,
          },
          {
            word: 'boost',
            count: 1,
          },
          {
            word: 'matrxofficial',
            count: 1,
          },
          {
            word: 'matrxio',
            count: 1,
          },
          {
            word: 'maxscore',
            count: 1,
          },
          {
            word: 'matrx',
            count: 1,
          },
          {
            word: 'sssniperwolf',
            count: 1,
          },
          {
            word: 'wdwnt',
            count: 1,
          },
          {
            word: 'always',
            count: 1,
          },
          {
            word: 'comfy',
            count: 1,
          },
          {
            word: 'httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'httpstcokcitcynua',
            count: 1,
          },
          {
            word: 'quality',
            count: 1,
          },
          {
            word: 'tracksuit',
            count: 1,
          },
          {
            word: 'size',
            count: 1,
          },
          {
            word: 'dmwhatsapp',
            count: 1,
          },
          {
            word: 'httpstcoopbczgsnx',
            count: 1,
          },
          {
            word: 'nationwide',
            count: 1,
          },
          {
            word: 'delivery',
            count: 1,
          },
          {
            word: 'help',
            count: 1,
          },
          {
            word: 'repost',
            count: 1,
          },
          {
            word: 'jomilojju',
            count: 1,
          },
          {
            word: 'httpstcosjfgrkrui',
            count: 1,
          },
          {
            word: 'nicekicks',
            count: 1,
          },
          {
            word: 'lamelo',
            count: 1,
          },
          {
            word: 'ball',
            count: 1,
          },
          {
            word: 'unveil',
            count: 1,
          },
          {
            word: 'highly',
            count: 1,
          },
          {
            word: 'anticipated',
            count: 1,
          },
          {
            word: 'porsche',
            count: 1,
          },
          {
            word: 'collection',
            count: 1,
          },
          {
            word: 'launching',
            count: 1,
          },
          {
            word: 'february',
            count: 1,
          },
          {
            word: 'nicedrops',
            count: 1,
          },
          {
            word: 'photocardrm',
            count: 1,
          },
          {
            word: 'eachmeet',
            count: 1,
          },
          {
            word: 'concertpostage',
            count: 1,
          },
          {
            word: 'want',
            count: 1,
          },
          {
            word: 'iveworldtour',
            count: 1,
          },
          {
            word: 'pasarive',
            count: 1,
          },
          {
            word: 'pasarnewjeanspasarivehelp',
            count: 1,
          },
          {
            word: 'guys',
            count: 1,
          },
          {
            word: 'httpstcoevkevv',
            count: 1,
          },
          {
            word: 'dear',
            count: 2,
          },
          {
            word: 'duck',
            count: 2,
          },
          {
            word: 'bungeopang',
            count: 2,
          },
          {
            word: 'niki',
            count: 2,
          },
          {
            word: 'enhypenas',
            count: 1,
          },
          {
            word: 'stars',
            count: 1,
          },
          {
            word: 'alligned',
            count: 1,
          },
          {
            word: 'dashing',
            count: 1,
          },
          {
            word: 'vampire',
            count: 1,
          },
          {
            word: 'appeared',
            count: 1,
          },
          {
            word: 'turned',
            count: 1,
          },
          {
            word: 'ever',
            count: 1,
          },
          {
            word: 'live',
            count: 1,
          },
          {
            word: 'earth',
            count: 1,
          },
          {
            word: 'love',
            count: 1,
          },
          {
            word: 'solace',
            count: 1,
          },
          {
            word: 'hope',
            count: 1,
          },
          {
            word: 'resting',
            count: 1,
          },
          {
            word: 'httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
        positive_bigram: [
          {
            word: 'check puma',
            count: 1,
          },
          {
            word: 'puma enzo',
            count: 1,
          },
          {
            word: 'enzo weave',
            count: 1,
          },
          {
            word: 'weave price',
            count: 1,
          },
          {
            word: 'price reduced',
            count: 1,
          },
          {
            word: 'reduced httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'httpstcoclxjonjnwp ebay',
            count: 1,
          },
          {
            word: 'ebay ebay',
            count: 1,
          },
          {
            word: 'alexluck daveisfree',
            count: 1,
          },
          {
            word: 'daveisfree goldrandlösung',
            count: 1,
          },
          {
            word: 'goldrandlösung cool',
            count: 1,
          },
          {
            word: 'cool shit',
            count: 1,
          },
          {
            word: 'shit like',
            count: 1,
          },
          {
            word: 'like puma',
            count: 1,
          },
          {
            word: 'yeobosbaby kookie',
            count: 2,
          },
          {
            word: 'kookie holding',
            count: 2,
          },
          {
            word: 'holding jinnies',
            count: 2,
          },
          {
            word: 'jinnies jacketso',
            count: 2,
          },
          {
            word: 'jacketso advertising',
            count: 2,
          },
          {
            word: 'advertising photo',
            count: 2,
          },
          {
            word: 'photo shooting',
            count: 2,
          },
          {
            word: 'shooting behind',
            count: 2,
          },
          {
            word: 'behind httpstcorexihih',
            count: 2,
          },
          {
            word: 'goaljpofficial puma',
            count: 4,
          },
          {
            word: 'puma super',
            count: 2,
          },
          {
            word: 'treat puma',
            count: 1,
          },
          {
            word: 'puma promo',
            count: 1,
          },
          {
            word: 'promo dayhttpstcoizsgxesbh',
            count: 1,
          },
          {
            word: 'pumakorea happy',
            count: 2,
          },
          {
            word: 'happy valentines',
            count: 2,
          },
          {
            word: 'valentines pumaxkd',
            count: 2,
          },
          {
            word: 'pumaxkd kangdaniel',
            count: 2,
          },
          {
            word: 'kangdaniel 강다니엘',
            count: 2,
          },
          {
            word: '강다니엘 rsxcube',
            count: 2,
          },
          {
            word: 'rsxcube rsx큐브',
            count: 2,
          },
          {
            word: 'rsx큐브 httpstcoiirruv',
            count: 2,
          },
          {
            word: 'tiomoney puma',
            count: 5,
          },
          {
            word: 'puma nice',
            count: 2,
          },
          {
            word: 'puma goal',
            count: 1,
          },
          {
            word: 'goal japan',
            count: 1,
          },
          {
            word: 'japan beautiful',
            count: 1,
          },
          {
            word: 'ford revealed',
            count: 1,
          },
          {
            word: 'revealed updated',
            count: 1,
          },
          {
            word: 'updated puma',
            count: 1,
          },
          {
            word: 'puma giving',
            count: 1,
          },
          {
            word: 'giving greater',
            count: 1,
          },
          {
            word: 'greater connectivity',
            count: 1,
          },
          {
            word: 'connectivity larger',
            count: 1,
          },
          {
            word: 'larger infotainment',
            count: 1,
          },
          {
            word: 'infotainment screen',
            count: 1,
          },
          {
            word: 'screen well',
            count: 1,
          },
          {
            word: 'well enhanced',
            count: 1,
          },
          {
            word: 'enhanced digital',
            count: 1,
          },
          {
            word: 'digital drivers',
            count: 1,
          },
          {
            word: 'drivers display',
            count: 1,
          },
          {
            word: 'display find',
            count: 1,
          },
          {
            word: 'find news',
            count: 1,
          },
          {
            word: 'news story',
            count: 1,
          },
          {
            word: 'story httpstcozxmarnbsj',
            count: 1,
          },
          {
            word: 'httpstcozxmarnbsj httpstcoxdldxpwa',
            count: 1,
          },
          {
            word: 'thegreysole dash',
            count: 1,
          },
          {
            word: 'dash puma',
            count: 1,
          },
          {
            word: 'puma place',
            count: 1,
          },
          {
            word: 'place order',
            count: 1,
          },
          {
            word: 'order httpstcopwurqgmc',
            count: 1,
          },
          {
            word: 'score airdrop',
            count: 1,
          },
          {
            word: 'airdrop event',
            count: 1,
          },
          {
            word: 'event future',
            count: 1,
          },
          {
            word: 'future airdrop',
            count: 1,
          },
          {
            word: 'airdrop miss',
            count: 1,
          },
          {
            word: 'miss remember',
            count: 1,
          },
          {
            word: 'remember invitation',
            count: 1,
          },
          {
            word: 'invitation code',
            count: 1,
          },
          {
            word: 'code boost',
            count: 1,
          },
          {
            word: 'boost score',
            count: 1,
          },
          {
            word: 'score matrxofficial',
            count: 1,
          },
          {
            word: 'matrxofficial matrxio',
            count: 1,
          },
          {
            word: 'matrxio maxscore',
            count: 1,
          },
          {
            word: 'maxscore airdrop',
            count: 1,
          },
          {
            word: 'airdrop matrx',
            count: 1,
          },
          {
            word: 'matrx sssniperwolf',
            count: 1,
          },
          {
            word: 'sssniperwolf wdwnt',
            count: 1,
          },
          {
            word: 'wdwnt puma',
            count: 1,
          },
          {
            word: 'always puma',
            count: 1,
          },
          {
            word: 'super comfy',
            count: 1,
          },
          {
            word: 'comfy httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'puma httpstcokcitcynua',
            count: 1,
          },
          {
            word: 'quality puma',
            count: 1,
          },
          {
            word: 'puma tracksuit',
            count: 1,
          },
          {
            word: 'tracksuit size',
            count: 1,
          },
          {
            word: 'size dmwhatsapp',
            count: 1,
          },
          {
            word: 'dmwhatsapp httpstcoopbczgsnx',
            count: 1,
          },
          {
            word: 'httpstcoopbczgsnx nationwide',
            count: 1,
          },
          {
            word: 'nationwide delivery',
            count: 1,
          },
          {
            word: 'delivery help',
            count: 1,
          },
          {
            word: 'help repost',
            count: 1,
          },
          {
            word: 'repost jomilojju',
            count: 1,
          },
          {
            word: 'jomilojju httpstcosjfgrkrui',
            count: 1,
          },
          {
            word: 'nicekicks lamelo',
            count: 1,
          },
          {
            word: 'lamelo ball',
            count: 1,
          },
          {
            word: 'ball puma',
            count: 1,
          },
          {
            word: 'puma unveil',
            count: 1,
          },
          {
            word: 'unveil highly',
            count: 1,
          },
          {
            word: 'highly anticipated',
            count: 1,
          },
          {
            word: 'anticipated porsche',
            count: 1,
          },
          {
            word: 'porsche collection',
            count: 1,
          },
          {
            word: 'collection launching',
            count: 1,
          },
          {
            word: 'launching february',
            count: 1,
          },
          {
            word: 'february nicedrops',
            count: 1,
          },
          {
            word: 'puma photocardrm',
            count: 1,
          },
          {
            word: 'photocardrm eachmeet',
            count: 1,
          },
          {
            word: 'eachmeet concertpostage',
            count: 1,
          },
          {
            word: 'concertpostage want',
            count: 1,
          },
          {
            word: 'want iveworldtour',
            count: 1,
          },
          {
            word: 'iveworldtour pasarive',
            count: 1,
          },
          {
            word: 'pasarive pasarnewjeanspasarivehelp',
            count: 1,
          },
          {
            word: 'pasarnewjeanspasarivehelp guys',
            count: 1,
          },
          {
            word: 'guys httpstcoevkevv',
            count: 1,
          },
          {
            word: 'dear duck',
            count: 2,
          },
          {
            word: 'duck puma',
            count: 2,
          },
          {
            word: 'puma bungeopang',
            count: 2,
          },
          {
            word: 'bungeopang niki',
            count: 1,
          },
          {
            word: 'niki enhypenas',
            count: 1,
          },
          {
            word: 'enhypenas stars',
            count: 1,
          },
          {
            word: 'stars alligned',
            count: 1,
          },
          {
            word: 'alligned dashing',
            count: 1,
          },
          {
            word: 'dashing vampire',
            count: 1,
          },
          {
            word: 'vampire appeared',
            count: 1,
          },
          {
            word: 'appeared turned',
            count: 1,
          },
          {
            word: 'turned beautiful',
            count: 1,
          },
          {
            word: 'beautiful ever',
            count: 1,
          },
          {
            word: 'ever live',
            count: 1,
          },
          {
            word: 'live earth',
            count: 1,
          },
          {
            word: 'earth dear',
            count: 1,
          },
          {
            word: 'bungeopang love',
            count: 1,
          },
          {
            word: 'love solace',
            count: 1,
          },
          {
            word: 'solace niki',
            count: 1,
          },
          {
            word: 'niki hope',
            count: 1,
          },
          {
            word: 'hope resting',
            count: 1,
          },
          {
            word: 'resting well',
            count: 1,
          },
          {
            word: 'well httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
        positive_trigram: [
          {
            word: 'check puma enzo',
            count: 1,
          },
          {
            word: 'puma enzo weave',
            count: 1,
          },
          {
            word: 'enzo weave price',
            count: 1,
          },
          {
            word: 'weave price reduced',
            count: 1,
          },
          {
            word: 'price reduced httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'reduced httpstcoclxjonjnwp ebay',
            count: 1,
          },
          {
            word: 'httpstcoclxjonjnwp ebay ebay',
            count: 1,
          },
          {
            word: 'alexluck daveisfree goldrandlösung',
            count: 1,
          },
          {
            word: 'daveisfree goldrandlösung cool',
            count: 1,
          },
          {
            word: 'goldrandlösung cool shit',
            count: 1,
          },
          {
            word: 'cool shit like',
            count: 1,
          },
          {
            word: 'shit like puma',
            count: 1,
          },
          {
            word: 'yeobosbaby kookie holding',
            count: 2,
          },
          {
            word: 'kookie holding jinnies',
            count: 2,
          },
          {
            word: 'holding jinnies jacketso',
            count: 2,
          },
          {
            word: 'jinnies jacketso advertising',
            count: 2,
          },
          {
            word: 'jacketso advertising photo',
            count: 2,
          },
          {
            word: 'advertising photo shooting',
            count: 2,
          },
          {
            word: 'photo shooting behind',
            count: 2,
          },
          {
            word: 'shooting behind httpstcorexihih',
            count: 2,
          },
          {
            word: 'goaljpofficial puma super',
            count: 1,
          },
          {
            word: 'treat puma promo',
            count: 1,
          },
          {
            word: 'puma promo dayhttpstcoizsgxesbh',
            count: 1,
          },
          {
            word: 'pumakorea happy valentines',
            count: 2,
          },
          {
            word: 'happy valentines pumaxkd',
            count: 2,
          },
          {
            word: 'valentines pumaxkd kangdaniel',
            count: 2,
          },
          {
            word: 'pumaxkd kangdaniel 강다니엘',
            count: 2,
          },
          {
            word: 'kangdaniel 강다니엘 rsxcube',
            count: 2,
          },
          {
            word: '강다니엘 rsxcube rsx큐브',
            count: 2,
          },
          {
            word: 'rsxcube rsx큐브 httpstcoiirruv',
            count: 2,
          },
          {
            word: 'goaljpofficial puma nice',
            count: 2,
          },
          {
            word: 'goaljpofficial puma goal',
            count: 1,
          },
          {
            word: 'puma goal japan',
            count: 1,
          },
          {
            word: 'goal japan beautiful',
            count: 1,
          },
          {
            word: 'ford revealed updated',
            count: 1,
          },
          {
            word: 'revealed updated puma',
            count: 1,
          },
          {
            word: 'updated puma giving',
            count: 1,
          },
          {
            word: 'puma giving greater',
            count: 1,
          },
          {
            word: 'giving greater connectivity',
            count: 1,
          },
          {
            word: 'greater connectivity larger',
            count: 1,
          },
          {
            word: 'connectivity larger infotainment',
            count: 1,
          },
          {
            word: 'larger infotainment screen',
            count: 1,
          },
          {
            word: 'infotainment screen well',
            count: 1,
          },
          {
            word: 'screen well enhanced',
            count: 1,
          },
          {
            word: 'well enhanced digital',
            count: 1,
          },
          {
            word: 'enhanced digital drivers',
            count: 1,
          },
          {
            word: 'digital drivers display',
            count: 1,
          },
          {
            word: 'drivers display find',
            count: 1,
          },
          {
            word: 'display find news',
            count: 1,
          },
          {
            word: 'find news story',
            count: 1,
          },
          {
            word: 'news story httpstcozxmarnbsj',
            count: 1,
          },
          {
            word: 'story httpstcozxmarnbsj httpstcoxdldxpwa',
            count: 1,
          },
          {
            word: 'thegreysole dash puma',
            count: 1,
          },
          {
            word: 'dash puma place',
            count: 1,
          },
          {
            word: 'puma place order',
            count: 1,
          },
          {
            word: 'place order httpstcopwurqgmc',
            count: 1,
          },
          {
            word: 'score airdrop event',
            count: 1,
          },
          {
            word: 'airdrop event future',
            count: 1,
          },
          {
            word: 'event future airdrop',
            count: 1,
          },
          {
            word: 'future airdrop miss',
            count: 1,
          },
          {
            word: 'airdrop miss remember',
            count: 1,
          },
          {
            word: 'miss remember invitation',
            count: 1,
          },
          {
            word: 'remember invitation code',
            count: 1,
          },
          {
            word: 'invitation code boost',
            count: 1,
          },
          {
            word: 'code boost score',
            count: 1,
          },
          {
            word: 'boost score matrxofficial',
            count: 1,
          },
          {
            word: 'score matrxofficial matrxio',
            count: 1,
          },
          {
            word: 'matrxofficial matrxio maxscore',
            count: 1,
          },
          {
            word: 'matrxio maxscore airdrop',
            count: 1,
          },
          {
            word: 'maxscore airdrop matrx',
            count: 1,
          },
          {
            word: 'airdrop matrx sssniperwolf',
            count: 1,
          },
          {
            word: 'matrx sssniperwolf wdwnt',
            count: 1,
          },
          {
            word: 'sssniperwolf wdwnt puma',
            count: 1,
          },
          {
            word: 'always puma super',
            count: 1,
          },
          {
            word: 'puma super comfy',
            count: 1,
          },
          {
            word: 'super comfy httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'quality puma tracksuit',
            count: 1,
          },
          {
            word: 'puma tracksuit size',
            count: 1,
          },
          {
            word: 'tracksuit size dmwhatsapp',
            count: 1,
          },
          {
            word: 'size dmwhatsapp httpstcoopbczgsnx',
            count: 1,
          },
          {
            word: 'dmwhatsapp httpstcoopbczgsnx nationwide',
            count: 1,
          },
          {
            word: 'httpstcoopbczgsnx nationwide delivery',
            count: 1,
          },
          {
            word: 'nationwide delivery help',
            count: 1,
          },
          {
            word: 'delivery help repost',
            count: 1,
          },
          {
            word: 'help repost jomilojju',
            count: 1,
          },
          {
            word: 'repost jomilojju httpstcosjfgrkrui',
            count: 1,
          },
          {
            word: 'nicekicks lamelo ball',
            count: 1,
          },
          {
            word: 'lamelo ball puma',
            count: 1,
          },
          {
            word: 'ball puma unveil',
            count: 1,
          },
          {
            word: 'puma unveil highly',
            count: 1,
          },
          {
            word: 'unveil highly anticipated',
            count: 1,
          },
          {
            word: 'highly anticipated porsche',
            count: 1,
          },
          {
            word: 'anticipated porsche collection',
            count: 1,
          },
          {
            word: 'porsche collection launching',
            count: 1,
          },
          {
            word: 'collection launching february',
            count: 1,
          },
          {
            word: 'launching february nicedrops',
            count: 1,
          },
          {
            word: 'puma photocardrm eachmeet',
            count: 1,
          },
          {
            word: 'photocardrm eachmeet concertpostage',
            count: 1,
          },
          {
            word: 'eachmeet concertpostage want',
            count: 1,
          },
          {
            word: 'concertpostage want iveworldtour',
            count: 1,
          },
          {
            word: 'want iveworldtour pasarive',
            count: 1,
          },
          {
            word: 'iveworldtour pasarive pasarnewjeanspasarivehelp',
            count: 1,
          },
          {
            word: 'pasarive pasarnewjeanspasarivehelp guys',
            count: 1,
          },
          {
            word: 'pasarnewjeanspasarivehelp guys httpstcoevkevv',
            count: 1,
          },
          {
            word: 'dear duck puma',
            count: 2,
          },
          {
            word: 'duck puma bungeopang',
            count: 2,
          },
          {
            word: 'puma bungeopang niki',
            count: 1,
          },
          {
            word: 'bungeopang niki enhypenas',
            count: 1,
          },
          {
            word: 'niki enhypenas stars',
            count: 1,
          },
          {
            word: 'enhypenas stars alligned',
            count: 1,
          },
          {
            word: 'stars alligned dashing',
            count: 1,
          },
          {
            word: 'alligned dashing vampire',
            count: 1,
          },
          {
            word: 'dashing vampire appeared',
            count: 1,
          },
          {
            word: 'vampire appeared turned',
            count: 1,
          },
          {
            word: 'appeared turned beautiful',
            count: 1,
          },
          {
            word: 'turned beautiful ever',
            count: 1,
          },
          {
            word: 'beautiful ever live',
            count: 1,
          },
          {
            word: 'ever live earth',
            count: 1,
          },
          {
            word: 'live earth dear',
            count: 1,
          },
          {
            word: 'earth dear duck',
            count: 1,
          },
          {
            word: 'puma bungeopang love',
            count: 1,
          },
          {
            word: 'bungeopang love solace',
            count: 1,
          },
          {
            word: 'love solace niki',
            count: 1,
          },
          {
            word: 'solace niki hope',
            count: 1,
          },
          {
            word: 'niki hope resting',
            count: 1,
          },
          {
            word: 'hope resting well',
            count: 1,
          },
          {
            word: 'resting well httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
        negative_unigram: [
          {
            word: 'koffiofphc',
            count: 1,
          },
          {
            word: 'fellow',
            count: 1,
          },
          {
            word: 'puma',
            count: 7,
          },
          {
            word: 'global',
            count: 1,
          },
          {
            word: 'ambassador',
            count: 1,
          },
          {
            word: 'birthday',
            count: 2,
          },
          {
            word: 'rinse',
            count: 1,
          },
          {
            word: 'repeat',
            count: 1,
          },
          {
            word: 'music',
            count: 1,
          },
          {
            word: 'incoming',
            count: 1,
          },
          {
            word: 'ordered',
            count: 1,
          },
          {
            word: 'pair',
            count: 1,
          },
          {
            word: 'shoes',
            count: 2,
          },
          {
            word: 'gift',
            count: 2,
          },
          {
            word: 'found',
            count: 1,
          },
          {
            word: 'flipkart',
            count: 2,
          },
          {
            word: 'delivered',
            count: 1,
          },
          {
            word: 'right',
            count: 1,
          },
          {
            word: 'foot',
            count: 1,
          },
          {
            word: 'package',
            count: 1,
          },
          {
            word: 'ruined',
            count: 1,
          },
          {
            word: 'cancelling',
            count: 1,
          },
          {
            word: 'refund',
            count: 1,
          },
          {
            word: 'well',
            count: 1,
          },
          {
            word: 'saying',
            count: 1,
          },
          {
            word: 'open',
            count: 1,
          },
          {
            word: 'delivery',
            count: 1,
          },
          {
            word: 'fraud',
            count: 1,
          },
          {
            word: 'customer',
            count: 1,
          },
          {
            word: 'httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'pacbi',
            count: 1,
          },
          {
            word: 'years',
            count: 1,
          },
          {
            word: 'campaigning',
            count: 1,
          },
          {
            word: 'cost',
            count: 1,
          },
          {
            word: 'german',
            count: 1,
          },
          {
            word: 'conglomerate',
            count: 1,
          },
          {
            word: 'dearly',
            count: 1,
          },
          {
            word: 'reputation',
            count: 1,
          },
          {
            word: 'projects',
            count: 1,
          },
          {
            word: 'forced',
            count: 1,
          },
          {
            word: 'godnessrin',
            count: 1,
          },
          {
            word: 'axelsauro',
            count: 1,
          },
          {
            word: 'dibblesaharicus',
            count: 1,
          },
          {
            word: 'dinomation',
            count: 1,
          },
          {
            word: 'even',
            count: 1,
          },
          {
            word: 'young',
            count: 1,
          },
          {
            word: 'adults',
            count: 1,
          },
          {
            word: 'taken',
            count: 1,
          },
          {
            word: 'considering',
            count: 1,
          },
          {
            word: 'case',
            count: 1,
          },
          {
            word: 'killing',
            count: 1,
          },
          {
            word: 'meter',
            count: 1,
          },
          {
            word: 'alligator',
            count: 1,
          },
          {
            word: 'land',
            count: 1,
          },
          {
            word: 'joachimschulz',
            count: 1,
          },
          {
            word: 'fair',
            count: 1,
          },
          {
            word: 'point',
            count: 1,
          },
          {
            word: 'rollout',
            count: 1,
          },
          {
            word: 'scalewise',
            count: 1,
          },
          {
            word: 'reason',
            count: 1,
          },
          {
            word: 'seem',
            count: 1,
          },
          {
            word: 'befit',
            count: 1,
          },
          {
            word: 'goat',
            count: 1,
          },
          {
            word: 'sport',
            count: 1,
          },
          {
            word: 'imagine',
            count: 1,
          },
          {
            word: 'nike',
            count: 3,
          },
          {
            word: 'adidas',
            count: 2,
          },
          {
            word: 'would',
            count: 1,
          },
          {
            word: 'pulling',
            count: 1,
          },
          {
            word: 'stops',
            count: 1,
          },
          {
            word: 'logo',
            count: 1,
          },
          {
            word: 'branding',
            count: 1,
          },
          {
            word: 'buzz',
            count: 1,
          },
          {
            word: 'social',
            count: 1,
          },
          {
            word: 'puts',
            count: 1,
          },
          {
            word: 'three',
            count: 1,
          },
          {
            word: 'decadelong',
            count: 1,
          },
          {
            word: 'erstwhile',
            count: 1,
          },
          {
            word: 'partnership',
            count: 1,
          },
          {
            word: 'httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'human',
            count: 1,
          },
          {
            word: 'toilet',
            count: 1,
          },
          {
            word: 'nasty',
            count: 1,
          },
          {
            word: 'sitting',
            count: 1,
          },
          {
            word: 'waiting',
            count: 1,
          },
          {
            word: 'collect',
            count: 1,
          },
          {
            word: 'piss',
            count: 1,
          },
          {
            word: 'shit',
            count: 1,
          },
          {
            word: 'souvenir',
            count: 1,
          },
          {
            word: 'ultimate',
            count: 1,
          },
          {
            word: 'predator',
            count: 1,
          },
          {
            word: 'femdom',
            count: 1,
          },
          {
            word: 'beta',
            count: 1,
          },
          {
            word: 'cuck',
            count: 1,
          },
          {
            word: 'loser',
            count: 1,
          },
          {
            word: 'white',
            count: 1,
          },
          {
            word: 'charlotte',
            count: 1,
          },
          {
            word: 'greensboro',
            count: 1,
          },
          {
            word: 'raleigh',
            count: 1,
          },
          {
            word: 'rock',
            count: 1,
          },
          {
            word: 'hill',
            count: 1,
          },
          {
            word: 'durham',
            count: 1,
          },
          {
            word: 'brattylatina',
            count: 1,
          },
          {
            word: 'latinaslut',
            count: 1,
          },
          {
            word: 'morena',
            count: 1,
          },
          {
            word: 'thick',
            count: 1,
          },
          {
            word: 'httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'andysfootyshirt',
            count: 1,
          },
          {
            word: 'cadecoweiigoat',
            count: 1,
          },
          {
            word: 'shirtsofneil',
            count: 1,
          },
          {
            word: 'cook',
            count: 1,
          },
          {
            word: 'unethical',
            count: 1,
          },
          {
            word: 'consumption',
            count: 1,
          },
          {
            word: 'pretending',
            count: 1,
          },
          {
            word: 'like',
            count: 1,
          },
          {
            word: 'official',
            count: 1,
          },
          {
            word: 'garbage',
            count: 1,
          },
          {
            word: 'dense',
            count: 1,
          },
        ],
        negative_bigram: [
          {
            word: 'koffiofphc fellow',
            count: 1,
          },
          {
            word: 'fellow puma',
            count: 1,
          },
          {
            word: 'puma global',
            count: 1,
          },
          {
            word: 'global ambassador',
            count: 1,
          },
          {
            word: 'ambassador birthday',
            count: 1,
          },
          {
            word: 'birthday rinse',
            count: 1,
          },
          {
            word: 'rinse repeat',
            count: 1,
          },
          {
            word: 'repeat music',
            count: 1,
          },
          {
            word: 'music incoming',
            count: 1,
          },
          {
            word: 'ordered pair',
            count: 1,
          },
          {
            word: 'pair puma',
            count: 1,
          },
          {
            word: 'puma shoes',
            count: 1,
          },
          {
            word: 'shoes birthday',
            count: 1,
          },
          {
            word: 'birthday gift',
            count: 1,
          },
          {
            word: 'gift found',
            count: 1,
          },
          {
            word: 'found flipkart',
            count: 1,
          },
          {
            word: 'flipkart delivered',
            count: 1,
          },
          {
            word: 'delivered shoes',
            count: 1,
          },
          {
            word: 'shoes right',
            count: 1,
          },
          {
            word: 'right foot',
            count: 1,
          },
          {
            word: 'foot package',
            count: 1,
          },
          {
            word: 'package gift',
            count: 1,
          },
          {
            word: 'gift ruined',
            count: 1,
          },
          {
            word: 'ruined cancelling',
            count: 1,
          },
          {
            word: 'cancelling refund',
            count: 1,
          },
          {
            word: 'refund well',
            count: 1,
          },
          {
            word: 'well saying',
            count: 1,
          },
          {
            word: 'saying open',
            count: 1,
          },
          {
            word: 'open delivery',
            count: 1,
          },
          {
            word: 'delivery fraud',
            count: 1,
          },
          {
            word: 'fraud customer',
            count: 1,
          },
          {
            word: 'customer flipkart',
            count: 1,
          },
          {
            word: 'flipkart httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'pacbi years',
            count: 1,
          },
          {
            word: 'years campaigning',
            count: 1,
          },
          {
            word: 'campaigning cost',
            count: 1,
          },
          {
            word: 'cost german',
            count: 1,
          },
          {
            word: 'german conglomerate',
            count: 1,
          },
          {
            word: 'conglomerate puma',
            count: 1,
          },
          {
            word: 'puma dearly',
            count: 1,
          },
          {
            word: 'dearly reputation',
            count: 1,
          },
          {
            word: 'reputation projects',
            count: 1,
          },
          {
            word: 'projects forced',
            count: 1,
          },
          {
            word: 'godnessrin axelsauro',
            count: 1,
          },
          {
            word: 'axelsauro dibblesaharicus',
            count: 1,
          },
          {
            word: 'dibblesaharicus dinomation',
            count: 1,
          },
          {
            word: 'dinomation even',
            count: 1,
          },
          {
            word: 'even young',
            count: 1,
          },
          {
            word: 'young adults',
            count: 1,
          },
          {
            word: 'adults taken',
            count: 1,
          },
          {
            word: 'taken considering',
            count: 1,
          },
          {
            word: 'considering case',
            count: 1,
          },
          {
            word: 'case puma',
            count: 1,
          },
          {
            word: 'puma killing',
            count: 1,
          },
          {
            word: 'killing meter',
            count: 1,
          },
          {
            word: 'meter alligator',
            count: 1,
          },
          {
            word: 'alligator land',
            count: 1,
          },
          {
            word: 'joachimschulz fair',
            count: 1,
          },
          {
            word: 'fair point',
            count: 1,
          },
          {
            word: 'point rollout',
            count: 1,
          },
          {
            word: 'rollout scalewise',
            count: 1,
          },
          {
            word: 'scalewise reason',
            count: 1,
          },
          {
            word: 'reason seem',
            count: 1,
          },
          {
            word: 'seem befit',
            count: 1,
          },
          {
            word: 'befit goat',
            count: 1,
          },
          {
            word: 'goat sport',
            count: 1,
          },
          {
            word: 'sport imagine',
            count: 1,
          },
          {
            word: 'imagine nike',
            count: 1,
          },
          {
            word: 'nike puma',
            count: 2,
          },
          {
            word: 'puma adidas',
            count: 2,
          },
          {
            word: 'adidas would',
            count: 1,
          },
          {
            word: 'would pulling',
            count: 1,
          },
          {
            word: 'pulling stops',
            count: 1,
          },
          {
            word: 'stops logo',
            count: 1,
          },
          {
            word: 'logo branding',
            count: 1,
          },
          {
            word: 'branding buzz',
            count: 1,
          },
          {
            word: 'buzz social',
            count: 1,
          },
          {
            word: 'social puts',
            count: 1,
          },
          {
            word: 'puts three',
            count: 1,
          },
          {
            word: 'three decadelong',
            count: 1,
          },
          {
            word: 'decadelong erstwhile',
            count: 1,
          },
          {
            word: 'erstwhile partnership',
            count: 1,
          },
          {
            word: 'partnership nike',
            count: 1,
          },
          {
            word: 'nike httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'human toilet',
            count: 1,
          },
          {
            word: 'toilet nasty',
            count: 1,
          },
          {
            word: 'nasty sitting',
            count: 1,
          },
          {
            word: 'sitting waiting',
            count: 1,
          },
          {
            word: 'waiting collect',
            count: 1,
          },
          {
            word: 'collect piss',
            count: 1,
          },
          {
            word: 'piss shit',
            count: 1,
          },
          {
            word: 'shit souvenir',
            count: 1,
          },
          {
            word: 'souvenir ultimate',
            count: 1,
          },
          {
            word: 'ultimate predator',
            count: 1,
          },
          {
            word: 'predator puma',
            count: 1,
          },
          {
            word: 'puma femdom',
            count: 1,
          },
          {
            word: 'femdom beta',
            count: 1,
          },
          {
            word: 'beta cuck',
            count: 1,
          },
          {
            word: 'cuck loser',
            count: 1,
          },
          {
            word: 'loser white',
            count: 1,
          },
          {
            word: 'white charlotte',
            count: 1,
          },
          {
            word: 'charlotte greensboro',
            count: 1,
          },
          {
            word: 'greensboro raleigh',
            count: 1,
          },
          {
            word: 'raleigh rock',
            count: 1,
          },
          {
            word: 'rock hill',
            count: 1,
          },
          {
            word: 'hill durham',
            count: 1,
          },
          {
            word: 'durham brattylatina',
            count: 1,
          },
          {
            word: 'brattylatina latinaslut',
            count: 1,
          },
          {
            word: 'latinaslut morena',
            count: 1,
          },
          {
            word: 'morena thick',
            count: 1,
          },
          {
            word: 'thick httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'httpstcoldfmmz httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'andysfootyshirt cadecoweiigoat',
            count: 1,
          },
          {
            word: 'cadecoweiigoat shirtsofneil',
            count: 1,
          },
          {
            word: 'shirtsofneil cook',
            count: 1,
          },
          {
            word: 'cook unethical',
            count: 1,
          },
          {
            word: 'unethical consumption',
            count: 1,
          },
          {
            word: 'consumption pretending',
            count: 1,
          },
          {
            word: 'pretending like',
            count: 1,
          },
          {
            word: 'like official',
            count: 1,
          },
          {
            word: 'official nike',
            count: 1,
          },
          {
            word: 'adidas garbage',
            count: 1,
          },
          {
            word: 'garbage dense',
            count: 1,
          },
        ],
        negative_trigram: [
          {
            word: 'koffiofphc fellow puma',
            count: 1,
          },
          {
            word: 'fellow puma global',
            count: 1,
          },
          {
            word: 'puma global ambassador',
            count: 1,
          },
          {
            word: 'global ambassador birthday',
            count: 1,
          },
          {
            word: 'ambassador birthday rinse',
            count: 1,
          },
          {
            word: 'birthday rinse repeat',
            count: 1,
          },
          {
            word: 'rinse repeat music',
            count: 1,
          },
          {
            word: 'repeat music incoming',
            count: 1,
          },
          {
            word: 'ordered pair puma',
            count: 1,
          },
          {
            word: 'pair puma shoes',
            count: 1,
          },
          {
            word: 'puma shoes birthday',
            count: 1,
          },
          {
            word: 'shoes birthday gift',
            count: 1,
          },
          {
            word: 'birthday gift found',
            count: 1,
          },
          {
            word: 'gift found flipkart',
            count: 1,
          },
          {
            word: 'found flipkart delivered',
            count: 1,
          },
          {
            word: 'flipkart delivered shoes',
            count: 1,
          },
          {
            word: 'delivered shoes right',
            count: 1,
          },
          {
            word: 'shoes right foot',
            count: 1,
          },
          {
            word: 'right foot package',
            count: 1,
          },
          {
            word: 'foot package gift',
            count: 1,
          },
          {
            word: 'package gift ruined',
            count: 1,
          },
          {
            word: 'gift ruined cancelling',
            count: 1,
          },
          {
            word: 'ruined cancelling refund',
            count: 1,
          },
          {
            word: 'cancelling refund well',
            count: 1,
          },
          {
            word: 'refund well saying',
            count: 1,
          },
          {
            word: 'well saying open',
            count: 1,
          },
          {
            word: 'saying open delivery',
            count: 1,
          },
          {
            word: 'open delivery fraud',
            count: 1,
          },
          {
            word: 'delivery fraud customer',
            count: 1,
          },
          {
            word: 'fraud customer flipkart',
            count: 1,
          },
          {
            word: 'customer flipkart httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'pacbi years campaigning',
            count: 1,
          },
          {
            word: 'years campaigning cost',
            count: 1,
          },
          {
            word: 'campaigning cost german',
            count: 1,
          },
          {
            word: 'cost german conglomerate',
            count: 1,
          },
          {
            word: 'german conglomerate puma',
            count: 1,
          },
          {
            word: 'conglomerate puma dearly',
            count: 1,
          },
          {
            word: 'puma dearly reputation',
            count: 1,
          },
          {
            word: 'dearly reputation projects',
            count: 1,
          },
          {
            word: 'reputation projects forced',
            count: 1,
          },
          {
            word: 'godnessrin axelsauro dibblesaharicus',
            count: 1,
          },
          {
            word: 'axelsauro dibblesaharicus dinomation',
            count: 1,
          },
          {
            word: 'dibblesaharicus dinomation even',
            count: 1,
          },
          {
            word: 'dinomation even young',
            count: 1,
          },
          {
            word: 'even young adults',
            count: 1,
          },
          {
            word: 'young adults taken',
            count: 1,
          },
          {
            word: 'adults taken considering',
            count: 1,
          },
          {
            word: 'taken considering case',
            count: 1,
          },
          {
            word: 'considering case puma',
            count: 1,
          },
          {
            word: 'case puma killing',
            count: 1,
          },
          {
            word: 'puma killing meter',
            count: 1,
          },
          {
            word: 'killing meter alligator',
            count: 1,
          },
          {
            word: 'meter alligator land',
            count: 1,
          },
          {
            word: 'joachimschulz fair point',
            count: 1,
          },
          {
            word: 'fair point rollout',
            count: 1,
          },
          {
            word: 'point rollout scalewise',
            count: 1,
          },
          {
            word: 'rollout scalewise reason',
            count: 1,
          },
          {
            word: 'scalewise reason seem',
            count: 1,
          },
          {
            word: 'reason seem befit',
            count: 1,
          },
          {
            word: 'seem befit goat',
            count: 1,
          },
          {
            word: 'befit goat sport',
            count: 1,
          },
          {
            word: 'goat sport imagine',
            count: 1,
          },
          {
            word: 'sport imagine nike',
            count: 1,
          },
          {
            word: 'imagine nike puma',
            count: 1,
          },
          {
            word: 'nike puma adidas',
            count: 2,
          },
          {
            word: 'puma adidas would',
            count: 1,
          },
          {
            word: 'adidas would pulling',
            count: 1,
          },
          {
            word: 'would pulling stops',
            count: 1,
          },
          {
            word: 'pulling stops logo',
            count: 1,
          },
          {
            word: 'stops logo branding',
            count: 1,
          },
          {
            word: 'logo branding buzz',
            count: 1,
          },
          {
            word: 'branding buzz social',
            count: 1,
          },
          {
            word: 'buzz social puts',
            count: 1,
          },
          {
            word: 'social puts three',
            count: 1,
          },
          {
            word: 'puts three decadelong',
            count: 1,
          },
          {
            word: 'three decadelong erstwhile',
            count: 1,
          },
          {
            word: 'decadelong erstwhile partnership',
            count: 1,
          },
          {
            word: 'erstwhile partnership nike',
            count: 1,
          },
          {
            word: 'partnership nike httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'human toilet nasty',
            count: 1,
          },
          {
            word: 'toilet nasty sitting',
            count: 1,
          },
          {
            word: 'nasty sitting waiting',
            count: 1,
          },
          {
            word: 'sitting waiting collect',
            count: 1,
          },
          {
            word: 'waiting collect piss',
            count: 1,
          },
          {
            word: 'collect piss shit',
            count: 1,
          },
          {
            word: 'piss shit souvenir',
            count: 1,
          },
          {
            word: 'shit souvenir ultimate',
            count: 1,
          },
          {
            word: 'souvenir ultimate predator',
            count: 1,
          },
          {
            word: 'ultimate predator puma',
            count: 1,
          },
          {
            word: 'predator puma femdom',
            count: 1,
          },
          {
            word: 'puma femdom beta',
            count: 1,
          },
          {
            word: 'femdom beta cuck',
            count: 1,
          },
          {
            word: 'beta cuck loser',
            count: 1,
          },
          {
            word: 'cuck loser white',
            count: 1,
          },
          {
            word: 'loser white charlotte',
            count: 1,
          },
          {
            word: 'white charlotte greensboro',
            count: 1,
          },
          {
            word: 'charlotte greensboro raleigh',
            count: 1,
          },
          {
            word: 'greensboro raleigh rock',
            count: 1,
          },
          {
            word: 'raleigh rock hill',
            count: 1,
          },
          {
            word: 'rock hill durham',
            count: 1,
          },
          {
            word: 'hill durham brattylatina',
            count: 1,
          },
          {
            word: 'durham brattylatina latinaslut',
            count: 1,
          },
          {
            word: 'brattylatina latinaslut morena',
            count: 1,
          },
          {
            word: 'latinaslut morena thick',
            count: 1,
          },
          {
            word: 'morena thick httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'thick httpstcoldfmmz httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'andysfootyshirt cadecoweiigoat shirtsofneil',
            count: 1,
          },
          {
            word: 'cadecoweiigoat shirtsofneil cook',
            count: 1,
          },
          {
            word: 'shirtsofneil cook unethical',
            count: 1,
          },
          {
            word: 'cook unethical consumption',
            count: 1,
          },
          {
            word: 'unethical consumption pretending',
            count: 1,
          },
          {
            word: 'consumption pretending like',
            count: 1,
          },
          {
            word: 'pretending like official',
            count: 1,
          },
          {
            word: 'like official nike',
            count: 1,
          },
          {
            word: 'official nike puma',
            count: 1,
          },
          {
            word: 'puma adidas garbage',
            count: 1,
          },
          {
            word: 'adidas garbage dense',
            count: 1,
          },
        ],
        neutral_unigram: [
          {
            word: 'mawrongzjnr',
            count: 1,
          },
          {
            word: 'nooits',
            count: 1,
          },
          {
            word: 'puma',
            count: 40,
          },
          {
            word: 'httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'yashkarnwal',
            count: 1,
          },
          {
            word: 'ordered',
            count: 1,
          },
          {
            word: 'pair',
            count: 1,
          },
          {
            word: 'shoes',
            count: 2,
          },
          {
            word: 'birthday',
            count: 16,
          },
          {
            word: 'gift',
            count: 1,
          },
          {
            word: 'found',
            count: 1,
          },
          {
            word: 'flipkart',
            count: 1,
          },
          {
            word: 'delivered',
            count: 1,
          },
          {
            word: 'jimmythorn',
            count: 1,
          },
          {
            word: 'bazbowler',
            count: 1,
          },
          {
            word: 'kaaiot',
            count: 1,
          },
          {
            word: 'szlaban',
            count: 1,
          },
          {
            word: 'melizwalls',
            count: 1,
          },
          {
            word: 'notwuk',
            count: 1,
          },
          {
            word: 'starmakeriseye',
            count: 1,
          },
          {
            word: 'cookeben',
            count: 1,
          },
          {
            word: 'gazellassss',
            count: 1,
          },
          {
            word: 'akkyn',
            count: 1,
          },
          {
            word: 'asifmittal',
            count: 1,
          },
          {
            word: 'creeper',
            count: 1,
          },
          {
            word: 'mhsolis',
            count: 1,
          },
          {
            word: 'yfkdrags',
            count: 1,
          },
          {
            word: 'portb',
            count: 1,
          },
          {
            word: 'noelrz',
            count: 1,
          },
          {
            word: 'pumalovedragon',
            count: 1,
          },
          {
            word: 'seanclose',
            count: 1,
          },
          {
            word: 'taniaaass',
            count: 1,
          },
          {
            word: 'sakuyafgo',
            count: 1,
          },
          {
            word: 'cmillenbine',
            count: 1,
          },
          {
            word: 'slmakab',
            count: 1,
          },
          {
            word: 'carollaannee',
            count: 1,
          },
          {
            word: 'lilismr',
            count: 1,
          },
          {
            word: 'check',
            count: 15,
          },
          {
            word: 'pinned',
            count: 15,
          },
          {
            word: 'javiermcca',
            count: 1,
          },
          {
            word: 'sadgirlll',
            count: 1,
          },
          {
            word: 'doniamakhlouf',
            count: 1,
          },
          {
            word: 'franckkennedy',
            count: 1,
          },
          {
            word: 'zahrakrrrm',
            count: 1,
          },
          {
            word: 'yongsunnieverse',
            count: 1,
          },
          {
            word: 'maitezgz',
            count: 1,
          },
          {
            word: 'wannapa',
            count: 1,
          },
          {
            word: 'shotbyamccoy',
            count: 1,
          },
          {
            word: 'napalm',
            count: 1,
          },
          {
            word: 'dallahae',
            count: 1,
          },
          {
            word: 'moorocho',
            count: 1,
          },
          {
            word: 'muffinekshawna',
            count: 1,
          },
          {
            word: 'mmmehran',
            count: 1,
          },
          {
            word: 'pumapalace',
            count: 1,
          },
          {
            word: 'maktarli',
            count: 1,
          },
          {
            word: 'lenikalulu',
            count: 1,
          },
          {
            word: 'koechs',
            count: 1,
          },
          {
            word: 'maanrole',
            count: 1,
          },
          {
            word: 'chukaog',
            count: 1,
          },
          {
            word: 'miaojiang',
            count: 1,
          },
          {
            word: 'libya',
            count: 1,
          },
          {
            word: 'crown',
            count: 1,
          },
          {
            word: 'dont',
            count: 5,
          },
          {
            word: 'stress',
            count: 1,
          },
          {
            word: 'freeze',
            count: 1,
          },
          {
            word: 'light',
            count: 1,
          },
          {
            word: 'dear',
            count: 1,
          },
          {
            word: 'sputnik',
            count: 1,
          },
          {
            word: 'ghosting',
            count: 1,
          },
          {
            word: 'back',
            count: 1,
          },
          {
            word: 'httpstcouzjmzrveq',
            count: 1,
          },
          {
            word: 'swansonma',
            count: 1,
          },
          {
            word: 'tqhung',
            count: 1,
          },
          {
            word: 'rafaeltatis',
            count: 1,
          },
          {
            word: 'jaspreetkbansal',
            count: 1,
          },
          {
            word: 'ehsaniran',
            count: 1,
          },
          {
            word: 'javedadil',
            count: 1,
          },
          {
            word: 'nicocarnage',
            count: 1,
          },
          {
            word: 'ksimorri',
            count: 1,
          },
          {
            word: 'ianreds',
            count: 1,
          },
          {
            word: 'romantickasap',
            count: 1,
          },
          {
            word: 'swerrii',
            count: 1,
          },
          {
            word: 'nunesjosito',
            count: 1,
          },
          {
            word: 'giant',
            count: 1,
          },
          {
            word: 'mokgadivictor',
            count: 1,
          },
          {
            word: 'elmaelmasry',
            count: 1,
          },
          {
            word: 'isaadansari',
            count: 1,
          },
          {
            word: 'zonnbn',
            count: 1,
          },
          {
            word: 'brandopuma',
            count: 1,
          },
          {
            word: 'klkl',
            count: 1,
          },
          {
            word: 'juventudgh',
            count: 1,
          },
          {
            word: 'colonyuunagi',
            count: 1,
          },
          {
            word: 'almasnoora',
            count: 1,
          },
          {
            word: 'wallacepjw',
            count: 1,
          },
          {
            word: 'like',
            count: 3,
          },
          {
            word: 'ford',
            count: 1,
          },
          {
            word: 'missrozapepper',
            count: 15,
          },
          {
            word: 'davido',
            count: 15,
          },
          {
            word: 'performing',
            count: 15,
          },
          {
            word: 'party',
            count: 15,
          },
          {
            word: 'athletico',
            count: 15,
          },
          {
            word: 'madrid',
            count: 15,
          },
          {
            word: 'player',
            count: 15,
          },
          {
            word: 'fellow',
            count: 15,
          },
          {
            word: 'global',
            count: 15,
          },
          {
            word: 'ambassador',
            count: 15,
          },
          {
            word: 'memphis',
            count: 15,
          },
          {
            word: 'depay',
            count: 15,
          },
          {
            word: 'bowerskamd',
            count: 1,
          },
          {
            word: 'empressaura',
            count: 1,
          },
          {
            word: 'rafaelars',
            count: 1,
          },
          {
            word: 'simgejkjm',
            count: 1,
          },
          {
            word: 'garaku',
            count: 1,
          },
          {
            word: 'hajiskyyy',
            count: 1,
          },
          {
            word: 'buttameer',
            count: 1,
          },
          {
            word: 'regret',
            count: 1,
          },
          {
            word: 'topcop',
            count: 1,
          },
          {
            word: 'killadikamal',
            count: 1,
          },
          {
            word: 'valorshero',
            count: 1,
          },
          {
            word: 'toocozymarcus',
            count: 1,
          },
          {
            word: 'ahsanwahid',
            count: 1,
          },
          {
            word: 'ssjgoten',
            count: 1,
          },
          {
            word: 'maurom',
            count: 1,
          },
          {
            word: 'something',
            count: 1,
          },
          {
            word: 'bloversmile',
            count: 1,
          },
          {
            word: 'canuluer',
            count: 1,
          },
          {
            word: 'pumakun',
            count: 1,
          },
          {
            word: 'merrittworthy',
            count: 1,
          },
          {
            word: 'tarnason',
            count: 1,
          },
          {
            word: 'shipmatusri',
            count: 1,
          },
          {
            word: 'longiek',
            count: 1,
          },
          {
            word: 'nigerian',
            count: 1,
          },
          {
            word: 'restaurant',
            count: 1,
          },
          {
            word: 'area',
            count: 1,
          },
          {
            word: 'filling',
            count: 1,
          },
          {
            word: 'station',
            count: 1,
          },
          {
            word: 'next',
            count: 1,
          },
          {
            word: 'zathu',
            count: 1,
          },
          {
            word: 'trading',
            count: 1,
          },
          {
            word: 'jollof',
            count: 1,
          },
          {
            word: 'shyammeerasingh',
            count: 1,
          },
          {
            word: 'phone',
            count: 1,
          },
          {
            word: 'apple',
            count: 1,
          },
          {
            word: 'iphonecameranikon',
            count: 1,
          },
          {
            word: 'sketcherstshirt',
            count: 1,
          },
          {
            word: 'zara',
            count: 1,
          },
          {
            word: 'paint',
            count: 1,
          },
          {
            word: 'hampm',
            count: 1,
          },
          {
            word: 'jacket',
            count: 1,
          },
          {
            word: 'timothycas',
            count: 1,
          },
          {
            word: 'charliemonroe',
            count: 1,
          },
          {
            word: 'siiryuregim',
            count: 1,
          },
          {
            word: 'emmamcgilloway',
            count: 1,
          },
          {
            word: 'oupsray',
            count: 1,
          },
          {
            word: 'alicliskan',
            count: 1,
          },
          {
            word: 'nooruddin',
            count: 1,
          },
          {
            word: 'omery',
            count: 1,
          },
          {
            word: 'rumournoff',
            count: 1,
          },
          {
            word: 'tamurutkowski',
            count: 1,
          },
          {
            word: 'pumabaako',
            count: 1,
          },
          {
            word: 'shofunaki',
            count: 1,
          },
          {
            word: 'lollyratsiu',
            count: 1,
          },
          {
            word: 'fastgeraint',
            count: 1,
          },
          {
            word: 'chalxdon',
            count: 1,
          },
          {
            word: 'dwderizzo',
            count: 1,
          },
          {
            word: 'jonsley',
            count: 1,
          },
          {
            word: 'doukalucy',
            count: 1,
          },
          {
            word: 'ayato',
            count: 1,
          },
          {
            word: 'nhsnclicb',
            count: 1,
          },
          {
            word: 'jomaalarimi',
            count: 1,
          },
          {
            word: 'abuqaze',
            count: 1,
          },
          {
            word: 'ppaleoartist',
            count: 3,
          },
          {
            word: 'reminder',
            count: 4,
          },
          {
            word: 'extinct',
            count: 4,
          },
          {
            word: 'animals',
            count: 4,
          },
          {
            word: 'need',
            count: 4,
          },
          {
            word: 'common',
            count: 9,
          },
          {
            word: 'names',
            count: 13,
          },
          {
            word: 'generic',
            count: 6,
          },
          {
            word: 'work',
            count: 4,
          },
          {
            word: 'perfectly',
            count: 4,
          },
          {
            word: 'fine',
            count: 4,
          },
          {
            word: 'pions',
            count: 5,
          },
          {
            word: 'torc',
            count: 5,
          },
          {
            word: 'unmanned',
            count: 5,
          },
          {
            word: 'turret',
            count: 10,
          },
          {
            word: 'designed',
            count: 5,
          },
          {
            word: 'brazil',
            count: 5,
          },
          {
            word: 'brings',
            count: 5,
          },
          {
            word: 'vehicle',
            count: 5,
          },
          {
            word: 'level',
            count: 5,
          },
          {
            word: 'firepower',
            count: 5,
          },
          {
            word: 'comparable',
            count: 5,
          },
          {
            word: 'lowerio',
            count: 1,
          },
          {
            word: 'thunderdarcy',
            count: 1,
          },
          {
            word: 'goodforsmething',
            count: 1,
          },
          {
            word: 'growstudiohq',
            count: 1,
          },
          {
            word: 'shimaaa',
            count: 1,
          },
          {
            word: 'unstoppablebs',
            count: 1,
          },
          {
            word: 'xiiiix',
            count: 1,
          },
          {
            word: 'ebondarcom',
            count: 1,
          },
          {
            word: 'konsonanti',
            count: 1,
          },
          {
            word: 'kingmoye',
            count: 1,
          },
          {
            word: 'seamyfarmer',
            count: 1,
          },
          {
            word: 'furkancobaan',
            count: 1,
          },
          {
            word: 'anil',
            count: 1,
          },
          {
            word: 'alirezadini',
            count: 1,
          },
          {
            word: 'bhanbhroamjad',
            count: 1,
          },
          {
            word: 'wirwerdenreich',
            count: 1,
          },
          {
            word: 'clebrun',
            count: 1,
          },
          {
            word: 'ewingjeremy',
            count: 1,
          },
          {
            word: 'takooryouri',
            count: 1,
          },
          {
            word: 'collierwes',
            count: 2,
          },
          {
            word: 'deadmansrock',
            count: 2,
          },
          {
            word: 'caraembh',
            count: 2,
          },
          {
            word: 'skunktwine',
            count: 2,
          },
          {
            word: 'mohamme',
            count: 2,
          },
          {
            word: 'zakizaidi',
            count: 2,
          },
          {
            word: 'jamessbutler',
            count: 2,
          },
          {
            word: 'zdamilaree',
            count: 2,
          },
          {
            word: 'rracarvalho',
            count: 2,
          },
          {
            word: 'ropukdeb',
            count: 2,
          },
          {
            word: 'bankykanta',
            count: 2,
          },
          {
            word: 'pbacelar',
            count: 2,
          },
          {
            word: 'kidrauhlgirl',
            count: 2,
          },
          {
            word: 'dookydabarber',
            count: 2,
          },
          {
            word: 'oluafolabi',
            count: 2,
          },
          {
            word: 'claralaflore',
            count: 2,
          },
          {
            word: 'wigmorewelsh',
            count: 2,
          },
          {
            word: 'ayaibrahem',
            count: 2,
          },
          {
            word: 'lulpuma',
            count: 2,
          },
          {
            word: 'rociowittib',
            count: 2,
          },
          {
            word: 'mardeamal',
            count: 2,
          },
          {
            word: 'calumcrane',
            count: 1,
          },
          {
            word: 'gochimin',
            count: 1,
          },
          {
            word: 'iiik',
            count: 1,
          },
          {
            word: 'yansaladino',
            count: 1,
          },
          {
            word: 'rebeccaradovsk',
            count: 1,
          },
          {
            word: 'aboabaman',
            count: 1,
          },
          {
            word: 'ponkotupyoko',
            count: 1,
          },
          {
            word: 'rksharma',
            count: 1,
          },
          {
            word: 'dmirandaaaa',
            count: 1,
          },
          {
            word: 'uhle',
            count: 1,
          },
          {
            word: 'estelleh',
            count: 1,
          },
          {
            word: 'manjeet',
            count: 1,
          },
          {
            word: 'karadeniz',
            count: 1,
          },
          {
            word: 'salifouide',
            count: 1,
          },
          {
            word: 'scalteurbh',
            count: 1,
          },
          {
            word: 'pumatapia',
            count: 1,
          },
          {
            word: 'meltural',
            count: 1,
          },
          {
            word: 'jayseamusic',
            count: 1,
          },
          {
            word: 'oshiosleep',
            count: 1,
          },
          {
            word: 'esqueben',
            count: 1,
          },
          {
            word: 'datatapper',
            count: 1,
          },
          {
            word: 'goaljpofficial',
            count: 1,
          },
          {
            word: 'drop',
            count: 2,
          },
          {
            word: 'yourshttpstcotalxjgacz',
            count: 1,
          },
          {
            word: 'hippopotamus',
            count: 2,
          },
          {
            word: 'gorilla',
            count: 1,
          },
          {
            word: 'octopus',
            count: 1,
          },
          {
            word: 'namesbut',
            count: 1,
          },
          {
            word: 'long',
            count: 2,
          },
          {
            word: 'complex',
            count: 1,
          },
          {
            word: 'dennisj',
            count: 1,
          },
          {
            word: 'whoknowsmysoul',
            count: 1,
          },
          {
            word: 'kaabuame',
            count: 1,
          },
          {
            word: 'kcdmsmkecfngere',
            count: 1,
          },
          {
            word: 'phronesianos',
            count: 1,
          },
          {
            word: 'yrsweet',
            count: 1,
          },
          {
            word: 'easyfires',
            count: 1,
          },
          {
            word: 'keyvanrahmadi',
            count: 1,
          },
          {
            word: 'macanth',
            count: 1,
          },
          {
            word: 'vhpeuch',
            count: 1,
          },
          {
            word: 'takekittyshit',
            count: 1,
          },
          {
            word: 'esdglobaltech',
            count: 1,
          },
          {
            word: 'myramalways',
            count: 1,
          },
          {
            word: 'jazzlynpuma',
            count: 1,
          },
          {
            word: 'iiionceau',
            count: 1,
          },
          {
            word: 'nidhi',
            count: 1,
          },
          {
            word: 'leeorocha',
            count: 1,
          },
          {
            word: 'bushmanind',
            count: 1,
          },
          {
            word: 'wales',
            count: 1,
          },
          {
            word: 'murdocklvrr',
            count: 1,
          },
          {
            word: 'lokopeter',
            count: 1,
          },
          {
            word: 'knoxmack',
            count: 1,
          },
          {
            word: 'womanwomanwomam',
            count: 1,
          },
          {
            word: 'forscorn',
            count: 1,
          },
          {
            word: 'leothatguy',
            count: 1,
          },
          {
            word: 'malqadi',
            count: 1,
          },
          {
            word: 'carolinevoelke',
            count: 1,
          },
          {
            word: 'abujasher',
            count: 1,
          },
          {
            word: 'yamadaissei',
            count: 1,
          },
          {
            word: 'butchershook',
            count: 1,
          },
          {
            word: 'muets',
            count: 1,
          },
          {
            word: 'emilytu',
            count: 1,
          },
          {
            word: 'dubaioutca',
            count: 1,
          },
          {
            word: 'batapuma',
            count: 1,
          },
          {
            word: 'mysticmonae',
            count: 1,
          },
          {
            word: 'anacarolbooks',
            count: 1,
          },
          {
            word: 'xskillerr',
            count: 1,
          },
          {
            word: 'callumwilson',
            count: 1,
          },
          {
            word: 'liakeek',
            count: 1,
          },
          {
            word: 'melektayfun',
            count: 1,
          },
          {
            word: 'miriamjablon',
            count: 1,
          },
          {
            word: 'johnathank',
            count: 1,
          },
          {
            word: 'jasonv',
            count: 1,
          },
          {
            word: 'abobadralsu',
            count: 1,
          },
          {
            word: 'supremedonzeeky',
            count: 1,
          },
          {
            word: 'hyraiora',
            count: 1,
          },
          {
            word: 'pelonmanny',
            count: 1,
          },
          {
            word: 'iamomahbella',
            count: 1,
          },
          {
            word: 'gregorigregg',
            count: 1,
          },
          {
            word: 'hongota',
            count: 1,
          },
          {
            word: 'brentduke',
            count: 1,
          },
          {
            word: 'fahmiishak',
            count: 1,
          },
          {
            word: 'perhermanrud',
            count: 1,
          },
          {
            word: 'crazyluis',
            count: 1,
          },
          {
            word: 'galanoleukos',
            count: 1,
          },
          {
            word: 'marielamarie',
            count: 1,
          },
          {
            word: 'roadkillsindia',
            count: 1,
          },
          {
            word: 'mikaillxx',
            count: 1,
          },
          {
            word: 'okellodoc',
            count: 1,
          },
          {
            word: 'vynxpmjnml',
            count: 1,
          },
          {
            word: 'rodgerssan',
            count: 1,
          },
          {
            word: 'adaziecallister',
            count: 1,
          },
          {
            word: 'filipaclopess',
            count: 1,
          },
          {
            word: 'hobisdimpleee',
            count: 1,
          },
          {
            word: 'yavahlns',
            count: 1,
          },
          {
            word: 'yarelisramona',
            count: 1,
          },
          {
            word: 'aurallduck',
            count: 1,
          },
          {
            word: 'upperuser',
            count: 1,
          },
          {
            word: 'slimguy',
            count: 1,
          },
          {
            word: 'bignfuzzy',
            count: 1,
          },
          {
            word: 'gpolitics',
            count: 1,
          },
          {
            word: 'esraaelzany',
            count: 1,
          },
          {
            word: 'burhankamal',
            count: 1,
          },
          {
            word: 'juanseb',
            count: 1,
          },
          {
            word: 'shippuma',
            count: 1,
          },
          {
            word: 'eveferreira',
            count: 1,
          },
          {
            word: 'oxmar',
            count: 1,
          },
          {
            word: 'djohnson',
            count: 1,
          },
          {
            word: 'yanpingzhang',
            count: 1,
          },
          {
            word: 'mdeleonretired',
            count: 1,
          },
          {
            word: 'live',
            count: 3,
          },
          {
            word: 'stewie',
            count: 2,
          },
          {
            word: 'httpstcotygduzrbtry',
            count: 1,
          },
          {
            word: 'code',
            count: 2,
          },
          {
            word: 'sneakers',
            count: 2,
          },
          {
            word: 'httpstcokpgcwaqho',
            count: 1,
          },
          {
            word: 'uithemovie',
            count: 5,
          },
          {
            word: 'stockmarketcrash',
            count: 5,
          },
          {
            word: 'saipallavi',
            count: 5,
          },
          {
            word: 'hariharaveeramallu',
            count: 5,
          },
          {
            word: 'elvishyadav',
            count: 5,
          },
          {
            word: 'gamechanger',
            count: 5,
          },
          {
            word: 'kanguva',
            count: 5,
          },
          {
            word: 'fake',
            count: 5,
          },
          {
            word: 'news',
            count: 7,
          },
          {
            word: 'thegoathttpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'declanhort',
            count: 1,
          },
          {
            word: 'makelelejunior',
            count: 1,
          },
          {
            word: 'alfredorenfra',
            count: 1,
          },
          {
            word: 'pumadelmonte',
            count: 1,
          },
          {
            word: 'mcgeesinead',
            count: 1,
          },
          {
            word: 'shibrumour',
            count: 1,
          },
          {
            word: 'patriciafranco',
            count: 1,
          },
          {
            word: 'omarsabat',
            count: 1,
          },
          {
            word: 'cheke',
            count: 1,
          },
          {
            word: 'ffanoch',
            count: 1,
          },
          {
            word: 'ruanvieirar',
            count: 1,
          },
          {
            word: 'matthewswain',
            count: 1,
          },
          {
            word: 'eagleny',
            count: 1,
          },
          {
            word: 'ozpolataptullah',
            count: 1,
          },
          {
            word: 'meredithrose',
            count: 1,
          },
          {
            word: 'rahul',
            count: 1,
          },
          {
            word: 'niels',
            count: 1,
          },
          {
            word: 'magutaku',
            count: 1,
          },
          {
            word: 'kingsndimele',
            count: 1,
          },
          {
            word: 'pumasouthafrica',
            count: 1,
          },
          {
            word: 'pumahoops',
            count: 1,
          },
          {
            word: 'lamelo',
            count: 2,
          },
          {
            word: 'ball',
            count: 2,
          },
          {
            word: 'porsche',
            count: 4,
          },
          {
            word: 'step',
            count: 2,
          },
          {
            word: 'fast',
            count: 2,
          },
          {
            word: 'lanelink',
            count: 2,
          },
          {
            word: 'httpstcoolcsrsxqxlameloball',
            count: 2,
          },
          {
            word: 'nbanews',
            count: 2,
          },
          {
            word: 'pumapumaandporsche',
            count: 2,
          },
          {
            word: 'thefastlane',
            count: 2,
          },
          {
            word: 'httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'thegreysole',
            count: 1,
          },
          {
            word: 'socks',
            count: 1,
          },
          {
            word: 'available',
            count: 2,
          },
          {
            word: 'women',
            count: 1,
          },
          {
            word: 'pack',
            count: 1,
          },
          {
            word: 'send',
            count: 1,
          },
          {
            word: 'visit',
            count: 1,
          },
          {
            word: 'website',
            count: 1,
          },
          {
            word: 'httpstcoixdocyvk',
            count: 1,
          },
          {
            word: 'roachcalla',
            count: 1,
          },
          {
            word: 'jyrago',
            count: 1,
          },
          {
            word: 'mingifixsong',
            count: 1,
          },
          {
            word: 'majdialbana',
            count: 1,
          },
          {
            word: 'tonymelendez',
            count: 1,
          },
          {
            word: 'stanby',
            count: 1,
          },
          {
            word: 'shahannayoung',
            count: 1,
          },
          {
            word: 'jokerfiko',
            count: 1,
          },
          {
            word: 'shwkyamanaka',
            count: 1,
          },
          {
            word: 'kaccccchan',
            count: 1,
          },
          {
            word: 'luxluct',
            count: 1,
          },
          {
            word: 'fetichistaputin',
            count: 1,
          },
          {
            word: 'pumars',
            count: 1,
          },
          {
            word: 'animenougat',
            count: 1,
          },
          {
            word: 'eliasyusuff',
            count: 1,
          },
          {
            word: 'nadahonor',
            count: 1,
          },
          {
            word: 'sazaross',
            count: 1,
          },
          {
            word: 'frwansham',
            count: 1,
          },
          {
            word: 'jonathandkeck',
            count: 1,
          },
          {
            word: 'justsodamngood',
            count: 1,
          },
          {
            word: 'jmool',
            count: 1,
          },
          {
            word: 'maentina',
            count: 1,
          },
          {
            word: 'thegoathttpstcomvnum',
            count: 1,
          },
          {
            word: 'snkrtwitr',
            count: 1,
          },
          {
            word: 'almost',
            count: 2,
          },
          {
            word: 'snswmns',
            count: 2,
          },
          {
            word: 'jordan',
            count: 2,
          },
          {
            word: 'retro',
            count: 2,
          },
          {
            word: 'high',
            count: 2,
          },
          {
            word: 'whitemetallic',
            count: 2,
          },
          {
            word: 'goldw',
            count: 2,
          },
          {
            word: 'httpstcoowfflyfzjxp',
            count: 2,
          },
          {
            word: 'httpstcopqbjaksddwt',
            count: 1,
          },
          {
            word: 'httpstcomkgmpurqniwmns',
            count: 1,
          },
          {
            word: 'amor',
            count: 1,
          },
          {
            word: 'httpstcomuvnkyhytry',
            count: 1,
          },
          {
            word: 'httpstconfymgs',
            count: 1,
          },
          {
            word: 'seiyuusunika',
            count: 1,
          },
          {
            word: 'miharu',
            count: 2,
          },
          {
            word: 'hanai',
            count: 2,
          },
          {
            word: 'wmns',
            count: 2,
          },
          {
            word: 'mayze',
            count: 2,
          },
          {
            word: 'thrifted',
            count: 2,
          },
          {
            word: 'white',
            count: 3,
          },
          {
            word: 'little',
            count: 2,
          },
          {
            word: 'sister',
            count: 2,
          },
          {
            word: 'httpstcouppihntdn',
            count: 2,
          },
          {
            word: 'petercrouch',
            count: 1,
          },
          {
            word: 'hoodie',
            count: 1,
          },
          {
            word: 'looks',
            count: 1,
          },
          {
            word: 'someone',
            count: 1,
          },
          {
            word: 'grabbed',
            count: 1,
          },
          {
            word: 'fill',
            count: 1,
          },
          {
            word: 'seat',
            count: 1,
          },
          {
            word: 'waiting',
            count: 1,
          },
          {
            word: 'didi',
            count: 1,
          },
          {
            word: 'hamann',
            count: 1,
          },
          {
            word: 'arrive',
            count: 1,
          },
          {
            word: 'gentlewong',
            count: 1,
          },
          {
            word: 'daejeon',
            count: 1,
          },
          {
            word: 'home',
            count: 1,
          },
          {
            word: 'away',
            count: 1,
          },
          {
            word: 'ktis',
            count: 1,
          },
          {
            word: 'httpstcodwrnlkbgov',
            count: 1,
          },
          {
            word: 'thegoathttpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'suede',
            count: 1,
          },
          {
            word: 'size',
            count: 1,
          },
          {
            word: 'delivery',
            count: 1,
          },
          {
            word: 'within',
            count: 1,
          },
          {
            word: 'nairobi',
            count: 1,
          },
          {
            word: 'pickup',
            count: 1,
          },
          {
            word: 'mtaani',
            count: 1,
          },
          {
            word: 'services',
            count: 1,
          },
          {
            word: 'httpstcocyxgdzxlb',
            count: 1,
          },
          {
            word: 'gyusphoria',
            count: 1,
          },
          {
            word: 'want',
            count: 1,
          },
          {
            word: 'peanits',
            count: 1,
          },
          {
            word: 'mouth',
            count: 1,
          },
          {
            word: 'eachmeet',
            count: 1,
          },
          {
            word: 'concertpm',
            count: 1,
          },
          {
            word: 'interestediveinkl',
            count: 1,
          },
          {
            word: 'httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'maturenl',
            count: 1,
          },
          {
            word: 'swede',
            count: 1,
          },
          {
            word: 'angel',
            count: 1,
          },
          {
            word: 'diamond',
            count: 1,
          },
          {
            word: 'angela',
            count: 1,
          },
          {
            word: 'aspen',
            count: 1,
          },
          {
            word: 'kinky',
            count: 1,
          },
          {
            word: 'pussyeating',
            count: 1,
          },
          {
            word: 'milf',
            count: 1,
          },
          {
            word: 'sexparty',
            count: 1,
          },
          {
            word: 'everything',
            count: 1,
          },
          {
            word: 'allowed',
            count: 1,
          },
          {
            word: 'thegoathttpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'hltco',
            count: 1,
          },
          {
            word: 'httpstcoutmvbes',
            count: 1,
          },
          {
            word: 'hardchorus',
            count: 1,
          },
          {
            word: 'full',
            count: 1,
          },
          {
            word: 'european',
            count: 1,
          },
          {
            word: 'version',
            count: 1,
          },
          {
            word: 'anyone',
            count: 1,
          },
          {
            word: 'interested',
            count: 1,
          },
          {
            word: 'thegoathttpstcodulysnr',
            count: 1,
          },
          {
            word: 'annapumakig',
            count: 1,
          },
          {
            word: 'peekaboo',
            count: 1,
          },
          {
            word: 'httpstcojnmcznni',
            count: 1,
          },
          {
            word: 'smash',
            count: 1,
          },
          {
            word: 'leather',
            count: 1,
          },
          {
            word: 'retail',
            count: 1,
          },
          {
            word: 'mandmdirect',
            count: 1,
          },
          {
            word: 'sale',
            count: 1,
          },
          {
            word: 'price',
            count: 1,
          },
          {
            word: 'link',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'sizes',
            count: 1,
          },
          {
            word: 'httpstcowtcknauzu',
            count: 1,
          },
        ],
        neutral_bigram: [
          {
            word: 'mawrongzjnr nooits',
            count: 1,
          },
          {
            word: 'nooits puma',
            count: 1,
          },
          {
            word: 'puma httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'yashkarnwal ordered',
            count: 1,
          },
          {
            word: 'ordered pair',
            count: 1,
          },
          {
            word: 'pair puma',
            count: 1,
          },
          {
            word: 'puma shoes',
            count: 1,
          },
          {
            word: 'shoes birthday',
            count: 1,
          },
          {
            word: 'birthday gift',
            count: 1,
          },
          {
            word: 'gift found',
            count: 1,
          },
          {
            word: 'found flipkart',
            count: 1,
          },
          {
            word: 'flipkart delivered',
            count: 1,
          },
          {
            word: 'jimmythorn bazbowler',
            count: 1,
          },
          {
            word: 'bazbowler kaaiot',
            count: 1,
          },
          {
            word: 'kaaiot szlaban',
            count: 1,
          },
          {
            word: 'szlaban melizwalls',
            count: 1,
          },
          {
            word: 'melizwalls notwuk',
            count: 1,
          },
          {
            word: 'notwuk starmakeriseye',
            count: 1,
          },
          {
            word: 'starmakeriseye cookeben',
            count: 1,
          },
          {
            word: 'cookeben gazellassss',
            count: 1,
          },
          {
            word: 'gazellassss akkyn',
            count: 1,
          },
          {
            word: 'akkyn asifmittal',
            count: 1,
          },
          {
            word: 'asifmittal creeper',
            count: 1,
          },
          {
            word: 'creeper mhsolis',
            count: 1,
          },
          {
            word: 'mhsolis yfkdrags',
            count: 1,
          },
          {
            word: 'yfkdrags portb',
            count: 1,
          },
          {
            word: 'portb noelrz',
            count: 1,
          },
          {
            word: 'noelrz pumalovedragon',
            count: 1,
          },
          {
            word: 'pumalovedragon seanclose',
            count: 1,
          },
          {
            word: 'seanclose taniaaass',
            count: 1,
          },
          {
            word: 'taniaaass sakuyafgo',
            count: 1,
          },
          {
            word: 'sakuyafgo cmillenbine',
            count: 1,
          },
          {
            word: 'cmillenbine slmakab',
            count: 1,
          },
          {
            word: 'slmakab carollaannee',
            count: 1,
          },
          {
            word: 'carollaannee lilismr',
            count: 1,
          },
          {
            word: 'lilismr check',
            count: 1,
          },
          {
            word: 'check pinned',
            count: 15,
          },
          {
            word: 'javiermcca sadgirlll',
            count: 1,
          },
          {
            word: 'sadgirlll doniamakhlouf',
            count: 1,
          },
          {
            word: 'doniamakhlouf franckkennedy',
            count: 1,
          },
          {
            word: 'franckkennedy zahrakrrrm',
            count: 1,
          },
          {
            word: 'zahrakrrrm yongsunnieverse',
            count: 1,
          },
          {
            word: 'yongsunnieverse maitezgz',
            count: 1,
          },
          {
            word: 'maitezgz wannapa',
            count: 1,
          },
          {
            word: 'wannapa shotbyamccoy',
            count: 1,
          },
          {
            word: 'shotbyamccoy napalm',
            count: 1,
          },
          {
            word: 'napalm dallahae',
            count: 1,
          },
          {
            word: 'dallahae moorocho',
            count: 1,
          },
          {
            word: 'moorocho muffinekshawna',
            count: 1,
          },
          {
            word: 'muffinekshawna mmmehran',
            count: 1,
          },
          {
            word: 'mmmehran pumapalace',
            count: 1,
          },
          {
            word: 'pumapalace maktarli',
            count: 1,
          },
          {
            word: 'maktarli lenikalulu',
            count: 1,
          },
          {
            word: 'lenikalulu koechs',
            count: 1,
          },
          {
            word: 'koechs maanrole',
            count: 1,
          },
          {
            word: 'maanrole chukaog',
            count: 1,
          },
          {
            word: 'chukaog miaojiang',
            count: 1,
          },
          {
            word: 'miaojiang libya',
            count: 1,
          },
          {
            word: 'libya check',
            count: 1,
          },
          {
            word: 'crown dont',
            count: 1,
          },
          {
            word: 'dont stress',
            count: 1,
          },
          {
            word: 'stress freeze',
            count: 1,
          },
          {
            word: 'freeze light',
            count: 1,
          },
          {
            word: 'light dear',
            count: 1,
          },
          {
            word: 'dear sputnik',
            count: 1,
          },
          {
            word: 'sputnik ghosting',
            count: 1,
          },
          {
            word: 'ghosting back',
            count: 1,
          },
          {
            word: 'back puma',
            count: 1,
          },
          {
            word: 'puma httpstcouzjmzrveq',
            count: 1,
          },
          {
            word: 'swansonma tqhung',
            count: 1,
          },
          {
            word: 'tqhung rafaeltatis',
            count: 1,
          },
          {
            word: 'rafaeltatis jaspreetkbansal',
            count: 1,
          },
          {
            word: 'jaspreetkbansal ehsaniran',
            count: 1,
          },
          {
            word: 'ehsaniran javedadil',
            count: 1,
          },
          {
            word: 'javedadil nicocarnage',
            count: 1,
          },
          {
            word: 'nicocarnage ksimorri',
            count: 1,
          },
          {
            word: 'ksimorri ianreds',
            count: 1,
          },
          {
            word: 'ianreds romantickasap',
            count: 1,
          },
          {
            word: 'romantickasap swerrii',
            count: 1,
          },
          {
            word: 'swerrii nunesjosito',
            count: 1,
          },
          {
            word: 'nunesjosito giant',
            count: 1,
          },
          {
            word: 'giant mokgadivictor',
            count: 1,
          },
          {
            word: 'mokgadivictor elmaelmasry',
            count: 1,
          },
          {
            word: 'elmaelmasry isaadansari',
            count: 1,
          },
          {
            word: 'isaadansari zonnbn',
            count: 1,
          },
          {
            word: 'zonnbn brandopuma',
            count: 1,
          },
          {
            word: 'brandopuma klkl',
            count: 1,
          },
          {
            word: 'klkl juventudgh',
            count: 1,
          },
          {
            word: 'juventudgh colonyuunagi',
            count: 1,
          },
          {
            word: 'colonyuunagi almasnoora',
            count: 1,
          },
          {
            word: 'almasnoora check',
            count: 1,
          },
          {
            word: 'wallacepjw like',
            count: 1,
          },
          {
            word: 'like ford',
            count: 1,
          },
          {
            word: 'ford puma',
            count: 1,
          },
          {
            word: 'missrozapepper davido',
            count: 15,
          },
          {
            word: 'davido performing',
            count: 15,
          },
          {
            word: 'performing birthday',
            count: 15,
          },
          {
            word: 'birthday party',
            count: 15,
          },
          {
            word: 'party athletico',
            count: 15,
          },
          {
            word: 'athletico madrid',
            count: 15,
          },
          {
            word: 'madrid player',
            count: 15,
          },
          {
            word: 'player fellow',
            count: 15,
          },
          {
            word: 'fellow puma',
            count: 15,
          },
          {
            word: 'puma global',
            count: 15,
          },
          {
            word: 'global ambassador',
            count: 15,
          },
          {
            word: 'ambassador memphis',
            count: 15,
          },
          {
            word: 'memphis depay',
            count: 15,
          },
          {
            word: 'bowerskamd empressaura',
            count: 1,
          },
          {
            word: 'empressaura rafaelars',
            count: 1,
          },
          {
            word: 'rafaelars simgejkjm',
            count: 1,
          },
          {
            word: 'simgejkjm garaku',
            count: 1,
          },
          {
            word: 'garaku hajiskyyy',
            count: 1,
          },
          {
            word: 'hajiskyyy buttameer',
            count: 1,
          },
          {
            word: 'buttameer regret',
            count: 1,
          },
          {
            word: 'regret topcop',
            count: 1,
          },
          {
            word: 'topcop killadikamal',
            count: 1,
          },
          {
            word: 'killadikamal valorshero',
            count: 1,
          },
          {
            word: 'valorshero toocozymarcus',
            count: 1,
          },
          {
            word: 'toocozymarcus ahsanwahid',
            count: 1,
          },
          {
            word: 'ahsanwahid ssjgoten',
            count: 1,
          },
          {
            word: 'ssjgoten maurom',
            count: 1,
          },
          {
            word: 'maurom something',
            count: 1,
          },
          {
            word: 'something bloversmile',
            count: 1,
          },
          {
            word: 'bloversmile canuluer',
            count: 1,
          },
          {
            word: 'canuluer pumakun',
            count: 1,
          },
          {
            word: 'pumakun merrittworthy',
            count: 1,
          },
          {
            word: 'merrittworthy tarnason',
            count: 1,
          },
          {
            word: 'tarnason shipmatusri',
            count: 1,
          },
          {
            word: 'shipmatusri check',
            count: 1,
          },
          {
            word: 'longiek nigerian',
            count: 1,
          },
          {
            word: 'nigerian restaurant',
            count: 1,
          },
          {
            word: 'restaurant area',
            count: 1,
          },
          {
            word: 'area puma',
            count: 1,
          },
          {
            word: 'puma filling',
            count: 1,
          },
          {
            word: 'filling station',
            count: 1,
          },
          {
            word: 'station next',
            count: 1,
          },
          {
            word: 'next zathu',
            count: 1,
          },
          {
            word: 'zathu trading',
            count: 1,
          },
          {
            word: 'trading jollof',
            count: 1,
          },
          {
            word: 'shyammeerasingh phone',
            count: 1,
          },
          {
            word: 'phone apple',
            count: 1,
          },
          {
            word: 'apple iphonecameranikon',
            count: 1,
          },
          {
            word: 'iphonecameranikon shoes',
            count: 1,
          },
          {
            word: 'shoes sketcherstshirt',
            count: 1,
          },
          {
            word: 'sketcherstshirt zara',
            count: 1,
          },
          {
            word: 'zara paint',
            count: 1,
          },
          {
            word: 'paint hampm',
            count: 1,
          },
          {
            word: 'hampm jacket',
            count: 1,
          },
          {
            word: 'timothycas charliemonroe',
            count: 1,
          },
          {
            word: 'charliemonroe siiryuregim',
            count: 1,
          },
          {
            word: 'siiryuregim emmamcgilloway',
            count: 1,
          },
          {
            word: 'emmamcgilloway oupsray',
            count: 1,
          },
          {
            word: 'oupsray alicliskan',
            count: 1,
          },
          {
            word: 'alicliskan nooruddin',
            count: 1,
          },
          {
            word: 'nooruddin omery',
            count: 1,
          },
          {
            word: 'omery rumournoff',
            count: 1,
          },
          {
            word: 'rumournoff tamurutkowski',
            count: 1,
          },
          {
            word: 'tamurutkowski pumabaako',
            count: 1,
          },
          {
            word: 'pumabaako shofunaki',
            count: 1,
          },
          {
            word: 'shofunaki lollyratsiu',
            count: 1,
          },
          {
            word: 'lollyratsiu fastgeraint',
            count: 1,
          },
          {
            word: 'fastgeraint chalxdon',
            count: 1,
          },
          {
            word: 'chalxdon dwderizzo',
            count: 1,
          },
          {
            word: 'dwderizzo jonsley',
            count: 1,
          },
          {
            word: 'jonsley doukalucy',
            count: 1,
          },
          {
            word: 'doukalucy ayato',
            count: 1,
          },
          {
            word: 'ayato nhsnclicb',
            count: 1,
          },
          {
            word: 'nhsnclicb jomaalarimi',
            count: 1,
          },
          {
            word: 'jomaalarimi abuqaze',
            count: 1,
          },
          {
            word: 'abuqaze check',
            count: 1,
          },
          {
            word: 'ppaleoartist reminder',
            count: 3,
          },
          {
            word: 'reminder extinct',
            count: 4,
          },
          {
            word: 'extinct animals',
            count: 4,
          },
          {
            word: 'animals dont',
            count: 4,
          },
          {
            word: 'dont need',
            count: 4,
          },
          {
            word: 'need common',
            count: 4,
          },
          {
            word: 'common names',
            count: 8,
          },
          {
            word: 'names generic',
            count: 4,
          },
          {
            word: 'generic names',
            count: 5,
          },
          {
            word: 'names work',
            count: 4,
          },
          {
            word: 'work perfectly',
            count: 4,
          },
          {
            word: 'perfectly fine',
            count: 4,
          },
          {
            word: 'fine common',
            count: 4,
          },
          {
            word: 'pions torc',
            count: 5,
          },
          {
            word: 'torc unmanned',
            count: 5,
          },
          {
            word: 'unmanned turret',
            count: 5,
          },
          {
            word: 'turret designed',
            count: 5,
          },
          {
            word: 'designed brazil',
            count: 5,
          },
          {
            word: 'brazil turret',
            count: 5,
          },
          {
            word: 'turret brings',
            count: 5,
          },
          {
            word: 'brings vehicle',
            count: 5,
          },
          {
            word: 'vehicle level',
            count: 5,
          },
          {
            word: 'level firepower',
            count: 5,
          },
          {
            word: 'firepower comparable',
            count: 5,
          },
          {
            word: 'lowerio thunderdarcy',
            count: 1,
          },
          {
            word: 'thunderdarcy goodforsmething',
            count: 1,
          },
          {
            word: 'goodforsmething growstudiohq',
            count: 1,
          },
          {
            word: 'growstudiohq puma',
            count: 1,
          },
          {
            word: 'puma shimaaa',
            count: 1,
          },
          {
            word: 'shimaaa unstoppablebs',
            count: 1,
          },
          {
            word: 'unstoppablebs xiiiix',
            count: 1,
          },
          {
            word: 'xiiiix ebondarcom',
            count: 1,
          },
          {
            word: 'ebondarcom konsonanti',
            count: 1,
          },
          {
            word: 'konsonanti kingmoye',
            count: 1,
          },
          {
            word: 'kingmoye seamyfarmer',
            count: 1,
          },
          {
            word: 'seamyfarmer furkancobaan',
            count: 1,
          },
          {
            word: 'furkancobaan anil',
            count: 1,
          },
          {
            word: 'anil alirezadini',
            count: 1,
          },
          {
            word: 'alirezadini bhanbhroamjad',
            count: 1,
          },
          {
            word: 'bhanbhroamjad wirwerdenreich',
            count: 1,
          },
          {
            word: 'wirwerdenreich clebrun',
            count: 1,
          },
          {
            word: 'clebrun ewingjeremy',
            count: 1,
          },
          {
            word: 'ewingjeremy takooryouri',
            count: 1,
          },
          {
            word: 'takooryouri check',
            count: 1,
          },
          {
            word: 'collierwes deadmansrock',
            count: 2,
          },
          {
            word: 'deadmansrock caraembh',
            count: 2,
          },
          {
            word: 'caraembh skunktwine',
            count: 2,
          },
          {
            word: 'skunktwine mohamme',
            count: 2,
          },
          {
            word: 'mohamme zakizaidi',
            count: 2,
          },
          {
            word: 'zakizaidi jamessbutler',
            count: 2,
          },
          {
            word: 'jamessbutler zdamilaree',
            count: 2,
          },
          {
            word: 'zdamilaree rracarvalho',
            count: 2,
          },
          {
            word: 'rracarvalho ropukdeb',
            count: 2,
          },
          {
            word: 'ropukdeb bankykanta',
            count: 2,
          },
          {
            word: 'bankykanta pbacelar',
            count: 2,
          },
          {
            word: 'pbacelar kidrauhlgirl',
            count: 2,
          },
          {
            word: 'kidrauhlgirl dookydabarber',
            count: 2,
          },
          {
            word: 'dookydabarber oluafolabi',
            count: 2,
          },
          {
            word: 'oluafolabi claralaflore',
            count: 2,
          },
          {
            word: 'claralaflore wigmorewelsh',
            count: 2,
          },
          {
            word: 'wigmorewelsh ayaibrahem',
            count: 2,
          },
          {
            word: 'ayaibrahem lulpuma',
            count: 2,
          },
          {
            word: 'lulpuma rociowittib',
            count: 2,
          },
          {
            word: 'rociowittib mardeamal',
            count: 2,
          },
          {
            word: 'mardeamal check',
            count: 2,
          },
          {
            word: 'calumcrane gochimin',
            count: 1,
          },
          {
            word: 'gochimin iiik',
            count: 1,
          },
          {
            word: 'iiik yansaladino',
            count: 1,
          },
          {
            word: 'yansaladino rebeccaradovsk',
            count: 1,
          },
          {
            word: 'rebeccaradovsk aboabaman',
            count: 1,
          },
          {
            word: 'aboabaman ponkotupyoko',
            count: 1,
          },
          {
            word: 'ponkotupyoko rksharma',
            count: 1,
          },
          {
            word: 'rksharma dmirandaaaa',
            count: 1,
          },
          {
            word: 'dmirandaaaa uhle',
            count: 1,
          },
          {
            word: 'uhle estelleh',
            count: 1,
          },
          {
            word: 'estelleh manjeet',
            count: 1,
          },
          {
            word: 'manjeet karadeniz',
            count: 1,
          },
          {
            word: 'karadeniz salifouide',
            count: 1,
          },
          {
            word: 'salifouide scalteurbh',
            count: 1,
          },
          {
            word: 'scalteurbh pumatapia',
            count: 1,
          },
          {
            word: 'pumatapia meltural',
            count: 1,
          },
          {
            word: 'meltural jayseamusic',
            count: 1,
          },
          {
            word: 'jayseamusic oshiosleep',
            count: 1,
          },
          {
            word: 'oshiosleep esqueben',
            count: 1,
          },
          {
            word: 'esqueben datatapper',
            count: 1,
          },
          {
            word: 'datatapper check',
            count: 1,
          },
          {
            word: 'goaljpofficial puma',
            count: 1,
          },
          {
            word: 'puma drop',
            count: 1,
          },
          {
            word: 'drop yourshttpstcotalxjgacz',
            count: 1,
          },
          {
            word: 'names hippopotamus',
            count: 1,
          },
          {
            word: 'hippopotamus gorilla',
            count: 1,
          },
          {
            word: 'gorilla octopus',
            count: 1,
          },
          {
            word: 'octopus puma',
            count: 1,
          },
          {
            word: 'puma common',
            count: 1,
          },
          {
            word: 'common generic',
            count: 1,
          },
          {
            word: 'generic namesbut',
            count: 1,
          },
          {
            word: 'namesbut generic',
            count: 1,
          },
          {
            word: 'names long',
            count: 1,
          },
          {
            word: 'long complex',
            count: 1,
          },
          {
            word: 'complex hippopotamus',
            count: 1,
          },
          {
            word: 'dennisj whoknowsmysoul',
            count: 1,
          },
          {
            word: 'whoknowsmysoul kaabuame',
            count: 1,
          },
          {
            word: 'kaabuame kcdmsmkecfngere',
            count: 1,
          },
          {
            word: 'kcdmsmkecfngere phronesianos',
            count: 1,
          },
          {
            word: 'phronesianos yrsweet',
            count: 1,
          },
          {
            word: 'yrsweet easyfires',
            count: 1,
          },
          {
            word: 'easyfires keyvanrahmadi',
            count: 1,
          },
          {
            word: 'keyvanrahmadi macanth',
            count: 1,
          },
          {
            word: 'macanth vhpeuch',
            count: 1,
          },
          {
            word: 'vhpeuch takekittyshit',
            count: 1,
          },
          {
            word: 'takekittyshit esdglobaltech',
            count: 1,
          },
          {
            word: 'esdglobaltech myramalways',
            count: 1,
          },
          {
            word: 'myramalways jazzlynpuma',
            count: 1,
          },
          {
            word: 'jazzlynpuma iiionceau',
            count: 1,
          },
          {
            word: 'iiionceau nidhi',
            count: 1,
          },
          {
            word: 'nidhi leeorocha',
            count: 1,
          },
          {
            word: 'leeorocha bushmanind',
            count: 1,
          },
          {
            word: 'bushmanind wales',
            count: 1,
          },
          {
            word: 'wales murdocklvrr',
            count: 1,
          },
          {
            word: 'murdocklvrr lokopeter',
            count: 1,
          },
          {
            word: 'lokopeter check',
            count: 1,
          },
          {
            word: 'knoxmack womanwomanwomam',
            count: 1,
          },
          {
            word: 'womanwomanwomam forscorn',
            count: 1,
          },
          {
            word: 'forscorn leothatguy',
            count: 1,
          },
          {
            word: 'leothatguy malqadi',
            count: 1,
          },
          {
            word: 'malqadi carolinevoelke',
            count: 1,
          },
          {
            word: 'carolinevoelke abujasher',
            count: 1,
          },
          {
            word: 'abujasher yamadaissei',
            count: 1,
          },
          {
            word: 'yamadaissei butchershook',
            count: 1,
          },
          {
            word: 'butchershook muets',
            count: 1,
          },
          {
            word: 'muets emilytu',
            count: 1,
          },
          {
            word: 'emilytu dubaioutca',
            count: 1,
          },
          {
            word: 'dubaioutca batapuma',
            count: 1,
          },
          {
            word: 'batapuma mysticmonae',
            count: 1,
          },
          {
            word: 'mysticmonae anacarolbooks',
            count: 1,
          },
          {
            word: 'anacarolbooks xskillerr',
            count: 1,
          },
          {
            word: 'xskillerr callumwilson',
            count: 1,
          },
          {
            word: 'callumwilson liakeek',
            count: 1,
          },
          {
            word: 'liakeek melektayfun',
            count: 1,
          },
          {
            word: 'melektayfun miriamjablon',
            count: 1,
          },
          {
            word: 'miriamjablon check',
            count: 1,
          },
          {
            word: 'johnathank jasonv',
            count: 1,
          },
          {
            word: 'jasonv abobadralsu',
            count: 1,
          },
          {
            word: 'abobadralsu supremedonzeeky',
            count: 1,
          },
          {
            word: 'supremedonzeeky hyraiora',
            count: 1,
          },
          {
            word: 'hyraiora pelonmanny',
            count: 1,
          },
          {
            word: 'pelonmanny iamomahbella',
            count: 1,
          },
          {
            word: 'iamomahbella gregorigregg',
            count: 1,
          },
          {
            word: 'gregorigregg hongota',
            count: 1,
          },
          {
            word: 'hongota brentduke',
            count: 1,
          },
          {
            word: 'brentduke fahmiishak',
            count: 1,
          },
          {
            word: 'fahmiishak perhermanrud',
            count: 1,
          },
          {
            word: 'perhermanrud crazyluis',
            count: 1,
          },
          {
            word: 'crazyluis galanoleukos',
            count: 1,
          },
          {
            word: 'galanoleukos marielamarie',
            count: 1,
          },
          {
            word: 'marielamarie roadkillsindia',
            count: 1,
          },
          {
            word: 'roadkillsindia puma',
            count: 1,
          },
          {
            word: 'puma mikaillxx',
            count: 1,
          },
          {
            word: 'mikaillxx okellodoc',
            count: 1,
          },
          {
            word: 'okellodoc vynxpmjnml',
            count: 1,
          },
          {
            word: 'vynxpmjnml check',
            count: 1,
          },
          {
            word: 'rodgerssan adaziecallister',
            count: 1,
          },
          {
            word: 'adaziecallister filipaclopess',
            count: 1,
          },
          {
            word: 'filipaclopess hobisdimpleee',
            count: 1,
          },
          {
            word: 'hobisdimpleee yavahlns',
            count: 1,
          },
          {
            word: 'yavahlns yarelisramona',
            count: 1,
          },
          {
            word: 'yarelisramona aurallduck',
            count: 1,
          },
          {
            word: 'aurallduck upperuser',
            count: 1,
          },
          {
            word: 'upperuser slimguy',
            count: 1,
          },
          {
            word: 'slimguy bignfuzzy',
            count: 1,
          },
          {
            word: 'bignfuzzy gpolitics',
            count: 1,
          },
          {
            word: 'gpolitics esraaelzany',
            count: 1,
          },
          {
            word: 'esraaelzany burhankamal',
            count: 1,
          },
          {
            word: 'burhankamal juanseb',
            count: 1,
          },
          {
            word: 'juanseb shippuma',
            count: 1,
          },
          {
            word: 'shippuma eveferreira',
            count: 1,
          },
          {
            word: 'eveferreira oxmar',
            count: 1,
          },
          {
            word: 'oxmar djohnson',
            count: 1,
          },
          {
            word: 'djohnson yanpingzhang',
            count: 1,
          },
          {
            word: 'yanpingzhang mdeleonretired',
            count: 1,
          },
          {
            word: 'mdeleonretired check',
            count: 1,
          },
          {
            word: 'live puma',
            count: 1,
          },
          {
            word: 'puma stewie',
            count: 2,
          },
          {
            word: 'stewie httpstcotygduzrbtry',
            count: 1,
          },
          {
            word: 'httpstcotygduzrbtry code',
            count: 1,
          },
          {
            word: 'code sneakers',
            count: 2,
          },
          {
            word: 'sneakers httpstcokpgcwaqho',
            count: 1,
          },
          {
            word: 'uithemovie stockmarketcrash',
            count: 5,
          },
          {
            word: 'stockmarketcrash saipallavi',
            count: 5,
          },
          {
            word: 'saipallavi hariharaveeramallu',
            count: 5,
          },
          {
            word: 'hariharaveeramallu elvishyadav',
            count: 5,
          },
          {
            word: 'elvishyadav gamechanger',
            count: 5,
          },
          {
            word: 'gamechanger kanguva',
            count: 5,
          },
          {
            word: 'kanguva fake',
            count: 5,
          },
          {
            word: 'fake news',
            count: 5,
          },
          {
            word: 'news thegoathttpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'declanhort makelelejunior',
            count: 1,
          },
          {
            word: 'makelelejunior alfredorenfra',
            count: 1,
          },
          {
            word: 'alfredorenfra pumadelmonte',
            count: 1,
          },
          {
            word: 'pumadelmonte mcgeesinead',
            count: 1,
          },
          {
            word: 'mcgeesinead shibrumour',
            count: 1,
          },
          {
            word: 'shibrumour patriciafranco',
            count: 1,
          },
          {
            word: 'patriciafranco omarsabat',
            count: 1,
          },
          {
            word: 'omarsabat cheke',
            count: 1,
          },
          {
            word: 'cheke ffanoch',
            count: 1,
          },
          {
            word: 'ffanoch ruanvieirar',
            count: 1,
          },
          {
            word: 'ruanvieirar matthewswain',
            count: 1,
          },
          {
            word: 'matthewswain eagleny',
            count: 1,
          },
          {
            word: 'eagleny ozpolataptullah',
            count: 1,
          },
          {
            word: 'ozpolataptullah meredithrose',
            count: 1,
          },
          {
            word: 'meredithrose rahul',
            count: 1,
          },
          {
            word: 'rahul niels',
            count: 1,
          },
          {
            word: 'niels magutaku',
            count: 1,
          },
          {
            word: 'magutaku kingsndimele',
            count: 1,
          },
          {
            word: 'kingsndimele check',
            count: 1,
          },
          {
            word: 'puma pumasouthafrica',
            count: 1,
          },
          {
            word: 'pumasouthafrica pumahoops',
            count: 1,
          },
          {
            word: 'pumahoops news',
            count: 1,
          },
          {
            word: 'news lamelo',
            count: 2,
          },
          {
            word: 'lamelo ball',
            count: 2,
          },
          {
            word: 'ball puma',
            count: 2,
          },
          {
            word: 'puma porsche',
            count: 2,
          },
          {
            word: 'porsche step',
            count: 2,
          },
          {
            word: 'step fast',
            count: 2,
          },
          {
            word: 'fast lanelink',
            count: 2,
          },
          {
            word: 'lanelink httpstcoolcsrsxqxlameloball',
            count: 2,
          },
          {
            word: 'httpstcoolcsrsxqxlameloball nbanews',
            count: 2,
          },
          {
            word: 'nbanews porsche',
            count: 2,
          },
          {
            word: 'porsche pumapumaandporsche',
            count: 2,
          },
          {
            word: 'pumapumaandporsche thefastlane',
            count: 2,
          },
          {
            word: 'thefastlane httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'thefastlane httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'thegreysole puma',
            count: 1,
          },
          {
            word: 'puma socks',
            count: 1,
          },
          {
            word: 'socks available',
            count: 1,
          },
          {
            word: 'available women',
            count: 1,
          },
          {
            word: 'women pack',
            count: 1,
          },
          {
            word: 'pack send',
            count: 1,
          },
          {
            word: 'send visit',
            count: 1,
          },
          {
            word: 'visit website',
            count: 1,
          },
          {
            word: 'website httpstcoixdocyvk',
            count: 1,
          },
          {
            word: 'roachcalla jyrago',
            count: 1,
          },
          {
            word: 'jyrago mingifixsong',
            count: 1,
          },
          {
            word: 'mingifixsong majdialbana',
            count: 1,
          },
          {
            word: 'majdialbana tonymelendez',
            count: 1,
          },
          {
            word: 'tonymelendez stanby',
            count: 1,
          },
          {
            word: 'stanby shahannayoung',
            count: 1,
          },
          {
            word: 'shahannayoung jokerfiko',
            count: 1,
          },
          {
            word: 'jokerfiko shwkyamanaka',
            count: 1,
          },
          {
            word: 'shwkyamanaka kaccccchan',
            count: 1,
          },
          {
            word: 'kaccccchan luxluct',
            count: 1,
          },
          {
            word: 'luxluct fetichistaputin',
            count: 1,
          },
          {
            word: 'fetichistaputin pumars',
            count: 1,
          },
          {
            word: 'pumars animenougat',
            count: 1,
          },
          {
            word: 'animenougat eliasyusuff',
            count: 1,
          },
          {
            word: 'eliasyusuff nadahonor',
            count: 1,
          },
          {
            word: 'nadahonor sazaross',
            count: 1,
          },
          {
            word: 'sazaross frwansham',
            count: 1,
          },
          {
            word: 'frwansham jonathandkeck',
            count: 1,
          },
          {
            word: 'jonathandkeck justsodamngood',
            count: 1,
          },
          {
            word: 'justsodamngood jmool',
            count: 1,
          },
          {
            word: 'jmool maentina',
            count: 1,
          },
          {
            word: 'maentina check',
            count: 1,
          },
          {
            word: 'news thegoathttpstcomvnum',
            count: 1,
          },
          {
            word: 'snkrtwitr almost',
            count: 1,
          },
          {
            word: 'almost live',
            count: 2,
          },
          {
            word: 'live snswmns',
            count: 2,
          },
          {
            word: 'snswmns jordan',
            count: 2,
          },
          {
            word: 'jordan retro',
            count: 2,
          },
          {
            word: 'retro high',
            count: 2,
          },
          {
            word: 'high whitemetallic',
            count: 2,
          },
          {
            word: 'whitemetallic goldw',
            count: 2,
          },
          {
            word: 'goldw httpstcoowfflyfzjxp',
            count: 2,
          },
          {
            word: 'httpstcoowfflyfzjxp httpstcopqbjaksddwt',
            count: 1,
          },
          {
            word: 'httpstcopqbjaksddwt httpstcomkgmpurqniwmns',
            count: 1,
          },
          {
            word: 'httpstcomkgmpurqniwmns puma',
            count: 1,
          },
          {
            word: 'stewie amor',
            count: 1,
          },
          {
            word: 'amor httpstcomuvnkyhytry',
            count: 1,
          },
          {
            word: 'httpstcomuvnkyhytry code',
            count: 1,
          },
          {
            word: 'sneakers httpstconfymgs',
            count: 1,
          },
          {
            word: 'seiyuusunika miharu',
            count: 1,
          },
          {
            word: 'miharu hanai',
            count: 2,
          },
          {
            word: 'hanai wmns',
            count: 2,
          },
          {
            word: 'wmns puma',
            count: 2,
          },
          {
            word: 'puma mayze',
            count: 2,
          },
          {
            word: 'mayze thrifted',
            count: 2,
          },
          {
            word: 'thrifted white',
            count: 2,
          },
          {
            word: 'white little',
            count: 2,
          },
          {
            word: 'little sister',
            count: 2,
          },
          {
            word: 'sister httpstcouppihntdn',
            count: 2,
          },
          {
            word: 'petercrouch puma',
            count: 1,
          },
          {
            word: 'puma hoodie',
            count: 1,
          },
          {
            word: 'hoodie looks',
            count: 1,
          },
          {
            word: 'looks like',
            count: 1,
          },
          {
            word: 'like someone',
            count: 1,
          },
          {
            word: 'someone grabbed',
            count: 1,
          },
          {
            word: 'grabbed fill',
            count: 1,
          },
          {
            word: 'fill seat',
            count: 1,
          },
          {
            word: 'seat waiting',
            count: 1,
          },
          {
            word: 'waiting didi',
            count: 1,
          },
          {
            word: 'didi hamann',
            count: 1,
          },
          {
            word: 'hamann arrive',
            count: 1,
          },
          {
            word: 'gentlewong daejeon',
            count: 1,
          },
          {
            word: 'daejeon home',
            count: 1,
          },
          {
            word: 'home away',
            count: 1,
          },
          {
            word: 'away ktis',
            count: 1,
          },
          {
            word: 'ktis puma',
            count: 1,
          },
          {
            word: 'puma httpstcodwrnlkbgov',
            count: 1,
          },
          {
            word: 'news thegoathttpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'puma suede',
            count: 1,
          },
          {
            word: 'suede size',
            count: 1,
          },
          {
            word: 'size delivery',
            count: 1,
          },
          {
            word: 'delivery within',
            count: 1,
          },
          {
            word: 'within nairobi',
            count: 1,
          },
          {
            word: 'nairobi pickup',
            count: 1,
          },
          {
            word: 'pickup mtaani',
            count: 1,
          },
          {
            word: 'mtaani services',
            count: 1,
          },
          {
            word: 'services available',
            count: 1,
          },
          {
            word: 'available httpstcocyxgdzxlb',
            count: 1,
          },
          {
            word: 'gyusphoria like',
            count: 1,
          },
          {
            word: 'like want',
            count: 1,
          },
          {
            word: 'want puma',
            count: 1,
          },
          {
            word: 'puma peanits',
            count: 1,
          },
          {
            word: 'peanits mouth',
            count: 1,
          },
          {
            word: 'puma eachmeet',
            count: 1,
          },
          {
            word: 'eachmeet concertpm',
            count: 1,
          },
          {
            word: 'concertpm interestediveinkl',
            count: 1,
          },
          {
            word: 'interestediveinkl httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'maturenl puma',
            count: 1,
          },
          {
            word: 'puma swede',
            count: 1,
          },
          {
            word: 'swede angel',
            count: 1,
          },
          {
            word: 'angel long',
            count: 1,
          },
          {
            word: 'long diamond',
            count: 1,
          },
          {
            word: 'diamond angela',
            count: 1,
          },
          {
            word: 'angela aspen',
            count: 1,
          },
          {
            word: 'aspen kinky',
            count: 1,
          },
          {
            word: 'kinky pussyeating',
            count: 1,
          },
          {
            word: 'pussyeating milf',
            count: 1,
          },
          {
            word: 'milf sexparty',
            count: 1,
          },
          {
            word: 'sexparty everything',
            count: 1,
          },
          {
            word: 'everything allowed',
            count: 1,
          },
          {
            word: 'news thegoathttpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'hltco httpstcoutmvbes',
            count: 1,
          },
          {
            word: 'httpstcoutmvbes puma',
            count: 1,
          },
          {
            word: 'puma hardchorus',
            count: 1,
          },
          {
            word: 'hardchorus full',
            count: 1,
          },
          {
            word: 'full european',
            count: 1,
          },
          {
            word: 'european version',
            count: 1,
          },
          {
            word: 'version anyone',
            count: 1,
          },
          {
            word: 'anyone interested',
            count: 1,
          },
          {
            word: 'news thegoathttpstcodulysnr',
            count: 1,
          },
          {
            word: 'annapumakig peekaboo',
            count: 1,
          },
          {
            word: 'peekaboo httpstcojnmcznni',
            count: 1,
          },
          {
            word: 'puma smash',
            count: 1,
          },
          {
            word: 'smash leather',
            count: 1,
          },
          {
            word: 'leather white',
            count: 1,
          },
          {
            word: 'white retail',
            count: 1,
          },
          {
            word: 'retail mandmdirect',
            count: 1,
          },
          {
            word: 'mandmdirect sale',
            count: 1,
          },
          {
            word: 'sale price',
            count: 1,
          },
          {
            word: 'price drop',
            count: 1,
          },
          {
            word: 'drop link',
            count: 1,
          },
          {
            word: 'link httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq sizes',
            count: 1,
          },
          {
            word: 'sizes httpstcowtcknauzu',
            count: 1,
          },
        ],
        neutral_trigram: [
          {
            word: 'mawrongzjnr nooits puma',
            count: 1,
          },
          {
            word: 'nooits puma httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'yashkarnwal ordered pair',
            count: 1,
          },
          {
            word: 'ordered pair puma',
            count: 1,
          },
          {
            word: 'pair puma shoes',
            count: 1,
          },
          {
            word: 'puma shoes birthday',
            count: 1,
          },
          {
            word: 'shoes birthday gift',
            count: 1,
          },
          {
            word: 'birthday gift found',
            count: 1,
          },
          {
            word: 'gift found flipkart',
            count: 1,
          },
          {
            word: 'found flipkart delivered',
            count: 1,
          },
          {
            word: 'jimmythorn bazbowler kaaiot',
            count: 1,
          },
          {
            word: 'bazbowler kaaiot szlaban',
            count: 1,
          },
          {
            word: 'kaaiot szlaban melizwalls',
            count: 1,
          },
          {
            word: 'szlaban melizwalls notwuk',
            count: 1,
          },
          {
            word: 'melizwalls notwuk starmakeriseye',
            count: 1,
          },
          {
            word: 'notwuk starmakeriseye cookeben',
            count: 1,
          },
          {
            word: 'starmakeriseye cookeben gazellassss',
            count: 1,
          },
          {
            word: 'cookeben gazellassss akkyn',
            count: 1,
          },
          {
            word: 'gazellassss akkyn asifmittal',
            count: 1,
          },
          {
            word: 'akkyn asifmittal creeper',
            count: 1,
          },
          {
            word: 'asifmittal creeper mhsolis',
            count: 1,
          },
          {
            word: 'creeper mhsolis yfkdrags',
            count: 1,
          },
          {
            word: 'mhsolis yfkdrags portb',
            count: 1,
          },
          {
            word: 'yfkdrags portb noelrz',
            count: 1,
          },
          {
            word: 'portb noelrz pumalovedragon',
            count: 1,
          },
          {
            word: 'noelrz pumalovedragon seanclose',
            count: 1,
          },
          {
            word: 'pumalovedragon seanclose taniaaass',
            count: 1,
          },
          {
            word: 'seanclose taniaaass sakuyafgo',
            count: 1,
          },
          {
            word: 'taniaaass sakuyafgo cmillenbine',
            count: 1,
          },
          {
            word: 'sakuyafgo cmillenbine slmakab',
            count: 1,
          },
          {
            word: 'cmillenbine slmakab carollaannee',
            count: 1,
          },
          {
            word: 'slmakab carollaannee lilismr',
            count: 1,
          },
          {
            word: 'carollaannee lilismr check',
            count: 1,
          },
          {
            word: 'lilismr check pinned',
            count: 1,
          },
          {
            word: 'javiermcca sadgirlll doniamakhlouf',
            count: 1,
          },
          {
            word: 'sadgirlll doniamakhlouf franckkennedy',
            count: 1,
          },
          {
            word: 'doniamakhlouf franckkennedy zahrakrrrm',
            count: 1,
          },
          {
            word: 'franckkennedy zahrakrrrm yongsunnieverse',
            count: 1,
          },
          {
            word: 'zahrakrrrm yongsunnieverse maitezgz',
            count: 1,
          },
          {
            word: 'yongsunnieverse maitezgz wannapa',
            count: 1,
          },
          {
            word: 'maitezgz wannapa shotbyamccoy',
            count: 1,
          },
          {
            word: 'wannapa shotbyamccoy napalm',
            count: 1,
          },
          {
            word: 'shotbyamccoy napalm dallahae',
            count: 1,
          },
          {
            word: 'napalm dallahae moorocho',
            count: 1,
          },
          {
            word: 'dallahae moorocho muffinekshawna',
            count: 1,
          },
          {
            word: 'moorocho muffinekshawna mmmehran',
            count: 1,
          },
          {
            word: 'muffinekshawna mmmehran pumapalace',
            count: 1,
          },
          {
            word: 'mmmehran pumapalace maktarli',
            count: 1,
          },
          {
            word: 'pumapalace maktarli lenikalulu',
            count: 1,
          },
          {
            word: 'maktarli lenikalulu koechs',
            count: 1,
          },
          {
            word: 'lenikalulu koechs maanrole',
            count: 1,
          },
          {
            word: 'koechs maanrole chukaog',
            count: 1,
          },
          {
            word: 'maanrole chukaog miaojiang',
            count: 1,
          },
          {
            word: 'chukaog miaojiang libya',
            count: 1,
          },
          {
            word: 'miaojiang libya check',
            count: 1,
          },
          {
            word: 'libya check pinned',
            count: 1,
          },
          {
            word: 'crown dont stress',
            count: 1,
          },
          {
            word: 'dont stress freeze',
            count: 1,
          },
          {
            word: 'stress freeze light',
            count: 1,
          },
          {
            word: 'freeze light dear',
            count: 1,
          },
          {
            word: 'light dear sputnik',
            count: 1,
          },
          {
            word: 'dear sputnik ghosting',
            count: 1,
          },
          {
            word: 'sputnik ghosting back',
            count: 1,
          },
          {
            word: 'ghosting back puma',
            count: 1,
          },
          {
            word: 'back puma httpstcouzjmzrveq',
            count: 1,
          },
          {
            word: 'swansonma tqhung rafaeltatis',
            count: 1,
          },
          {
            word: 'tqhung rafaeltatis jaspreetkbansal',
            count: 1,
          },
          {
            word: 'rafaeltatis jaspreetkbansal ehsaniran',
            count: 1,
          },
          {
            word: 'jaspreetkbansal ehsaniran javedadil',
            count: 1,
          },
          {
            word: 'ehsaniran javedadil nicocarnage',
            count: 1,
          },
          {
            word: 'javedadil nicocarnage ksimorri',
            count: 1,
          },
          {
            word: 'nicocarnage ksimorri ianreds',
            count: 1,
          },
          {
            word: 'ksimorri ianreds romantickasap',
            count: 1,
          },
          {
            word: 'ianreds romantickasap swerrii',
            count: 1,
          },
          {
            word: 'romantickasap swerrii nunesjosito',
            count: 1,
          },
          {
            word: 'swerrii nunesjosito giant',
            count: 1,
          },
          {
            word: 'nunesjosito giant mokgadivictor',
            count: 1,
          },
          {
            word: 'giant mokgadivictor elmaelmasry',
            count: 1,
          },
          {
            word: 'mokgadivictor elmaelmasry isaadansari',
            count: 1,
          },
          {
            word: 'elmaelmasry isaadansari zonnbn',
            count: 1,
          },
          {
            word: 'isaadansari zonnbn brandopuma',
            count: 1,
          },
          {
            word: 'zonnbn brandopuma klkl',
            count: 1,
          },
          {
            word: 'brandopuma klkl juventudgh',
            count: 1,
          },
          {
            word: 'klkl juventudgh colonyuunagi',
            count: 1,
          },
          {
            word: 'juventudgh colonyuunagi almasnoora',
            count: 1,
          },
          {
            word: 'colonyuunagi almasnoora check',
            count: 1,
          },
          {
            word: 'almasnoora check pinned',
            count: 1,
          },
          {
            word: 'wallacepjw like ford',
            count: 1,
          },
          {
            word: 'like ford puma',
            count: 1,
          },
          {
            word: 'missrozapepper davido performing',
            count: 15,
          },
          {
            word: 'davido performing birthday',
            count: 15,
          },
          {
            word: 'performing birthday party',
            count: 15,
          },
          {
            word: 'birthday party athletico',
            count: 15,
          },
          {
            word: 'party athletico madrid',
            count: 15,
          },
          {
            word: 'athletico madrid player',
            count: 15,
          },
          {
            word: 'madrid player fellow',
            count: 15,
          },
          {
            word: 'player fellow puma',
            count: 15,
          },
          {
            word: 'fellow puma global',
            count: 15,
          },
          {
            word: 'puma global ambassador',
            count: 15,
          },
          {
            word: 'global ambassador memphis',
            count: 15,
          },
          {
            word: 'ambassador memphis depay',
            count: 15,
          },
          {
            word: 'bowerskamd empressaura rafaelars',
            count: 1,
          },
          {
            word: 'empressaura rafaelars simgejkjm',
            count: 1,
          },
          {
            word: 'rafaelars simgejkjm garaku',
            count: 1,
          },
          {
            word: 'simgejkjm garaku hajiskyyy',
            count: 1,
          },
          {
            word: 'garaku hajiskyyy buttameer',
            count: 1,
          },
          {
            word: 'hajiskyyy buttameer regret',
            count: 1,
          },
          {
            word: 'buttameer regret topcop',
            count: 1,
          },
          {
            word: 'regret topcop killadikamal',
            count: 1,
          },
          {
            word: 'topcop killadikamal valorshero',
            count: 1,
          },
          {
            word: 'killadikamal valorshero toocozymarcus',
            count: 1,
          },
          {
            word: 'valorshero toocozymarcus ahsanwahid',
            count: 1,
          },
          {
            word: 'toocozymarcus ahsanwahid ssjgoten',
            count: 1,
          },
          {
            word: 'ahsanwahid ssjgoten maurom',
            count: 1,
          },
          {
            word: 'ssjgoten maurom something',
            count: 1,
          },
          {
            word: 'maurom something bloversmile',
            count: 1,
          },
          {
            word: 'something bloversmile canuluer',
            count: 1,
          },
          {
            word: 'bloversmile canuluer pumakun',
            count: 1,
          },
          {
            word: 'canuluer pumakun merrittworthy',
            count: 1,
          },
          {
            word: 'pumakun merrittworthy tarnason',
            count: 1,
          },
          {
            word: 'merrittworthy tarnason shipmatusri',
            count: 1,
          },
          {
            word: 'tarnason shipmatusri check',
            count: 1,
          },
          {
            word: 'shipmatusri check pinned',
            count: 1,
          },
          {
            word: 'longiek nigerian restaurant',
            count: 1,
          },
          {
            word: 'nigerian restaurant area',
            count: 1,
          },
          {
            word: 'restaurant area puma',
            count: 1,
          },
          {
            word: 'area puma filling',
            count: 1,
          },
          {
            word: 'puma filling station',
            count: 1,
          },
          {
            word: 'filling station next',
            count: 1,
          },
          {
            word: 'station next zathu',
            count: 1,
          },
          {
            word: 'next zathu trading',
            count: 1,
          },
          {
            word: 'zathu trading jollof',
            count: 1,
          },
          {
            word: 'shyammeerasingh phone apple',
            count: 1,
          },
          {
            word: 'phone apple iphonecameranikon',
            count: 1,
          },
          {
            word: 'apple iphonecameranikon shoes',
            count: 1,
          },
          {
            word: 'iphonecameranikon shoes sketcherstshirt',
            count: 1,
          },
          {
            word: 'shoes sketcherstshirt zara',
            count: 1,
          },
          {
            word: 'sketcherstshirt zara paint',
            count: 1,
          },
          {
            word: 'zara paint hampm',
            count: 1,
          },
          {
            word: 'paint hampm jacket',
            count: 1,
          },
          {
            word: 'timothycas charliemonroe siiryuregim',
            count: 1,
          },
          {
            word: 'charliemonroe siiryuregim emmamcgilloway',
            count: 1,
          },
          {
            word: 'siiryuregim emmamcgilloway oupsray',
            count: 1,
          },
          {
            word: 'emmamcgilloway oupsray alicliskan',
            count: 1,
          },
          {
            word: 'oupsray alicliskan nooruddin',
            count: 1,
          },
          {
            word: 'alicliskan nooruddin omery',
            count: 1,
          },
          {
            word: 'nooruddin omery rumournoff',
            count: 1,
          },
          {
            word: 'omery rumournoff tamurutkowski',
            count: 1,
          },
          {
            word: 'rumournoff tamurutkowski pumabaako',
            count: 1,
          },
          {
            word: 'tamurutkowski pumabaako shofunaki',
            count: 1,
          },
          {
            word: 'pumabaako shofunaki lollyratsiu',
            count: 1,
          },
          {
            word: 'shofunaki lollyratsiu fastgeraint',
            count: 1,
          },
          {
            word: 'lollyratsiu fastgeraint chalxdon',
            count: 1,
          },
          {
            word: 'fastgeraint chalxdon dwderizzo',
            count: 1,
          },
          {
            word: 'chalxdon dwderizzo jonsley',
            count: 1,
          },
          {
            word: 'dwderizzo jonsley doukalucy',
            count: 1,
          },
          {
            word: 'jonsley doukalucy ayato',
            count: 1,
          },
          {
            word: 'doukalucy ayato nhsnclicb',
            count: 1,
          },
          {
            word: 'ayato nhsnclicb jomaalarimi',
            count: 1,
          },
          {
            word: 'nhsnclicb jomaalarimi abuqaze',
            count: 1,
          },
          {
            word: 'jomaalarimi abuqaze check',
            count: 1,
          },
          {
            word: 'abuqaze check pinned',
            count: 1,
          },
          {
            word: 'ppaleoartist reminder extinct',
            count: 3,
          },
          {
            word: 'reminder extinct animals',
            count: 4,
          },
          {
            word: 'extinct animals dont',
            count: 4,
          },
          {
            word: 'animals dont need',
            count: 4,
          },
          {
            word: 'dont need common',
            count: 4,
          },
          {
            word: 'need common names',
            count: 4,
          },
          {
            word: 'common names generic',
            count: 4,
          },
          {
            word: 'names generic names',
            count: 4,
          },
          {
            word: 'generic names work',
            count: 4,
          },
          {
            word: 'names work perfectly',
            count: 4,
          },
          {
            word: 'work perfectly fine',
            count: 4,
          },
          {
            word: 'perfectly fine common',
            count: 4,
          },
          {
            word: 'fine common names',
            count: 4,
          },
          {
            word: 'pions torc unmanned',
            count: 5,
          },
          {
            word: 'torc unmanned turret',
            count: 5,
          },
          {
            word: 'unmanned turret designed',
            count: 5,
          },
          {
            word: 'turret designed brazil',
            count: 5,
          },
          {
            word: 'designed brazil turret',
            count: 5,
          },
          {
            word: 'brazil turret brings',
            count: 5,
          },
          {
            word: 'turret brings vehicle',
            count: 5,
          },
          {
            word: 'brings vehicle level',
            count: 5,
          },
          {
            word: 'vehicle level firepower',
            count: 5,
          },
          {
            word: 'level firepower comparable',
            count: 5,
          },
          {
            word: 'lowerio thunderdarcy goodforsmething',
            count: 1,
          },
          {
            word: 'thunderdarcy goodforsmething growstudiohq',
            count: 1,
          },
          {
            word: 'goodforsmething growstudiohq puma',
            count: 1,
          },
          {
            word: 'growstudiohq puma shimaaa',
            count: 1,
          },
          {
            word: 'puma shimaaa unstoppablebs',
            count: 1,
          },
          {
            word: 'shimaaa unstoppablebs xiiiix',
            count: 1,
          },
          {
            word: 'unstoppablebs xiiiix ebondarcom',
            count: 1,
          },
          {
            word: 'xiiiix ebondarcom konsonanti',
            count: 1,
          },
          {
            word: 'ebondarcom konsonanti kingmoye',
            count: 1,
          },
          {
            word: 'konsonanti kingmoye seamyfarmer',
            count: 1,
          },
          {
            word: 'kingmoye seamyfarmer furkancobaan',
            count: 1,
          },
          {
            word: 'seamyfarmer furkancobaan anil',
            count: 1,
          },
          {
            word: 'furkancobaan anil alirezadini',
            count: 1,
          },
          {
            word: 'anil alirezadini bhanbhroamjad',
            count: 1,
          },
          {
            word: 'alirezadini bhanbhroamjad wirwerdenreich',
            count: 1,
          },
          {
            word: 'bhanbhroamjad wirwerdenreich clebrun',
            count: 1,
          },
          {
            word: 'wirwerdenreich clebrun ewingjeremy',
            count: 1,
          },
          {
            word: 'clebrun ewingjeremy takooryouri',
            count: 1,
          },
          {
            word: 'ewingjeremy takooryouri check',
            count: 1,
          },
          {
            word: 'takooryouri check pinned',
            count: 1,
          },
          {
            word: 'collierwes deadmansrock caraembh',
            count: 2,
          },
          {
            word: 'deadmansrock caraembh skunktwine',
            count: 2,
          },
          {
            word: 'caraembh skunktwine mohamme',
            count: 2,
          },
          {
            word: 'skunktwine mohamme zakizaidi',
            count: 2,
          },
          {
            word: 'mohamme zakizaidi jamessbutler',
            count: 2,
          },
          {
            word: 'zakizaidi jamessbutler zdamilaree',
            count: 2,
          },
          {
            word: 'jamessbutler zdamilaree rracarvalho',
            count: 2,
          },
          {
            word: 'zdamilaree rracarvalho ropukdeb',
            count: 2,
          },
          {
            word: 'rracarvalho ropukdeb bankykanta',
            count: 2,
          },
          {
            word: 'ropukdeb bankykanta pbacelar',
            count: 2,
          },
          {
            word: 'bankykanta pbacelar kidrauhlgirl',
            count: 2,
          },
          {
            word: 'pbacelar kidrauhlgirl dookydabarber',
            count: 2,
          },
          {
            word: 'kidrauhlgirl dookydabarber oluafolabi',
            count: 2,
          },
          {
            word: 'dookydabarber oluafolabi claralaflore',
            count: 2,
          },
          {
            word: 'oluafolabi claralaflore wigmorewelsh',
            count: 2,
          },
          {
            word: 'claralaflore wigmorewelsh ayaibrahem',
            count: 2,
          },
          {
            word: 'wigmorewelsh ayaibrahem lulpuma',
            count: 2,
          },
          {
            word: 'ayaibrahem lulpuma rociowittib',
            count: 2,
          },
          {
            word: 'lulpuma rociowittib mardeamal',
            count: 2,
          },
          {
            word: 'rociowittib mardeamal check',
            count: 2,
          },
          {
            word: 'mardeamal check pinned',
            count: 2,
          },
          {
            word: 'calumcrane gochimin iiik',
            count: 1,
          },
          {
            word: 'gochimin iiik yansaladino',
            count: 1,
          },
          {
            word: 'iiik yansaladino rebeccaradovsk',
            count: 1,
          },
          {
            word: 'yansaladino rebeccaradovsk aboabaman',
            count: 1,
          },
          {
            word: 'rebeccaradovsk aboabaman ponkotupyoko',
            count: 1,
          },
          {
            word: 'aboabaman ponkotupyoko rksharma',
            count: 1,
          },
          {
            word: 'ponkotupyoko rksharma dmirandaaaa',
            count: 1,
          },
          {
            word: 'rksharma dmirandaaaa uhle',
            count: 1,
          },
          {
            word: 'dmirandaaaa uhle estelleh',
            count: 1,
          },
          {
            word: 'uhle estelleh manjeet',
            count: 1,
          },
          {
            word: 'estelleh manjeet karadeniz',
            count: 1,
          },
          {
            word: 'manjeet karadeniz salifouide',
            count: 1,
          },
          {
            word: 'karadeniz salifouide scalteurbh',
            count: 1,
          },
          {
            word: 'salifouide scalteurbh pumatapia',
            count: 1,
          },
          {
            word: 'scalteurbh pumatapia meltural',
            count: 1,
          },
          {
            word: 'pumatapia meltural jayseamusic',
            count: 1,
          },
          {
            word: 'meltural jayseamusic oshiosleep',
            count: 1,
          },
          {
            word: 'jayseamusic oshiosleep esqueben',
            count: 1,
          },
          {
            word: 'oshiosleep esqueben datatapper',
            count: 1,
          },
          {
            word: 'esqueben datatapper check',
            count: 1,
          },
          {
            word: 'datatapper check pinned',
            count: 1,
          },
          {
            word: 'goaljpofficial puma drop',
            count: 1,
          },
          {
            word: 'puma drop yourshttpstcotalxjgacz',
            count: 1,
          },
          {
            word: 'common names hippopotamus',
            count: 1,
          },
          {
            word: 'names hippopotamus gorilla',
            count: 1,
          },
          {
            word: 'hippopotamus gorilla octopus',
            count: 1,
          },
          {
            word: 'gorilla octopus puma',
            count: 1,
          },
          {
            word: 'octopus puma common',
            count: 1,
          },
          {
            word: 'puma common generic',
            count: 1,
          },
          {
            word: 'common generic namesbut',
            count: 1,
          },
          {
            word: 'generic namesbut generic',
            count: 1,
          },
          {
            word: 'namesbut generic names',
            count: 1,
          },
          {
            word: 'generic names long',
            count: 1,
          },
          {
            word: 'names long complex',
            count: 1,
          },
          {
            word: 'long complex hippopotamus',
            count: 1,
          },
          {
            word: 'dennisj whoknowsmysoul kaabuame',
            count: 1,
          },
          {
            word: 'whoknowsmysoul kaabuame kcdmsmkecfngere',
            count: 1,
          },
          {
            word: 'kaabuame kcdmsmkecfngere phronesianos',
            count: 1,
          },
          {
            word: 'kcdmsmkecfngere phronesianos yrsweet',
            count: 1,
          },
          {
            word: 'phronesianos yrsweet easyfires',
            count: 1,
          },
          {
            word: 'yrsweet easyfires keyvanrahmadi',
            count: 1,
          },
          {
            word: 'easyfires keyvanrahmadi macanth',
            count: 1,
          },
          {
            word: 'keyvanrahmadi macanth vhpeuch',
            count: 1,
          },
          {
            word: 'macanth vhpeuch takekittyshit',
            count: 1,
          },
          {
            word: 'vhpeuch takekittyshit esdglobaltech',
            count: 1,
          },
          {
            word: 'takekittyshit esdglobaltech myramalways',
            count: 1,
          },
          {
            word: 'esdglobaltech myramalways jazzlynpuma',
            count: 1,
          },
          {
            word: 'myramalways jazzlynpuma iiionceau',
            count: 1,
          },
          {
            word: 'jazzlynpuma iiionceau nidhi',
            count: 1,
          },
          {
            word: 'iiionceau nidhi leeorocha',
            count: 1,
          },
          {
            word: 'nidhi leeorocha bushmanind',
            count: 1,
          },
          {
            word: 'leeorocha bushmanind wales',
            count: 1,
          },
          {
            word: 'bushmanind wales murdocklvrr',
            count: 1,
          },
          {
            word: 'wales murdocklvrr lokopeter',
            count: 1,
          },
          {
            word: 'murdocklvrr lokopeter check',
            count: 1,
          },
          {
            word: 'lokopeter check pinned',
            count: 1,
          },
          {
            word: 'knoxmack womanwomanwomam forscorn',
            count: 1,
          },
          {
            word: 'womanwomanwomam forscorn leothatguy',
            count: 1,
          },
          {
            word: 'forscorn leothatguy malqadi',
            count: 1,
          },
          {
            word: 'leothatguy malqadi carolinevoelke',
            count: 1,
          },
          {
            word: 'malqadi carolinevoelke abujasher',
            count: 1,
          },
          {
            word: 'carolinevoelke abujasher yamadaissei',
            count: 1,
          },
          {
            word: 'abujasher yamadaissei butchershook',
            count: 1,
          },
          {
            word: 'yamadaissei butchershook muets',
            count: 1,
          },
          {
            word: 'butchershook muets emilytu',
            count: 1,
          },
          {
            word: 'muets emilytu dubaioutca',
            count: 1,
          },
          {
            word: 'emilytu dubaioutca batapuma',
            count: 1,
          },
          {
            word: 'dubaioutca batapuma mysticmonae',
            count: 1,
          },
          {
            word: 'batapuma mysticmonae anacarolbooks',
            count: 1,
          },
          {
            word: 'mysticmonae anacarolbooks xskillerr',
            count: 1,
          },
          {
            word: 'anacarolbooks xskillerr callumwilson',
            count: 1,
          },
          {
            word: 'xskillerr callumwilson liakeek',
            count: 1,
          },
          {
            word: 'callumwilson liakeek melektayfun',
            count: 1,
          },
          {
            word: 'liakeek melektayfun miriamjablon',
            count: 1,
          },
          {
            word: 'melektayfun miriamjablon check',
            count: 1,
          },
          {
            word: 'miriamjablon check pinned',
            count: 1,
          },
          {
            word: 'johnathank jasonv abobadralsu',
            count: 1,
          },
          {
            word: 'jasonv abobadralsu supremedonzeeky',
            count: 1,
          },
          {
            word: 'abobadralsu supremedonzeeky hyraiora',
            count: 1,
          },
          {
            word: 'supremedonzeeky hyraiora pelonmanny',
            count: 1,
          },
          {
            word: 'hyraiora pelonmanny iamomahbella',
            count: 1,
          },
          {
            word: 'pelonmanny iamomahbella gregorigregg',
            count: 1,
          },
          {
            word: 'iamomahbella gregorigregg hongota',
            count: 1,
          },
          {
            word: 'gregorigregg hongota brentduke',
            count: 1,
          },
          {
            word: 'hongota brentduke fahmiishak',
            count: 1,
          },
          {
            word: 'brentduke fahmiishak perhermanrud',
            count: 1,
          },
          {
            word: 'fahmiishak perhermanrud crazyluis',
            count: 1,
          },
          {
            word: 'perhermanrud crazyluis galanoleukos',
            count: 1,
          },
          {
            word: 'crazyluis galanoleukos marielamarie',
            count: 1,
          },
          {
            word: 'galanoleukos marielamarie roadkillsindia',
            count: 1,
          },
          {
            word: 'marielamarie roadkillsindia puma',
            count: 1,
          },
          {
            word: 'roadkillsindia puma mikaillxx',
            count: 1,
          },
          {
            word: 'puma mikaillxx okellodoc',
            count: 1,
          },
          {
            word: 'mikaillxx okellodoc vynxpmjnml',
            count: 1,
          },
          {
            word: 'okellodoc vynxpmjnml check',
            count: 1,
          },
          {
            word: 'vynxpmjnml check pinned',
            count: 1,
          },
          {
            word: 'rodgerssan adaziecallister filipaclopess',
            count: 1,
          },
          {
            word: 'adaziecallister filipaclopess hobisdimpleee',
            count: 1,
          },
          {
            word: 'filipaclopess hobisdimpleee yavahlns',
            count: 1,
          },
          {
            word: 'hobisdimpleee yavahlns yarelisramona',
            count: 1,
          },
          {
            word: 'yavahlns yarelisramona aurallduck',
            count: 1,
          },
          {
            word: 'yarelisramona aurallduck upperuser',
            count: 1,
          },
          {
            word: 'aurallduck upperuser slimguy',
            count: 1,
          },
          {
            word: 'upperuser slimguy bignfuzzy',
            count: 1,
          },
          {
            word: 'slimguy bignfuzzy gpolitics',
            count: 1,
          },
          {
            word: 'bignfuzzy gpolitics esraaelzany',
            count: 1,
          },
          {
            word: 'gpolitics esraaelzany burhankamal',
            count: 1,
          },
          {
            word: 'esraaelzany burhankamal juanseb',
            count: 1,
          },
          {
            word: 'burhankamal juanseb shippuma',
            count: 1,
          },
          {
            word: 'juanseb shippuma eveferreira',
            count: 1,
          },
          {
            word: 'shippuma eveferreira oxmar',
            count: 1,
          },
          {
            word: 'eveferreira oxmar djohnson',
            count: 1,
          },
          {
            word: 'oxmar djohnson yanpingzhang',
            count: 1,
          },
          {
            word: 'djohnson yanpingzhang mdeleonretired',
            count: 1,
          },
          {
            word: 'yanpingzhang mdeleonretired check',
            count: 1,
          },
          {
            word: 'mdeleonretired check pinned',
            count: 1,
          },
          {
            word: 'live puma stewie',
            count: 1,
          },
          {
            word: 'puma stewie httpstcotygduzrbtry',
            count: 1,
          },
          {
            word: 'stewie httpstcotygduzrbtry code',
            count: 1,
          },
          {
            word: 'httpstcotygduzrbtry code sneakers',
            count: 1,
          },
          {
            word: 'code sneakers httpstcokpgcwaqho',
            count: 1,
          },
          {
            word: 'uithemovie stockmarketcrash saipallavi',
            count: 5,
          },
          {
            word: 'stockmarketcrash saipallavi hariharaveeramallu',
            count: 5,
          },
          {
            word: 'saipallavi hariharaveeramallu elvishyadav',
            count: 5,
          },
          {
            word: 'hariharaveeramallu elvishyadav gamechanger',
            count: 5,
          },
          {
            word: 'elvishyadav gamechanger kanguva',
            count: 5,
          },
          {
            word: 'gamechanger kanguva fake',
            count: 5,
          },
          {
            word: 'kanguva fake news',
            count: 5,
          },
          {
            word: 'fake news thegoathttpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'declanhort makelelejunior alfredorenfra',
            count: 1,
          },
          {
            word: 'makelelejunior alfredorenfra pumadelmonte',
            count: 1,
          },
          {
            word: 'alfredorenfra pumadelmonte mcgeesinead',
            count: 1,
          },
          {
            word: 'pumadelmonte mcgeesinead shibrumour',
            count: 1,
          },
          {
            word: 'mcgeesinead shibrumour patriciafranco',
            count: 1,
          },
          {
            word: 'shibrumour patriciafranco omarsabat',
            count: 1,
          },
          {
            word: 'patriciafranco omarsabat cheke',
            count: 1,
          },
          {
            word: 'omarsabat cheke ffanoch',
            count: 1,
          },
          {
            word: 'cheke ffanoch ruanvieirar',
            count: 1,
          },
          {
            word: 'ffanoch ruanvieirar matthewswain',
            count: 1,
          },
          {
            word: 'ruanvieirar matthewswain eagleny',
            count: 1,
          },
          {
            word: 'matthewswain eagleny ozpolataptullah',
            count: 1,
          },
          {
            word: 'eagleny ozpolataptullah meredithrose',
            count: 1,
          },
          {
            word: 'ozpolataptullah meredithrose rahul',
            count: 1,
          },
          {
            word: 'meredithrose rahul niels',
            count: 1,
          },
          {
            word: 'rahul niels magutaku',
            count: 1,
          },
          {
            word: 'niels magutaku kingsndimele',
            count: 1,
          },
          {
            word: 'magutaku kingsndimele check',
            count: 1,
          },
          {
            word: 'kingsndimele check pinned',
            count: 1,
          },
          {
            word: 'puma pumasouthafrica pumahoops',
            count: 1,
          },
          {
            word: 'pumasouthafrica pumahoops news',
            count: 1,
          },
          {
            word: 'pumahoops news lamelo',
            count: 1,
          },
          {
            word: 'news lamelo ball',
            count: 2,
          },
          {
            word: 'lamelo ball puma',
            count: 2,
          },
          {
            word: 'ball puma porsche',
            count: 2,
          },
          {
            word: 'puma porsche step',
            count: 2,
          },
          {
            word: 'porsche step fast',
            count: 2,
          },
          {
            word: 'step fast lanelink',
            count: 2,
          },
          {
            word: 'fast lanelink httpstcoolcsrsxqxlameloball',
            count: 2,
          },
          {
            word: 'lanelink httpstcoolcsrsxqxlameloball nbanews',
            count: 2,
          },
          {
            word: 'httpstcoolcsrsxqxlameloball nbanews porsche',
            count: 2,
          },
          {
            word: 'nbanews porsche pumapumaandporsche',
            count: 2,
          },
          {
            word: 'porsche pumapumaandporsche thefastlane',
            count: 2,
          },
          {
            word: 'pumapumaandporsche thefastlane httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'pumapumaandporsche thefastlane httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'thegreysole puma socks',
            count: 1,
          },
          {
            word: 'puma socks available',
            count: 1,
          },
          {
            word: 'socks available women',
            count: 1,
          },
          {
            word: 'available women pack',
            count: 1,
          },
          {
            word: 'women pack send',
            count: 1,
          },
          {
            word: 'pack send visit',
            count: 1,
          },
          {
            word: 'send visit website',
            count: 1,
          },
          {
            word: 'visit website httpstcoixdocyvk',
            count: 1,
          },
          {
            word: 'roachcalla jyrago mingifixsong',
            count: 1,
          },
          {
            word: 'jyrago mingifixsong majdialbana',
            count: 1,
          },
          {
            word: 'mingifixsong majdialbana tonymelendez',
            count: 1,
          },
          {
            word: 'majdialbana tonymelendez stanby',
            count: 1,
          },
          {
            word: 'tonymelendez stanby shahannayoung',
            count: 1,
          },
          {
            word: 'stanby shahannayoung jokerfiko',
            count: 1,
          },
          {
            word: 'shahannayoung jokerfiko shwkyamanaka',
            count: 1,
          },
          {
            word: 'jokerfiko shwkyamanaka kaccccchan',
            count: 1,
          },
          {
            word: 'shwkyamanaka kaccccchan luxluct',
            count: 1,
          },
          {
            word: 'kaccccchan luxluct fetichistaputin',
            count: 1,
          },
          {
            word: 'luxluct fetichistaputin pumars',
            count: 1,
          },
          {
            word: 'fetichistaputin pumars animenougat',
            count: 1,
          },
          {
            word: 'pumars animenougat eliasyusuff',
            count: 1,
          },
          {
            word: 'animenougat eliasyusuff nadahonor',
            count: 1,
          },
          {
            word: 'eliasyusuff nadahonor sazaross',
            count: 1,
          },
          {
            word: 'nadahonor sazaross frwansham',
            count: 1,
          },
          {
            word: 'sazaross frwansham jonathandkeck',
            count: 1,
          },
          {
            word: 'frwansham jonathandkeck justsodamngood',
            count: 1,
          },
          {
            word: 'jonathandkeck justsodamngood jmool',
            count: 1,
          },
          {
            word: 'justsodamngood jmool maentina',
            count: 1,
          },
          {
            word: 'jmool maentina check',
            count: 1,
          },
          {
            word: 'maentina check pinned',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcomvnum',
            count: 1,
          },
          {
            word: 'snkrtwitr almost live',
            count: 1,
          },
          {
            word: 'almost live snswmns',
            count: 2,
          },
          {
            word: 'live snswmns jordan',
            count: 2,
          },
          {
            word: 'snswmns jordan retro',
            count: 2,
          },
          {
            word: 'jordan retro high',
            count: 2,
          },
          {
            word: 'retro high whitemetallic',
            count: 2,
          },
          {
            word: 'high whitemetallic goldw',
            count: 2,
          },
          {
            word: 'whitemetallic goldw httpstcoowfflyfzjxp',
            count: 2,
          },
          {
            word: 'goldw httpstcoowfflyfzjxp httpstcopqbjaksddwt',
            count: 1,
          },
          {
            word: 'httpstcoowfflyfzjxp httpstcopqbjaksddwt httpstcomkgmpurqniwmns',
            count: 1,
          },
          {
            word: 'httpstcopqbjaksddwt httpstcomkgmpurqniwmns puma',
            count: 1,
          },
          {
            word: 'httpstcomkgmpurqniwmns puma stewie',
            count: 1,
          },
          {
            word: 'puma stewie amor',
            count: 1,
          },
          {
            word: 'stewie amor httpstcomuvnkyhytry',
            count: 1,
          },
          {
            word: 'amor httpstcomuvnkyhytry code',
            count: 1,
          },
          {
            word: 'httpstcomuvnkyhytry code sneakers',
            count: 1,
          },
          {
            word: 'code sneakers httpstconfymgs',
            count: 1,
          },
          {
            word: 'seiyuusunika miharu hanai',
            count: 1,
          },
          {
            word: 'miharu hanai wmns',
            count: 2,
          },
          {
            word: 'hanai wmns puma',
            count: 2,
          },
          {
            word: 'wmns puma mayze',
            count: 2,
          },
          {
            word: 'puma mayze thrifted',
            count: 2,
          },
          {
            word: 'mayze thrifted white',
            count: 2,
          },
          {
            word: 'thrifted white little',
            count: 2,
          },
          {
            word: 'white little sister',
            count: 2,
          },
          {
            word: 'little sister httpstcouppihntdn',
            count: 2,
          },
          {
            word: 'petercrouch puma hoodie',
            count: 1,
          },
          {
            word: 'puma hoodie looks',
            count: 1,
          },
          {
            word: 'hoodie looks like',
            count: 1,
          },
          {
            word: 'looks like someone',
            count: 1,
          },
          {
            word: 'like someone grabbed',
            count: 1,
          },
          {
            word: 'someone grabbed fill',
            count: 1,
          },
          {
            word: 'grabbed fill seat',
            count: 1,
          },
          {
            word: 'fill seat waiting',
            count: 1,
          },
          {
            word: 'seat waiting didi',
            count: 1,
          },
          {
            word: 'waiting didi hamann',
            count: 1,
          },
          {
            word: 'didi hamann arrive',
            count: 1,
          },
          {
            word: 'gentlewong daejeon home',
            count: 1,
          },
          {
            word: 'daejeon home away',
            count: 1,
          },
          {
            word: 'home away ktis',
            count: 1,
          },
          {
            word: 'away ktis puma',
            count: 1,
          },
          {
            word: 'ktis puma httpstcodwrnlkbgov',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'puma suede size',
            count: 1,
          },
          {
            word: 'suede size delivery',
            count: 1,
          },
          {
            word: 'size delivery within',
            count: 1,
          },
          {
            word: 'delivery within nairobi',
            count: 1,
          },
          {
            word: 'within nairobi pickup',
            count: 1,
          },
          {
            word: 'nairobi pickup mtaani',
            count: 1,
          },
          {
            word: 'pickup mtaani services',
            count: 1,
          },
          {
            word: 'mtaani services available',
            count: 1,
          },
          {
            word: 'services available httpstcocyxgdzxlb',
            count: 1,
          },
          {
            word: 'gyusphoria like want',
            count: 1,
          },
          {
            word: 'like want puma',
            count: 1,
          },
          {
            word: 'want puma peanits',
            count: 1,
          },
          {
            word: 'puma peanits mouth',
            count: 1,
          },
          {
            word: 'puma eachmeet concertpm',
            count: 1,
          },
          {
            word: 'eachmeet concertpm interestediveinkl',
            count: 1,
          },
          {
            word: 'concertpm interestediveinkl httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'maturenl puma swede',
            count: 1,
          },
          {
            word: 'puma swede angel',
            count: 1,
          },
          {
            word: 'swede angel long',
            count: 1,
          },
          {
            word: 'angel long diamond',
            count: 1,
          },
          {
            word: 'long diamond angela',
            count: 1,
          },
          {
            word: 'diamond angela aspen',
            count: 1,
          },
          {
            word: 'angela aspen kinky',
            count: 1,
          },
          {
            word: 'aspen kinky pussyeating',
            count: 1,
          },
          {
            word: 'kinky pussyeating milf',
            count: 1,
          },
          {
            word: 'pussyeating milf sexparty',
            count: 1,
          },
          {
            word: 'milf sexparty everything',
            count: 1,
          },
          {
            word: 'sexparty everything allowed',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'hltco httpstcoutmvbes puma',
            count: 1,
          },
          {
            word: 'httpstcoutmvbes puma hardchorus',
            count: 1,
          },
          {
            word: 'puma hardchorus full',
            count: 1,
          },
          {
            word: 'hardchorus full european',
            count: 1,
          },
          {
            word: 'full european version',
            count: 1,
          },
          {
            word: 'european version anyone',
            count: 1,
          },
          {
            word: 'version anyone interested',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcodulysnr',
            count: 1,
          },
          {
            word: 'annapumakig peekaboo httpstcojnmcznni',
            count: 1,
          },
          {
            word: 'puma smash leather',
            count: 1,
          },
          {
            word: 'smash leather white',
            count: 1,
          },
          {
            word: 'leather white retail',
            count: 1,
          },
          {
            word: 'white retail mandmdirect',
            count: 1,
          },
          {
            word: 'retail mandmdirect sale',
            count: 1,
          },
          {
            word: 'mandmdirect sale price',
            count: 1,
          },
          {
            word: 'sale price drop',
            count: 1,
          },
          {
            word: 'price drop link',
            count: 1,
          },
          {
            word: 'drop link httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'link httpstcozapjuehdq sizes',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq sizes httpstcowtcknauzu',
            count: 1,
          },
        ],
      },
      emotion_wordcloud: {
        joy_unigram: [
          {
            word: 'puma',
            count: 25,
          },
          {
            word: 'missrozapepper',
            count: 15,
          },
          {
            word: 'davido',
            count: 15,
          },
          {
            word: 'performing',
            count: 15,
          },
          {
            word: 'birthday',
            count: 15,
          },
          {
            word: 'party',
            count: 15,
          },
          {
            word: 'athletico',
            count: 15,
          },
          {
            word: 'madrid',
            count: 15,
          },
          {
            word: 'player',
            count: 15,
          },
          {
            word: 'fellow',
            count: 15,
          },
          {
            word: 'global',
            count: 15,
          },
          {
            word: 'ambassador',
            count: 15,
          },
          {
            word: 'memphis',
            count: 15,
          },
          {
            word: 'depay',
            count: 15,
          },
          {
            word: 'check',
            count: 8,
          },
          {
            word: 'pinned',
            count: 7,
          },
          {
            word: 'ebay',
            count: 2,
          },
          {
            word: 'like',
            count: 2,
          },
          {
            word: 'pumakorea',
            count: 2,
          },
          {
            word: 'happy',
            count: 2,
          },
          {
            word: 'valentines',
            count: 2,
          },
          {
            word: 'pumaxkd',
            count: 2,
          },
          {
            word: 'kangdaniel',
            count: 2,
          },
          {
            word: '강다니엘',
            count: 2,
          },
          {
            word: 'rsxcube',
            count: 2,
          },
          {
            word: 'rsx큐브',
            count: 2,
          },
          {
            word: 'httpstcoiirruv',
            count: 2,
          },
          {
            word: 'collierwes',
            count: 2,
          },
          {
            word: 'deadmansrock',
            count: 2,
          },
          {
            word: 'caraembh',
            count: 2,
          },
          {
            word: 'skunktwine',
            count: 2,
          },
          {
            word: 'mohamme',
            count: 2,
          },
          {
            word: 'zakizaidi',
            count: 2,
          },
          {
            word: 'jamessbutler',
            count: 2,
          },
          {
            word: 'zdamilaree',
            count: 2,
          },
          {
            word: 'rracarvalho',
            count: 2,
          },
          {
            word: 'ropukdeb',
            count: 2,
          },
          {
            word: 'bankykanta',
            count: 2,
          },
          {
            word: 'pbacelar',
            count: 2,
          },
          {
            word: 'kidrauhlgirl',
            count: 2,
          },
          {
            word: 'dookydabarber',
            count: 2,
          },
          {
            word: 'oluafolabi',
            count: 2,
          },
          {
            word: 'claralaflore',
            count: 2,
          },
          {
            word: 'wigmorewelsh',
            count: 2,
          },
          {
            word: 'ayaibrahem',
            count: 2,
          },
          {
            word: 'lulpuma',
            count: 2,
          },
          {
            word: 'rociowittib',
            count: 2,
          },
          {
            word: 'mardeamal',
            count: 2,
          },
          {
            word: 'beautiful',
            count: 2,
          },
          {
            word: 'dear',
            count: 2,
          },
          {
            word: 'duck',
            count: 2,
          },
          {
            word: 'bungeopang',
            count: 2,
          },
          {
            word: 'niki',
            count: 2,
          },
          {
            word: 'jimmythorn',
            count: 1,
          },
          {
            word: 'bazbowler',
            count: 1,
          },
          {
            word: 'kaaiot',
            count: 1,
          },
          {
            word: 'szlaban',
            count: 1,
          },
          {
            word: 'melizwalls',
            count: 1,
          },
          {
            word: 'notwuk',
            count: 1,
          },
          {
            word: 'starmakeriseye',
            count: 1,
          },
          {
            word: 'cookeben',
            count: 1,
          },
          {
            word: 'gazellassss',
            count: 1,
          },
          {
            word: 'akkyn',
            count: 1,
          },
          {
            word: 'asifmittal',
            count: 1,
          },
          {
            word: 'creeper',
            count: 1,
          },
          {
            word: 'mhsolis',
            count: 1,
          },
          {
            word: 'yfkdrags',
            count: 1,
          },
          {
            word: 'portb',
            count: 1,
          },
          {
            word: 'noelrz',
            count: 1,
          },
          {
            word: 'pumalovedragon',
            count: 1,
          },
          {
            word: 'seanclose',
            count: 1,
          },
          {
            word: 'taniaaass',
            count: 1,
          },
          {
            word: 'sakuyafgo',
            count: 1,
          },
          {
            word: 'cmillenbine',
            count: 1,
          },
          {
            word: 'slmakab',
            count: 1,
          },
          {
            word: 'carollaannee',
            count: 1,
          },
          {
            word: 'lilismr',
            count: 1,
          },
          {
            word: 'enzo',
            count: 1,
          },
          {
            word: 'weave',
            count: 1,
          },
          {
            word: 'price',
            count: 1,
          },
          {
            word: 'reduced',
            count: 1,
          },
          {
            word: 'httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'alexluck',
            count: 1,
          },
          {
            word: 'daveisfree',
            count: 1,
          },
          {
            word: 'goldrandlösung',
            count: 1,
          },
          {
            word: 'cool',
            count: 1,
          },
          {
            word: 'shit',
            count: 1,
          },
          {
            word: 'swansonma',
            count: 1,
          },
          {
            word: 'tqhung',
            count: 1,
          },
          {
            word: 'rafaeltatis',
            count: 1,
          },
          {
            word: 'jaspreetkbansal',
            count: 1,
          },
          {
            word: 'ehsaniran',
            count: 1,
          },
          {
            word: 'javedadil',
            count: 1,
          },
          {
            word: 'nicocarnage',
            count: 1,
          },
          {
            word: 'ksimorri',
            count: 1,
          },
          {
            word: 'ianreds',
            count: 1,
          },
          {
            word: 'romantickasap',
            count: 1,
          },
          {
            word: 'swerrii',
            count: 1,
          },
          {
            word: 'nunesjosito',
            count: 1,
          },
          {
            word: 'giant',
            count: 1,
          },
          {
            word: 'mokgadivictor',
            count: 1,
          },
          {
            word: 'elmaelmasry',
            count: 1,
          },
          {
            word: 'isaadansari',
            count: 1,
          },
          {
            word: 'zonnbn',
            count: 1,
          },
          {
            word: 'brandopuma',
            count: 1,
          },
          {
            word: 'klkl',
            count: 1,
          },
          {
            word: 'juventudgh',
            count: 1,
          },
          {
            word: 'colonyuunagi',
            count: 1,
          },
          {
            word: 'almasnoora',
            count: 1,
          },
          {
            word: 'wallacepjw',
            count: 1,
          },
          {
            word: 'ford',
            count: 1,
          },
          {
            word: 'lowerio',
            count: 1,
          },
          {
            word: 'thunderdarcy',
            count: 1,
          },
          {
            word: 'goodforsmething',
            count: 1,
          },
          {
            word: 'growstudiohq',
            count: 1,
          },
          {
            word: 'shimaaa',
            count: 1,
          },
          {
            word: 'unstoppablebs',
            count: 1,
          },
          {
            word: 'xiiiix',
            count: 1,
          },
          {
            word: 'ebondarcom',
            count: 1,
          },
          {
            word: 'konsonanti',
            count: 1,
          },
          {
            word: 'kingmoye',
            count: 1,
          },
          {
            word: 'seamyfarmer',
            count: 1,
          },
          {
            word: 'furkancobaan',
            count: 1,
          },
          {
            word: 'anil',
            count: 1,
          },
          {
            word: 'alirezadini',
            count: 1,
          },
          {
            word: 'bhanbhroamjad',
            count: 1,
          },
          {
            word: 'wirwerdenreich',
            count: 1,
          },
          {
            word: 'clebrun',
            count: 1,
          },
          {
            word: 'ewingjeremy',
            count: 1,
          },
          {
            word: 'takooryouri',
            count: 1,
          },
          {
            word: 'goaljpofficial',
            count: 1,
          },
          {
            word: 'goal',
            count: 1,
          },
          {
            word: 'japan',
            count: 1,
          },
          {
            word: 'dennisj',
            count: 1,
          },
          {
            word: 'whoknowsmysoul',
            count: 1,
          },
          {
            word: 'kaabuame',
            count: 1,
          },
          {
            word: 'kcdmsmkecfngere',
            count: 1,
          },
          {
            word: 'phronesianos',
            count: 1,
          },
          {
            word: 'yrsweet',
            count: 1,
          },
          {
            word: 'easyfires',
            count: 1,
          },
          {
            word: 'keyvanrahmadi',
            count: 1,
          },
          {
            word: 'macanth',
            count: 1,
          },
          {
            word: 'vhpeuch',
            count: 1,
          },
          {
            word: 'takekittyshit',
            count: 1,
          },
          {
            word: 'esdglobaltech',
            count: 1,
          },
          {
            word: 'myramalways',
            count: 1,
          },
          {
            word: 'jazzlynpuma',
            count: 1,
          },
          {
            word: 'iiionceau',
            count: 1,
          },
          {
            word: 'nidhi',
            count: 1,
          },
          {
            word: 'leeorocha',
            count: 1,
          },
          {
            word: 'bushmanind',
            count: 1,
          },
          {
            word: 'wales',
            count: 1,
          },
          {
            word: 'murdocklvrr',
            count: 1,
          },
          {
            word: 'lokopeter',
            count: 1,
          },
          {
            word: 'roachcalla',
            count: 1,
          },
          {
            word: 'jyrago',
            count: 1,
          },
          {
            word: 'mingifixsong',
            count: 1,
          },
          {
            word: 'majdialbana',
            count: 1,
          },
          {
            word: 'tonymelendez',
            count: 1,
          },
          {
            word: 'stanby',
            count: 1,
          },
          {
            word: 'shahannayoung',
            count: 1,
          },
          {
            word: 'jokerfiko',
            count: 1,
          },
          {
            word: 'shwkyamanaka',
            count: 1,
          },
          {
            word: 'kaccccchan',
            count: 1,
          },
          {
            word: 'luxluct',
            count: 1,
          },
          {
            word: 'fetichistaputin',
            count: 1,
          },
          {
            word: 'pumars',
            count: 1,
          },
          {
            word: 'animenougat',
            count: 1,
          },
          {
            word: 'eliasyusuff',
            count: 1,
          },
          {
            word: 'nadahonor',
            count: 1,
          },
          {
            word: 'sazaross',
            count: 1,
          },
          {
            word: 'frwansham',
            count: 1,
          },
          {
            word: 'jonathandkeck',
            count: 1,
          },
          {
            word: 'justsodamngood',
            count: 1,
          },
          {
            word: 'jmool',
            count: 1,
          },
          {
            word: 'maentina',
            count: 1,
          },
          {
            word: 'always',
            count: 1,
          },
          {
            word: 'super',
            count: 1,
          },
          {
            word: 'comfy',
            count: 1,
          },
          {
            word: 'httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'maturenl',
            count: 1,
          },
          {
            word: 'swede',
            count: 1,
          },
          {
            word: 'angel',
            count: 1,
          },
          {
            word: 'long',
            count: 1,
          },
          {
            word: 'diamond',
            count: 1,
          },
          {
            word: 'angela',
            count: 1,
          },
          {
            word: 'aspen',
            count: 1,
          },
          {
            word: 'kinky',
            count: 1,
          },
          {
            word: 'pussyeating',
            count: 1,
          },
          {
            word: 'milf',
            count: 1,
          },
          {
            word: 'sexparty',
            count: 1,
          },
          {
            word: 'everything',
            count: 1,
          },
          {
            word: 'allowed',
            count: 1,
          },
          {
            word: 'annapumakig',
            count: 1,
          },
          {
            word: 'peekaboo',
            count: 1,
          },
          {
            word: 'httpstcojnmcznni',
            count: 1,
          },
          {
            word: 'nicekicks',
            count: 1,
          },
          {
            word: 'lamelo',
            count: 1,
          },
          {
            word: 'ball',
            count: 1,
          },
          {
            word: 'unveil',
            count: 1,
          },
          {
            word: 'highly',
            count: 1,
          },
          {
            word: 'anticipated',
            count: 1,
          },
          {
            word: 'porsche',
            count: 1,
          },
          {
            word: 'collection',
            count: 1,
          },
          {
            word: 'launching',
            count: 1,
          },
          {
            word: 'february',
            count: 1,
          },
          {
            word: 'nicedrops',
            count: 1,
          },
          {
            word: 'enhypenas',
            count: 1,
          },
          {
            word: 'stars',
            count: 1,
          },
          {
            word: 'alligned',
            count: 1,
          },
          {
            word: 'dashing',
            count: 1,
          },
          {
            word: 'vampire',
            count: 1,
          },
          {
            word: 'appeared',
            count: 1,
          },
          {
            word: 'turned',
            count: 1,
          },
          {
            word: 'ever',
            count: 1,
          },
          {
            word: 'live',
            count: 1,
          },
          {
            word: 'earth',
            count: 1,
          },
          {
            word: 'love',
            count: 1,
          },
          {
            word: 'solace',
            count: 1,
          },
          {
            word: 'hope',
            count: 1,
          },
          {
            word: 'resting',
            count: 1,
          },
          {
            word: 'well',
            count: 1,
          },
          {
            word: 'httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
        joy_bigram: [
          {
            word: 'missrozapepper davido',
            count: 15,
          },
          {
            word: 'davido performing',
            count: 15,
          },
          {
            word: 'performing birthday',
            count: 15,
          },
          {
            word: 'birthday party',
            count: 15,
          },
          {
            word: 'party athletico',
            count: 15,
          },
          {
            word: 'athletico madrid',
            count: 15,
          },
          {
            word: 'madrid player',
            count: 15,
          },
          {
            word: 'player fellow',
            count: 15,
          },
          {
            word: 'fellow puma',
            count: 15,
          },
          {
            word: 'puma global',
            count: 15,
          },
          {
            word: 'global ambassador',
            count: 15,
          },
          {
            word: 'ambassador memphis',
            count: 15,
          },
          {
            word: 'memphis depay',
            count: 15,
          },
          {
            word: 'check pinned',
            count: 7,
          },
          {
            word: 'pumakorea happy',
            count: 2,
          },
          {
            word: 'happy valentines',
            count: 2,
          },
          {
            word: 'valentines pumaxkd',
            count: 2,
          },
          {
            word: 'pumaxkd kangdaniel',
            count: 2,
          },
          {
            word: 'kangdaniel 강다니엘',
            count: 2,
          },
          {
            word: '강다니엘 rsxcube',
            count: 2,
          },
          {
            word: 'rsxcube rsx큐브',
            count: 2,
          },
          {
            word: 'rsx큐브 httpstcoiirruv',
            count: 2,
          },
          {
            word: 'collierwes deadmansrock',
            count: 2,
          },
          {
            word: 'deadmansrock caraembh',
            count: 2,
          },
          {
            word: 'caraembh skunktwine',
            count: 2,
          },
          {
            word: 'skunktwine mohamme',
            count: 2,
          },
          {
            word: 'mohamme zakizaidi',
            count: 2,
          },
          {
            word: 'zakizaidi jamessbutler',
            count: 2,
          },
          {
            word: 'jamessbutler zdamilaree',
            count: 2,
          },
          {
            word: 'zdamilaree rracarvalho',
            count: 2,
          },
          {
            word: 'rracarvalho ropukdeb',
            count: 2,
          },
          {
            word: 'ropukdeb bankykanta',
            count: 2,
          },
          {
            word: 'bankykanta pbacelar',
            count: 2,
          },
          {
            word: 'pbacelar kidrauhlgirl',
            count: 2,
          },
          {
            word: 'kidrauhlgirl dookydabarber',
            count: 2,
          },
          {
            word: 'dookydabarber oluafolabi',
            count: 2,
          },
          {
            word: 'oluafolabi claralaflore',
            count: 2,
          },
          {
            word: 'claralaflore wigmorewelsh',
            count: 2,
          },
          {
            word: 'wigmorewelsh ayaibrahem',
            count: 2,
          },
          {
            word: 'ayaibrahem lulpuma',
            count: 2,
          },
          {
            word: 'lulpuma rociowittib',
            count: 2,
          },
          {
            word: 'rociowittib mardeamal',
            count: 2,
          },
          {
            word: 'mardeamal check',
            count: 2,
          },
          {
            word: 'dear duck',
            count: 2,
          },
          {
            word: 'duck puma',
            count: 2,
          },
          {
            word: 'puma bungeopang',
            count: 2,
          },
          {
            word: 'jimmythorn bazbowler',
            count: 1,
          },
          {
            word: 'bazbowler kaaiot',
            count: 1,
          },
          {
            word: 'kaaiot szlaban',
            count: 1,
          },
          {
            word: 'szlaban melizwalls',
            count: 1,
          },
          {
            word: 'melizwalls notwuk',
            count: 1,
          },
          {
            word: 'notwuk starmakeriseye',
            count: 1,
          },
          {
            word: 'starmakeriseye cookeben',
            count: 1,
          },
          {
            word: 'cookeben gazellassss',
            count: 1,
          },
          {
            word: 'gazellassss akkyn',
            count: 1,
          },
          {
            word: 'akkyn asifmittal',
            count: 1,
          },
          {
            word: 'asifmittal creeper',
            count: 1,
          },
          {
            word: 'creeper mhsolis',
            count: 1,
          },
          {
            word: 'mhsolis yfkdrags',
            count: 1,
          },
          {
            word: 'yfkdrags portb',
            count: 1,
          },
          {
            word: 'portb noelrz',
            count: 1,
          },
          {
            word: 'noelrz pumalovedragon',
            count: 1,
          },
          {
            word: 'pumalovedragon seanclose',
            count: 1,
          },
          {
            word: 'seanclose taniaaass',
            count: 1,
          },
          {
            word: 'taniaaass sakuyafgo',
            count: 1,
          },
          {
            word: 'sakuyafgo cmillenbine',
            count: 1,
          },
          {
            word: 'cmillenbine slmakab',
            count: 1,
          },
          {
            word: 'slmakab carollaannee',
            count: 1,
          },
          {
            word: 'carollaannee lilismr',
            count: 1,
          },
          {
            word: 'lilismr check',
            count: 1,
          },
          {
            word: 'check puma',
            count: 1,
          },
          {
            word: 'puma enzo',
            count: 1,
          },
          {
            word: 'enzo weave',
            count: 1,
          },
          {
            word: 'weave price',
            count: 1,
          },
          {
            word: 'price reduced',
            count: 1,
          },
          {
            word: 'reduced httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'httpstcoclxjonjnwp ebay',
            count: 1,
          },
          {
            word: 'ebay ebay',
            count: 1,
          },
          {
            word: 'alexluck daveisfree',
            count: 1,
          },
          {
            word: 'daveisfree goldrandlösung',
            count: 1,
          },
          {
            word: 'goldrandlösung cool',
            count: 1,
          },
          {
            word: 'cool shit',
            count: 1,
          },
          {
            word: 'shit like',
            count: 1,
          },
          {
            word: 'like puma',
            count: 1,
          },
          {
            word: 'swansonma tqhung',
            count: 1,
          },
          {
            word: 'tqhung rafaeltatis',
            count: 1,
          },
          {
            word: 'rafaeltatis jaspreetkbansal',
            count: 1,
          },
          {
            word: 'jaspreetkbansal ehsaniran',
            count: 1,
          },
          {
            word: 'ehsaniran javedadil',
            count: 1,
          },
          {
            word: 'javedadil nicocarnage',
            count: 1,
          },
          {
            word: 'nicocarnage ksimorri',
            count: 1,
          },
          {
            word: 'ksimorri ianreds',
            count: 1,
          },
          {
            word: 'ianreds romantickasap',
            count: 1,
          },
          {
            word: 'romantickasap swerrii',
            count: 1,
          },
          {
            word: 'swerrii nunesjosito',
            count: 1,
          },
          {
            word: 'nunesjosito giant',
            count: 1,
          },
          {
            word: 'giant mokgadivictor',
            count: 1,
          },
          {
            word: 'mokgadivictor elmaelmasry',
            count: 1,
          },
          {
            word: 'elmaelmasry isaadansari',
            count: 1,
          },
          {
            word: 'isaadansari zonnbn',
            count: 1,
          },
          {
            word: 'zonnbn brandopuma',
            count: 1,
          },
          {
            word: 'brandopuma klkl',
            count: 1,
          },
          {
            word: 'klkl juventudgh',
            count: 1,
          },
          {
            word: 'juventudgh colonyuunagi',
            count: 1,
          },
          {
            word: 'colonyuunagi almasnoora',
            count: 1,
          },
          {
            word: 'almasnoora check',
            count: 1,
          },
          {
            word: 'wallacepjw like',
            count: 1,
          },
          {
            word: 'like ford',
            count: 1,
          },
          {
            word: 'ford puma',
            count: 1,
          },
          {
            word: 'lowerio thunderdarcy',
            count: 1,
          },
          {
            word: 'thunderdarcy goodforsmething',
            count: 1,
          },
          {
            word: 'goodforsmething growstudiohq',
            count: 1,
          },
          {
            word: 'growstudiohq puma',
            count: 1,
          },
          {
            word: 'puma shimaaa',
            count: 1,
          },
          {
            word: 'shimaaa unstoppablebs',
            count: 1,
          },
          {
            word: 'unstoppablebs xiiiix',
            count: 1,
          },
          {
            word: 'xiiiix ebondarcom',
            count: 1,
          },
          {
            word: 'ebondarcom konsonanti',
            count: 1,
          },
          {
            word: 'konsonanti kingmoye',
            count: 1,
          },
          {
            word: 'kingmoye seamyfarmer',
            count: 1,
          },
          {
            word: 'seamyfarmer furkancobaan',
            count: 1,
          },
          {
            word: 'furkancobaan anil',
            count: 1,
          },
          {
            word: 'anil alirezadini',
            count: 1,
          },
          {
            word: 'alirezadini bhanbhroamjad',
            count: 1,
          },
          {
            word: 'bhanbhroamjad wirwerdenreich',
            count: 1,
          },
          {
            word: 'wirwerdenreich clebrun',
            count: 1,
          },
          {
            word: 'clebrun ewingjeremy',
            count: 1,
          },
          {
            word: 'ewingjeremy takooryouri',
            count: 1,
          },
          {
            word: 'takooryouri check',
            count: 1,
          },
          {
            word: 'goaljpofficial puma',
            count: 1,
          },
          {
            word: 'puma goal',
            count: 1,
          },
          {
            word: 'goal japan',
            count: 1,
          },
          {
            word: 'japan beautiful',
            count: 1,
          },
          {
            word: 'dennisj whoknowsmysoul',
            count: 1,
          },
          {
            word: 'whoknowsmysoul kaabuame',
            count: 1,
          },
          {
            word: 'kaabuame kcdmsmkecfngere',
            count: 1,
          },
          {
            word: 'kcdmsmkecfngere phronesianos',
            count: 1,
          },
          {
            word: 'phronesianos yrsweet',
            count: 1,
          },
          {
            word: 'yrsweet easyfires',
            count: 1,
          },
          {
            word: 'easyfires keyvanrahmadi',
            count: 1,
          },
          {
            word: 'keyvanrahmadi macanth',
            count: 1,
          },
          {
            word: 'macanth vhpeuch',
            count: 1,
          },
          {
            word: 'vhpeuch takekittyshit',
            count: 1,
          },
          {
            word: 'takekittyshit esdglobaltech',
            count: 1,
          },
          {
            word: 'esdglobaltech myramalways',
            count: 1,
          },
          {
            word: 'myramalways jazzlynpuma',
            count: 1,
          },
          {
            word: 'jazzlynpuma iiionceau',
            count: 1,
          },
          {
            word: 'iiionceau nidhi',
            count: 1,
          },
          {
            word: 'nidhi leeorocha',
            count: 1,
          },
          {
            word: 'leeorocha bushmanind',
            count: 1,
          },
          {
            word: 'bushmanind wales',
            count: 1,
          },
          {
            word: 'wales murdocklvrr',
            count: 1,
          },
          {
            word: 'murdocklvrr lokopeter',
            count: 1,
          },
          {
            word: 'lokopeter check',
            count: 1,
          },
          {
            word: 'roachcalla jyrago',
            count: 1,
          },
          {
            word: 'jyrago mingifixsong',
            count: 1,
          },
          {
            word: 'mingifixsong majdialbana',
            count: 1,
          },
          {
            word: 'majdialbana tonymelendez',
            count: 1,
          },
          {
            word: 'tonymelendez stanby',
            count: 1,
          },
          {
            word: 'stanby shahannayoung',
            count: 1,
          },
          {
            word: 'shahannayoung jokerfiko',
            count: 1,
          },
          {
            word: 'jokerfiko shwkyamanaka',
            count: 1,
          },
          {
            word: 'shwkyamanaka kaccccchan',
            count: 1,
          },
          {
            word: 'kaccccchan luxluct',
            count: 1,
          },
          {
            word: 'luxluct fetichistaputin',
            count: 1,
          },
          {
            word: 'fetichistaputin pumars',
            count: 1,
          },
          {
            word: 'pumars animenougat',
            count: 1,
          },
          {
            word: 'animenougat eliasyusuff',
            count: 1,
          },
          {
            word: 'eliasyusuff nadahonor',
            count: 1,
          },
          {
            word: 'nadahonor sazaross',
            count: 1,
          },
          {
            word: 'sazaross frwansham',
            count: 1,
          },
          {
            word: 'frwansham jonathandkeck',
            count: 1,
          },
          {
            word: 'jonathandkeck justsodamngood',
            count: 1,
          },
          {
            word: 'justsodamngood jmool',
            count: 1,
          },
          {
            word: 'jmool maentina',
            count: 1,
          },
          {
            word: 'maentina check',
            count: 1,
          },
          {
            word: 'always puma',
            count: 1,
          },
          {
            word: 'puma super',
            count: 1,
          },
          {
            word: 'super comfy',
            count: 1,
          },
          {
            word: 'comfy httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'maturenl puma',
            count: 1,
          },
          {
            word: 'puma swede',
            count: 1,
          },
          {
            word: 'swede angel',
            count: 1,
          },
          {
            word: 'angel long',
            count: 1,
          },
          {
            word: 'long diamond',
            count: 1,
          },
          {
            word: 'diamond angela',
            count: 1,
          },
          {
            word: 'angela aspen',
            count: 1,
          },
          {
            word: 'aspen kinky',
            count: 1,
          },
          {
            word: 'kinky pussyeating',
            count: 1,
          },
          {
            word: 'pussyeating milf',
            count: 1,
          },
          {
            word: 'milf sexparty',
            count: 1,
          },
          {
            word: 'sexparty everything',
            count: 1,
          },
          {
            word: 'everything allowed',
            count: 1,
          },
          {
            word: 'annapumakig peekaboo',
            count: 1,
          },
          {
            word: 'peekaboo httpstcojnmcznni',
            count: 1,
          },
          {
            word: 'nicekicks lamelo',
            count: 1,
          },
          {
            word: 'lamelo ball',
            count: 1,
          },
          {
            word: 'ball puma',
            count: 1,
          },
          {
            word: 'puma unveil',
            count: 1,
          },
          {
            word: 'unveil highly',
            count: 1,
          },
          {
            word: 'highly anticipated',
            count: 1,
          },
          {
            word: 'anticipated porsche',
            count: 1,
          },
          {
            word: 'porsche collection',
            count: 1,
          },
          {
            word: 'collection launching',
            count: 1,
          },
          {
            word: 'launching february',
            count: 1,
          },
          {
            word: 'february nicedrops',
            count: 1,
          },
          {
            word: 'bungeopang niki',
            count: 1,
          },
          {
            word: 'niki enhypenas',
            count: 1,
          },
          {
            word: 'enhypenas stars',
            count: 1,
          },
          {
            word: 'stars alligned',
            count: 1,
          },
          {
            word: 'alligned dashing',
            count: 1,
          },
          {
            word: 'dashing vampire',
            count: 1,
          },
          {
            word: 'vampire appeared',
            count: 1,
          },
          {
            word: 'appeared turned',
            count: 1,
          },
          {
            word: 'turned beautiful',
            count: 1,
          },
          {
            word: 'beautiful ever',
            count: 1,
          },
          {
            word: 'ever live',
            count: 1,
          },
          {
            word: 'live earth',
            count: 1,
          },
          {
            word: 'earth dear',
            count: 1,
          },
          {
            word: 'bungeopang love',
            count: 1,
          },
          {
            word: 'love solace',
            count: 1,
          },
          {
            word: 'solace niki',
            count: 1,
          },
          {
            word: 'niki hope',
            count: 1,
          },
          {
            word: 'hope resting',
            count: 1,
          },
          {
            word: 'resting well',
            count: 1,
          },
          {
            word: 'well httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
        joy_trigram: [
          {
            word: 'missrozapepper davido performing',
            count: 15,
          },
          {
            word: 'davido performing birthday',
            count: 15,
          },
          {
            word: 'performing birthday party',
            count: 15,
          },
          {
            word: 'birthday party athletico',
            count: 15,
          },
          {
            word: 'party athletico madrid',
            count: 15,
          },
          {
            word: 'athletico madrid player',
            count: 15,
          },
          {
            word: 'madrid player fellow',
            count: 15,
          },
          {
            word: 'player fellow puma',
            count: 15,
          },
          {
            word: 'fellow puma global',
            count: 15,
          },
          {
            word: 'puma global ambassador',
            count: 15,
          },
          {
            word: 'global ambassador memphis',
            count: 15,
          },
          {
            word: 'ambassador memphis depay',
            count: 15,
          },
          {
            word: 'pumakorea happy valentines',
            count: 2,
          },
          {
            word: 'happy valentines pumaxkd',
            count: 2,
          },
          {
            word: 'valentines pumaxkd kangdaniel',
            count: 2,
          },
          {
            word: 'pumaxkd kangdaniel 강다니엘',
            count: 2,
          },
          {
            word: 'kangdaniel 강다니엘 rsxcube',
            count: 2,
          },
          {
            word: '강다니엘 rsxcube rsx큐브',
            count: 2,
          },
          {
            word: 'rsxcube rsx큐브 httpstcoiirruv',
            count: 2,
          },
          {
            word: 'collierwes deadmansrock caraembh',
            count: 2,
          },
          {
            word: 'deadmansrock caraembh skunktwine',
            count: 2,
          },
          {
            word: 'caraembh skunktwine mohamme',
            count: 2,
          },
          {
            word: 'skunktwine mohamme zakizaidi',
            count: 2,
          },
          {
            word: 'mohamme zakizaidi jamessbutler',
            count: 2,
          },
          {
            word: 'zakizaidi jamessbutler zdamilaree',
            count: 2,
          },
          {
            word: 'jamessbutler zdamilaree rracarvalho',
            count: 2,
          },
          {
            word: 'zdamilaree rracarvalho ropukdeb',
            count: 2,
          },
          {
            word: 'rracarvalho ropukdeb bankykanta',
            count: 2,
          },
          {
            word: 'ropukdeb bankykanta pbacelar',
            count: 2,
          },
          {
            word: 'bankykanta pbacelar kidrauhlgirl',
            count: 2,
          },
          {
            word: 'pbacelar kidrauhlgirl dookydabarber',
            count: 2,
          },
          {
            word: 'kidrauhlgirl dookydabarber oluafolabi',
            count: 2,
          },
          {
            word: 'dookydabarber oluafolabi claralaflore',
            count: 2,
          },
          {
            word: 'oluafolabi claralaflore wigmorewelsh',
            count: 2,
          },
          {
            word: 'claralaflore wigmorewelsh ayaibrahem',
            count: 2,
          },
          {
            word: 'wigmorewelsh ayaibrahem lulpuma',
            count: 2,
          },
          {
            word: 'ayaibrahem lulpuma rociowittib',
            count: 2,
          },
          {
            word: 'lulpuma rociowittib mardeamal',
            count: 2,
          },
          {
            word: 'rociowittib mardeamal check',
            count: 2,
          },
          {
            word: 'mardeamal check pinned',
            count: 2,
          },
          {
            word: 'dear duck puma',
            count: 2,
          },
          {
            word: 'duck puma bungeopang',
            count: 2,
          },
          {
            word: 'jimmythorn bazbowler kaaiot',
            count: 1,
          },
          {
            word: 'bazbowler kaaiot szlaban',
            count: 1,
          },
          {
            word: 'kaaiot szlaban melizwalls',
            count: 1,
          },
          {
            word: 'szlaban melizwalls notwuk',
            count: 1,
          },
          {
            word: 'melizwalls notwuk starmakeriseye',
            count: 1,
          },
          {
            word: 'notwuk starmakeriseye cookeben',
            count: 1,
          },
          {
            word: 'starmakeriseye cookeben gazellassss',
            count: 1,
          },
          {
            word: 'cookeben gazellassss akkyn',
            count: 1,
          },
          {
            word: 'gazellassss akkyn asifmittal',
            count: 1,
          },
          {
            word: 'akkyn asifmittal creeper',
            count: 1,
          },
          {
            word: 'asifmittal creeper mhsolis',
            count: 1,
          },
          {
            word: 'creeper mhsolis yfkdrags',
            count: 1,
          },
          {
            word: 'mhsolis yfkdrags portb',
            count: 1,
          },
          {
            word: 'yfkdrags portb noelrz',
            count: 1,
          },
          {
            word: 'portb noelrz pumalovedragon',
            count: 1,
          },
          {
            word: 'noelrz pumalovedragon seanclose',
            count: 1,
          },
          {
            word: 'pumalovedragon seanclose taniaaass',
            count: 1,
          },
          {
            word: 'seanclose taniaaass sakuyafgo',
            count: 1,
          },
          {
            word: 'taniaaass sakuyafgo cmillenbine',
            count: 1,
          },
          {
            word: 'sakuyafgo cmillenbine slmakab',
            count: 1,
          },
          {
            word: 'cmillenbine slmakab carollaannee',
            count: 1,
          },
          {
            word: 'slmakab carollaannee lilismr',
            count: 1,
          },
          {
            word: 'carollaannee lilismr check',
            count: 1,
          },
          {
            word: 'lilismr check pinned',
            count: 1,
          },
          {
            word: 'check puma enzo',
            count: 1,
          },
          {
            word: 'puma enzo weave',
            count: 1,
          },
          {
            word: 'enzo weave price',
            count: 1,
          },
          {
            word: 'weave price reduced',
            count: 1,
          },
          {
            word: 'price reduced httpstcoclxjonjnwp',
            count: 1,
          },
          {
            word: 'reduced httpstcoclxjonjnwp ebay',
            count: 1,
          },
          {
            word: 'httpstcoclxjonjnwp ebay ebay',
            count: 1,
          },
          {
            word: 'alexluck daveisfree goldrandlösung',
            count: 1,
          },
          {
            word: 'daveisfree goldrandlösung cool',
            count: 1,
          },
          {
            word: 'goldrandlösung cool shit',
            count: 1,
          },
          {
            word: 'cool shit like',
            count: 1,
          },
          {
            word: 'shit like puma',
            count: 1,
          },
          {
            word: 'swansonma tqhung rafaeltatis',
            count: 1,
          },
          {
            word: 'tqhung rafaeltatis jaspreetkbansal',
            count: 1,
          },
          {
            word: 'rafaeltatis jaspreetkbansal ehsaniran',
            count: 1,
          },
          {
            word: 'jaspreetkbansal ehsaniran javedadil',
            count: 1,
          },
          {
            word: 'ehsaniran javedadil nicocarnage',
            count: 1,
          },
          {
            word: 'javedadil nicocarnage ksimorri',
            count: 1,
          },
          {
            word: 'nicocarnage ksimorri ianreds',
            count: 1,
          },
          {
            word: 'ksimorri ianreds romantickasap',
            count: 1,
          },
          {
            word: 'ianreds romantickasap swerrii',
            count: 1,
          },
          {
            word: 'romantickasap swerrii nunesjosito',
            count: 1,
          },
          {
            word: 'swerrii nunesjosito giant',
            count: 1,
          },
          {
            word: 'nunesjosito giant mokgadivictor',
            count: 1,
          },
          {
            word: 'giant mokgadivictor elmaelmasry',
            count: 1,
          },
          {
            word: 'mokgadivictor elmaelmasry isaadansari',
            count: 1,
          },
          {
            word: 'elmaelmasry isaadansari zonnbn',
            count: 1,
          },
          {
            word: 'isaadansari zonnbn brandopuma',
            count: 1,
          },
          {
            word: 'zonnbn brandopuma klkl',
            count: 1,
          },
          {
            word: 'brandopuma klkl juventudgh',
            count: 1,
          },
          {
            word: 'klkl juventudgh colonyuunagi',
            count: 1,
          },
          {
            word: 'juventudgh colonyuunagi almasnoora',
            count: 1,
          },
          {
            word: 'colonyuunagi almasnoora check',
            count: 1,
          },
          {
            word: 'almasnoora check pinned',
            count: 1,
          },
          {
            word: 'wallacepjw like ford',
            count: 1,
          },
          {
            word: 'like ford puma',
            count: 1,
          },
          {
            word: 'lowerio thunderdarcy goodforsmething',
            count: 1,
          },
          {
            word: 'thunderdarcy goodforsmething growstudiohq',
            count: 1,
          },
          {
            word: 'goodforsmething growstudiohq puma',
            count: 1,
          },
          {
            word: 'growstudiohq puma shimaaa',
            count: 1,
          },
          {
            word: 'puma shimaaa unstoppablebs',
            count: 1,
          },
          {
            word: 'shimaaa unstoppablebs xiiiix',
            count: 1,
          },
          {
            word: 'unstoppablebs xiiiix ebondarcom',
            count: 1,
          },
          {
            word: 'xiiiix ebondarcom konsonanti',
            count: 1,
          },
          {
            word: 'ebondarcom konsonanti kingmoye',
            count: 1,
          },
          {
            word: 'konsonanti kingmoye seamyfarmer',
            count: 1,
          },
          {
            word: 'kingmoye seamyfarmer furkancobaan',
            count: 1,
          },
          {
            word: 'seamyfarmer furkancobaan anil',
            count: 1,
          },
          {
            word: 'furkancobaan anil alirezadini',
            count: 1,
          },
          {
            word: 'anil alirezadini bhanbhroamjad',
            count: 1,
          },
          {
            word: 'alirezadini bhanbhroamjad wirwerdenreich',
            count: 1,
          },
          {
            word: 'bhanbhroamjad wirwerdenreich clebrun',
            count: 1,
          },
          {
            word: 'wirwerdenreich clebrun ewingjeremy',
            count: 1,
          },
          {
            word: 'clebrun ewingjeremy takooryouri',
            count: 1,
          },
          {
            word: 'ewingjeremy takooryouri check',
            count: 1,
          },
          {
            word: 'takooryouri check pinned',
            count: 1,
          },
          {
            word: 'goaljpofficial puma goal',
            count: 1,
          },
          {
            word: 'puma goal japan',
            count: 1,
          },
          {
            word: 'goal japan beautiful',
            count: 1,
          },
          {
            word: 'dennisj whoknowsmysoul kaabuame',
            count: 1,
          },
          {
            word: 'whoknowsmysoul kaabuame kcdmsmkecfngere',
            count: 1,
          },
          {
            word: 'kaabuame kcdmsmkecfngere phronesianos',
            count: 1,
          },
          {
            word: 'kcdmsmkecfngere phronesianos yrsweet',
            count: 1,
          },
          {
            word: 'phronesianos yrsweet easyfires',
            count: 1,
          },
          {
            word: 'yrsweet easyfires keyvanrahmadi',
            count: 1,
          },
          {
            word: 'easyfires keyvanrahmadi macanth',
            count: 1,
          },
          {
            word: 'keyvanrahmadi macanth vhpeuch',
            count: 1,
          },
          {
            word: 'macanth vhpeuch takekittyshit',
            count: 1,
          },
          {
            word: 'vhpeuch takekittyshit esdglobaltech',
            count: 1,
          },
          {
            word: 'takekittyshit esdglobaltech myramalways',
            count: 1,
          },
          {
            word: 'esdglobaltech myramalways jazzlynpuma',
            count: 1,
          },
          {
            word: 'myramalways jazzlynpuma iiionceau',
            count: 1,
          },
          {
            word: 'jazzlynpuma iiionceau nidhi',
            count: 1,
          },
          {
            word: 'iiionceau nidhi leeorocha',
            count: 1,
          },
          {
            word: 'nidhi leeorocha bushmanind',
            count: 1,
          },
          {
            word: 'leeorocha bushmanind wales',
            count: 1,
          },
          {
            word: 'bushmanind wales murdocklvrr',
            count: 1,
          },
          {
            word: 'wales murdocklvrr lokopeter',
            count: 1,
          },
          {
            word: 'murdocklvrr lokopeter check',
            count: 1,
          },
          {
            word: 'lokopeter check pinned',
            count: 1,
          },
          {
            word: 'roachcalla jyrago mingifixsong',
            count: 1,
          },
          {
            word: 'jyrago mingifixsong majdialbana',
            count: 1,
          },
          {
            word: 'mingifixsong majdialbana tonymelendez',
            count: 1,
          },
          {
            word: 'majdialbana tonymelendez stanby',
            count: 1,
          },
          {
            word: 'tonymelendez stanby shahannayoung',
            count: 1,
          },
          {
            word: 'stanby shahannayoung jokerfiko',
            count: 1,
          },
          {
            word: 'shahannayoung jokerfiko shwkyamanaka',
            count: 1,
          },
          {
            word: 'jokerfiko shwkyamanaka kaccccchan',
            count: 1,
          },
          {
            word: 'shwkyamanaka kaccccchan luxluct',
            count: 1,
          },
          {
            word: 'kaccccchan luxluct fetichistaputin',
            count: 1,
          },
          {
            word: 'luxluct fetichistaputin pumars',
            count: 1,
          },
          {
            word: 'fetichistaputin pumars animenougat',
            count: 1,
          },
          {
            word: 'pumars animenougat eliasyusuff',
            count: 1,
          },
          {
            word: 'animenougat eliasyusuff nadahonor',
            count: 1,
          },
          {
            word: 'eliasyusuff nadahonor sazaross',
            count: 1,
          },
          {
            word: 'nadahonor sazaross frwansham',
            count: 1,
          },
          {
            word: 'sazaross frwansham jonathandkeck',
            count: 1,
          },
          {
            word: 'frwansham jonathandkeck justsodamngood',
            count: 1,
          },
          {
            word: 'jonathandkeck justsodamngood jmool',
            count: 1,
          },
          {
            word: 'justsodamngood jmool maentina',
            count: 1,
          },
          {
            word: 'jmool maentina check',
            count: 1,
          },
          {
            word: 'maentina check pinned',
            count: 1,
          },
          {
            word: 'always puma super',
            count: 1,
          },
          {
            word: 'puma super comfy',
            count: 1,
          },
          {
            word: 'super comfy httpstcolzzqcfais',
            count: 1,
          },
          {
            word: 'maturenl puma swede',
            count: 1,
          },
          {
            word: 'puma swede angel',
            count: 1,
          },
          {
            word: 'swede angel long',
            count: 1,
          },
          {
            word: 'angel long diamond',
            count: 1,
          },
          {
            word: 'long diamond angela',
            count: 1,
          },
          {
            word: 'diamond angela aspen',
            count: 1,
          },
          {
            word: 'angela aspen kinky',
            count: 1,
          },
          {
            word: 'aspen kinky pussyeating',
            count: 1,
          },
          {
            word: 'kinky pussyeating milf',
            count: 1,
          },
          {
            word: 'pussyeating milf sexparty',
            count: 1,
          },
          {
            word: 'milf sexparty everything',
            count: 1,
          },
          {
            word: 'sexparty everything allowed',
            count: 1,
          },
          {
            word: 'annapumakig peekaboo httpstcojnmcznni',
            count: 1,
          },
          {
            word: 'nicekicks lamelo ball',
            count: 1,
          },
          {
            word: 'lamelo ball puma',
            count: 1,
          },
          {
            word: 'ball puma unveil',
            count: 1,
          },
          {
            word: 'puma unveil highly',
            count: 1,
          },
          {
            word: 'unveil highly anticipated',
            count: 1,
          },
          {
            word: 'highly anticipated porsche',
            count: 1,
          },
          {
            word: 'anticipated porsche collection',
            count: 1,
          },
          {
            word: 'porsche collection launching',
            count: 1,
          },
          {
            word: 'collection launching february',
            count: 1,
          },
          {
            word: 'launching february nicedrops',
            count: 1,
          },
          {
            word: 'puma bungeopang niki',
            count: 1,
          },
          {
            word: 'bungeopang niki enhypenas',
            count: 1,
          },
          {
            word: 'niki enhypenas stars',
            count: 1,
          },
          {
            word: 'enhypenas stars alligned',
            count: 1,
          },
          {
            word: 'stars alligned dashing',
            count: 1,
          },
          {
            word: 'alligned dashing vampire',
            count: 1,
          },
          {
            word: 'dashing vampire appeared',
            count: 1,
          },
          {
            word: 'vampire appeared turned',
            count: 1,
          },
          {
            word: 'appeared turned beautiful',
            count: 1,
          },
          {
            word: 'turned beautiful ever',
            count: 1,
          },
          {
            word: 'beautiful ever live',
            count: 1,
          },
          {
            word: 'ever live earth',
            count: 1,
          },
          {
            word: 'live earth dear',
            count: 1,
          },
          {
            word: 'earth dear duck',
            count: 1,
          },
          {
            word: 'puma bungeopang love',
            count: 1,
          },
          {
            word: 'bungeopang love solace',
            count: 1,
          },
          {
            word: 'love solace niki',
            count: 1,
          },
          {
            word: 'solace niki hope',
            count: 1,
          },
          {
            word: 'niki hope resting',
            count: 1,
          },
          {
            word: 'hope resting well',
            count: 1,
          },
          {
            word: 'resting well httpstcojdxqzbwhyo',
            count: 1,
          },
        ],
        surprise_unigram: [
          {
            word: 'puma',
            count: 5,
          },
          {
            word: 'nike',
            count: 2,
          },
          {
            word: 'mawrongzjnr',
            count: 1,
          },
          {
            word: 'nooits',
            count: 1,
          },
          {
            word: 'httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'longiek',
            count: 1,
          },
          {
            word: 'nigerian',
            count: 1,
          },
          {
            word: 'restaurant',
            count: 1,
          },
          {
            word: 'area',
            count: 1,
          },
          {
            word: 'filling',
            count: 1,
          },
          {
            word: 'station',
            count: 1,
          },
          {
            word: 'next',
            count: 1,
          },
          {
            word: 'zathu',
            count: 1,
          },
          {
            word: 'trading',
            count: 1,
          },
          {
            word: 'jollof',
            count: 1,
          },
          {
            word: 'petercrouch',
            count: 1,
          },
          {
            word: 'hoodie',
            count: 1,
          },
          {
            word: 'looks',
            count: 1,
          },
          {
            word: 'like',
            count: 1,
          },
          {
            word: 'someone',
            count: 1,
          },
          {
            word: 'grabbed',
            count: 1,
          },
          {
            word: 'fill',
            count: 1,
          },
          {
            word: 'seat',
            count: 1,
          },
          {
            word: 'waiting',
            count: 1,
          },
          {
            word: 'didi',
            count: 1,
          },
          {
            word: 'hamann',
            count: 1,
          },
          {
            word: 'arrive',
            count: 1,
          },
          {
            word: 'joachimschulz',
            count: 1,
          },
          {
            word: 'fair',
            count: 1,
          },
          {
            word: 'point',
            count: 1,
          },
          {
            word: 'rollout',
            count: 1,
          },
          {
            word: 'scalewise',
            count: 1,
          },
          {
            word: 'reason',
            count: 1,
          },
          {
            word: 'seem',
            count: 1,
          },
          {
            word: 'befit',
            count: 1,
          },
          {
            word: 'goat',
            count: 1,
          },
          {
            word: 'sport',
            count: 1,
          },
          {
            word: 'imagine',
            count: 1,
          },
          {
            word: 'adidas',
            count: 1,
          },
          {
            word: 'would',
            count: 1,
          },
          {
            word: 'pulling',
            count: 1,
          },
          {
            word: 'stops',
            count: 1,
          },
          {
            word: 'logo',
            count: 1,
          },
          {
            word: 'branding',
            count: 1,
          },
          {
            word: 'buzz',
            count: 1,
          },
          {
            word: 'social',
            count: 1,
          },
          {
            word: 'puts',
            count: 1,
          },
          {
            word: 'three',
            count: 1,
          },
          {
            word: 'decadelong',
            count: 1,
          },
          {
            word: 'erstwhile',
            count: 1,
          },
          {
            word: 'partnership',
            count: 1,
          },
          {
            word: 'httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'smash',
            count: 1,
          },
          {
            word: 'leather',
            count: 1,
          },
          {
            word: 'white',
            count: 1,
          },
          {
            word: 'retail',
            count: 1,
          },
          {
            word: 'mandmdirect',
            count: 1,
          },
          {
            word: 'sale',
            count: 1,
          },
          {
            word: 'price',
            count: 1,
          },
          {
            word: 'drop',
            count: 1,
          },
          {
            word: 'link',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'sizes',
            count: 1,
          },
          {
            word: 'httpstcowtcknauzu',
            count: 1,
          },
        ],
        surprise_bigram: [
          {
            word: 'mawrongzjnr nooits',
            count: 1,
          },
          {
            word: 'nooits puma',
            count: 1,
          },
          {
            word: 'puma httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'longiek nigerian',
            count: 1,
          },
          {
            word: 'nigerian restaurant',
            count: 1,
          },
          {
            word: 'restaurant area',
            count: 1,
          },
          {
            word: 'area puma',
            count: 1,
          },
          {
            word: 'puma filling',
            count: 1,
          },
          {
            word: 'filling station',
            count: 1,
          },
          {
            word: 'station next',
            count: 1,
          },
          {
            word: 'next zathu',
            count: 1,
          },
          {
            word: 'zathu trading',
            count: 1,
          },
          {
            word: 'trading jollof',
            count: 1,
          },
          {
            word: 'petercrouch puma',
            count: 1,
          },
          {
            word: 'puma hoodie',
            count: 1,
          },
          {
            word: 'hoodie looks',
            count: 1,
          },
          {
            word: 'looks like',
            count: 1,
          },
          {
            word: 'like someone',
            count: 1,
          },
          {
            word: 'someone grabbed',
            count: 1,
          },
          {
            word: 'grabbed fill',
            count: 1,
          },
          {
            word: 'fill seat',
            count: 1,
          },
          {
            word: 'seat waiting',
            count: 1,
          },
          {
            word: 'waiting didi',
            count: 1,
          },
          {
            word: 'didi hamann',
            count: 1,
          },
          {
            word: 'hamann arrive',
            count: 1,
          },
          {
            word: 'joachimschulz fair',
            count: 1,
          },
          {
            word: 'fair point',
            count: 1,
          },
          {
            word: 'point rollout',
            count: 1,
          },
          {
            word: 'rollout scalewise',
            count: 1,
          },
          {
            word: 'scalewise reason',
            count: 1,
          },
          {
            word: 'reason seem',
            count: 1,
          },
          {
            word: 'seem befit',
            count: 1,
          },
          {
            word: 'befit goat',
            count: 1,
          },
          {
            word: 'goat sport',
            count: 1,
          },
          {
            word: 'sport imagine',
            count: 1,
          },
          {
            word: 'imagine nike',
            count: 1,
          },
          {
            word: 'nike puma',
            count: 1,
          },
          {
            word: 'puma adidas',
            count: 1,
          },
          {
            word: 'adidas would',
            count: 1,
          },
          {
            word: 'would pulling',
            count: 1,
          },
          {
            word: 'pulling stops',
            count: 1,
          },
          {
            word: 'stops logo',
            count: 1,
          },
          {
            word: 'logo branding',
            count: 1,
          },
          {
            word: 'branding buzz',
            count: 1,
          },
          {
            word: 'buzz social',
            count: 1,
          },
          {
            word: 'social puts',
            count: 1,
          },
          {
            word: 'puts three',
            count: 1,
          },
          {
            word: 'three decadelong',
            count: 1,
          },
          {
            word: 'decadelong erstwhile',
            count: 1,
          },
          {
            word: 'erstwhile partnership',
            count: 1,
          },
          {
            word: 'partnership nike',
            count: 1,
          },
          {
            word: 'nike httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'puma smash',
            count: 1,
          },
          {
            word: 'smash leather',
            count: 1,
          },
          {
            word: 'leather white',
            count: 1,
          },
          {
            word: 'white retail',
            count: 1,
          },
          {
            word: 'retail mandmdirect',
            count: 1,
          },
          {
            word: 'mandmdirect sale',
            count: 1,
          },
          {
            word: 'sale price',
            count: 1,
          },
          {
            word: 'price drop',
            count: 1,
          },
          {
            word: 'drop link',
            count: 1,
          },
          {
            word: 'link httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq sizes',
            count: 1,
          },
          {
            word: 'sizes httpstcowtcknauzu',
            count: 1,
          },
        ],
        surprise_trigram: [
          {
            word: 'mawrongzjnr nooits puma',
            count: 1,
          },
          {
            word: 'nooits puma httpstcotrsfevlh',
            count: 1,
          },
          {
            word: 'longiek nigerian restaurant',
            count: 1,
          },
          {
            word: 'nigerian restaurant area',
            count: 1,
          },
          {
            word: 'restaurant area puma',
            count: 1,
          },
          {
            word: 'area puma filling',
            count: 1,
          },
          {
            word: 'puma filling station',
            count: 1,
          },
          {
            word: 'filling station next',
            count: 1,
          },
          {
            word: 'station next zathu',
            count: 1,
          },
          {
            word: 'next zathu trading',
            count: 1,
          },
          {
            word: 'zathu trading jollof',
            count: 1,
          },
          {
            word: 'petercrouch puma hoodie',
            count: 1,
          },
          {
            word: 'puma hoodie looks',
            count: 1,
          },
          {
            word: 'hoodie looks like',
            count: 1,
          },
          {
            word: 'looks like someone',
            count: 1,
          },
          {
            word: 'like someone grabbed',
            count: 1,
          },
          {
            word: 'someone grabbed fill',
            count: 1,
          },
          {
            word: 'grabbed fill seat',
            count: 1,
          },
          {
            word: 'fill seat waiting',
            count: 1,
          },
          {
            word: 'seat waiting didi',
            count: 1,
          },
          {
            word: 'waiting didi hamann',
            count: 1,
          },
          {
            word: 'didi hamann arrive',
            count: 1,
          },
          {
            word: 'joachimschulz fair point',
            count: 1,
          },
          {
            word: 'fair point rollout',
            count: 1,
          },
          {
            word: 'point rollout scalewise',
            count: 1,
          },
          {
            word: 'rollout scalewise reason',
            count: 1,
          },
          {
            word: 'scalewise reason seem',
            count: 1,
          },
          {
            word: 'reason seem befit',
            count: 1,
          },
          {
            word: 'seem befit goat',
            count: 1,
          },
          {
            word: 'befit goat sport',
            count: 1,
          },
          {
            word: 'goat sport imagine',
            count: 1,
          },
          {
            word: 'sport imagine nike',
            count: 1,
          },
          {
            word: 'imagine nike puma',
            count: 1,
          },
          {
            word: 'nike puma adidas',
            count: 1,
          },
          {
            word: 'puma adidas would',
            count: 1,
          },
          {
            word: 'adidas would pulling',
            count: 1,
          },
          {
            word: 'would pulling stops',
            count: 1,
          },
          {
            word: 'pulling stops logo',
            count: 1,
          },
          {
            word: 'stops logo branding',
            count: 1,
          },
          {
            word: 'logo branding buzz',
            count: 1,
          },
          {
            word: 'branding buzz social',
            count: 1,
          },
          {
            word: 'buzz social puts',
            count: 1,
          },
          {
            word: 'social puts three',
            count: 1,
          },
          {
            word: 'puts three decadelong',
            count: 1,
          },
          {
            word: 'three decadelong erstwhile',
            count: 1,
          },
          {
            word: 'decadelong erstwhile partnership',
            count: 1,
          },
          {
            word: 'erstwhile partnership nike',
            count: 1,
          },
          {
            word: 'partnership nike httpstcoogsnpdn',
            count: 1,
          },
          {
            word: 'puma smash leather',
            count: 1,
          },
          {
            word: 'smash leather white',
            count: 1,
          },
          {
            word: 'leather white retail',
            count: 1,
          },
          {
            word: 'white retail mandmdirect',
            count: 1,
          },
          {
            word: 'retail mandmdirect sale',
            count: 1,
          },
          {
            word: 'mandmdirect sale price',
            count: 1,
          },
          {
            word: 'sale price drop',
            count: 1,
          },
          {
            word: 'price drop link',
            count: 1,
          },
          {
            word: 'drop link httpstcozapjuehdq',
            count: 1,
          },
          {
            word: 'link httpstcozapjuehdq sizes',
            count: 1,
          },
          {
            word: 'httpstcozapjuehdq sizes httpstcowtcknauzu',
            count: 1,
          },
        ],
        neutral_unigram: [
          {
            word: 'puma',
            count: 7,
          },
          {
            word: 'shoes',
            count: 2,
          },
          {
            word: 'gift',
            count: 2,
          },
          {
            word: 'flipkart',
            count: 2,
          },
          {
            word: 'eachmeet',
            count: 2,
          },
          {
            word: 'ordered',
            count: 1,
          },
          {
            word: 'pair',
            count: 1,
          },
          {
            word: 'birthday',
            count: 1,
          },
          {
            word: 'found',
            count: 1,
          },
          {
            word: 'delivered',
            count: 1,
          },
          {
            word: 'right',
            count: 1,
          },
          {
            word: 'foot',
            count: 1,
          },
          {
            word: 'package',
            count: 1,
          },
          {
            word: 'ruined',
            count: 1,
          },
          {
            word: 'cancelling',
            count: 1,
          },
          {
            word: 'refund',
            count: 1,
          },
          {
            word: 'well',
            count: 1,
          },
          {
            word: 'saying',
            count: 1,
          },
          {
            word: 'open',
            count: 1,
          },
          {
            word: 'delivery',
            count: 1,
          },
          {
            word: 'fraud',
            count: 1,
          },
          {
            word: 'customer',
            count: 1,
          },
          {
            word: 'httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'pacbi',
            count: 1,
          },
          {
            word: 'years',
            count: 1,
          },
          {
            word: 'campaigning',
            count: 1,
          },
          {
            word: 'cost',
            count: 1,
          },
          {
            word: 'german',
            count: 1,
          },
          {
            word: 'conglomerate',
            count: 1,
          },
          {
            word: 'dearly',
            count: 1,
          },
          {
            word: 'reputation',
            count: 1,
          },
          {
            word: 'projects',
            count: 1,
          },
          {
            word: 'forced',
            count: 1,
          },
          {
            word: 'godnessrin',
            count: 1,
          },
          {
            word: 'axelsauro',
            count: 1,
          },
          {
            word: 'dibblesaharicus',
            count: 1,
          },
          {
            word: 'dinomation',
            count: 1,
          },
          {
            word: 'even',
            count: 1,
          },
          {
            word: 'young',
            count: 1,
          },
          {
            word: 'adults',
            count: 1,
          },
          {
            word: 'taken',
            count: 1,
          },
          {
            word: 'considering',
            count: 1,
          },
          {
            word: 'case',
            count: 1,
          },
          {
            word: 'killing',
            count: 1,
          },
          {
            word: 'meter',
            count: 1,
          },
          {
            word: 'alligator',
            count: 1,
          },
          {
            word: 'land',
            count: 1,
          },
          {
            word: 'human',
            count: 1,
          },
          {
            word: 'toilet',
            count: 1,
          },
          {
            word: 'nasty',
            count: 1,
          },
          {
            word: 'sitting',
            count: 1,
          },
          {
            word: 'waiting',
            count: 1,
          },
          {
            word: 'collect',
            count: 1,
          },
          {
            word: 'piss',
            count: 1,
          },
          {
            word: 'shit',
            count: 1,
          },
          {
            word: 'souvenir',
            count: 1,
          },
          {
            word: 'ultimate',
            count: 1,
          },
          {
            word: 'predator',
            count: 1,
          },
          {
            word: 'femdom',
            count: 1,
          },
          {
            word: 'beta',
            count: 1,
          },
          {
            word: 'cuck',
            count: 1,
          },
          {
            word: 'loser',
            count: 1,
          },
          {
            word: 'white',
            count: 1,
          },
          {
            word: 'charlotte',
            count: 1,
          },
          {
            word: 'greensboro',
            count: 1,
          },
          {
            word: 'raleigh',
            count: 1,
          },
          {
            word: 'rock',
            count: 1,
          },
          {
            word: 'hill',
            count: 1,
          },
          {
            word: 'durham',
            count: 1,
          },
          {
            word: 'brattylatina',
            count: 1,
          },
          {
            word: 'latinaslut',
            count: 1,
          },
          {
            word: 'morena',
            count: 1,
          },
          {
            word: 'thick',
            count: 1,
          },
          {
            word: 'httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'concertpm',
            count: 1,
          },
          {
            word: 'interestediveinkl',
            count: 1,
          },
          {
            word: 'httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'andysfootyshirt',
            count: 1,
          },
          {
            word: 'cadecoweiigoat',
            count: 1,
          },
          {
            word: 'shirtsofneil',
            count: 1,
          },
          {
            word: 'cook',
            count: 1,
          },
          {
            word: 'unethical',
            count: 1,
          },
          {
            word: 'consumption',
            count: 1,
          },
          {
            word: 'pretending',
            count: 1,
          },
          {
            word: 'like',
            count: 1,
          },
          {
            word: 'official',
            count: 1,
          },
          {
            word: 'nike',
            count: 1,
          },
          {
            word: 'adidas',
            count: 1,
          },
          {
            word: 'garbage',
            count: 1,
          },
          {
            word: 'dense',
            count: 1,
          },
          {
            word: 'photocardrm',
            count: 1,
          },
          {
            word: 'concertpostage',
            count: 1,
          },
          {
            word: 'want',
            count: 1,
          },
          {
            word: 'iveworldtour',
            count: 1,
          },
          {
            word: 'pasarive',
            count: 1,
          },
          {
            word: 'pasarnewjeanspasarivehelp',
            count: 1,
          },
          {
            word: 'guys',
            count: 1,
          },
          {
            word: 'httpstcoevkevv',
            count: 1,
          },
        ],
        neutral_bigram: [
          {
            word: 'ordered pair',
            count: 1,
          },
          {
            word: 'pair puma',
            count: 1,
          },
          {
            word: 'puma shoes',
            count: 1,
          },
          {
            word: 'shoes birthday',
            count: 1,
          },
          {
            word: 'birthday gift',
            count: 1,
          },
          {
            word: 'gift found',
            count: 1,
          },
          {
            word: 'found flipkart',
            count: 1,
          },
          {
            word: 'flipkart delivered',
            count: 1,
          },
          {
            word: 'delivered shoes',
            count: 1,
          },
          {
            word: 'shoes right',
            count: 1,
          },
          {
            word: 'right foot',
            count: 1,
          },
          {
            word: 'foot package',
            count: 1,
          },
          {
            word: 'package gift',
            count: 1,
          },
          {
            word: 'gift ruined',
            count: 1,
          },
          {
            word: 'ruined cancelling',
            count: 1,
          },
          {
            word: 'cancelling refund',
            count: 1,
          },
          {
            word: 'refund well',
            count: 1,
          },
          {
            word: 'well saying',
            count: 1,
          },
          {
            word: 'saying open',
            count: 1,
          },
          {
            word: 'open delivery',
            count: 1,
          },
          {
            word: 'delivery fraud',
            count: 1,
          },
          {
            word: 'fraud customer',
            count: 1,
          },
          {
            word: 'customer flipkart',
            count: 1,
          },
          {
            word: 'flipkart httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'pacbi years',
            count: 1,
          },
          {
            word: 'years campaigning',
            count: 1,
          },
          {
            word: 'campaigning cost',
            count: 1,
          },
          {
            word: 'cost german',
            count: 1,
          },
          {
            word: 'german conglomerate',
            count: 1,
          },
          {
            word: 'conglomerate puma',
            count: 1,
          },
          {
            word: 'puma dearly',
            count: 1,
          },
          {
            word: 'dearly reputation',
            count: 1,
          },
          {
            word: 'reputation projects',
            count: 1,
          },
          {
            word: 'projects forced',
            count: 1,
          },
          {
            word: 'godnessrin axelsauro',
            count: 1,
          },
          {
            word: 'axelsauro dibblesaharicus',
            count: 1,
          },
          {
            word: 'dibblesaharicus dinomation',
            count: 1,
          },
          {
            word: 'dinomation even',
            count: 1,
          },
          {
            word: 'even young',
            count: 1,
          },
          {
            word: 'young adults',
            count: 1,
          },
          {
            word: 'adults taken',
            count: 1,
          },
          {
            word: 'taken considering',
            count: 1,
          },
          {
            word: 'considering case',
            count: 1,
          },
          {
            word: 'case puma',
            count: 1,
          },
          {
            word: 'puma killing',
            count: 1,
          },
          {
            word: 'killing meter',
            count: 1,
          },
          {
            word: 'meter alligator',
            count: 1,
          },
          {
            word: 'alligator land',
            count: 1,
          },
          {
            word: 'human toilet',
            count: 1,
          },
          {
            word: 'toilet nasty',
            count: 1,
          },
          {
            word: 'nasty sitting',
            count: 1,
          },
          {
            word: 'sitting waiting',
            count: 1,
          },
          {
            word: 'waiting collect',
            count: 1,
          },
          {
            word: 'collect piss',
            count: 1,
          },
          {
            word: 'piss shit',
            count: 1,
          },
          {
            word: 'shit souvenir',
            count: 1,
          },
          {
            word: 'souvenir ultimate',
            count: 1,
          },
          {
            word: 'ultimate predator',
            count: 1,
          },
          {
            word: 'predator puma',
            count: 1,
          },
          {
            word: 'puma femdom',
            count: 1,
          },
          {
            word: 'femdom beta',
            count: 1,
          },
          {
            word: 'beta cuck',
            count: 1,
          },
          {
            word: 'cuck loser',
            count: 1,
          },
          {
            word: 'loser white',
            count: 1,
          },
          {
            word: 'white charlotte',
            count: 1,
          },
          {
            word: 'charlotte greensboro',
            count: 1,
          },
          {
            word: 'greensboro raleigh',
            count: 1,
          },
          {
            word: 'raleigh rock',
            count: 1,
          },
          {
            word: 'rock hill',
            count: 1,
          },
          {
            word: 'hill durham',
            count: 1,
          },
          {
            word: 'durham brattylatina',
            count: 1,
          },
          {
            word: 'brattylatina latinaslut',
            count: 1,
          },
          {
            word: 'latinaslut morena',
            count: 1,
          },
          {
            word: 'morena thick',
            count: 1,
          },
          {
            word: 'thick httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'httpstcoldfmmz httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'puma eachmeet',
            count: 1,
          },
          {
            word: 'eachmeet concertpm',
            count: 1,
          },
          {
            word: 'concertpm interestediveinkl',
            count: 1,
          },
          {
            word: 'interestediveinkl httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'andysfootyshirt cadecoweiigoat',
            count: 1,
          },
          {
            word: 'cadecoweiigoat shirtsofneil',
            count: 1,
          },
          {
            word: 'shirtsofneil cook',
            count: 1,
          },
          {
            word: 'cook unethical',
            count: 1,
          },
          {
            word: 'unethical consumption',
            count: 1,
          },
          {
            word: 'consumption pretending',
            count: 1,
          },
          {
            word: 'pretending like',
            count: 1,
          },
          {
            word: 'like official',
            count: 1,
          },
          {
            word: 'official nike',
            count: 1,
          },
          {
            word: 'nike puma',
            count: 1,
          },
          {
            word: 'puma adidas',
            count: 1,
          },
          {
            word: 'adidas garbage',
            count: 1,
          },
          {
            word: 'garbage dense',
            count: 1,
          },
          {
            word: 'puma photocardrm',
            count: 1,
          },
          {
            word: 'photocardrm eachmeet',
            count: 1,
          },
          {
            word: 'eachmeet concertpostage',
            count: 1,
          },
          {
            word: 'concertpostage want',
            count: 1,
          },
          {
            word: 'want iveworldtour',
            count: 1,
          },
          {
            word: 'iveworldtour pasarive',
            count: 1,
          },
          {
            word: 'pasarive pasarnewjeanspasarivehelp',
            count: 1,
          },
          {
            word: 'pasarnewjeanspasarivehelp guys',
            count: 1,
          },
          {
            word: 'guys httpstcoevkevv',
            count: 1,
          },
        ],
        neutral_trigram: [
          {
            word: 'ordered pair puma',
            count: 1,
          },
          {
            word: 'pair puma shoes',
            count: 1,
          },
          {
            word: 'puma shoes birthday',
            count: 1,
          },
          {
            word: 'shoes birthday gift',
            count: 1,
          },
          {
            word: 'birthday gift found',
            count: 1,
          },
          {
            word: 'gift found flipkart',
            count: 1,
          },
          {
            word: 'found flipkart delivered',
            count: 1,
          },
          {
            word: 'flipkart delivered shoes',
            count: 1,
          },
          {
            word: 'delivered shoes right',
            count: 1,
          },
          {
            word: 'shoes right foot',
            count: 1,
          },
          {
            word: 'right foot package',
            count: 1,
          },
          {
            word: 'foot package gift',
            count: 1,
          },
          {
            word: 'package gift ruined',
            count: 1,
          },
          {
            word: 'gift ruined cancelling',
            count: 1,
          },
          {
            word: 'ruined cancelling refund',
            count: 1,
          },
          {
            word: 'cancelling refund well',
            count: 1,
          },
          {
            word: 'refund well saying',
            count: 1,
          },
          {
            word: 'well saying open',
            count: 1,
          },
          {
            word: 'saying open delivery',
            count: 1,
          },
          {
            word: 'open delivery fraud',
            count: 1,
          },
          {
            word: 'delivery fraud customer',
            count: 1,
          },
          {
            word: 'fraud customer flipkart',
            count: 1,
          },
          {
            word: 'customer flipkart httpstcoofctebfrqj',
            count: 1,
          },
          {
            word: 'pacbi years campaigning',
            count: 1,
          },
          {
            word: 'years campaigning cost',
            count: 1,
          },
          {
            word: 'campaigning cost german',
            count: 1,
          },
          {
            word: 'cost german conglomerate',
            count: 1,
          },
          {
            word: 'german conglomerate puma',
            count: 1,
          },
          {
            word: 'conglomerate puma dearly',
            count: 1,
          },
          {
            word: 'puma dearly reputation',
            count: 1,
          },
          {
            word: 'dearly reputation projects',
            count: 1,
          },
          {
            word: 'reputation projects forced',
            count: 1,
          },
          {
            word: 'godnessrin axelsauro dibblesaharicus',
            count: 1,
          },
          {
            word: 'axelsauro dibblesaharicus dinomation',
            count: 1,
          },
          {
            word: 'dibblesaharicus dinomation even',
            count: 1,
          },
          {
            word: 'dinomation even young',
            count: 1,
          },
          {
            word: 'even young adults',
            count: 1,
          },
          {
            word: 'young adults taken',
            count: 1,
          },
          {
            word: 'adults taken considering',
            count: 1,
          },
          {
            word: 'taken considering case',
            count: 1,
          },
          {
            word: 'considering case puma',
            count: 1,
          },
          {
            word: 'case puma killing',
            count: 1,
          },
          {
            word: 'puma killing meter',
            count: 1,
          },
          {
            word: 'killing meter alligator',
            count: 1,
          },
          {
            word: 'meter alligator land',
            count: 1,
          },
          {
            word: 'human toilet nasty',
            count: 1,
          },
          {
            word: 'toilet nasty sitting',
            count: 1,
          },
          {
            word: 'nasty sitting waiting',
            count: 1,
          },
          {
            word: 'sitting waiting collect',
            count: 1,
          },
          {
            word: 'waiting collect piss',
            count: 1,
          },
          {
            word: 'collect piss shit',
            count: 1,
          },
          {
            word: 'piss shit souvenir',
            count: 1,
          },
          {
            word: 'shit souvenir ultimate',
            count: 1,
          },
          {
            word: 'souvenir ultimate predator',
            count: 1,
          },
          {
            word: 'ultimate predator puma',
            count: 1,
          },
          {
            word: 'predator puma femdom',
            count: 1,
          },
          {
            word: 'puma femdom beta',
            count: 1,
          },
          {
            word: 'femdom beta cuck',
            count: 1,
          },
          {
            word: 'beta cuck loser',
            count: 1,
          },
          {
            word: 'cuck loser white',
            count: 1,
          },
          {
            word: 'loser white charlotte',
            count: 1,
          },
          {
            word: 'white charlotte greensboro',
            count: 1,
          },
          {
            word: 'charlotte greensboro raleigh',
            count: 1,
          },
          {
            word: 'greensboro raleigh rock',
            count: 1,
          },
          {
            word: 'raleigh rock hill',
            count: 1,
          },
          {
            word: 'rock hill durham',
            count: 1,
          },
          {
            word: 'hill durham brattylatina',
            count: 1,
          },
          {
            word: 'durham brattylatina latinaslut',
            count: 1,
          },
          {
            word: 'brattylatina latinaslut morena',
            count: 1,
          },
          {
            word: 'latinaslut morena thick',
            count: 1,
          },
          {
            word: 'morena thick httpstcoldfmmz',
            count: 1,
          },
          {
            word: 'thick httpstcoldfmmz httpstcoykjxufgt',
            count: 1,
          },
          {
            word: 'puma eachmeet concertpm',
            count: 1,
          },
          {
            word: 'eachmeet concertpm interestediveinkl',
            count: 1,
          },
          {
            word: 'concertpm interestediveinkl httpstcoapuzbvp',
            count: 1,
          },
          {
            word: 'andysfootyshirt cadecoweiigoat shirtsofneil',
            count: 1,
          },
          {
            word: 'cadecoweiigoat shirtsofneil cook',
            count: 1,
          },
          {
            word: 'shirtsofneil cook unethical',
            count: 1,
          },
          {
            word: 'cook unethical consumption',
            count: 1,
          },
          {
            word: 'unethical consumption pretending',
            count: 1,
          },
          {
            word: 'consumption pretending like',
            count: 1,
          },
          {
            word: 'pretending like official',
            count: 1,
          },
          {
            word: 'like official nike',
            count: 1,
          },
          {
            word: 'official nike puma',
            count: 1,
          },
          {
            word: 'nike puma adidas',
            count: 1,
          },
          {
            word: 'puma adidas garbage',
            count: 1,
          },
          {
            word: 'adidas garbage dense',
            count: 1,
          },
          {
            word: 'puma photocardrm eachmeet',
            count: 1,
          },
          {
            word: 'photocardrm eachmeet concertpostage',
            count: 1,
          },
          {
            word: 'eachmeet concertpostage want',
            count: 1,
          },
          {
            word: 'concertpostage want iveworldtour',
            count: 1,
          },
          {
            word: 'want iveworldtour pasarive',
            count: 1,
          },
          {
            word: 'iveworldtour pasarive pasarnewjeanspasarivehelp',
            count: 1,
          },
          {
            word: 'pasarive pasarnewjeanspasarivehelp guys',
            count: 1,
          },
          {
            word: 'pasarnewjeanspasarivehelp guys httpstcoevkevv',
            count: 1,
          },
        ],
        disgust_unigram: [],
        disgust_bigram: [],
        disgust_trigram: [],
        sadness_unigram: [
          {
            word: 'calumcrane',
            count: 1,
          },
          {
            word: 'gochimin',
            count: 1,
          },
          {
            word: 'iiik',
            count: 1,
          },
          {
            word: 'yansaladino',
            count: 1,
          },
          {
            word: 'rebeccaradovsk',
            count: 1,
          },
          {
            word: 'aboabaman',
            count: 1,
          },
          {
            word: 'ponkotupyoko',
            count: 1,
          },
          {
            word: 'rksharma',
            count: 1,
          },
          {
            word: 'dmirandaaaa',
            count: 1,
          },
          {
            word: 'uhle',
            count: 1,
          },
          {
            word: 'estelleh',
            count: 1,
          },
          {
            word: 'manjeet',
            count: 1,
          },
          {
            word: 'karadeniz',
            count: 1,
          },
          {
            word: 'salifouide',
            count: 1,
          },
          {
            word: 'scalteurbh',
            count: 1,
          },
          {
            word: 'pumatapia',
            count: 1,
          },
          {
            word: 'meltural',
            count: 1,
          },
          {
            word: 'jayseamusic',
            count: 1,
          },
          {
            word: 'oshiosleep',
            count: 1,
          },
          {
            word: 'esqueben',
            count: 1,
          },
          {
            word: 'datatapper',
            count: 1,
          },
          {
            word: 'check',
            count: 1,
          },
          {
            word: 'pinned',
            count: 1,
          },
        ],
        sadness_bigram: [
          {
            word: 'calumcrane gochimin',
            count: 1,
          },
          {
            word: 'gochimin iiik',
            count: 1,
          },
          {
            word: 'iiik yansaladino',
            count: 1,
          },
          {
            word: 'yansaladino rebeccaradovsk',
            count: 1,
          },
          {
            word: 'rebeccaradovsk aboabaman',
            count: 1,
          },
          {
            word: 'aboabaman ponkotupyoko',
            count: 1,
          },
          {
            word: 'ponkotupyoko rksharma',
            count: 1,
          },
          {
            word: 'rksharma dmirandaaaa',
            count: 1,
          },
          {
            word: 'dmirandaaaa uhle',
            count: 1,
          },
          {
            word: 'uhle estelleh',
            count: 1,
          },
          {
            word: 'estelleh manjeet',
            count: 1,
          },
          {
            word: 'manjeet karadeniz',
            count: 1,
          },
          {
            word: 'karadeniz salifouide',
            count: 1,
          },
          {
            word: 'salifouide scalteurbh',
            count: 1,
          },
          {
            word: 'scalteurbh pumatapia',
            count: 1,
          },
          {
            word: 'pumatapia meltural',
            count: 1,
          },
          {
            word: 'meltural jayseamusic',
            count: 1,
          },
          {
            word: 'jayseamusic oshiosleep',
            count: 1,
          },
          {
            word: 'oshiosleep esqueben',
            count: 1,
          },
          {
            word: 'esqueben datatapper',
            count: 1,
          },
          {
            word: 'datatapper check',
            count: 1,
          },
          {
            word: 'check pinned',
            count: 1,
          },
        ],
        sadness_trigram: [
          {
            word: 'calumcrane gochimin iiik',
            count: 1,
          },
          {
            word: 'gochimin iiik yansaladino',
            count: 1,
          },
          {
            word: 'iiik yansaladino rebeccaradovsk',
            count: 1,
          },
          {
            word: 'yansaladino rebeccaradovsk aboabaman',
            count: 1,
          },
          {
            word: 'rebeccaradovsk aboabaman ponkotupyoko',
            count: 1,
          },
          {
            word: 'aboabaman ponkotupyoko rksharma',
            count: 1,
          },
          {
            word: 'ponkotupyoko rksharma dmirandaaaa',
            count: 1,
          },
          {
            word: 'rksharma dmirandaaaa uhle',
            count: 1,
          },
          {
            word: 'dmirandaaaa uhle estelleh',
            count: 1,
          },
          {
            word: 'uhle estelleh manjeet',
            count: 1,
          },
          {
            word: 'estelleh manjeet karadeniz',
            count: 1,
          },
          {
            word: 'manjeet karadeniz salifouide',
            count: 1,
          },
          {
            word: 'karadeniz salifouide scalteurbh',
            count: 1,
          },
          {
            word: 'salifouide scalteurbh pumatapia',
            count: 1,
          },
          {
            word: 'scalteurbh pumatapia meltural',
            count: 1,
          },
          {
            word: 'pumatapia meltural jayseamusic',
            count: 1,
          },
          {
            word: 'meltural jayseamusic oshiosleep',
            count: 1,
          },
          {
            word: 'jayseamusic oshiosleep esqueben',
            count: 1,
          },
          {
            word: 'oshiosleep esqueben datatapper',
            count: 1,
          },
          {
            word: 'esqueben datatapper check',
            count: 1,
          },
          {
            word: 'datatapper check pinned',
            count: 1,
          },
        ],
        fear_unigram: [
          {
            word: 'news',
            count: 7,
          },
          {
            word: 'uithemovie',
            count: 5,
          },
          {
            word: 'stockmarketcrash',
            count: 5,
          },
          {
            word: 'saipallavi',
            count: 5,
          },
          {
            word: 'hariharaveeramallu',
            count: 5,
          },
          {
            word: 'elvishyadav',
            count: 5,
          },
          {
            word: 'gamechanger',
            count: 5,
          },
          {
            word: 'kanguva',
            count: 5,
          },
          {
            word: 'fake',
            count: 5,
          },
          {
            word: 'puma',
            count: 4,
          },
          {
            word: 'porsche',
            count: 4,
          },
          {
            word: 'airdrop',
            count: 3,
          },
          {
            word: 'lamelo',
            count: 2,
          },
          {
            word: 'ball',
            count: 2,
          },
          {
            word: 'step',
            count: 2,
          },
          {
            word: 'fast',
            count: 2,
          },
          {
            word: 'lanelink',
            count: 2,
          },
          {
            word: 'httpstcoolcsrsxqxlameloball',
            count: 2,
          },
          {
            word: 'nbanews',
            count: 2,
          },
          {
            word: 'pumapumaandporsche',
            count: 2,
          },
          {
            word: 'thefastlane',
            count: 2,
          },
          {
            word: 'score',
            count: 2,
          },
          {
            word: 'knoxmack',
            count: 1,
          },
          {
            word: 'womanwomanwomam',
            count: 1,
          },
          {
            word: 'forscorn',
            count: 1,
          },
          {
            word: 'leothatguy',
            count: 1,
          },
          {
            word: 'malqadi',
            count: 1,
          },
          {
            word: 'carolinevoelke',
            count: 1,
          },
          {
            word: 'abujasher',
            count: 1,
          },
          {
            word: 'yamadaissei',
            count: 1,
          },
          {
            word: 'butchershook',
            count: 1,
          },
          {
            word: 'muets',
            count: 1,
          },
          {
            word: 'emilytu',
            count: 1,
          },
          {
            word: 'dubaioutca',
            count: 1,
          },
          {
            word: 'batapuma',
            count: 1,
          },
          {
            word: 'mysticmonae',
            count: 1,
          },
          {
            word: 'anacarolbooks',
            count: 1,
          },
          {
            word: 'xskillerr',
            count: 1,
          },
          {
            word: 'callumwilson',
            count: 1,
          },
          {
            word: 'liakeek',
            count: 1,
          },
          {
            word: 'melektayfun',
            count: 1,
          },
          {
            word: 'miriamjablon',
            count: 1,
          },
          {
            word: 'check',
            count: 1,
          },
          {
            word: 'pinned',
            count: 1,
          },
          {
            word: 'thegoathttpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'pumasouthafrica',
            count: 1,
          },
          {
            word: 'pumahoops',
            count: 1,
          },
          {
            word: 'httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'thegoathttpstcomvnum',
            count: 1,
          },
          {
            word: 'event',
            count: 1,
          },
          {
            word: 'future',
            count: 1,
          },
          {
            word: 'miss',
            count: 1,
          },
          {
            word: 'remember',
            count: 1,
          },
          {
            word: 'invitation',
            count: 1,
          },
          {
            word: 'code',
            count: 1,
          },
          {
            word: 'boost',
            count: 1,
          },
          {
            word: 'matrxofficial',
            count: 1,
          },
          {
            word: 'matrxio',
            count: 1,
          },
          {
            word: 'maxscore',
            count: 1,
          },
          {
            word: 'matrx',
            count: 1,
          },
          {
            word: 'sssniperwolf',
            count: 1,
          },
          {
            word: 'wdwnt',
            count: 1,
          },
          {
            word: 'thegoathttpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'thegoathttpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'thegoathttpstcodulysnr',
            count: 1,
          },
        ],
        fear_bigram: [
          {
            word: 'uithemovie stockmarketcrash',
            count: 5,
          },
          {
            word: 'stockmarketcrash saipallavi',
            count: 5,
          },
          {
            word: 'saipallavi hariharaveeramallu',
            count: 5,
          },
          {
            word: 'hariharaveeramallu elvishyadav',
            count: 5,
          },
          {
            word: 'elvishyadav gamechanger',
            count: 5,
          },
          {
            word: 'gamechanger kanguva',
            count: 5,
          },
          {
            word: 'kanguva fake',
            count: 5,
          },
          {
            word: 'fake news',
            count: 5,
          },
          {
            word: 'news lamelo',
            count: 2,
          },
          {
            word: 'lamelo ball',
            count: 2,
          },
          {
            word: 'ball puma',
            count: 2,
          },
          {
            word: 'puma porsche',
            count: 2,
          },
          {
            word: 'porsche step',
            count: 2,
          },
          {
            word: 'step fast',
            count: 2,
          },
          {
            word: 'fast lanelink',
            count: 2,
          },
          {
            word: 'lanelink httpstcoolcsrsxqxlameloball',
            count: 2,
          },
          {
            word: 'httpstcoolcsrsxqxlameloball nbanews',
            count: 2,
          },
          {
            word: 'nbanews porsche',
            count: 2,
          },
          {
            word: 'porsche pumapumaandporsche',
            count: 2,
          },
          {
            word: 'pumapumaandporsche thefastlane',
            count: 2,
          },
          {
            word: 'knoxmack womanwomanwomam',
            count: 1,
          },
          {
            word: 'womanwomanwomam forscorn',
            count: 1,
          },
          {
            word: 'forscorn leothatguy',
            count: 1,
          },
          {
            word: 'leothatguy malqadi',
            count: 1,
          },
          {
            word: 'malqadi carolinevoelke',
            count: 1,
          },
          {
            word: 'carolinevoelke abujasher',
            count: 1,
          },
          {
            word: 'abujasher yamadaissei',
            count: 1,
          },
          {
            word: 'yamadaissei butchershook',
            count: 1,
          },
          {
            word: 'butchershook muets',
            count: 1,
          },
          {
            word: 'muets emilytu',
            count: 1,
          },
          {
            word: 'emilytu dubaioutca',
            count: 1,
          },
          {
            word: 'dubaioutca batapuma',
            count: 1,
          },
          {
            word: 'batapuma mysticmonae',
            count: 1,
          },
          {
            word: 'mysticmonae anacarolbooks',
            count: 1,
          },
          {
            word: 'anacarolbooks xskillerr',
            count: 1,
          },
          {
            word: 'xskillerr callumwilson',
            count: 1,
          },
          {
            word: 'callumwilson liakeek',
            count: 1,
          },
          {
            word: 'liakeek melektayfun',
            count: 1,
          },
          {
            word: 'melektayfun miriamjablon',
            count: 1,
          },
          {
            word: 'miriamjablon check',
            count: 1,
          },
          {
            word: 'check pinned',
            count: 1,
          },
          {
            word: 'news thegoathttpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'puma pumasouthafrica',
            count: 1,
          },
          {
            word: 'pumasouthafrica pumahoops',
            count: 1,
          },
          {
            word: 'pumahoops news',
            count: 1,
          },
          {
            word: 'thefastlane httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'thefastlane httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'news thegoathttpstcomvnum',
            count: 1,
          },
          {
            word: 'score airdrop',
            count: 1,
          },
          {
            word: 'airdrop event',
            count: 1,
          },
          {
            word: 'event future',
            count: 1,
          },
          {
            word: 'future airdrop',
            count: 1,
          },
          {
            word: 'airdrop miss',
            count: 1,
          },
          {
            word: 'miss remember',
            count: 1,
          },
          {
            word: 'remember invitation',
            count: 1,
          },
          {
            word: 'invitation code',
            count: 1,
          },
          {
            word: 'code boost',
            count: 1,
          },
          {
            word: 'boost score',
            count: 1,
          },
          {
            word: 'score matrxofficial',
            count: 1,
          },
          {
            word: 'matrxofficial matrxio',
            count: 1,
          },
          {
            word: 'matrxio maxscore',
            count: 1,
          },
          {
            word: 'maxscore airdrop',
            count: 1,
          },
          {
            word: 'airdrop matrx',
            count: 1,
          },
          {
            word: 'matrx sssniperwolf',
            count: 1,
          },
          {
            word: 'sssniperwolf wdwnt',
            count: 1,
          },
          {
            word: 'wdwnt puma',
            count: 1,
          },
          {
            word: 'news thegoathttpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'news thegoathttpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'news thegoathttpstcodulysnr',
            count: 1,
          },
        ],
        fear_trigram: [
          {
            word: 'uithemovie stockmarketcrash saipallavi',
            count: 5,
          },
          {
            word: 'stockmarketcrash saipallavi hariharaveeramallu',
            count: 5,
          },
          {
            word: 'saipallavi hariharaveeramallu elvishyadav',
            count: 5,
          },
          {
            word: 'hariharaveeramallu elvishyadav gamechanger',
            count: 5,
          },
          {
            word: 'elvishyadav gamechanger kanguva',
            count: 5,
          },
          {
            word: 'gamechanger kanguva fake',
            count: 5,
          },
          {
            word: 'kanguva fake news',
            count: 5,
          },
          {
            word: 'news lamelo ball',
            count: 2,
          },
          {
            word: 'lamelo ball puma',
            count: 2,
          },
          {
            word: 'ball puma porsche',
            count: 2,
          },
          {
            word: 'puma porsche step',
            count: 2,
          },
          {
            word: 'porsche step fast',
            count: 2,
          },
          {
            word: 'step fast lanelink',
            count: 2,
          },
          {
            word: 'fast lanelink httpstcoolcsrsxqxlameloball',
            count: 2,
          },
          {
            word: 'lanelink httpstcoolcsrsxqxlameloball nbanews',
            count: 2,
          },
          {
            word: 'httpstcoolcsrsxqxlameloball nbanews porsche',
            count: 2,
          },
          {
            word: 'nbanews porsche pumapumaandporsche',
            count: 2,
          },
          {
            word: 'porsche pumapumaandporsche thefastlane',
            count: 2,
          },
          {
            word: 'knoxmack womanwomanwomam forscorn',
            count: 1,
          },
          {
            word: 'womanwomanwomam forscorn leothatguy',
            count: 1,
          },
          {
            word: 'forscorn leothatguy malqadi',
            count: 1,
          },
          {
            word: 'leothatguy malqadi carolinevoelke',
            count: 1,
          },
          {
            word: 'malqadi carolinevoelke abujasher',
            count: 1,
          },
          {
            word: 'carolinevoelke abujasher yamadaissei',
            count: 1,
          },
          {
            word: 'abujasher yamadaissei butchershook',
            count: 1,
          },
          {
            word: 'yamadaissei butchershook muets',
            count: 1,
          },
          {
            word: 'butchershook muets emilytu',
            count: 1,
          },
          {
            word: 'muets emilytu dubaioutca',
            count: 1,
          },
          {
            word: 'emilytu dubaioutca batapuma',
            count: 1,
          },
          {
            word: 'dubaioutca batapuma mysticmonae',
            count: 1,
          },
          {
            word: 'batapuma mysticmonae anacarolbooks',
            count: 1,
          },
          {
            word: 'mysticmonae anacarolbooks xskillerr',
            count: 1,
          },
          {
            word: 'anacarolbooks xskillerr callumwilson',
            count: 1,
          },
          {
            word: 'xskillerr callumwilson liakeek',
            count: 1,
          },
          {
            word: 'callumwilson liakeek melektayfun',
            count: 1,
          },
          {
            word: 'liakeek melektayfun miriamjablon',
            count: 1,
          },
          {
            word: 'melektayfun miriamjablon check',
            count: 1,
          },
          {
            word: 'miriamjablon check pinned',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcoenrlfzeh',
            count: 1,
          },
          {
            word: 'puma pumasouthafrica pumahoops',
            count: 1,
          },
          {
            word: 'pumasouthafrica pumahoops news',
            count: 1,
          },
          {
            word: 'pumahoops news lamelo',
            count: 1,
          },
          {
            word: 'pumapumaandporsche thefastlane httpstcontdmqzw',
            count: 1,
          },
          {
            word: 'pumapumaandporsche thefastlane httpstcorhauigdl',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcomvnum',
            count: 1,
          },
          {
            word: 'score airdrop event',
            count: 1,
          },
          {
            word: 'airdrop event future',
            count: 1,
          },
          {
            word: 'event future airdrop',
            count: 1,
          },
          {
            word: 'future airdrop miss',
            count: 1,
          },
          {
            word: 'airdrop miss remember',
            count: 1,
          },
          {
            word: 'miss remember invitation',
            count: 1,
          },
          {
            word: 'remember invitation code',
            count: 1,
          },
          {
            word: 'invitation code boost',
            count: 1,
          },
          {
            word: 'code boost score',
            count: 1,
          },
          {
            word: 'boost score matrxofficial',
            count: 1,
          },
          {
            word: 'score matrxofficial matrxio',
            count: 1,
          },
          {
            word: 'matrxofficial matrxio maxscore',
            count: 1,
          },
          {
            word: 'matrxio maxscore airdrop',
            count: 1,
          },
          {
            word: 'maxscore airdrop matrx',
            count: 1,
          },
          {
            word: 'airdrop matrx sssniperwolf',
            count: 1,
          },
          {
            word: 'matrx sssniperwolf wdwnt',
            count: 1,
          },
          {
            word: 'sssniperwolf wdwnt puma',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcohnneftkuq',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcoypfzkqzast',
            count: 1,
          },
          {
            word: 'fake news thegoathttpstcodulysnr',
            count: 1,
          },
        ],
      },
    },
    cluster_analysis: {
      sentiment_data: {
        'X-axis': [
          -86.3, 107.41, 70.89, -79.94, 39.06, 30.97, -8.56, -44.6, 29.03,
          332.26, 197.57, 39.06, 145.45, 102.79, 39.06, 378.95, -269.71, 332.26,
          378.95, 127.01, 332.26, -356.33, 332.26, -9.37, 332.26, -436.02, 7.95,
          -294.69, 101.72, -436.02, 92.14, -294.69, 46.71, 378.95, -44.6,
          332.26, -264.86, -293.57, 380.26, 157.77, -356.33, 7.95, 7.95, 332.26,
          7.95, 210.7, 7.95, -174.68, 332.26, -218.46, -436.02, -11.81, 22.61,
          -93.17, -356.33, -110.95, 97.89, 79.22, -44.6, 46.71, -218.46,
          -436.02, -193.35, 332.26, -186.61, -197.45, -436.02, -9.37, 30.24,
          89.46, 221.01, 332.26, 208.4, 332.26, 332.26, -218.46, 17.25, -82.69,
          62.12, 197.57, -160.3, -236.58, -443.53, 332.26, 184.39, 193.62,
          -356.33, -218.46, -133.25, 332.26, -218.46, -81.57, -57.26, -117.1,
          -101.69, 332.26, -356.33, 210.08, -177.26, -4.29,
        ],
        'Y-axis': [
          105.87, 261.14, -243.43, 27.59, -190.49, 135.61, -5.36, -219.06,
          156.9, -225.99, -59.9, -190.49, 88.69, -42.75, -190.49, 68.75, -28.48,
          -225.99, 68.75, 66.81, -225.99, 263.56, -225.99, 340.16, -225.99,
          -144.53, -250.19, -4.95, 155.67, -144.53, 272.61, -4.95, -376.38,
          68.75, -219.06, -225.99, -60.55, -53.46, 98.58, 135.01, 263.56,
          -250.19, -250.19, -225.99, -250.19, 188.27, -250.19, 149.12, -225.99,
          -389, -144.53, 156.26, -228.8, -75.87, 263.56, -68.64, 49.69, 42.57,
          -219.06, -376.38, -389, -144.53, 190.35, -225.99, 171.46, -143.93,
          -144.53, 340.16, 186.49, -28.61, 205.88, -225.99, 97.46, -225.99,
          -225.99, -389, 106.18, 161.43, 156.34, -59.9, 74.16, -16.76, -92.83,
          -225.99, 2126.2, 125.94, 263.56, -389, 88.25, -225.99, -389, 141.4,
          99.85, -98.29, 41.77, -225.99, 263.56, 77.68, 64.2, -23.92,
        ],
        sentiment: [
          0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0,
          1, 0, 1, 0, 1, -1, 1, -1, 1, 0, 0, 0, 0, 0, 1, 0, -1, 0, 0, 0, 0, 0,
          -1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 1,
          0, 0, 1, 0, -1, 0, 0, 0, -1, 0, 0, 1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0,
          1, 0, 1, 0, 0, 0, -1, 1, 1,
        ],
        reviews: [
          "@MawrongzJnr Noo..It's a PUMA https://t.co/TrS8FEv7Lh",
          'RT @YashKarnwal27: I ordered a Pair of puma shoes for my father’s birthday gift and what i found was that flipkart delivered both the shoes…',
          '@JimmyThorn48125 @bazbowler1 @KaaIoT @szlaban7 @melizwalls @notwuk @starmakeriseye @cooke_ben12 @gazellassss @akky9n2 @asif_mittal @Creeper11559818 @mhsolis1 @yfk_drags @Port_B @noel_rz @Puma_Lovedragon @SeanClose18 @taniaaa_ss @sakuyafgo @CMillenbine @Slmakab @carollaannee @lilismr Yep bro, you can check in our pinned',
          'Check out Puma Enzo Weave Men. Price Reduced! https://t.co/ClXJoNjNwP #eBay via @eBay',
          '@JavierMcca17583 @sadgirlll09 @donia_makhlouf7 @franckkennedy45 @zahrakrrrm @yongsunnieverse @maitezgz @Wannapa031139 @shotbyamccoy @napalm334 @dallahae @_moorocho @muffinekshawna @mmmehran01 @Puma_Palace @m_aktarli @Leni_kalulu @koechS @maanrole1 @chuka_OG @miaojiang @Libya20176 Yep bro, you can check in our pinned',
          '@AlexLuck9 @Daveisfree1 But if you do a Goldrandlösung you get cool shit like the Puma. 🤩',
          '1. Crown2. IDK DONT STRESS ME OUT3. Freeze4. Your light 5. Dear Sputnik 6. Ghosting7. Back for more 8. Puma https://t.co/uZJMz9RVeQ',
          '@swanson_ma35985 @tqhung268 @RafaelTatis19 @JaspreetkBansal @EhsanIran354273 @JavedAdil12 @nicocarnage @ksimorri @ian_reds @romantickasap @_swerrii @NunesJosito @GIANT24_ @MokgadiVictor @elma_elmasry @isaadansari @ZoNn1bn @Brando_Puma @kl40kl40 @JuventudGh @ColonYuunagi @AlmasNoorA75768 Yep bro, you can check in our pinned',
          '@WallacePJW It’s like a ford puma lol',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          "RT @yeobosbaby: Kookie holding Jinnies jacket..so adorable..🐹🐰'PUMA' ADVERTISING PHOTO SHOOTING BEHIND. 01'30'2016 https://t.co/5RExihi20H",
          '@BowersKamd82701 @1EmpressAura @rafaelars6 @SimgeJkJm @garaku11235813 @hajiskyyy @buttameer1997 @Regret08825406 @top4cop @killadikamal96 @ValorsHero @toocozymarcus @AhsanWahid13 @SSJGoten99 @Mauro120M @something_2000 @BLoversmile @canuluer2 @puma_kun @MerrittWorthy @tarnason_ @Ship10_matusri Yep bro, you can check in our pinned',
          'RT @longie_k: There is a new Nigerian restaurant by Area 18a just before the puma filling station next to Zathu trading. Their jollof and e…',
          '@ShyamMeeraSingh Phone- Apple iPhone,Camera-Nikon, Shoes- SketchersT-Shirt- Zara  Paint- H&amp;M Jacket- PUMA—लेकिन है तू एजेंडाजीवी सुवर',
          '@TimothyCas44727 @CharlieMonroe21 @siir_yuregim @emmamcgilloway @OupsRay @alicliskan @nooruddin02 @omery1978 @Rumournoff @TAMU_Rutkowski @puma_baako @SHO_FUNAKI_ @LollyRatsiu @FastGeraint @Chalxdon @dwderizzo @jonsley7 @DoukaLucy @ayato13592 @NHS_NCLICB @joma24alarimi @abuqaze9 Yep bro, you can check in our pinned',
          'RT @PPaleoartist: just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hi…',
          '@GoalJP_Official @PUMA Super',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @PPaleoartist: just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hi…',
          'Treat Yourself And Yours With The PUMA Valentine’s Day Promo – Just For The Dayhttps://t.co/izsGxeSBh3',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          "RT @pumakorea: Happy Valentine's Day!💕⠀#PUMA #PUMAxKD #KangDaniel #강다니엘 #푸마 #RS_X3 #RS_X_CUBE #RS_X큐브 https://t.co/I8ir574rUv",
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          '@lowe_rio11145 @Thunderdarcy @goodforsmething @GrowStudioHQ @PUMA_98 @TK12569966 @Shimaaa21975186 @UnstoppableBs @Xii68iix @ebondar_com @Konsonanti2 @King_Moye @seamyfarmer @furkan_cobaan @anil1974844 @alirezadini25 @Bhanbhroamjad14 @wirwerdenreich @CleBrun6 @EwingJeremy @TAKO_Oryouri Yep bro, you can check in our pinned',
          '@GoalJP_Official @PUMA Nice',
          'RT @koffi_of_phc7: One Dey him fellow puma global ambassador birthday, the other one Dey rinse and repeat old music. Incoming flop🤣🤣🤣Anyw…',
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          'I ordered a Pair of puma shoes for my father’s birthday gift and what i found was that flipkart delivered both the shoes of right foot in the package. My gift got ruined. They are cancelling the refund as well saying that it was an open box delivery. #fraud #customer #flipkart https://t.co/OFCTEBFrqJ',
          '@GoalJP_Official @PUMA Nice',
          '@CollierWes52497 @deadmansrock @CaraemBH @skunktwine @Mohamme95499672 @Zaki_Zaidi_26 @jamessbutler20 @zdamilaree @RRACARVALHO @ropukdeb @Bankykanta @pbacelar28 @Kidrauhlgirl15 @dookydabarber @oluafolabi @ClaraLaFlore @wigmore_welsh @Aya_Ibrahem9 @lul_puma @RocioWittib @marde_amal_ Yep bro, you can check in our pinned',
          'RT @PPaleoartist: just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hi…',
          '@CalumCrane93132 @gochimin0722 @i2ii_k @yansaladino @RebeccaRadovsk1 @aboabaman @ponkotupyoko @rksharma0312 @f0j8r @Dmirandaaaa @6uhle @_estelle_h @Manjeet77430390 @Karadeniz_34_34 @salifouide @ScalteurBH @puma_tapia666 @meltural @JaySeaMusic @oshio_sleep @EsqueBen @datatapper Yep bro, you can check in our pinned',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '@GoalJP_Official @PUMA Drop yourshttps://t.co/tAL8XjGaCZ',
          '@GoalJP_Official @PUMA Goal Japan so beautiful',
          'just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hippopotamus, Gorilla, Octopus, Puma, etc. all these are both the common and generic names"but some generic names are too long and complex" ...Hippopotamus.',
          'RT @PACBI: After years of BDS campaigning that has cost German conglomerate @PUMA dearly in reputation and projects, we have forced it to a…',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          '@DennisJ68033506 @whoknowsmysoul @kaabuame_ @kCdmsmkecFnGERE @PHRONESIANOS @yR1_sweet @easyfires @keyvanrahmadi @macanth62443119 @VHPeuch @TAKEKITTYSHIT @EsdglobalTech @myramalways @jazzlyn_puma @IIionceau @Nidhi94627108 @leeorocha88 @bushman_ind @wales1988 @murdocklvrr @LokoPeter3 Yep bro, you can check in our pinned',
          '@knox_mack55820 @Womanwomanwomam @ForScorn @leothatguy @malqadi18 @CarolineVoelke9 @Abujasher2 @YAMADAISSEI1111 @ButchersHook69 @mue_t_s @a15183181 @EmilyTu33337173 @DubaiOutca99912 @bata_puma @mysticmonae @anacarolbooks @xSkillerr @Callumwilson13 @liakeek @melektayfun @miriamjablon Yep bro, you can check in our pinned',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '@JohnathanK15766 @JasonV75547709 @abobadralsu @supremedonzeeky @hyraiora @Pelon_manny @iam_omahbella @gregorigregg @hongo_ta898701 @BrentDuke10 @fahmiishak93 @perhermanrud @crazyluis420 @galanoleukos_13 @mariela1marie @N66YK @RoadkillsIndia @puma_699 @mikaillxx @okellodoc @9vYNx0p1MJ7nMl1 Yep bro, you can check in our pinned',
          "I'm skeptical about participating in the MAX Score Airdrop Event for future $MAX airdrop. I doubt you'll regret missing out on this. Don't bother getting an invitation code; it probably won't boost your Score by much anyway. 🚀🎮 @Matr1xOfficial @Matr1x_io #MAXScore #Airdrop #MATR1X @sssniperwolf @WDWNT @PUMA",
          '@RodgersSan39986 @AdazieCallister @filipaclopess @Hobisdimpleee @YavahLns @YarelisRamona @AurallDuck @You096430252571 @Upperuser2 @slimguy07 @bignfuzzy @GPolitics24 @EsraaElzany @BurhanKamal8 @JuanSeb96756428 @ship_puma @EveFerreira10 @oxmar85 @djohnson3373 @YanpingZhang11 @MDeLeonRetired Yep bro, you can check in our pinned',
          'Ad: LIVE in 15minsWomen’s Puma Stewie 2 ‘Mi Amor’=&gt; https://t.co/8TYgduZ7RB*try code sneakers for 10% off https://t.co/2KPgCwAqHo',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/En8R3LFzEH',
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          "Ford has revealed the updated Puma, giving the car greater connectivity and a larger infotainment screen, as well as an enhanced digital driver's display. Find out more in our news story.  https://t.co/zxMArNbs3J https://t.co/xD7ldxpwA4",
          '@DeclanHort9578 @makelelejunior @Alfredo_RenFra @puma_del_monte @McgeeSinead @XXX20687560 @ShibRumour @PatriciaFranco0 @Omar_Sabat @Cheke40809808 @ffanoch @ruanvieira2r @matthewswain582 @Eagle1903NY @OzpolatAptullah @MeredithRose713 @Rahul9669555665 @Niels9067 @_magutaku_0 @KingsNdimele Yep bro, you can check in our pinned',
          '@PUMA @PUMASouthAfrica @PUMAHoops NBA news: LaMelo Ball, PUMA and Porsche step into the fast laneLink: https://t.co/oL5CsRSxqx#LAMELOBALL #NBAnews #Porsche #Puma#PUMAandPorsche #TheFastLane https://t.co/n7TDMQ4z2w',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          'NBA news: LaMelo Ball, PUMA and Porsche step into the fast laneLink: https://t.co/oL5CsRSxqx#LAMELOBALL #NBAnews #Porsche #Puma#PUMAandPorsche #TheFastLane https://t.co/RhAU5I0gdl',
          'RT @thegreysole: Do your day with a dash of Puma flair🧦✨ 🏷️250ghcSend us a dm to place your order https://t.co/p0wu8rQgmC',
          'RT @thegreysole: Puma Socks available for women (pack of 6)🏷️220ghcKindly send us a DM or visit our website https://t.co/Ix9d5ocyvK',
          '@RoachCalla60704 @Jyrago @mingifixsong @majdialbana1 @Tony_Melendez16 @stanby_01 @Shahanna_Young @jokerfiko @sh0wk0yamanaka @kaccccchan1 @luxluc3t @Fetichistaputin @puma_7_rs @AnimeNougat @EliasYusuff @NadaHonor @sazaross @FrwanSham8 @Jonathandkeck @JustSoDamnGood @jmool2009 @Maentina Yep bro, you can check in our pinned',
          '@CollierWes52497 @deadmansrock @CaraemBH @skunktwine @Mohamme95499672 @Zaki_Zaidi_26 @jamessbutler20 @zdamilaree @RRACARVALHO @ropukdeb @Bankykanta @pbacelar28 @Kidrauhlgirl15 @dookydabarber @oluafolabi @ClaraLaFlore @wigmore_welsh @Aya_Ibrahem9 @lul_puma @RocioWittib @marde_amal_ Yep bro, you can check in our pinned',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/m9V2nU303m',
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          "RT @snkr_twitr: AD: ALMOST LIVE via SNSWMNS Jordan Retro 1 High OG 'White/Metallic Gold'W https://t.co/oWffLYFzJxP https://t.co/PQbJAkS…",
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          "AD: ALMOST LIVE via SNSWMNS Jordan Retro 1 High OG 'White/Metallic Gold'W https://t.co/oWffLYFzJxP https://t.co/PQbJAkSDDwT https://t.co/MkGmpurQniWMNS Puma Stewie 2 'Mi Amor' https://t.co/MUvnkYH40YTry code SNEAKERS https://t.co/N38fyMGs43",
          "RT @seiyuu_sunika: Miharu Hanai (花井 美春) x (WMNS) PUMA Mayze 'Thrifted - White Grey'(Ainya’s little sister. Heh) https://t.co/u7PpIHntdN",
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          "RT @pumakorea: Happy Valentine's Day!💕⠀#PUMA #PUMAxKD #KangDaniel #강다니엘 #푸마 #RS_X3 #RS_X_CUBE #RS_X큐브 https://t.co/I8ir574rUv",
          '@petercrouch The guy in the Puma hoodie looks like someone you grabbed off the bar to fill the seat while waiting for Didi Hamann to arrive',
          'RT @gentle_wong: Daejeon Hana🇰🇷2024 Home &amp; Away Ktis by Puma https://t.co/dWrNLkBGoV',
          "I'm in the MAX Score Airdrop Event for future $MAX airdrop! I bet you won't miss this. Remember to get an invitation code, which can boost your Score 10x 🚀🎮  @Matr1xOfficial @Matr1x_io #MAXScore #Airdrop #MATR1X   @sssniperwolf @WDWNT @PUMA",
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '@joachimschulz Fair point. The roll-out (scale-wise) for some reason didn’t seem to befit a GOAT of the sport. Imagine what a Nike, Puma, Adidas would do pulling out all the stops wrt logo, branding, buzz, social etc. This just puts the three decade-long erstwhile partnership w/ Nike into… https://t.co/OG54S5NpdN',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/hnnEFt8KUq',
          'human toilet 🚽 😂🫵🏽 that’s you nasty #pig sitting waiting to collect my piss and shit as a souvenir from your ultimate predator Puma 🐈‍⬛latinadomme femdom sub beta cuck loser white charlotte greensboro raleigh rock hill dmv nc durham brattylatina latinaslut morena thick bbw https://t.co/5Ld93FMM3z https://t.co/YKJx8ufG9T',
          'Puma suede.     @3500kshs📞 0724894850(Size 38---45)Pay after delivery within Nairobi (Pickup mtaani services available) https://t.co/CYXgd1zxlB',
          'RT @gyusphoria: do you like txt bc i want to puma peanits in ur mouth',
          "RT @yeobosbaby: Kookie holding Jinnies jacket..so adorable..🐹🐰'PUMA' ADVERTISING PHOTO SHOOTING BEHIND. 01'30'2016 https://t.co/5RExihi20H",
          'WTS IVE PUMA RM2O EACHMEET AT IVE CONCERTPM ME IF YOU INTERESTED#IVEinKL https://t.co/AP3UzbvP66',
          'I have always been a fan of PUMA, super comfy :) https://t.co/LzzQc0FAIS',
          'PUMA fam ❤️❤️ https://t.co/KCItCy4nua',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          "Miharu Hanai (花井 美春) x (WMNS) PUMA Mayze 'Thrifted - White Grey'(Ainya’s little sister. Heh) https://t.co/u7PpIHntdN",
          'RT @maturenl: Puma Swede, Angel Long, Diamond Fox and Angela Aspen have a kinky pussyeating MILF sexparty and everything is allowed! @matur…',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/ypfZkqzAst',
          '@HLTCO https://t.co/uTM7vb8E3s It’s Puma Hardchorus.. here is the full European version for anyone interested',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/dULY2SN4r8',
          'Quality Puma Tracksuit 🔥Tell us your size!        Dm/WhatsApp: https://t.co/OpBCZGsn1X                  Nationwide delivery 📦Pls help repost. 📷What you see is what you get.✅@Ishow_leck @Jomilojju https://t.co/sJ0FGrKRuI',
          '@Anna_Puma_Kig Peek-a-boo! https://t.co/JNmC6ZNn6I',
          'RT @nicekicks: LaMelo Ball + @PUMA unveil their highly anticipated Porsche collection launching February 17 💫 @nicedrops https://t.co/GrrQ3…',
          'Ad: The Puma Smash 3.0 Leather White is now under retail at the Mandmdirect Sale. Price drop from £51.99 to just £24.99! Link &gt; https://t.co/zAP9JUeHdq WAS: £51.99 Sizes UK-7/8/9/10 https://t.co/wt2CKNauZu',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          '@andysfootyshirt @CadeCoweIIGOAT @ShirtsofNeil You can cook him for unethical consumption but pretending like official Nike puma or adidas isn’t the same garbage is just being dense',
          'WTS IVE PUMA PHOTOCARDRM20 EACHMEET AT IVE CONCERTPOSTAGE RM8🎀DM ME IF YOU WANT TO BUY🎀#IVEinKL #IVE_WORLD_TOUR #pasarive #pasarnewjeans@PasarIveHelp rt guys!! https://t.co/7E25VkEv3v',
          'To my dear duck, puma, and bungeopang, Ni-Ki of Enhypen.As the stars alligned and the dashing vampire appeared, it turned into one of the beautiful man to ever live on earth, my dear duck, puma, and bungeopang, my love and solace, Ni-Ki, i hope you are resting well.+++ https://t.co/JdxqzbWHyo',
        ],
        user_name: [
          'Katlego@NC',
          'DIVYANSH',
          'Francisco Johnston',
          'UniqueBiz100',
          'Kaiser Valenzuela',
          'Stefan Labmonkey',
          'Ash ✧ 🧸ྀིྀི ✧*⋆ฺ࿐',
          'Miller Brock',
          'Ciaran Collins',
          'MissMusk',
          '🍃𝕊𝕙𝕚𝕚𝕟𝔹𝕚𝕚 🐰🍀🍃',
          'Mason O’brien',
          'Call-me mamie',
          'Baba ka bhakt',
          'Dax Patel',
          'Mazzy the MazdaTiger',
          'Aashiq Hussain',
          'Miz J_greatness',
          '🦕🦖Othnielia🦖🦕',
          '2oceansvibe News - South African online news',
          '16🫂',
          'Kakhovka forever',
          'Austin_Ironside 👿',
          '🌟KANG DANIEL🌟',
          '.*.',
          '.*.',
          'Agustin Underwood',
          '🎋🥀🌻🌺 CHANDA 🌻🥀🎋🌺',
          'p..😎',
          'Olayode Blessing',
          'Yash Karnwal',
          'Alhassan Alhassan 💙 De.Fi Army”',
          'Ruben Esquivel',
          '🔥Talha the Piranha🔥 | 🔻',
          'Mateo Hayden',
          'Olatunji Ogeleyinbo😈',
          'ᖴreeThᎥᑎᛕer',
          '🎋🥀🌻🌺 CHANDA 🌻🥀🎋🌺',
          'Petite Paleoartist | Sauriazoicillus 🍥',
          'Apex',
          'Goodguy12345',
          'Milo Zhang',
          'Russell Moon',
          'TGOLD 🫥',
          'Everest Delarosa',
          'Metasuchus (メタスクス）',
          'Zavier Sexton',
          'SOLELINKS',
          'Sunny best👿',
          'Modi Online Bazaar',
          'Brainy 👿',
          'CompleteCar.ie',
          'Conner Ellis',
          'Urban Street Culture',
          'Efthymios Rotsios',
          'Urban Street Culture',
          'thegreysole 🧦',
          'thegreysole 🧦',
          'Israel Heath',
          'Max Keith',
          'Modi Online Bazaar',
          'emmaboy__',
          'MissPiggy',
          'Omar O.B.O',
          'SNKR_TWITR',
          '乙兄',
          'T£DDY 🦁 HUSSLE🖕🏽',
          '케이디_KD 🤘🏻RE8EL🤘🏻(·•︠‿•︡ )',
          'Seppie',
          'AllFootballShirts Official',
          'blist.eth(:D, ;D)☠️blist.lens🏴🫡🚀MATR1XCoin',
          'Fowoplay 😈💚💙',
          'BrandGully',
          'DAVIDO IS BIGGER THAN YOUR FAV🫵',
          'FASHIONISTA🔌',
          'Modi Market Place',
          'PRINCESS PUMA 🐈‍⬛👑',
          'Vee Mwega',
          'andrea ᰔ',
          'Anita👑JINKOOK',
          'ehee?!',
          'Rizza Juanillo',
          'TIO',
          'TIO',
          '声優スニーカー',
          'Love Motorsports ❤',
          'Junsupreme',
          'Modi Online Bazaar',
          'Rob - Never Tory - Always Socialist',
          'Rozapepper 🌍',
          'Modi Market Place',
          'Efozzy Luxury (City Boy)',
          '弾むような Kaddina Hitana Kyuoruto 着ぐるみ',
          '菊池義孝義孝',
          'Captain Creps - Sneaker Deals UK',
          'Timeless Albert Berry👿🌟',
          '꧁ℭ℟Åℤ¥༒₭ÏḼḼ℥℟꧂,',
          'brandon',
          'ehee?!',
          'jino | saw enha for the first time',
        ],
        retweet_count: [
          '',
          '1 people found it useful',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '66 people found it useful',
          '11 people found it useful',
          '',
          '11 people found it useful',
          '',
          '',
          '3 people found it useful',
          '',
          '66 people found it useful',
          '3 people found it useful',
          '',
          '66 people found it useful',
          '10 people found it useful',
          '66 people found it useful',
          '8068 people found it useful',
          '66 people found it useful',
          '5 people found it useful',
          '',
          '',
          '2 people found it useful',
          '5 people found it useful',
          '1 people found it useful',
          '',
          '',
          '3 people found it useful',
          '',
          '66 people found it useful',
          '',
          '',
          '3 people found it useful',
          '11634 people found it useful',
          '10 people found it useful',
          '',
          '',
          '66 people found it useful',
          '',
          '',
          '',
          '',
          '66 people found it useful',
          '',
          '5 people found it useful',
          '',
          '',
          '',
          '10 people found it useful',
          '',
          '50 people found it useful',
          '51 people found it useful',
          '',
          '',
          '',
          '5 people found it useful',
          '1 people found it useful',
          '66 people found it useful',
          '1 people found it useful',
          '1 people found it useful',
          '5 people found it useful',
          '8068 people found it useful',
          '',
          '1 people found it useful',
          '',
          '66 people found it useful',
          '',
          '66 people found it useful',
          '66 people found it useful',
          '',
          '',
          '',
          '2 people found it useful',
          '11 people found it useful',
          '',
          '',
          '5 people found it useful',
          '66 people found it useful',
          '1 people found it useful',
          '15 people found it useful',
          '10 people found it useful',
          '',
          '',
          '66 people found it useful',
          '',
          '',
          '',
          '17 people found it useful',
          '',
          '66 people found it useful',
          '10 people found it useful',
          '',
          '',
          '',
        ],
        date: [
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
        ],
      },
      emotion_data: {
        'X-axis': [
          -86.3, 107.41, 70.89, -79.94, 39.06, 30.97, -8.56, -44.6, 29.03,
          332.26, 197.57, 39.06, 145.45, 102.79, 39.06, 378.95, -269.71, 332.26,
          378.95, 127.01, 332.26, -356.33, 332.26, -9.37, 332.26, -436.02, 7.95,
          -294.69, 101.72, -436.02, 92.14, -294.69, 46.71, 378.95, -44.6,
          332.26, -264.86, -293.57, 380.26, 157.77, -356.33, 7.95, 7.95, 332.26,
          7.95, 210.7, 7.95, -174.68, 332.26, -218.46, -436.02, -11.81, 22.61,
          -93.17, -356.33, -110.95, 97.89, 79.22, -44.6, 46.71, -218.46,
          -436.02, -193.35, 332.26, -186.61, -197.45, -436.02, -9.37, 30.24,
          89.46, 221.01, 332.26, 208.4, 332.26, 332.26, -218.46, 17.25, -82.69,
          62.12, 197.57, -160.3, -236.58, -443.53, 332.26, 184.39, 193.62,
          -356.33, -218.46, -133.25, 332.26, -218.46, -81.57, -57.26, -117.1,
          -101.69, 332.26, -356.33, 210.08, -177.26, -4.29,
        ],
        'Y-axis': [
          105.87, 261.14, -243.43, 27.59, -190.49, 135.61, -5.36, -219.06,
          156.9, -225.99, -59.9, -190.49, 88.69, -42.75, -190.49, 68.75, -28.48,
          -225.99, 68.75, 66.81, -225.99, 263.56, -225.99, 340.16, -225.99,
          -144.53, -250.19, -4.95, 155.67, -144.53, 272.61, -4.95, -376.38,
          68.75, -219.06, -225.99, -60.55, -53.46, 98.58, 135.01, 263.56,
          -250.19, -250.19, -225.99, -250.19, 188.27, -250.19, 149.12, -225.99,
          -389, -144.53, 156.26, -228.8, -75.87, 263.56, -68.64, 49.69, 42.57,
          -219.06, -376.38, -389, -144.53, 190.35, -225.99, 171.46, -143.93,
          -144.53, 340.16, 186.49, -28.61, 205.88, -225.99, 97.46, -225.99,
          -225.99, -389, 106.18, 161.43, 156.34, -59.9, 74.16, -16.76, -92.83,
          -225.99, 2126.2, 125.94, 263.56, -389, 88.25, -225.99, -389, 141.4,
          99.85, -98.29, 41.77, -225.99, 263.56, 77.68, 64.2, -23.92,
        ],
        emotion: [
          'surprise',
          'neutral',
          'joy',
          'joy',
          'neutral',
          'joy',
          'neutral',
          'joy',
          'joy',
          'joy',
          'neutral',
          'neutral',
          'surprise',
          'neutral',
          'neutral',
          'neutral',
          'neutral',
          'joy',
          'neutral',
          'neutral',
          'joy',
          'neutral',
          'joy',
          'joy',
          'joy',
          'neutral',
          'joy',
          'neutral',
          'neutral',
          'neutral',
          'anger',
          'neutral',
          'joy',
          'neutral',
          'sadness',
          'joy',
          'neutral',
          'joy',
          'neutral',
          'anger',
          'neutral',
          'joy',
          'fear',
          'joy',
          'neutral',
          'anger',
          'neutral',
          'neutral',
          'joy',
          'fear',
          'neutral',
          'neutral',
          'neutral',
          'fear',
          'neutral',
          'fear',
          'neutral',
          'neutral',
          'joy',
          'joy',
          'fear',
          'neutral',
          'neutral',
          'joy',
          'neutral',
          'neutral',
          'neutral',
          'joy',
          'surprise',
          'neutral',
          'fear',
          'joy',
          'surprise',
          'joy',
          'joy',
          'fear',
          'anger',
          'neutral',
          'neutral',
          'neutral',
          'anger',
          'joy',
          'neutral',
          'joy',
          'neutral',
          'joy',
          'neutral',
          'fear',
          'neutral',
          'joy',
          'fear',
          'neutral',
          'joy',
          'joy',
          'surprise',
          'joy',
          'neutral',
          'anger',
          'anger',
          'joy',
        ],
        reviews: [
          "@MawrongzJnr Noo..It's a PUMA https://t.co/TrS8FEv7Lh",
          'RT @YashKarnwal27: I ordered a Pair of puma shoes for my father’s birthday gift and what i found was that flipkart delivered both the shoes…',
          '@JimmyThorn48125 @bazbowler1 @KaaIoT @szlaban7 @melizwalls @notwuk @starmakeriseye @cooke_ben12 @gazellassss @akky9n2 @asif_mittal @Creeper11559818 @mhsolis1 @yfk_drags @Port_B @noel_rz @Puma_Lovedragon @SeanClose18 @taniaaa_ss @sakuyafgo @CMillenbine @Slmakab @carollaannee @lilismr Yep bro, you can check in our pinned',
          'Check out Puma Enzo Weave Men. Price Reduced! https://t.co/ClXJoNjNwP #eBay via @eBay',
          '@JavierMcca17583 @sadgirlll09 @donia_makhlouf7 @franckkennedy45 @zahrakrrrm @yongsunnieverse @maitezgz @Wannapa031139 @shotbyamccoy @napalm334 @dallahae @_moorocho @muffinekshawna @mmmehran01 @Puma_Palace @m_aktarli @Leni_kalulu @koechS @maanrole1 @chuka_OG @miaojiang @Libya20176 Yep bro, you can check in our pinned',
          '@AlexLuck9 @Daveisfree1 But if you do a Goldrandlösung you get cool shit like the Puma. 🤩',
          '1. Crown2. IDK DONT STRESS ME OUT3. Freeze4. Your light 5. Dear Sputnik 6. Ghosting7. Back for more 8. Puma https://t.co/uZJMz9RVeQ',
          '@swanson_ma35985 @tqhung268 @RafaelTatis19 @JaspreetkBansal @EhsanIran354273 @JavedAdil12 @nicocarnage @ksimorri @ian_reds @romantickasap @_swerrii @NunesJosito @GIANT24_ @MokgadiVictor @elma_elmasry @isaadansari @ZoNn1bn @Brando_Puma @kl40kl40 @JuventudGh @ColonYuunagi @AlmasNoorA75768 Yep bro, you can check in our pinned',
          '@WallacePJW It’s like a ford puma lol',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          "RT @yeobosbaby: Kookie holding Jinnies jacket..so adorable..🐹🐰'PUMA' ADVERTISING PHOTO SHOOTING BEHIND. 01'30'2016 https://t.co/5RExihi20H",
          '@BowersKamd82701 @1EmpressAura @rafaelars6 @SimgeJkJm @garaku11235813 @hajiskyyy @buttameer1997 @Regret08825406 @top4cop @killadikamal96 @ValorsHero @toocozymarcus @AhsanWahid13 @SSJGoten99 @Mauro120M @something_2000 @BLoversmile @canuluer2 @puma_kun @MerrittWorthy @tarnason_ @Ship10_matusri Yep bro, you can check in our pinned',
          'RT @longie_k: There is a new Nigerian restaurant by Area 18a just before the puma filling station next to Zathu trading. Their jollof and e…',
          '@ShyamMeeraSingh Phone- Apple iPhone,Camera-Nikon, Shoes- SketchersT-Shirt- Zara  Paint- H&amp;M Jacket- PUMA—लेकिन है तू एजेंडाजीवी सुवर',
          '@TimothyCas44727 @CharlieMonroe21 @siir_yuregim @emmamcgilloway @OupsRay @alicliskan @nooruddin02 @omery1978 @Rumournoff @TAMU_Rutkowski @puma_baako @SHO_FUNAKI_ @LollyRatsiu @FastGeraint @Chalxdon @dwderizzo @jonsley7 @DoukaLucy @ayato13592 @NHS_NCLICB @joma24alarimi @abuqaze9 Yep bro, you can check in our pinned',
          'RT @PPaleoartist: just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hi…',
          '@GoalJP_Official @PUMA Super',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @PPaleoartist: just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hi…',
          'Treat Yourself And Yours With The PUMA Valentine’s Day Promo – Just For The Dayhttps://t.co/izsGxeSBh3',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          "RT @pumakorea: Happy Valentine's Day!💕⠀#PUMA #PUMAxKD #KangDaniel #강다니엘 #푸마 #RS_X3 #RS_X_CUBE #RS_X큐브 https://t.co/I8ir574rUv",
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          '@lowe_rio11145 @Thunderdarcy @goodforsmething @GrowStudioHQ @PUMA_98 @TK12569966 @Shimaaa21975186 @UnstoppableBs @Xii68iix @ebondar_com @Konsonanti2 @King_Moye @seamyfarmer @furkan_cobaan @anil1974844 @alirezadini25 @Bhanbhroamjad14 @wirwerdenreich @CleBrun6 @EwingJeremy @TAKO_Oryouri Yep bro, you can check in our pinned',
          '@GoalJP_Official @PUMA Nice',
          'RT @koffi_of_phc7: One Dey him fellow puma global ambassador birthday, the other one Dey rinse and repeat old music. Incoming flop🤣🤣🤣Anyw…',
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          'I ordered a Pair of puma shoes for my father’s birthday gift and what i found was that flipkart delivered both the shoes of right foot in the package. My gift got ruined. They are cancelling the refund as well saying that it was an open box delivery. #fraud #customer #flipkart https://t.co/OFCTEBFrqJ',
          '@GoalJP_Official @PUMA Nice',
          '@CollierWes52497 @deadmansrock @CaraemBH @skunktwine @Mohamme95499672 @Zaki_Zaidi_26 @jamessbutler20 @zdamilaree @RRACARVALHO @ropukdeb @Bankykanta @pbacelar28 @Kidrauhlgirl15 @dookydabarber @oluafolabi @ClaraLaFlore @wigmore_welsh @Aya_Ibrahem9 @lul_puma @RocioWittib @marde_amal_ Yep bro, you can check in our pinned',
          'RT @PPaleoartist: just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hi…',
          '@CalumCrane93132 @gochimin0722 @i2ii_k @yansaladino @RebeccaRadovsk1 @aboabaman @ponkotupyoko @rksharma0312 @f0j8r @Dmirandaaaa @6uhle @_estelle_h @Manjeet77430390 @Karadeniz_34_34 @salifouide @ScalteurBH @puma_tapia666 @meltural @JaySeaMusic @oshio_sleep @EsqueBen @datatapper Yep bro, you can check in our pinned',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '@GoalJP_Official @PUMA Drop yourshttps://t.co/tAL8XjGaCZ',
          '@GoalJP_Official @PUMA Goal Japan so beautiful',
          'just a reminder that extinct animals dont need common names because generic names work perfectly fine as common names, Hippopotamus, Gorilla, Octopus, Puma, etc. all these are both the common and generic names"but some generic names are too long and complex" ...Hippopotamus.',
          'RT @PACBI: After years of BDS campaigning that has cost German conglomerate @PUMA dearly in reputation and projects, we have forced it to a…',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          '@DennisJ68033506 @whoknowsmysoul @kaabuame_ @kCdmsmkecFnGERE @PHRONESIANOS @yR1_sweet @easyfires @keyvanrahmadi @macanth62443119 @VHPeuch @TAKEKITTYSHIT @EsdglobalTech @myramalways @jazzlyn_puma @IIionceau @Nidhi94627108 @leeorocha88 @bushman_ind @wales1988 @murdocklvrr @LokoPeter3 Yep bro, you can check in our pinned',
          '@knox_mack55820 @Womanwomanwomam @ForScorn @leothatguy @malqadi18 @CarolineVoelke9 @Abujasher2 @YAMADAISSEI1111 @ButchersHook69 @mue_t_s @a15183181 @EmilyTu33337173 @DubaiOutca99912 @bata_puma @mysticmonae @anacarolbooks @xSkillerr @Callumwilson13 @liakeek @melektayfun @miriamjablon Yep bro, you can check in our pinned',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '@JohnathanK15766 @JasonV75547709 @abobadralsu @supremedonzeeky @hyraiora @Pelon_manny @iam_omahbella @gregorigregg @hongo_ta898701 @BrentDuke10 @fahmiishak93 @perhermanrud @crazyluis420 @galanoleukos_13 @mariela1marie @N66YK @RoadkillsIndia @puma_699 @mikaillxx @okellodoc @9vYNx0p1MJ7nMl1 Yep bro, you can check in our pinned',
          '@GodnessRin54191 @AxelSauro @DibbleSaharicus @dinomation2 Yes, but even young adults can be taken considering we have a case of a Puma killing a ~2.8 meter Alligator on land.',
          '@RodgersSan39986 @AdazieCallister @filipaclopess @Hobisdimpleee @YavahLns @YarelisRamona @AurallDuck @You096430252571 @Upperuser2 @slimguy07 @bignfuzzy @GPolitics24 @EsraaElzany @BurhanKamal8 @JuanSeb96756428 @ship_puma @EveFerreira10 @oxmar85 @djohnson3373 @YanpingZhang11 @MDeLeonRetired Yep bro, you can check in our pinned',
          'Ad: LIVE in 15minsWomen’s Puma Stewie 2 ‘Mi Amor’=&gt; https://t.co/8TYgduZ7RB*try code sneakers for 10% off https://t.co/2KPgCwAqHo',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/En8R3LFzEH',
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          "Ford has revealed the updated Puma, giving the car greater connectivity and a larger infotainment screen, as well as an enhanced digital driver's display. Find out more in our news story.  https://t.co/zxMArNbs3J https://t.co/xD7ldxpwA4",
          '@DeclanHort9578 @makelelejunior @Alfredo_RenFra @puma_del_monte @McgeeSinead @XXX20687560 @ShibRumour @PatriciaFranco0 @Omar_Sabat @Cheke40809808 @ffanoch @ruanvieira2r @matthewswain582 @Eagle1903NY @OzpolatAptullah @MeredithRose713 @Rahul9669555665 @Niels9067 @_magutaku_0 @KingsNdimele Yep bro, you can check in our pinned',
          '@PUMA @PUMASouthAfrica @PUMAHoops NBA news: LaMelo Ball, PUMA and Porsche step into the fast laneLink: https://t.co/oL5CsRSxqx#LAMELOBALL #NBAnews #Porsche #Puma#PUMAandPorsche #TheFastLane https://t.co/n7TDMQ4z2w',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          'NBA news: LaMelo Ball, PUMA and Porsche step into the fast laneLink: https://t.co/oL5CsRSxqx#LAMELOBALL #NBAnews #Porsche #Puma#PUMAandPorsche #TheFastLane https://t.co/RhAU5I0gdl',
          'RT @thegreysole: Do your day with a dash of Puma flair🧦✨ 🏷️250ghcSend us a dm to place your order https://t.co/p0wu8rQgmC',
          'RT @thegreysole: Puma Socks available for women (pack of 6)🏷️220ghcKindly send us a DM or visit our website https://t.co/Ix9d5ocyvK',
          '@RoachCalla60704 @Jyrago @mingifixsong @majdialbana1 @Tony_Melendez16 @stanby_01 @Shahanna_Young @jokerfiko @sh0wk0yamanaka @kaccccchan1 @luxluc3t @Fetichistaputin @puma_7_rs @AnimeNougat @EliasYusuff @NadaHonor @sazaross @FrwanSham8 @Jonathandkeck @JustSoDamnGood @jmool2009 @Maentina Yep bro, you can check in our pinned',
          '@CollierWes52497 @deadmansrock @CaraemBH @skunktwine @Mohamme95499672 @Zaki_Zaidi_26 @jamessbutler20 @zdamilaree @RRACARVALHO @ropukdeb @Bankykanta @pbacelar28 @Kidrauhlgirl15 @dookydabarber @oluafolabi @ClaraLaFlore @wigmore_welsh @Aya_Ibrahem9 @lul_puma @RocioWittib @marde_amal_ Yep bro, you can check in our pinned',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/m9V2nU303m',
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          "RT @snkr_twitr: AD: ALMOST LIVE via SNSWMNS Jordan Retro 1 High OG 'White/Metallic Gold'W https://t.co/oWffLYFzJxP https://t.co/PQbJAkS…",
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          "AD: ALMOST LIVE via SNSWMNS Jordan Retro 1 High OG 'White/Metallic Gold'W https://t.co/oWffLYFzJxP https://t.co/PQbJAkSDDwT https://t.co/MkGmpurQniWMNS Puma Stewie 2 'Mi Amor' https://t.co/MUvnkYH40YTry code SNEAKERS https://t.co/N38fyMGs43",
          "RT @seiyuu_sunika: Miharu Hanai (花井 美春) x (WMNS) PUMA Mayze 'Thrifted - White Grey'(Ainya’s little sister. Heh) https://t.co/u7PpIHntdN",
          'RT @Tiomoney1308: PUMA fam ❤️❤️',
          "RT @pumakorea: Happy Valentine's Day!💕⠀#PUMA #PUMAxKD #KangDaniel #강다니엘 #푸마 #RS_X3 #RS_X_CUBE #RS_X큐브 https://t.co/I8ir574rUv",
          '@petercrouch The guy in the Puma hoodie looks like someone you grabbed off the bar to fill the seat while waiting for Didi Hamann to arrive',
          'RT @gentle_wong: Daejeon Hana🇰🇷2024 Home &amp; Away Ktis by Puma https://t.co/dWrNLkBGoV',
          "I'm in the MAX Score Airdrop Event for future $MAX airdrop! I bet you won't miss this. Remember to get an invitation code, which can boost your Score 10x 🚀🎮  @Matr1xOfficial @Matr1x_io #MAXScore #Airdrop #MATR1X   @sssniperwolf @WDWNT @PUMA",
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '@joachimschulz Fair point. The roll-out (scale-wise) for some reason didn’t seem to befit a GOAT of the sport. Imagine what a Nike, Puma, Adidas would do pulling out all the stops wrt logo, branding, buzz, social etc. This just puts the three decade-long erstwhile partnership w/ Nike into… https://t.co/OG54S5NpdN',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/hnnEFt8KUq',
          'human toilet 🚽 😂🫵🏽 that’s you nasty #pig sitting waiting to collect my piss and shit as a souvenir from your ultimate predator Puma 🐈‍⬛latinadomme femdom sub beta cuck loser white charlotte greensboro raleigh rock hill dmv nc durham brattylatina latinaslut morena thick bbw https://t.co/5Ld93FMM3z https://t.co/YKJx8ufG9T',
          'Puma suede.     @3500kshs📞 0724894850(Size 38---45)Pay after delivery within Nairobi (Pickup mtaani services available) https://t.co/CYXgd1zxlB',
          'RT @gyusphoria: do you like txt bc i want to puma peanits in ur mouth',
          "RT @yeobosbaby: Kookie holding Jinnies jacket..so adorable..🐹🐰'PUMA' ADVERTISING PHOTO SHOOTING BEHIND. 01'30'2016 https://t.co/5RExihi20H",
          'WTS IVE PUMA RM2O EACHMEET AT IVE CONCERTPM ME IF YOU INTERESTED#IVEinKL https://t.co/AP3UzbvP66',
          'I have always been a fan of PUMA, super comfy :) https://t.co/LzzQc0FAIS',
          'PUMA fam ❤️❤️ https://t.co/KCItCy4nua',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          "Miharu Hanai (花井 美春) x (WMNS) PUMA Mayze 'Thrifted - White Grey'(Ainya’s little sister. Heh) https://t.co/u7PpIHntdN",
          'RT @maturenl: Puma Swede, Angel Long, Diamond Fox and Angela Aspen have a kinky pussyeating MILF sexparty and everything is allowed! @matur…',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/ypfZkqzAst',
          '@HLTCO https://t.co/uTM7vb8E3s It’s Puma Hardchorus.. here is the full European version for anyone interested',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          '#UITheMovie #stockmarketcrash #SaiPallavi #HariHaraVeeraMallu #ElvishYadav #GameChanger #Kanguva Fake News #TheGOAT,https://t.co/dULY2SN4r8',
          'Quality Puma Tracksuit 🔥Tell us your size!        Dm/WhatsApp: https://t.co/OpBCZGsn1X                  Nationwide delivery 📦Pls help repost. 📷What you see is what you get.✅@Ishow_leck @Jomilojju https://t.co/sJ0FGrKRuI',
          '@Anna_Puma_Kig Peek-a-boo! https://t.co/JNmC6ZNn6I',
          'RT @nicekicks: LaMelo Ball + @PUMA unveil their highly anticipated Porsche collection launching February 17 💫 @nicedrops https://t.co/GrrQ3…',
          'Ad: The Puma Smash 3.0 Leather White is now under retail at the Mandmdirect Sale. Price drop from £51.99 to just £24.99! Link &gt; https://t.co/zAP9JUeHdq WAS: £51.99 Sizes UK-7/8/9/10 https://t.co/wt2CKNauZu',
          'RT @MissRozapepper: Davido performing at the birthday party of Athletico Madrid player and fellow PUMA global ambassador -Memphis Depay.  h…',
          'RT @Pion_2S7: TORC-30 is an unmanned turret designed in Brazil, This turret brings any vehicle to a level of firepower comparable to modern…',
          '@andysfootyshirt @CadeCoweIIGOAT @ShirtsofNeil You can cook him for unethical consumption but pretending like official Nike puma or adidas isn’t the same garbage is just being dense',
          'WTS IVE PUMA PHOTOCARDRM20 EACHMEET AT IVE CONCERTPOSTAGE RM8🎀DM ME IF YOU WANT TO BUY🎀#IVEinKL #IVE_WORLD_TOUR #pasarive #pasarnewjeans@PasarIveHelp rt guys!! https://t.co/7E25VkEv3v',
          'To my dear duck, puma, and bungeopang, Ni-Ki of Enhypen.As the stars alligned and the dashing vampire appeared, it turned into one of the beautiful man to ever live on earth, my dear duck, puma, and bungeopang, my love and solace, Ni-Ki, i hope you are resting well.+++ https://t.co/JdxqzbWHyo',
        ],
        user_name: [
          'Katlego@NC',
          'DIVYANSH',
          'Francisco Johnston',
          'UniqueBiz100',
          'Kaiser Valenzuela',
          'Stefan Labmonkey',
          'Ash ✧ 🧸ྀིྀི ✧*⋆ฺ࿐',
          'Miller Brock',
          'Ciaran Collins',
          'MissMusk',
          '🍃𝕊𝕙𝕚𝕚𝕟𝔹𝕚𝕚 🐰🍀🍃',
          'Mason O’brien',
          'Call-me mamie',
          'Baba ka bhakt',
          'Dax Patel',
          'Mazzy the MazdaTiger',
          'Aashiq Hussain',
          'Miz J_greatness',
          '🦕🦖Othnielia🦖🦕',
          '2oceansvibe News - South African online news',
          '16🫂',
          'Kakhovka forever',
          'Austin_Ironside 👿',
          '🌟KANG DANIEL🌟',
          '.*.',
          '.*.',
          'Agustin Underwood',
          '🎋🥀🌻🌺 CHANDA 🌻🥀🎋🌺',
          'p..😎',
          'Olayode Blessing',
          'Yash Karnwal',
          'Alhassan Alhassan 💙 De.Fi Army”',
          'Ruben Esquivel',
          '🔥Talha the Piranha🔥 | 🔻',
          'Mateo Hayden',
          'Olatunji Ogeleyinbo😈',
          'ᖴreeThᎥᑎᛕer',
          '🎋🥀🌻🌺 CHANDA 🌻🥀🎋🌺',
          'Petite Paleoartist | Sauriazoicillus 🍥',
          'Apex',
          'Goodguy12345',
          'Milo Zhang',
          'Russell Moon',
          'TGOLD 🫥',
          'Everest Delarosa',
          'Metasuchus (メタスクス）',
          'Zavier Sexton',
          'SOLELINKS',
          'Sunny best👿',
          'Modi Online Bazaar',
          'Brainy 👿',
          'CompleteCar.ie',
          'Conner Ellis',
          'Urban Street Culture',
          'Efthymios Rotsios',
          'Urban Street Culture',
          'thegreysole 🧦',
          'thegreysole 🧦',
          'Israel Heath',
          'Max Keith',
          'Modi Online Bazaar',
          'emmaboy__',
          'MissPiggy',
          'Omar O.B.O',
          'SNKR_TWITR',
          '乙兄',
          'T£DDY 🦁 HUSSLE🖕🏽',
          '케이디_KD 🤘🏻RE8EL🤘🏻(·•︠‿•︡ )',
          'Seppie',
          'AllFootballShirts Official',
          'blist.eth(:D, ;D)☠️blist.lens🏴🫡🚀MATR1XCoin',
          'Fowoplay 😈💚💙',
          'BrandGully',
          'DAVIDO IS BIGGER THAN YOUR FAV🫵',
          'FASHIONISTA🔌',
          'Modi Market Place',
          'PRINCESS PUMA 🐈‍⬛👑',
          'Vee Mwega',
          'andrea ᰔ',
          'Anita👑JINKOOK',
          'ehee?!',
          'Rizza Juanillo',
          'TIO',
          'TIO',
          '声優スニーカー',
          'Love Motorsports ❤',
          'Junsupreme',
          'Modi Online Bazaar',
          'Rob - Never Tory - Always Socialist',
          'Rozapepper 🌍',
          'Modi Market Place',
          'Efozzy Luxury (City Boy)',
          '弾むような Kaddina Hitana Kyuoruto 着ぐるみ',
          '菊池義孝義孝',
          'Captain Creps - Sneaker Deals UK',
          'Timeless Albert Berry👿🌟',
          '꧁ℭ℟Åℤ¥༒₭ÏḼḼ℥℟꧂,',
          'brandon',
          'ehee?!',
          'jino | saw enha for the first time',
        ],
        retweet_count: [
          '',
          '1 people found it useful',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '66 people found it useful',
          '11 people found it useful',
          '',
          '11 people found it useful',
          '',
          '',
          '3 people found it useful',
          '',
          '66 people found it useful',
          '3 people found it useful',
          '',
          '66 people found it useful',
          '10 people found it useful',
          '66 people found it useful',
          '8068 people found it useful',
          '66 people found it useful',
          '5 people found it useful',
          '',
          '',
          '2 people found it useful',
          '5 people found it useful',
          '1 people found it useful',
          '',
          '',
          '3 people found it useful',
          '',
          '66 people found it useful',
          '',
          '',
          '3 people found it useful',
          '11634 people found it useful',
          '10 people found it useful',
          '',
          '',
          '66 people found it useful',
          '',
          '',
          '',
          '',
          '66 people found it useful',
          '',
          '5 people found it useful',
          '',
          '',
          '',
          '10 people found it useful',
          '',
          '50 people found it useful',
          '51 people found it useful',
          '',
          '',
          '',
          '5 people found it useful',
          '1 people found it useful',
          '66 people found it useful',
          '1 people found it useful',
          '1 people found it useful',
          '5 people found it useful',
          '8068 people found it useful',
          '',
          '1 people found it useful',
          '',
          '66 people found it useful',
          '',
          '66 people found it useful',
          '66 people found it useful',
          '',
          '',
          '',
          '2 people found it useful',
          '11 people found it useful',
          '',
          '',
          '5 people found it useful',
          '66 people found it useful',
          '1 people found it useful',
          '15 people found it useful',
          '10 people found it useful',
          '',
          '',
          '66 people found it useful',
          '',
          '',
          '',
          '17 people found it useful',
          '',
          '66 people found it useful',
          '10 people found it useful',
          '',
          '',
          '',
        ],
        date: [
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
          'Wed, Feb 14 2024',
        ],
      },
    },
  },
  preview_id: 8,
  tenant_id: '1',
  user_id: 2,
  preview_uuid: '202402-1408-2250-09782fa2-1fe9-4c58-b9b3-fffd6d39501a',
  status: 2,
  topic_title: 'Puma shoes',
  channel_name: "['Twitter']",
  description: '',
  languages: '{english}',
  time_frame: 'last 1 month',
  keywords_string: '(  puma )',
  exclusion_string: null,
  location_origin: '',
  location_origin_radius: '0',
  post_country: '{}',
  user_country: '',
};

const topicPreview = async (preview_uuid, tenant_id, user_id) => {
  return tenantAxiosInstance
    .post(API_URL + `${version}/report/preview/${tenant_id}/${user_id}`, {
      preview_uuid: preview_uuid, //'202406-1706-5423-62088a33-5b89-4b42-91d1-e58e03ac6c33'
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const fetch_uuid_for_topicPreview = (body, tenant_id, user_id) => {
  return tenantAxiosInstance
    .post(API_URL + `preview/createtopic/${tenant_id}/${user_id}`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const activeTopicList = (tenant_id, user_id) => {
  return tenantAxiosInstance
    .get(API_URL + `topic/active/list/${tenant_id}/${user_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const createNewTopic = (tenant_id, user_id, body) => {
  return tenantAxiosInstance
    .post(API_URL + `topic/create/${tenant_id}/${user_id}`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const fetchCreatedTopic = (tenant_id, job_id) => {
  return tenantAxiosInstance
    .post(API_URL + `${version}/report/job/${tenant_id}/${job_id}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const reviewsFilterTopic = (tenant_id, job_id, dates) => {
  /* const jobId = localStorage.getItem('jobId');  */
  return tenantAxiosInstance
    .post(API_URL + `${version}/reviews/job/${tenant_id}/${job_id}`, dates)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const reviewsFilterTopicPreview = (tenant_id, preview_uuid, dates) => {
  /* const jobId = localStorage.getItem('jobId');  */
  return tenantAxiosInstance
    .post(API_URL + `${version}/reviews/preview/${tenant_id}/${preview_uuid}`, dates)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const reviewsForTopic = async (tenant_id, user_id, preview_uuid) => {
  return tenantAxiosInstance
  
    .post(API_URL + `${version}/reviews/preview/${tenant_id}/${preview_uuid}`, {})
    // .post(API_URL + `${version}/reviews/job/${tenant_id}/${preview_uuid}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const reviewsForDownload = async (tenant_id,job_id,email_id) => {
  return tenantAxiosInstance
  
  .post(API_URL + `${version}/reviews/job/${tenant_id}/${job_id}`, {})
    // .get(API_URL + `report/reviews/${tenant_id}/${job_id}/${email_id}/`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

const reviewsForCreatedTopic = async (tenant_id, job_id) => {
  return tenantAxiosInstance
    .post(API_URL + `${version}/reviews/job/${tenant_id}/${job_id}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const TopicHibernate = (tenant_id, user_id, job_id) => {
  return tenantAxiosInstance
    .delete(API_URL + `topic/hibernate/${tenant_id}/${user_id}/${job_id}`)
    .then((response) => {
      return response.data;
    });
};

const TopicDelete = (tenant_id, user_id, job_id) => {
  return tenantAxiosInstance
    .delete(
      API_URL + `topic/permanentdelete/${tenant_id}/${user_id}/${job_id}`
    )
    .then((response) => {
      return response.data;
    });
};

const TopicActive = (tenant_id, user_id, job_id) => {
  return tenantAxiosInstance
    .put(API_URL + `topic/hibernatetoactive/${tenant_id}/${user_id}/${job_id}`)
    .then((response) => {
      return response.data;
    });
};

const GetReportBasedOnDate = (startDate, endDate, tenant_id, job_id) => {
  return tenantAxiosInstance
    .post(
      API_URL + `${version}/report/job/${tenant_id}/${job_id}`,
      {
        start_date: startDate,
        end_date: endDate,
      }
    )
    .then((response) => {
      return response.data;
    });
};

const GetQueryPromptData = (prompt, channel_name) => {

  const brandUser = JSON.parse(localStorage.getItem('brandUser'))

  const tenant_id = brandUser?.tenant_id

  const user_id = brandUser?.user_details.user_id

  return tenantAxiosInstance
    .post(
      API_URL + `preview_topic/ai/draft/${tenant_id}/${user_id}`,
      {
        "prompt": prompt,
        "channel_name": channel_name
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${brandUser?.access_token}`
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};


const PreviewAiData = (inputData) => {

  const brandUser = JSON.parse(localStorage.getItem('brandUser'))

  const tenant_id = brandUser?.tenant_id

  const user_id = brandUser?.user_details.user_id

  return tenantAxiosInstance
    .post(
      API_URL + `preview/createtopic/ai/${tenant_id}/${user_id}`,
      {

        title: inputData?.title,
        channel_name: inputData?.channel_name,
        description: inputData?.description,
        prompt: inputData?.prompt,
        draft: inputData?.draft,
        keywords_string: inputData?.keywords_string,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${brandUser?.access_token}`
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};


const TopicService = {
  topicPreview,
  reviewsForDownload,
  reviewsForTopic,
  fetch_uuid_for_topicPreview,
  activeTopicList,
  fetchCreatedTopic,
  createNewTopic,
  reviewsForCreatedTopic,
  TopicHibernate,
  TopicDelete,
  TopicActive,
  GetReportBasedOnDate,
  GetQueryPromptData,
  PreviewAiData,
  reviewsFilterTopic,
  reviewsFilterTopicPreview
};

export default TopicService;
