
import styled from 'styled-components';


export const Wrapper = styled.div`
  padding: 30px;

  & .title {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
  }
`;

export const TopHashtagsWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  &> p: first-child {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    margin: 10px;
  }
`;

export const TopRepostWrapper = styled.div`
  margin-top: 30px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  align-items: center;
`;

export const FilteredGraphContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 50px;
  align-items: center;
`;

export const PostCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  padding: 0 18px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3em;
  }
`;
export const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;

  border: 2px solid #fff;
`;

export const PositiveCircle = styled(Circle)`
  background: #5ce390;
`;

export const NeutralCircle = styled(Circle)`
  background: #f5cc61;
`;

export const NegativeCircle = styled(Circle)`
  background: #fc6758;
`;

export const TopPostWrapper = styled.div`
  & > div {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 10px 86% 10%;
    align-items: center;
    gap: 1em;
    border-radius: 15px;
    padding: 20px;

    .reviewText {
    
    word-wrap: break-word;
      flex: 1;
      border-right: 1px solid #d9d9d9;
      padding: 0 10px;
    }
    .reviewCount {
      // width: 10%;
      text-align: center;

      .peopleLiked {
        font-size: 10px;
      }
    }
  }

  .positiveReview {
    background: rgba(92, 227, 144, 0.2);
  }

  .negativeReview {
    background: rgba(249, 98, 89, 0.18);
  }

  .neutralReview {
    background: rgba(254, 222, 141, 0.37);
  }
`;

export const SentimentDrilldownWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  padding-top: 0px;
  align-items: center;
`;

export const TopPostTopicWrapper = styled.div`
  margin-left: 40px;

  .topicList {
    padding-left: 35px;
    padding-bottom: 10px;
  }

  .positiveTopic {
    color: #40bb72;

    &:before {
      background: linear-gradient(180deg, #a1fcc6 0%, #5fe495 100%);
    }
  }
  .neutralTopic {
    color: #dfae30;
    &:before {
      background: linear-gradient(180deg, #ffe092 0%, #f9d16d 100%);
    }
  }
  .negativeTopic {
    color: #fc615a;
    &:before {
      background: linear-gradient(180deg, #ff8c86 0%, #fa6961 100%);
    }
  }
`;

export const TopPostTopicHeading = styled.div`
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 20px;

  &:before {
    display: inline-block;
    content: '';
    width: 17px;
    height: 17px;
  }
`;

export const SelectWrapper = styled.div`
  width: 100px;
  padding: 20px 0 10px 35px;
`;

export const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 30px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 10px;
  }
`;
