import React, { Children } from 'react';
import styled from 'styled-components';
import { DeleteIcon } from '../SvgIcon/CommonSvgIcons';
import Button from '../Button/Button';

const BlackDrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0%;
  left: 0%;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1.5px);
  z-index: 10;
`;

const Content = styled.div`
  position: fixed;
  top: 50%;
  right: 15%;
  transform: translate(50%, -50%);
  width: 100%;
  height: 100%;
  /* max-width: 80%;
  max-height: 80%; */
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 15px;
  z-index: 10;
  padding: 40px 0 20px 0;
  color: #636363;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
`;

const PopUp = ({ onClose, children }) => {
  return (
    <>
      <BlackDrop onClick={onClose} />
      <Content>{children}</Content>
    </>
  );
};

export default PopUp;
