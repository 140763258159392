import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prerviewData:""
};

const previewSlice = createSlice({
  name: 'previewSlice',
  initialState,
  reducers: {
    setChatterPreviewData: (state, action) => {
        state.prerviewData = action.payload;
    },
    clearChatterPreviewData: (state) => {
        state.prerviewData = '';
    },
  },
});

const { reducer, actions } = previewSlice;

export const { setChatterPreviewData, clearChatterPreviewData } = actions;
export default reducer;